import type { FlowLayoutNode } from '../components'
import { STOP_ACTION_PROPAGATION } from '../constants'

/**
 * 检查动作事件是否应停止触发
 * @param ev event
 * @returns {boolean} true触发/false不触发
 */
export function checkActionEvent(ev: React.MouseEvent) {
    const path = ev.nativeEvent.composedPath()
    const currentDomIndex = path.indexOf(ev.currentTarget)
    // 渲染在根节点之外（portal）
    if (currentDomIndex === -1) {
        return false
    }

    const slice = path.slice(0, currentDomIndex)
    return !slice.some(item => (item instanceof HTMLElement ? item.hasAttribute(STOP_ACTION_PROPAGATION) : false))
}
