import type {
    ApplicationSettingThemeColor,
    BackgroundNoImageProtocol,
    BackgroundProtocol,
    Border,
    EdgeValue,
    Shadow,
    VariableADTvalue,
    Veins
} from '@lighthouse/core'
import { BACKGROUND_TYPE, EDGE_MODE } from '@lighthouse/core'

import { applyFillPickerValue4CssColorValue, getGradientCssText } from '../components/FillPicker/utils'
import { getImageFullUrlInApplication } from './image'
import { VEINS_OPTIONS } from './veins'

/** 获取文本的颜色样式 */
export function getTextColorStyle(
    color: BackgroundNoImageProtocol | undefined,
    palettes: ApplicationSettingThemeColor[]
): React.CSSProperties {
    if (!color) {
        return {}
    }

    if (color.type === BACKGROUND_TYPE.gradient) {
        if (color.gradient) {
            return {
                backgroundImage: getGradientCssText(color.gradient, palettes),
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
            } satisfies React.CSSProperties
        }
        return {}
    }

    return { color: applyFillPickerValue4CssColorValue(color.color, palettes) }
}

/** 获取背景样式 */
export function getBackgroundStyle(
    appId: string | undefined,
    value: BackgroundProtocol | undefined,
    parseVariableImage: undefined | ((v: VariableADTvalue) => string),
    palettes: ApplicationSettingThemeColor[]
): React.CSSProperties {
    if (!value) {
        return {}
    }

    if (value.type === BACKGROUND_TYPE.color) {
        return { backgroundColor: applyFillPickerValue4CssColorValue(value.color, palettes) }
    }
    if (value.type === BACKGROUND_TYPE.gradient && value.gradient) {
        return {
            backgroundImage: getGradientCssText(value.gradient, palettes),
            backgroundSize: '100% 100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat'
        }
    }
    if (value.type === BACKGROUND_TYPE.image && value.image) {
        return {
            backgroundImage: `url(${getImageFullUrlInApplication(appId, parseVariableImage?.(value.image.resource))})`,
            backgroundSize: value.image.fit,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat'
        }
    }

    return {}
}

/** 获取纹理样式 */
export function getVeinsStyle(veins: Veins | undefined, palettes: ApplicationSettingThemeColor[]): React.CSSProperties {
    if (!veins) {
        return {}
    }
    const veinsConfigure = VEINS_OPTIONS.find(item => item.value === veins.value)
    if (!veinsConfigure) {
        return {}
    }

    let { raw } = veinsConfigure
    if (veins.color) {
        const color = applyFillPickerValue4CssColorValue(veins.color, palettes) || '#000'
        raw = raw.replaceAll('currentColor', color)
        raw = raw.replaceAll(/stroke="#\w+"/g, `stroke="${color}"`)
    }
    if (veins.strokeWidth) {
        raw = raw.replaceAll(/stroke-width="[\w.]+"/g, `stroke-width="${veins.strokeWidth}px"`)
        // eslint-disable-next-line unicorn/no-unsafe-regex
        raw = raw.replaceAll(/<circle(.+\s*)+\/>/g, match => match.replaceAll(/r="[\w%.]+"/g, `r="${veins.strokeWidth}%"`))
        // eslint-disable-next-line unicorn/no-unsafe-regex
        raw = raw.replaceAll(/<rect(.+\s*)+\/>/g, match => match.replaceAll(/(width|height)="[\w%.]+"/g, `$1="${veins.strokeWidth}%"`))
    }

    return {
        backgroundImage: `url('data:image/svg+xml;charset=utf8,${encodeURIComponent(raw)}')`,
        backgroundSize: `${Math.ceil((512 / 100) * (veins.size || 100))}px`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'repeat'
    }
}

/**
 * 合并背景样式
 * @param styles
 * @returns
 */
export function combineBackgroundStyle(styles: React.CSSProperties[]): React.CSSProperties {
    const backgroundColor = styles.find(item => item.backgroundColor)?.backgroundColor
    return {
        backgroundColor,
        backgroundImage: styles
            .map(item => item.backgroundImage)
            .filter(Boolean)
            .join(','),
        backgroundSize: styles
            .map(item => item.backgroundSize)
            .filter(Boolean)
            .join(','),
        backgroundPosition: styles
            .map(item => item.backgroundPosition)
            .filter(Boolean)
            .join(','),
        backgroundRepeat: styles
            .map(item => item.backgroundRepeat)
            .filter(Boolean)
            .join(',')
    }
}

/** 获取内边距样式 */
export function getPaddingStyle(padding: EdgeValue | undefined): React.CSSProperties {
    const [paddingLeft = 0, paddingTop = 0, paddingRight = 0, paddingBottom = 0] = padding || []

    return { padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px` }
}

/** 获取阴影样式 */
export function getShadowStyle(shadow: Shadow | undefined, palettes: ApplicationSettingThemeColor[]): React.CSSProperties {
    const { color, x: shadowOffsetX = 0, y: shadowOffsetY = 0, blur: shadowBlur = 0, diffusion: shadowDiffusion = 0 } = shadow ?? {}

    const shadowColor = color && applyFillPickerValue4CssColorValue(color, palettes)

    return { boxShadow: shadowColor && `${shadowOffsetX}px ${shadowOffsetY}px ${shadowBlur}px ${shadowDiffusion}px ${shadowColor}` }
}

/** 获取圆角样式 */
export function getRadiusStyle(value: EdgeValue | undefined): React.CSSProperties {
    if (!value) {
        return {}
    }

    const [lt, rt, lb, rb] = value
    return { borderRadius: `${lt || 0}px  ${rt || 0}px ${rb || 0}px ${lb || 0}px` }
}

/** 获取边框样式 */
export function getBorderStyle(border: Border | undefined, palettes: ApplicationSettingThemeColor[]): React.CSSProperties {
    const { type: borderType, color, mode: borderMode, all: borderAll, each: borderEach } = border ?? {}
    const [borderLeft = 0, borderTop = 0, borderRight = 0, borderBottom = 0] =
        borderMode === EDGE_MODE.each ? borderEach || [] : Array.from({ length: 4 }).map(() => borderAll || 0)

    const borderColor = color && applyFillPickerValue4CssColorValue(color, palettes)

    if (!borderColor) {
        return {}
    }
    return {
        borderLeft: borderLeft ? `${borderLeft}px solid ${borderColor}` : undefined,
        borderTop: borderTop ? `${borderTop}px solid ${borderColor}` : undefined,
        borderRight: borderRight ? `${borderRight}px solid ${borderColor}` : undefined,
        borderBottom: borderBottom ? `${borderBottom}px solid ${borderColor}` : undefined
    }
}
