import React, { useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useFlowLayoutContext } from '../Context'
import { DOM_DATA_NODE_NAME, DOM_DATA_PARENT_NAME, findNodeById } from '../Interaction/utils'
import type { FlowLayoutNode } from '../types'

const Padding = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(85, 81, 255, 0.12);
    pointer-events: none;
`

interface Props {
    id: string
    paddingLeft?: boolean
    paddingTop?: boolean
    paddingRight?: boolean
    paddingBottom?: boolean
}

export const NodePaddingIndicator = ({ id, paddingLeft, paddingTop, paddingRight, paddingBottom }: Props) => {
    const { data, rootElement, scale = 1, layout: rootLayout } = useFlowLayoutContext()
    if (!rootElement) {
        return null
    }

    const rootRect = rootElement.getBoundingClientRect()

    const isRoot = id === 'root'
    if (isRoot) {
        const [pl = 0, pt = 0, pr = 0, pb = 0] = rootLayout?.padding ?? []
        return (
            <>
                {paddingLeft && (
                    <Padding
                        style={{
                            width: pl / scale,
                            height: rootRect.height / scale,
                            transform: `translate3d(0px, 0px, 0)`
                        }}
                    />
                )}
                {paddingTop && (
                    <Padding
                        style={{
                            width: rootRect.width / scale,
                            height: pt / scale,
                            transform: `translate3d(0px, 0px, 0)`
                        }}
                    />
                )}
                {paddingRight && (
                    <Padding
                        style={{
                            width: pr / scale,
                            height: rootRect.height / scale,
                            transform: `translate3d(${(rootRect.width - pr) / scale}px, 0px, 0)`
                        }}
                    />
                )}
                {paddingBottom && (
                    <Padding
                        style={{
                            width: rootRect.width / scale,
                            height: pb / scale,
                            transform: `translate3d(0px, ${(rootRect.height - pb) / scale}px, 0)`
                        }}
                    />
                )}
            </>
        )
    }

    return (
        <DefaultPaddingIndicator
            id={id}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            paddingTop={paddingTop}
            data={data}
            root={rootElement}
            scale={scale}
        />
    )
}

function getContainer(root: HTMLElement, node: FlowLayoutNode) {
    if (node.type === 'custom') {
        const viewElm = root.querySelector(`[${DOM_DATA_NODE_NAME}="${node.id}"]`)
        if (!viewElm) {
            return
        }

        return viewElm.querySelector(`[${DOM_DATA_PARENT_NAME}="${node.id}"]`)
    }

    return root.querySelector(`[${DOM_DATA_NODE_NAME}="${node.id}"]`)
}

interface DefaultPaddingIndicatorProps extends Props {
    root: HTMLElement
    data: FlowLayoutNode[]
    scale: number
}
function DefaultPaddingIndicator({
    id,
    data,
    root,
    scale,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop
}: DefaultPaddingIndicatorProps) {
    const node = useMemo(() => findNodeById(id)(data), [data, id])

    const padding = node?.data?.layout?.padding

    const [pl = 0, pt = 0, pr = 0, pb = 0] = padding ?? []

    const [containerRect, setContainerRect] = useState<DOMRectReadOnly | null>(null)
    useLayoutEffect(() => {
        if (!node) {
            return
        }
        const container = getContainer(root, node)
        if (!container) {
            return
        }
        const observer = new ResizeObserver(([e]) => {
            setContainerRect(e.target.getBoundingClientRect())
        })

        observer.observe(container)

        return () => {
            observer.unobserve(container)
        }
    }, [node, root])

    if (!containerRect) {
        return null
    }

    const rootRect = root.getBoundingClientRect()

    return (
        <>
            {paddingLeft && (
                <Padding
                    style={{
                        width: pl / scale,
                        height: containerRect.height / scale,
                        transform: `translate3d(${(-rootRect.left + containerRect.left) / scale}px, ${
                            (-rootRect.top + containerRect.top) / scale
                        }px, 0)`
                    }}
                />
            )}
            {paddingTop && (
                <Padding
                    style={{
                        width: containerRect.width / scale,
                        height: pt / scale,
                        transform: `translate3d(${(-rootRect.left + containerRect.left) / scale}px, ${
                            (-rootRect.top + containerRect.top) / scale
                        }px, 0)`
                    }}
                />
            )}
            {paddingRight && (
                <Padding
                    style={{
                        width: pr / scale,
                        height: containerRect.height / scale,
                        transform: `translate3d(${(-rootRect.left + containerRect.right - pr) / scale}px, ${
                            (-rootRect.top + containerRect.top) / scale
                        }px, 0)`
                    }}
                />
            )}
            {paddingBottom && (
                <Padding
                    style={{
                        width: containerRect.width / scale,
                        height: pb / scale,
                        transform: `translate3d(${(-rootRect.left + containerRect.left) / scale}px, ${
                            (-rootRect.top + containerRect.bottom - pb) / scale
                        }px, 0)`
                    }}
                />
            )}
        </>
    )
}
