import type { Option } from '@byecode/ui'
import { Empty, IconFont, Image, MobileModal, Text, tinyButtons } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationSettingNavbarButton } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../../contexts'
import { ApplicationLinkItem } from '../../ApplicationLinkItem'

interface MobileLanguageBoxProps {
    options: Option[]
    option?: Option
    value: string
    showLabel?: boolean
    color: string
    style?: React.CSSProperties
    onChange: (v: string) => void
}
const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`
const SCxTagList = styled.div`
    flex: 1;
    overflow-y: auto;
    ${tinyButtons}
    width: 100%;
`

const SCxTagWrapper = styled.div`
    height: auto;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    overflow: hidden;
`

const SCxTagItem = styled.div<{ isShowBorder?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 16px;
    color: var(--color-black);
    border-bottom: 1px solid var(--color-gray-200);
    background: var(--color-white);

    :last-child {
        border: none;
    }
`

export const MobileLanguageBox: React.FunctionComponent<MobileLanguageBoxProps> = ({
    option,
    value,
    options,
    showLabel,
    color,
    style,
    onChange
}) => {
    const [opened, setOpened] = useState(false)
    const { pageTarget, isBuilder } = useApplicationContext()

    const btnConfig: ApplicationSettingNavbarButton = useMemo(
        () => ({
            id: nanoid(),
            name: typeof option?.label === 'string' ? option?.label : '',
            showType: showLabel ? 'icon-name' : 'icon',
            events: {
                handleEvent: 'openPage',
                triggerEvent: 'click',
                params: ['']
            },
            action: {
                type: 'none',
                params: {},
                trigger: 'click',
                data: {
                    none: {}
                }
            },
            child: [],
            icon: 'Earth'
        }),
        [option?.label, showLabel]
    )

    useEffect(() => {
        // 解决切换到数据源或其他无页面内容时，弹窗未关闭
        if (isBuilder && !pageTarget) {
            setOpened(false)
        }
    }, [isBuilder, pageTarget])

    return (
        <>
            <ApplicationLinkItem
                value={btnConfig}
                showRightSection
                opened={opened}
                isAction={opened}
                style={style}
                onCommonCallBack={e => {
                    setOpened(true)
                }}
            />
            <MobileModal
                data-ignore-click-away
                target={pageTarget}
                title="选择语言"
                open={opened}
                styles={{
                    modal: {
                        height: '50%'
                    }
                }}
                onClose={() => {
                    setOpened(false)
                }}
            >
                <SCxContainer>
                    {options.length > 0 ? (
                        <SCxTagList>
                            <SCxTagWrapper>
                                {options.map(item => (
                                    <SCxTagItem
                                        key={item.value}
                                        onClick={() => {
                                            onChange(item.value)
                                            setOpened(false)
                                        }}
                                        style={{ lineHeight: '32px', padding: '0px 16px', height: 32 }}
                                    >
                                        <Text>{item.label}</Text>
                                        {option?.value === item.value && <IconFont color="var(--color-app-main)" size={16} type="Tick" />}
                                    </SCxTagItem>
                                ))}
                            </SCxTagWrapper>
                        </SCxTagList>
                    ) : (
                        <Empty
                            styles={{
                                root: {
                                    padding: '28px 24px',
                                    color: 'var(--color-gray-500)'
                                },
                                wrapper: {
                                    gap: 20
                                }
                            }}
                            icon={<Image width={123} height={123} src={getAssetUrl('empty', 'empty_language_setting.jpg')} />}
                            description={
                                <Text whiteSpace="pre-wrap">
                                    如果您想让页面支持切换多语言版本，请到“<b>应用设置</b> {'>'} <b>语言版本</b>”添加语言版本
                                </Text>
                            }
                        />
                    )}
                </SCxContainer>
            </MobileModal>
        </>
    )
}
