import { Toast } from '@lighthouse/bui'
import type { FieldADTValue, FieldCellValue, FieldInputADTValue, InputValueItem, ViewRecordOperateProtocol } from '@lighthouse/core';
import { type FieldBlockAbstract, PAGE_TYPE , RecordOpenType } from '@lighthouse/core'
import {
    type ApplicationPreviewEnum,
    aliyunVideoProtocolList,
    avatarMaxFileSize,
    fileMaxUploadSize,
    fileSuffixRegex,
    getEmptyFieldInputValue,
    getFieldInputError,
    getFileSizeToMB,
    getFileTypeByFileName,
    getPrimaryDataSourceEnableFieldIds,
    getUrlNameByOpenType,
    isEmptyCellValue,
    pageStackPubSub,
    useAtomAction,
    useAtomData,
    useFormModuleContext
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { languageAtom } from '@/atoms/application/state'
import { updateCellAtom } from '@/atoms/dataSource/action'
import { pageAtomFamily, pageStackOfFieldBlockAtom, pageStackOfFormContainerBlockChangedAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { useFieldInputRenderLabel, useParentRecord } from '@/hooks/useParentRecord'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

const FieldBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.FieldBlock })))

interface FieldBlockControllerProps {
    blockData: FieldBlockAbstract
    previewType?: ApplicationPreviewEnum
}

const FieldBlockController: React.FC<FieldBlockControllerProps> = ({ blockData: fieldBlockData }) => {
    const { id: blockId, config } = fieldBlockData
    const { inputType, fieldPointer = '', required, canEdit } = config

    const { t } = useTranslation()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const previewType = usePreview()
    const navigate = useNavigate()
    const dataSourceList = useDataSourceList(appId)
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const formModule = useFormModuleContext()
    const onRenderLabel = useFieldInputRenderLabel()

    const { run: setPageStackOfFieldBlockAtom } = useAtomAction(pageStackOfFieldBlockAtom)
    const { run: setPageStackOfFormContainerBlockChangedAtom } = useAtomAction(pageStackOfFormContainerBlockChangedAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)

    const language = useAtomData(languageAtom)
    const [pageName, pageType] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.name ?? '', s?.type ?? ''], [])
    )
    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )

    const {
        isValid = true,
        errors,
        pointer,
        defaultValue: { inputList = [] },
        onChange: formOnChange
    } = formModule ?? {}

    const { record, dataSource: parentDataSource, initRecord } = useParentRecord()

    const formDataSource = useDataSource(appId, envId, pointer)

    const dataSource = formDataSource || parentDataSource

    const field = useMemo(() => dataSource?.schema[fieldPointer], [dataSource?.schema, fieldPointer])

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const joinFieldCanEdit = useMemo(() => {
        if (pageType === PAGE_TYPE.creator || pageType === PAGE_TYPE.edit) {
            return primaryDataSourceFieldIds?.has(fieldPointer)
        }
        return true
    }, [pageType, fieldPointer, primaryDataSourceFieldIds])

    const blockData = useMemo(
        () => ({ ...fieldBlockData, config: { ...config, canEdit: canEdit && joinFieldCanEdit } }),
        [fieldBlockData, config, canEdit, joinFieldCanEdit]
    )

    const fieldContent = useMemo(() => {
        if (!record) {
            return
        }
        return record.content?.[fieldPointer]?.value
    }, [fieldPointer, record])

    const [isValidFieldInput, setIsValidFieldInput] = useState(false)
    const [currentValue, setCurrentValue] = useState<FieldCellValue>('')
    const fieldError = useMemo(
        () =>
            field && isValidFieldInput
                ? getFieldInputError({
                      value: { value: currentValue, type: inputType } as FieldInputADTValue,
                      rule: {
                          required: {
                              label: '不能为空',
                              value: required ?? false
                          }
                      },
                      fieldType: field.type
                  })
                : undefined,
        [currentValue, field, inputType, isValidFieldInput, required]
    )

    const formCellValue = useMemo(() => find(item => item.id === blockId, inputList), [blockId, inputList])

    const { value, initValue, error } = useMemo(() => {
        const emptyValue = getEmptyFieldInputValue(inputType, field?.type)
        const initCellContent = find(item => item.id === blockId, formModule?.defaultValue?.initInputList ?? [])
        const initFieldCellContent = initRecord?.content?.[fieldPointer]?.value ?? emptyValue
        return formModule?.type === 'form'
            ? {
                  value: formCellValue?.value,
                  initValue: initCellContent?.value ?? emptyValue,
                  error: errors?.[blockId]
              }
            : {
                  value: fieldContent,
                  initValue: initFieldCellContent,
                  error: fieldError
              }
    }, [
        inputType,
        field?.type,
        formModule?.defaultValue?.initInputList,
        formModule?.type,
        initRecord?.content,
        fieldPointer,
        formCellValue?.value,
        errors,
        blockId,
        fieldContent,
        fieldError
    ])

    useEffect(() => {
        setCurrentValue(initValue)
    }, [initValue])

    const handleValueChange = useCallback(
        async (fieldValue: FieldADTValue) => {
            const { value } = fieldValue

            if (!record) {
                return
            }
            const isUpdateWillNotPass = required && isEmptyCellValue(fieldValue)
            // 如果为空，且是必填项，进行错误提示
            if (isUpdateWillNotPass) {
                Toast.error('必填项不能为空~')
            }

            const recordId = record?.id ?? ''
            const dsId = record?.dsId ?? ''

            await updateCell(
                {
                    recordId,
                    dsId,
                    appId,
                    pageId,
                    fieldId: fieldPointer,
                    value: { value }
                },
                { local: isUpdateWillNotPass }
            )
            // 创建行以后，需要通知对应订阅更新数据
            pageStackPubSub.emit(`${dsId}-ADD`)
        },
        [appId, fieldPointer, pageId, record, required, updateCell]
    )

    const handleSaveChange = useCallback(
        (v: FieldInputADTValue) => {
            formOnChange?.(blockId, v)
            setCurrentValue(v.value)
            setIsValidFieldInput(false)
            if (formModule.type === 'form') {
                setPageStackOfFormContainerBlockChangedAtom(draft => {
                    draft[stackId] = true
                })
            }
        },
        [blockId, formModule.type, formOnChange, setPageStackOfFormContainerBlockChangedAtom, setCurrentValue, stackId]
    )

    const handleChange = useCallback(
        (v: FieldInputADTValue) => {
            setCurrentValue(v.value)
            if (formModule.type === 'field') {
                handleValueChange({ value: v.value, ...field } as FieldADTValue)
            }
        },
        [formModule.type, field, handleValueChange, setCurrentValue]
    )

    const handleChangeSmsCode = useCallback(
        (v: string) => {
            if (formModule.type === 'form') {
                formModule?.onCodeChange?.(blockId, v)
            }
        },
        [blockId, formModule]
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                navigate(`./${getUrlNameByOpenType(openType)}/${page}`, { relative: 'path' })
            }
        },
        [navigate]
    )

    /** 获取关联数据源数据 开始 */
    const relativeDataSourceConfig = useMemo(
        () => [
            {
                config,
                value: formModule?.type === 'form' ? initValue : initRecord?.content?.[fieldPointer]?.value
            }
        ],
        [config, fieldPointer, formModule?.type, initRecord?.content, initValue]
    )

    const { onFetchDataSource, onLoadMoreData, onFetchPersonOptions, relativeDataSource, onFetchCascadeOptions } = useFieldBlockMethods({
        fieldBlocks: relativeDataSourceConfig,
        fieldBlockValueMap
    })
    /**  获取关联数据源数据 结束 */

    useEffect(() => {
        if (formModule.type === 'form' && formCellValue) {
            setPageStackOfFieldBlockAtom(draft => {
                if (!draft?.[stackId]) {
                    draft[stackId] = {}
                }
                draft[stackId][blockId] = { ...formCellValue, source: 'form', dsId: dataSource?.id }
            })
            return
        }
        setPageStackOfFieldBlockAtom(draft => {
            if (!draft?.[stackId]) {
                draft[stackId] = {}
            }

            draft[stackId][blockId] = {
                id: blockId,
                fieldId: fieldPointer,
                dsId: dataSource?.id,
                type: inputType,
                value: currentValue ?? '',
                fieldType: field?.type,
                source: 'field'
            } as InputValueItem
        })
    }, [
        blockId,
        currentValue,
        dataSource?.id,
        field?.type,
        fieldPointer,
        formCellValue,
        formModule.type,
        inputType,
        setPageStackOfFieldBlockAtom,
        stackId
    ])

    useEffect(() => {
        return () => {
            setPageStackOfFieldBlockAtom(draft => {
                if (draft?.[stackId]) {
                    Reflect.deleteProperty(draft?.[stackId], stackId)
                }
            })
        }
    }, [blockId, setPageStackOfFieldBlockAtom, stackId])

    const uploadyOptions = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(appId),
                fileFilter: (file: File | string, index: number) => {
                    if (field?.id === 'AVATAR') {
                        if (file instanceof File) {
                            if (file.size > avatarMaxFileSize) {
                                Toast.error(t('fileExceedsUploadAvatarLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                                return false
                            }
                            if (getFileTypeByFileName(file.name) !== 'image') {
                                Toast.error(t('onlyPicture'))
                                return false
                            }
                            return true
                        }
                        return true
                    }

                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(t('fileExceedsUploadLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }),
        [appId, field?.id, pageId, pageName, t]
    )

    const videoUploadyOption = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(appId),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(t('fileExceedsUploadLimit', { v: getFileSizeToMB(fileMaxUploadSize) }))
                            return false
                        }
                        const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                        if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                            Toast.error(t('unsupportedVideoFormatPleaseUploadMP4file'))
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }),
        [appId, pageId, pageName, t]
    )

    return (
        <Suspense fallback={<div />}>
            <FieldBlock
                videoUploadyOption={videoUploadyOption}
                uploadyOptions={uploadyOptions}
                richTextUploadOptions={uploadManagerInAppParams(appId)}
                isValid={isValid}
                previewType={previewType}
                blockData={blockData}
                value={value}
                language={language}
                onChange={handleChange}
                onSaveChange={handleSaveChange}
                blockType={formModule?.type === 'form' ? 'form' : 'field'}
                dataSource={dataSource}
                record={record}
                error={error}
                onChangeSmsCode={handleChangeSmsCode}
                onOpenPage={handleOpenPage}
                onFetchDataSource={onFetchDataSource}
                onLoadMoreData={onLoadMoreData}
                onFetchCascadeOptions={onFetchCascadeOptions}
                dataSourceList={dataSourceList}
                relativeDataSource={relativeDataSource}
                onFetchDataSourceMeta={({ dsId }) => srv.getDsMeta(appId, dsId, pageId)}
                onFetchSmsCode={formModule?.type === 'form' ? mobile => srv.getVerifyCode({ mobile, blockId, pageId }) : undefined}
                onFetchPersonOptions={onFetchPersonOptions}
                onRenderLabel={onRenderLabel}
            />
        </Suspense>
    )
}

export default FieldBlockController
