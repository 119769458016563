import type { BoxProps } from '@byecode/ui'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Box = styled.div`
    position: absolute;
    inset: 0;
    cursor: pointer;
`

export const NodeMask = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
    return <Box ref={ref} {...props} />
})
