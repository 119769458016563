import type { FieldType, OperatorOptions, TypeInstanceMap } from '@lighthouse/core'

export const JoinFieldIconColor = 'var(--color-green-500)'

export const JoinDataSourceDisableFieldType: FieldType[] = ['file', 'video', 'notes']

export const JoinCureText: FieldType[] = [
    'text',
    'url',
    'email',
    'phoneNumber',
    'id',
    'textGeneration',
    'department',
    'notes'
]

export const JoinArray: FieldType[] = ['select', 'person', 'department', 'selectGenerationByText', 'role']

export const JoinNumber: FieldType[] = ['number']

export const JoinDate: FieldType[] = ['date']

export const JoinBoolean: FieldType[] = ['checkbox']

export const fieldInnerTypes: TypeInstanceMap[] = ['TEXT', 'NUMBER', 'DATE', 'BOOL', 'ARRAY']

export const innerTypeNameMap: Record<TypeInstanceMap, string> = {
    NUMBER: '数值',
    TEXT: '文本',
    DATE: '时间',
    BOOL: '布尔',
    ARRAY: '数组',
    RICH_TEXT: '富文本',
    NULL: '空',
    RUNTIME: '空'
}

// export const innerTypeColorMap: Record<TypeInstanceMap, string> = {
//     NUMBER: 'var(--color-yellow-500)',
//     TEXT: 'var(--color-theme-5)',
//     DATE: 'var(--color-blue-500)',
//     BOOL: 'var(--color-red-500)',
//     ARRAY: 'var(--color-green-500)',
//     RICH_TEXT: 'var(--color-theme-5)',
//     NULL: 'var(--color-gray-500)'
// }

// export const innerTypeBackgroundColorMap: Record<TypeInstanceMap, string> = {
//     NUMBER: 'var(--color-yellow-100)',
//     TEXT: 'var(--color-theme-2)',
//     DATE: 'var(--color-blue-100)',
//     BOOL: 'var(--color-red-100)',
//     ARRAY: 'var(--color-green-100)',
//     RICH_TEXT: 'var(--color-theme-2)',
//     NULL: 'var(--color-gray-100)'
// }

export const joinDataSourceSettingFieldTypeMap: Record<FieldType, FieldType[]> = {
    textGeneration: JoinCureText,
    textGenerationByAudio: [],
    textGenerationByImage: [],
    booleanGenerationByText: [],
    selectGenerationByText: JoinArray,
    dateGenerationByText: [],
    numberGenerationByText: [],
    textGenerationByFile: [],
    text: JoinCureText,
    url: JoinCureText,
    email: JoinCureText,
    phoneNumber: JoinCureText,
    number: JoinNumber,
    date: JoinDate,
    file: [],
    video: [],
    notes: JoinCureText,
    person: JoinArray,
    id: JoinCureText,
    select: JoinArray,
    formula: [],
    role: JoinArray,
    checkbox: JoinBoolean,
    department: JoinArray,
    aggregation: [],
    singleSelect: JoinArray,
    parentDepartment: JoinArray
}

export const textJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '包含',
        value: 'contains'
    },
    {
        label: '不包含',
        value: 'notContains'
    },
    {
        label: '开头为',
        value: 'startWith'
    },
    {
        label: '结尾为',
        value: 'endWith'
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const numberJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '=',
        value: '='
    },
    {
        label: '≠',
        value: '!='
    },
    {
        label: '>',
        value: '>'
    },
    {
        label: '≥',
        value: '>='
    },
    {
        label: '<',
        value: '<'
    },
    {
        label: '≤',
        value: '<='
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const dateJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '早于',
        value: '<'
    },
    {
        label: '晚于',
        value: '>'
    },
    {
        label: '早于等于',
        value: '<='
    },
    {
        label: '晚于等于',
        value: '>='
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const arrayJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '等于',
        value: '='
    },
    {
        label: '不等于',
        value: '!='
    },
    {
        label: '包含',
        value: 'contains'
    },
    {
        label: '不包含',
        value: 'notContains'
    },
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]

export const boolJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '是',
        value: '='
    },
    {
        label: '不是',
        value: '!='
    }
]

export const richTextJoinOperatorOptions: OperatorOptions[] = [
    {
        label: '为空',
        value: 'isEmpty'
    },
    {
        label: '不为空',
        value: 'isNotEmpty'
    }
]


export const innerTypeJoinOperatorMap: Record<TypeInstanceMap, OperatorOptions[]> = {
    TEXT: textJoinOperatorOptions,
    NUMBER: numberJoinOperatorOptions,
    DATE: dateJoinOperatorOptions,
    BOOL: boolJoinOperatorOptions,
    ARRAY: arrayJoinOperatorOptions,
    RICH_TEXT: richTextJoinOperatorOptions,
    NULL: [],
    RUNTIME: []
}
