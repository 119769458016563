import { Divider } from '@byecode/ui/components/Divider'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import type { CollapseBlockItemConfig } from '@lighthouse/core'
import { type CollapseBlockAbstract } from '@lighthouse/core'
import { type RichTextEditorProps, isEmptyRichTextValue } from '@lighthouse/shared'
import React, { forwardRef, useCallback, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import { useUpdateEffect } from 'react-use'

import * as SC from './styles'

interface CollapseBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: CollapseBlockAbstract
    config: RichTextEditorProps['config']
}

const CollapseBlock = forwardRef<HTMLDivElement, CollapseBlockProps>((props, ref) => {
    const {
        blockData: {
            config: { list, breakPoint }
        },
        config,
        ...rest
    } = props

    return (
        <SC.Root {...rest} ref={ref} style={{ overflow: breakPoint.size.overflow, ...rest.style }}>
            {list.map((item, index) => (
                <Collapse key={index} data={item} config={config} />
            ))}
        </SC.Root>
    )
})

interface CollapseProps {
    defaultOpen?: boolean
    config: RichTextEditorProps['config']
    data: CollapseBlockItemConfig
}

const collapsedStyle = {
    display: 'none',
    height: 0,
    overflow: 'hidden'
}

const Collapse = ({ defaultOpen, config, data }: CollapseProps) => {
    const [open, setOpen] = useState(defaultOpen)
    const [internalCollapseStyle, setInternalCollapseStyle] = useState<React.CSSProperties>(open ? {} : collapsedStyle)

    const ref = useRef<HTMLDivElement>(null)

    useUpdateEffect(() => {
        if (open) {
            requestAnimationFrame(() => {
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, willChange: 'height', display: 'block', overflow: 'hidden' }))
                })
                requestAnimationFrame(() => {
                    const height = ref.current ? ref.current.scrollHeight : 'auto'
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height }))
                    })
                })
            })
        } else {
            requestAnimationFrame(() => {
                const height = ref.current ? ref.current.scrollHeight : 'auto'
                flushSync(() => {
                    setInternalCollapseStyle(s => ({ ...s, transition: `height 0.2s`, height, willChange: 'height' }))
                })
                requestAnimationFrame(() => {
                    flushSync(() => {
                        setInternalCollapseStyle(s => ({ ...s, height: 0, overflow: 'hidden' }))
                    })
                })
            })
        }
    }, [open])

    const transitionEndHandle = useCallback(
        (e: React.TransitionEvent<HTMLDivElement>) => {
            if (e.target !== ref.current || e.propertyName !== 'height') {
                return
            }
            if (open) {
                setInternalCollapseStyle({})
            } else {
                setInternalCollapseStyle(collapsedStyle)
            }
        },
        [open]
    )

    return (
        <SC.CollapseRoot>
            <Flex direction="column" gap={6} style={{ cursor: 'pointer' }} data-stop-action-propagation onClick={() => setOpen(s => !s)}>
                <Flex justifyContent="space-between" alignItems="center" gap={6}>
                    <SC.CollapseTitle readonly config={config} value={data.title} />
                    <IconFont type={open ? 'Reduce' : 'Add'} size={16} fill="var(--color-gray-400)" />
                </Flex>

                {data.subTitle && !isEmptyRichTextValue(data.subTitle, config) ? (
                    <SC.CollapseSubTitle readonly config={config} value={data.subTitle} />
                ) : null}
            </Flex>

            <div ref={ref} onTransitionEnd={transitionEndHandle} style={internalCollapseStyle}>
                <Divider my={12} color="var(--color-gray-300)" />
                <SC.CollapseContent readonly config={config} value={data.introduction} />
            </div>
        </SC.CollapseRoot>
    )
}

export default CollapseBlock
