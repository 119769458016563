import type { Workflow } from '@lighthouse/shared'
import { atom } from 'jotai'

import * as srv from '@/services'

import { workflowListAtom } from './state'

export const fetchWorkflowListAtom = atom(null, async (_, set, payload?: Workflow[]) => {
    const data = await srv.listFlowNodes()
    set(workflowListAtom, payload || data)
})
