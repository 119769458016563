import type { ActionsProtocol, AiFieldStatus, Field, FieldADTValue, RecordLikeProtocol, ViewField } from '@lighthouse/core'
import { fieldConvertValue, hasIconFieldType, ViewFieldStatusPreviewer } from '@lighthouse/shared'
import type { Vector2 } from '@use-gesture/react';
import { useDrag,useGesture } from '@use-gesture/react'
import { addDays,differenceInHours, isAfter, lightFormat } from 'date-fns'
import type { atomWithImmer } from 'jotai-immer'
import React, { forwardRef, useCallback, useLayoutEffect, useMemo,useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLatest } from 'react-use'

import { CELL_HEIGHT, DAY_MARGIN_BOTTOM, DAY_MARGIN_TOP, LINE_GAP } from '../Cell'
import * as SC from './TimeLine.style'
import type { TimeLineInfo } from './TimeLine.type'

interface TimeLineProps extends React.ComponentPropsWithoutRef<'div'>, ActionsProtocol {
    schema: Record<string, Field>
    record: RecordLikeProtocol
    columns: ViewField[]

    prevHeight?: number
    onHeightChange: (v: number) => void

    data: TimeLineInfo[]
    info: TimeLineInfo
    tableElement?: HTMLTableElement | null

    canEdit: boolean
    canDelete: boolean
    canView: boolean

    hoveredLine: string

    onResizeStart: () => void
    onResizeEnd: () => void
    onDragStart: () => void
    onDragEnd: () => void

    onUpdateDate: (id: string, startDate: Date, endDate: Date) => void
    onUpdateLocalDate: (id: string, startDate: Date, endDate: Date) => void
    onClickSchedule?: (id: string, record: RecordLikeProtocol) => void
    onEdit?: (id: string) => void
    onDelete?: (id: string) => void
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onAiGeneration: (recordId: string, fieldId: string) => Promise<boolean>
}

export const TimeLine = forwardRef<HTMLDivElement, TimeLineProps>((props, ref) => {
    const {
        actions,
        schema,
        record,
        columns,

        prevHeight = 0,
        onHeightChange,

        data,
        info,
        tableElement,
        canEdit,
        canDelete,
        canView,

        hoveredLine,

        onResizeStart,
        onResizeEnd,
        onDragStart,
        onDragEnd,

        onUpdateDate,
        onUpdateLocalDate,
        onClickSchedule,
        onEdit,
        onDelete,
        aiFieldStatusListAtom,
        onAiGeneration,

        style,
        ...rest
    } = props

    const [position, setPosition] = useState({ x: 0, y: 0, width: 0 })

    const lineRef = useRef<HTMLDivElement | null>(null)
    const latest = useLatest({ onHeightChange, onUpdateDate, onUpdateLocalDate })

    // 监听宽度变化
    useLayoutEffect(() => {
        if (!tableElement) {
            return
        }

        const resizeHandler: ResizeObserverCallback = ([e]) => {
            const tableRect = e.target.getBoundingClientRect()

            const dateStr = lightFormat(info.dates[0], 'yyyy-MM-dd')

            const cell = tableElement.querySelector(`[data-value="${dateStr}"]`)
            if (!cell) {
                return
            }

            const rect = cell.getBoundingClientRect()
            setPosition({
                x: rect.left - tableRect.left,
                y: rect.top - tableRect.top + prevHeight + info.index * LINE_GAP + CELL_HEIGHT + DAY_MARGIN_TOP + DAY_MARGIN_BOTTOM,
                width: rect.width * info.dates.length
            })
        }

        const observer = new ResizeObserver(resizeHandler)

        observer.observe(tableElement)

        return () => {
            observer.disconnect()
        }
    }, [tableElement, info.dates, info.index, prevHeight])

    // 监听自身高度
    useLayoutEffect(() => {
        const el = lineRef.current
        if (!el) {
            return
        }

        const handler: ResizeObserverCallback = entries => {
            const [e] = entries
            latest.current.onHeightChange(e.target.clientHeight)
        }

        const observer = new ResizeObserver(handler)

        observer.observe(el)

        return () => {
            observer.unobserve(el)
        }
    }, [latest, info.index, tableElement])

    // const resizeHandleRef = useRef<HTMLDivElement | null>(null)
    const stateRef = useRef({ isResizing: false, isMoving: false })

    const updateTimeLine = useCallback(
        (values: Vector2) => {
            const [x, y] = values
            if (!tableElement) {
                return
            }

            const cells = tableElement.querySelectorAll('td > div')

            for (let index = 0; index < cells.length; index++) {
                const el = cells.item(index)
                const rect = el.getBoundingClientRect()

                if (rect.left < x && rect.right > x && rect.top < y && rect.bottom > y) {
                    const str = el.getAttribute('data-value')
                    if (!str) {
                        return
                    }

                    const oldEndDate = info.originDates[info.originDates.length - 1]
                    const startDate = new Date(str)
                    startDate.setHours(0)
                    startDate.setMinutes(0)
                    startDate.setMilliseconds(0)

                    const maxDate = new Date(oldEndDate)
                    maxDate.setHours(0)
                    maxDate.setMinutes(0)
                    maxDate.setMilliseconds(0)
                    if (isAfter(startDate, maxDate)) {
                        return
                    }

                    stateRef.current.isResizing
                        ? latest.current.onUpdateLocalDate(info.id, startDate, oldEndDate)
                        : latest.current.onUpdateDate(info.id, startDate, oldEndDate)
                    break
                }
            }
        },
        [info.id, info.originDates, latest, tableElement]
    )

    const resizeHandleBind = useGesture({
        onDrag: state => {
            state.event.stopPropagation()
            if (state.first) {
                onResizeStart()
                stateRef.current.isResizing = true

                return
            }

            if (state.last) {
                onResizeEnd()
                stateRef.current.isResizing = false
                updateTimeLine(state.values)
                return
            }

            updateTimeLine(state.values)
        }
    })

    // 移动日程
    const moveHandle = useCallback(
        (values: Vector2) => {
            const [x, y] = values
            if (!tableElement) {
                return
            }

            const cells = tableElement.querySelectorAll('td > div')

            for (let index = 0; index < cells.length; index++) {
                const el = cells.item(index)
                const rect = el.getBoundingClientRect()

                if (rect.left < x && rect.right > x && rect.top < y && rect.bottom > y) {
                    const str = el.getAttribute('data-value')
                    if (!str) {
                        return
                    }

                    const startDate = new Date(str)
                    const oldStartDate = info.originDates[0]
                    const oldEndDate = info.originDates[info.originDates.length - 1]
                    const diff = Math.floor(differenceInHours(startDate, oldStartDate) / 24) | 0

                    // if (diff === 0) {
                    //     return
                    // }

                    const endDate = addDays(oldEndDate, diff)

                    stateRef.current.isMoving
                        ? latest.current.onUpdateLocalDate(info.id, startDate, endDate)
                        : latest.current.onUpdateDate(info.id, startDate, endDate)
                    break
                }
            }
        },
        [info.id, info.originDates, latest, tableElement]
    )

    const [mousePosition, setMousePosition] = useState({ x: -999, y: -999 })
    const [isDragging, setIsDragging] = useState(false)

    const bind = useDrag(
        state => {
            if (state.tap) {
                onClickSchedule?.(info.id, info.record)
                return
            }

            if (stateRef.current.isResizing) {
                return
            }

            if (state.first) {
                onDragStart()
                setIsDragging(true)
                stateRef.current.isMoving = true
                return
            }

            if (state.last) {
                onDragEnd()
                stateRef.current.isMoving = false
                moveHandle(state.values)

                setIsDragging(false)
                setMousePosition({ x: -999, y: -999 })
                return
            }
            setMousePosition({ x: state.values[0], y: state.values[1] })
            // moveHandle(state.values)
        },
        {
            filterTaps: true,
            pointer: {
                capture: false
            }
        }
    )

    // 提前过滤，判断内容是否为空
    const fields = useMemo(() => {
        return columns.filter(col => {
            const field = schema[col.fieldId]
            const data = record.content?.[col.fieldId]?.value
            const fieldValue = { ...field, value: data } as FieldADTValue
            const realValue = fieldConvertValue(fieldValue)
            return field && (realValue || hasIconFieldType.has(field.type))
        })
    }, [columns, record.content, schema])

    if (!tableElement) {
        return null
    }

    const dom = (
        <SC.Line
            {...bind()}
            ref={ref}
            color={info.color}
            data-ignore-tap
            hovered={hoveredLine === info.id}
            style={{
                opacity: position.width === 0 ? 0 : 1,
                left: position.x,
                top: position.y,
                width: position.width,
                ...style
            }}
            {...rest}
        >
            <SC.LineWrapper ref={lineRef}>
                {info.dates[0] === info.originDates[0] && <SC.LineResizeHandle {...resizeHandleBind()} />}
                <SC.LineTitle>{info.title}</SC.LineTitle>

                {fields.length > 0 ? (
                    <SC.LineContent>
                        {fields.map(col => {
                            const field = schema[col.fieldId]
                            const data = record.content?.[col.fieldId]?.value

                            return (
                                <ViewFieldStatusPreviewer
                                    key={col.fieldId}
                                    dsId={record.dsId}
                                    recordId={record?.id}
                                    field={field}
                                    data={data}
                                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                                    onAiGeneration={() => onAiGeneration(record.id, col.fieldId)}
                                />
                            )
                        })}
                    </SC.LineContent>
                ) : null}
            </SC.LineWrapper>
        </SC.Line>
    )

    const overlayDom = isDragging
        ? React.cloneElement(dom, {
              style: { pointerEvents: 'none', width: position.width, top: mousePosition.y, left: mousePosition.x, opacity: 0.5 }
          })
        : null

    return (
        <>
            {createPortal(
                dom,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tableElement.parentElement!
            )}

            {createPortal(overlayDom, document.body)}
        </>
    )
})
