import { Toast } from '@byecode/ui'
import type { AnyObject } from 'immer/dist/internal'

import type { ActionFlowNode, ConditionFlowNode, NodeInvokeResult, UpdateWorkflowLogPayload, Workflow } from '../../types'
import { SyncHook } from '../hooka/SyncHook'
import type { NodeInfo } from './helper'
import { getFlowByNodes, getFlowTreeByNodes } from './helper'

type InvokeId = string

type FlowNodeQueueMap = Map<string, (ActionFlowNode | ConditionFlowNode)[]>

type FlowJobMap = Map<string, NodeInfo>

type FlowNodeInvokeRuntimeParamsMap = Map<string, AnyObject>

type Hooks<T extends unknown[] = unknown[]> = {
    initialize: SyncHook<T>
    afterInvoke: SyncHook<T>
    afterInvokeNode: SyncHook<T>
}

export class ActionWorker<T, K extends keyof T> {
    /**
     * 动作流执行钩子函数
     * 可用于监听动作流执行的各个阶段
     * -----------------------------------
     * 1. initialize: 初始化钩子函数
     * 2. afterInvoke: 执行完毕钩子函数
     * 3. afterInvokeNode: 执行完毕节点钩子函数
     */
    private readonly hooks: Hooks = {
        initialize: new SyncHook(['data']),
        afterInvoke: new SyncHook(['data']),
        afterInvokeNode: new SyncHook(['data'])
    }

    private readonly queues: FlowNodeQueueMap = new Map()

    private readonly jobQueues: FlowJobMap = new Map()

    private readonly actionHandlers

    // private readonly invokeQueues: FlowNodeQueueMap = new Map()

    private readonly invokeQueueRuntimeParams: FlowNodeInvokeRuntimeParamsMap = new Map()

    constructor(actionHandlers: T) {
        this.actionHandlers = actionHandlers
    }

    public use(name: keyof Hooks, fn: (...args: unknown[]) => void, priority = 0) {
        this.hooks[name].tap(name, fn, priority)
    }

    public sequenceWorker(flow: Workflow) {
        const { content, id } = flow

        if (!content) {
            return
        }

        const sequenceNodes = getFlowByNodes(content.edges, content.nodes as (ActionFlowNode | ConditionFlowNode)[])
        const nodeTree = getFlowTreeByNodes(content.edges, content.nodes as (ActionFlowNode | ConditionFlowNode)[])

        this.queues.set(id, sequenceNodes)

        this.jobQueues.set(id, nodeTree)
    }

    public injectRuntimeParams(invokeId: InvokeId, flow: Workflow, runtimeParams: AnyObject) {
        const { clickTriggerNodeParams, ...restActionRuntimeParams } = runtimeParams
        const triggerNodeId = flow.content?.nodes?.[0].id ?? ''
        this.invokeQueueRuntimeParams.set(invokeId, {
            ...restActionRuntimeParams,
            clickTriggerNodeParams,
            [triggerNodeId]: clickTriggerNodeParams
        })
    }

    public getRuntimeParams(invokeId: InvokeId) {
        return this.invokeQueueRuntimeParams.get(invokeId)
    }

    private async invokeTreeAsync(params: {
        triggerNodeId: string
        tree: NodeInfo
        invokeId: string
        flowId: string
        prevRuntimeParams?: AnyObject
    }) {
        const { triggerNodeId, tree, invokeId, prevRuntimeParams, flowId } = params
        const { data, children } = tree

        if (!data) {
            return
        }

        if (data.data.nodeType === 'CLICK_TRIGGER') {
            this.hooks.afterInvokeNode.call({
                nodeId: data.id,
                // data,
                // tree,
                workflowId: flowId,
                workflowInstanceId: invokeId,
                status: 'RUNNING',
                nodeStatus: {
                    status: 'SUCCESS',
                    input: [],
                    output: []
                },
                runtimeParams: prevRuntimeParams
                // result
            })
            for (const child of children) {
                this.invokeTreeAsync({ triggerNodeId, tree: child, invokeId, flowId })
            }
        } else {
            const { actionHandlers, hooks } = this
            const handler = actionHandlers[data.data.nodeType as K]

            const runtimeParams = this.getRuntimeParams(invokeId) ?? {}

            let result

            const newRuntimeParams = { ...prevRuntimeParams, ...runtimeParams }
            try {
                // @ts-expect-error expect handler to be a function
                result = await handler(data, { ...newRuntimeParams, triggerNodeId })

                const isLeaf = children.every(child => !child.data)
                /**
                 * 执行节点后的钩子函数
                 */
                const payload: NodeInvokeResult = {
                    nodeId: data.id,
                    // data,
                    // tree,
                    workflowId: flowId,
                    workflowInstanceId: invokeId,
                    status: isLeaf ? 'SUCCESS' : 'RUNNING',
                    nodeStatus: {
                        status: 'SUCCESS',
                        input: [],
                        output: []
                    },
                    runtimeParams: newRuntimeParams,
                    result
                }
                hooks.afterInvokeNode.call(payload)
            } catch (error) {
                /**
                 * 执行节点后的钩子函数
                 */
                const payload: NodeInvokeResult = {
                    nodeId: data.id,
                    // data,
                    // tree,
                    workflowId: flowId,
                    workflowInstanceId: invokeId,
                    status: 'FAILED',
                    nodeStatus: {
                        status: 'FAILED',
                        input: [],
                        output: []
                    },
                    runtimeParams: newRuntimeParams,
                    result
                }
                hooks.afterInvokeNode.call(payload)
                // @ts-expect-error expect error to have message
                error?.message !== 'disabled' && Toast.error(error?.message)
                // return
                throw error
            }

            // condition handler return boolean, false means skip the children
            if (result.success === false) {
                return
            }

            if (children.length > 0) {
                for (const child of children) {
                    const runtimeParams = result ? { ...prevRuntimeParams, [data.id]: result } : prevRuntimeParams
                    this.invokeTreeAsync({ triggerNodeId, tree: child, invokeId, prevRuntimeParams: runtimeParams, flowId })
                }
            }
        }
    }

    public invoke(id: string, invokeId: InvokeId, prevRuntimeParams?: AnyObject) {
        const jobs = this.jobQueues.get(id)
        const runtimeParams = this.getRuntimeParams(invokeId)

        if (jobs) {
            // 执行初始化钩子
            this.hooks.initialize.call({
                flowId: id,
                invokeId,
                runtimeParams
            })
            this.invokeTreeAsync({
                triggerNodeId: jobs.data.id,
                tree: jobs,
                invokeId,
                prevRuntimeParams: { ...prevRuntimeParams, ...runtimeParams },
                flowId: id
            })
        }
    }
}
