import type { AnyObject } from '@byecode/ui/types'
import type {
    BasicPageStackMeta,
    BlockAbstract,
    BlockRuntimeState,
    BlockVisibilityProtocol,
    ContainerBlockAbstract,
    DataSourceAbstract,
    FilterFormType,
    FormContainerBlockAbstract,
    PageAbstract,
    RecordData,
    RecordLikeProtocol,
    Sorter,
    ViewOptions
} from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'

import type { FlowLayoutNode } from '../components'
import type { ApplicationPreviewEnum } from '../types'
import { getBlockChildren, getBreakPointConfigure, PureBlockAbstract } from './block'

const filterNilLayoutNode = (node: FlowLayoutNode | undefined): node is FlowLayoutNode => {
    return !!node
}

/** 普通页面内容返回 */
export type PageContentRes = Omit<PageAbstract, 'filter' | 'sorts'> & {
    pageConfig?: {
        filter: FilterFormType
        sorts: Sorter[]
    }
    blockNodes: (Omit<BlockAbstract, 'config'> & {
        config: Record<BlockType, AnyObject>
    })[]
    /** 除了普通页面，其他页面都会有该字段，存放的是筛选的数据源信息 */
    recordPage?: {
        datasource: DataSourceAbstract
        records: RecordLikeProtocol[]
    }
}

/** 将后端block数据转换为前端需要的格式 */
export function transformBlockRes2Blocks(blockRes: PageContentRes['blockNodes']): BlockAbstract[] {
    return blockRes.reduce<BlockAbstract[]>((total, curr) => {

        const { config, type } = curr
        if (type === BlockType.container) {
            return [
                ...total,
                {
                    ...curr,
                    config: config[type],
                    children: (curr as unknown as ContainerBlockAbstract).children.map(view => {
                        return {
                            ...view,
                            children: transformBlockRes2Blocks(view.children as unknown as PageContentRes['blockNodes'])
                        }
                    })
                } as BlockAbstract
            ]
        }

        if (type === BlockType.formContainer || (type === BlockType.view && (config.view as ViewOptions).viewType === 'custom')) {
            return [
                ...total,
                {
                    ...curr,
                    config: config[type],
                    children: transformBlockRes2Blocks(
                        (curr as unknown as FormContainerBlockAbstract).children as unknown as PageContentRes['blockNodes']
                    )
                } as BlockAbstract
            ]
        }

        return [...total, { ...curr, config: config[type] } as BlockAbstract]
    }, [])
}

type TransformParams = {
    blocks: BlockAbstract[]
    blockRuntimeState?: BlockRuntimeState
    getIsVisible?: (payload: { visibility?: BlockVisibilityProtocol; viewRecord?: RecordData; userId?: string }) => boolean
    userId?: string
    previewType: ApplicationPreviewEnum
}

/** 将blocks转换为栅格节点格式 */
export function transformBlock2FlowLayoutNode({ blocks, blockRuntimeState, getIsVisible, userId, previewType }: TransformParams): FlowLayoutNode[] {
    function recursion(tree: BlockAbstract[], parentIsCustomView?: boolean): FlowLayoutNode[] {
        return tree
            .map<FlowLayoutNode | undefined>(n => {
                const block = n

                if (!parentIsCustomView && getIsVisible) {
                    const visible = getIsVisible?.({
                        userId,
                        visibility: block.config.breakPoint?.visibility
                    })
                    if (!visible) {
                        return undefined
                    }
                }

                if (block.type === BlockType.view && block.config.viewType === 'custom') {
                    return {
                        id: n.id,
                        type: 'custom',
                        data: getBreakPointConfigure(previewType, block.config.breakPoint, block.config.breakPoints),
                        children: recursion(block.children ?? [], true)
                    }
                }

                if (block.type === BlockType.container) {
                    const view = block.children.find(item => item.id === blockRuntimeState?.container?.[block.id].currentView)
                    if (!view) {
                        return undefined
                    }
                    return {
                        id: n.id,
                        type: 'container',
                        data: getBreakPointConfigure(previewType, block.config.breakPoint, block.config.breakPoints),
                        children: recursion(view?.children ?? [], parentIsCustomView)
                    }
                }

                if (block.type === BlockType.formContainer) {
                    return {
                        id: n.id,
                        type: 'container',
                        data: getBreakPointConfigure(previewType, block.config.breakPoint, block.config.breakPoints),
                        children: recursion(block.children, parentIsCustomView)
                    }
                }

                return {
                    id: n.id,
                    data: getBreakPointConfigure(previewType, block.config.breakPoint, block.config.breakPoints),
                    type: 'block'
                }
            })
            .filter(filterNilLayoutNode)
    }
    return recursion(blocks)
}

export function initBlockRuntimeState(stack: BasicPageStackMeta, blocks: BlockAbstract[]) {
    function deepInit(block: BlockAbstract) {
        switch (block.type) {
            case BlockType.container: {
                if (stack.blockRuntimeState.container?.[block.id]?.currentView) {
                    break
                }
                const { container } = stack.blockRuntimeState
                stack.blockRuntimeState.container = {
                    ...container,
                    [block.id]: { ...container?.[block.id], currentView: block.config.viewList[0].id }
                }

                break
            }

            case BlockType.tabs: {
                if (stack.blockRuntimeState.tabs?.[block.id]?.currentTab) {
                    break
                }
                const { tabs } = stack.blockRuntimeState
                stack.blockRuntimeState.tabs = {
                    ...tabs,
                    [block.id]: {
                        ...tabs?.[block.id],
                        currentTab: block.config.baseList?.[0]?.id
                    }
                }
                break
            }

            default: {
                break
            }
        }

        const children = getBlockChildren(block)
        if (children && children.length !== 0) {
            children.forEach(deepInit)
        }
    }

    blocks.forEach(deepInit)
}
