import type { TypeInstanceMap } from './data'
import type { VariableFieldADTValue } from './field'
import type { FilterFormType } from './filter'
import type { Sorter } from './general'

export const SYSTEM_VARIABLE = [
     'NOW',
    'TODAY',
    'TOMORROW',
    'YESTERDAY',
    'THIS_WEEK',
    'LAST_WEEK',
    'THIS_MONTH',
    'LAST_MONTH',
    'THIS_YEAR',
    'PAST_SEVEN_DAYS',
    'FUTURE_SEVEN_HEAVENS',
    'PAST_THIRTY_DAYS',
    'FUTURE_THIRTY_HEAVENS',
    'BEFORE_SOMEWHAT_DAYS',
    'AFTER_SOMEWHAT_DAYS'
] as const

export const DATE_RANGE_SYSTEM_VARIABLE = [
    'TODAY',
    'TOMORROW',
    'YESTERDAY',
    'THIS_WEEK',
    'LAST_WEEK',
    'NEXT_WEEK',
    'THIS_MONTH',
    'LAST_MONTH',
    'NEXT_MONTH',
    'THIS_QUARTER',
    'LAST_QUARTER',
    'NEXT_QUARTER',
    'THIS_YEAR',
    'LAST_YEAR',
    'NEXT_YEAR',
    'PAST_SEVEN_DAYS',
    'FUTURE_SEVEN_HEAVENS',
    'PAST_FOURTEEN_DAYS',
    'FUTURE_FOURTEEN_HEAVENS',
    'PAST_THIRTY_DAYS',
    'FUTURE_THIRTY_HEAVENS'
] as const

export type DateRangeSystemVariableValue = typeof DATE_RANGE_SYSTEM_VARIABLE[number]

export type SystemVariableValue = typeof SYSTEM_VARIABLE[number]

export enum SystemVariableType {
    'TODAY' = 'TODAY',
    'TOMORROW' = 'tomorrow',
    'UPSTREAM' = 'UPSTREAM',
    'FIELD_ID' = 'FIELD_ID',
    'SYSTEM' = 'SYSTEM'
}
export enum VariableType {
    'VARIABLE' = 'VARIABLE',
    'PAGE' = 'PAGE',
    'VALUE' = 'VALUE',
    'UPSTREAM' = 'UPSTREAM',
    'AGGREGATOR' = 'AGGREGATOR',
    'FIELD_ID' = 'FIELD_ID',
    'SYSTEM' = 'SYSTEM',
    'USER' = 'USER',
    'INPUT' = 'INPUT',
    'FORM' = 'FORM',
    'VIEW' = 'VIEW',
    'PAGE_LINK' = 'PAGE_LINK',
    'FILTER' = 'FILTER',
    'SELECT_DATASOURCE' = 'SELECT_DATASOURCE',
    'ARG_VARIABLE' = 'ARG_VARIABLE',
    'RESOLVED' = 'RESOLVED'
}

export enum FilterMode {
    'CUSTOM' = 'CUSTOM',
    'NORMAL' = 'NORMAL'
    // 'WORKFLOW' = 'WORKFLOW'
}

/** 链接表用到的变量 */
export type FieldVariableConfig = {
    // type: 'VARIABLE'
    fieldVariable?: {
        dsId?: string
        fieldId?: string
    }
}

/** 用户数据的变量 */
export type UserVariableConfig = {
    // type: 'USER'
    userVariable?: {
        fieldId?: string
        format?: string
    }
}

/** 数据源及视图用到的变量 */
export type FieldIdVariableConfig = {
    // type: fieldId
    fieldIdVariable?: {
        fieldId?: string
    }
}

export type ValueVariableConfig = {
    valueVariable?: VariableFieldADTValue
}

// 子流程
export type ArgVariableConfig = {
    // type: upstream
    argVariable?: {
        nodeId?: string
        argId?: string
    }
}

export type UpstreamVariableConfig = {
    // type: upstream
    upstreamVariable?: {
        nodeId?: string
        fieldId?: string
    }
}

export type AggregatorVariableConfig = {
    // type: upstream
    aggregatorVariable?: {
        nodeId?: string
        fieldId?: string
    }
}

export type SystemVariableConfig = {
    // type: upstream
    systemVariable?: {
        value?: SystemVariableValue
        format?: string
    }
}

//  输入框的值
export type InputVariableConfig = {
    inputVariable?: {
        blockId: string
    }
}

// 表单容器
export type FormVariableConfig = {
    // type: 'USER'
    formVariable?: {
        fieldId?: string
    }
}

// 自定义视图
export type ViewVariableConfig = {
    viewVariable?: {
        fieldId: string
        format?: string
    }
}
// 页面链接
export type PageLinkVariableConfig = {
    pageLinkVariable?: {
        value: 'CURRENT_PAGE' | undefined
    }
}

// 筛选控制器
export type FilterVariableConfig = {
    filterVariable?: {
        blockId: string
        itemId: string
    }
}

export type PageVariableType = 'parentPage' | 'page'
/** 页面数据变量 */
export type PageVariableConfig = {
    pageVariable?: {
        type: PageVariableType
        dsId?: string
        fieldId?: string
        format?: string
    }
}

// 查询数据表
export type SelectDataSourceVariableConfig = {
    selectDataSourceVariable?: {
        dsId: string
        fieldId: string
        sorts: Sorter[]
        filter: FilterFormType
        // filterWhere: 'AND' | 'OR'
    }
}

export type ResolvedVariableConfig = {
    resolvedVariable?: VariableFieldADTValue & {
        innerType: TypeInstanceMap | undefined
    }
}


export type FieldVariable = Record<'type', VariableType.VARIABLE> & FieldVariableConfig

export type FieldIdVariable = Record<'type', VariableType.FIELD_ID> & FieldIdVariableConfig

export type ValueVariable = Record<'type', VariableType.VALUE> & ValueVariableConfig

export type UpstreamVariable = Record<'type', VariableType.UPSTREAM> & UpstreamVariableConfig

export type SystemVariable = Record<'type', VariableType.SYSTEM> & SystemVariableConfig

export type UserVariable = Record<'type', VariableType.USER> & UserVariableConfig

export type InputVariable = Record<'type', VariableType.INPUT> & InputVariableConfig

export type FormVariable = Record<'type', VariableType.FORM> & FormVariableConfig

export type ViewVariable = Record<'type', VariableType.VIEW> & ViewVariableConfig

export type PageVariable = Record<'type', VariableType.PAGE> & PageVariableConfig

export type PageLinkVariable = Record<'type', VariableType.PAGE_LINK> & PageLinkVariableConfig

export type FilterVariable = Record<'type', VariableType.FILTER> & FilterVariableConfig

export type SelectDataSourceVariable = Record<'type', VariableType.SELECT_DATASOURCE> & SelectDataSourceVariableConfig

export type ArgVariable = Record<'type', VariableType.ARG_VARIABLE> & ArgVariableConfig

export type ResolvedValueVariable = Record<'type', VariableType.RESOLVED> & ResolvedVariableConfig

export type VariableADTvalue =
    | FieldVariable
    | ValueVariable
    | UpstreamVariable
    | FieldIdVariable
    | SystemVariable
    | UserVariable
    | InputVariable
    | FormVariable
    | ViewVariable
    | PageVariable
    | PageLinkVariable
    | FilterVariable
    | SelectDataSourceVariable
    | ArgVariable
    | ResolvedValueVariable

// export type VariableADTField =
//     | UpstreamVariable
//     | FieldIdVariable
//     | FieldVariable
//     | ValueVariable
//     | SystemVariable
//     | UserVariable
//     | InputVariable
//     | FormVariable
//     | ViewVariable
//     | PageVariable
//     | PageLinkVariable
//     | FilterVariable
//     | SelectDataSourceVariable
//     | ArgVariable
