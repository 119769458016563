import { Button } from '@byecode/ui'
import {
    type DataSourceAbstract,
    type DateField,
    type Field,
    type FieldType,
    type FormulaField,
    type IdField,
    type NumberField,
    type PersonField,
    type SelectField,
    type SelectGenerationByTextField,
    type TextGenerationField,
    type ViewFieldProtocol,
    DataSourceType
} from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'

import { InnerTypeMapByFieldType } from '../../constants'
import DateConfigView from './DateConfigView'
import { FormulaConfigView } from './FormulaConfigView'
import { IdConfigView } from './IdConfigView'
import NumberConfigView from './NumberConfigView'
import PersonConfigView from './PersonConfigView'
import SelectConfigView from './SelectConfigView'
import { SelectGenerationByTextConfigView } from './SelectGenerationByTextConfigView'
import * as SC from './styles'
import { TextGenerationConfigView } from './TextGenerationConfigView'

export * from './FormulaConfigView'

export interface TableFieldConfigure {
    currentType: FieldType
    joinField?: Field
}
interface EditorConfigViewRendererProps {
    children?: React.ReactNode
    dataSource: DataSourceAbstract
    columns: ViewFieldProtocol[]
    dataSourceList: DataSourceAbstract[]
    config: TableFieldConfigure
    field?: Field
    onOk?: (data: Field) => void
    onCancel?: () => void
}

export const extraViewKeys = ['id', 'select', 'formula', 'number', 'date', 'person', 'textGeneration', 'selectGenerationByText']

const EditorConfigViewRenderer: React.FC<EditorConfigViewRendererProps> = ({
    dataSource,
    columns,
    dataSourceList,
    config,
    field,
    // allDataSources,
    onOk,
    onCancel
}) => {
    const { schema, type, viewOptions, id: dsId } = dataSource
    const { tableProps, joinConfig } = viewOptions
    const handleCancel = useCallback(() => {
        onCancel?.()
    }, [onCancel])

    const handleOk = useCallback(
        (data: Field) => {
            onCancel?.()
            onOk?.(data)
            return Promise.resolve(true)
        },
        [onCancel, onOk]
    )
    const { currentType, joinField } = config

    const handleDummyOk = useCallback(() => {
        const innerType = InnerTypeMapByFieldType[currentType]
        onOk?.({ ...field, id: field?.id ?? '', type: currentType, name: '', innerType } as Field)
    }, [currentType, field, onOk])

    const isExtraConfig = useMemo(() => {
        // if (!joinField) {
        //     return true
        // }
        if (type === DataSourceType.joinDataSource) {
            return !joinField
        }
        return true
    }, [joinField, type])

    const configView = useMemo(() => {
        if (!isExtraConfig) {
            return null
        }
        const configViewCommonProps = { onCancel: handleCancel, onOk: handleOk }
        switch (currentType) {
            case 'number': {
                return <NumberConfigView config={field as NumberField} {...configViewCommonProps} />
            }
            case 'date': {
                return <DateConfigView config={field as DateField} {...configViewCommonProps} />
            }
            case 'formula': {
                const fieldIds = tableProps.map(item => item.id)
                return <FormulaConfigView config={field as FormulaField} fieldIds={fieldIds} schema={schema} {...configViewCommonProps} />
            }
            case 'id': {
                return <IdConfigView config={field as IdField} {...configViewCommonProps} />
            }
            case 'select': {
                return <SelectConfigView config={field as SelectField} {...configViewCommonProps} />
            }
            case 'person': {
                return <PersonConfigView config={field as PersonField} {...configViewCommonProps} />
            }
            case 'textGeneration': {
                return (
                    <TextGenerationConfigView
                        config={field as TextGenerationField}
                        dataSource={dataSource}
                        dataSourceList={dataSourceList}
                        {...configViewCommonProps}
                    />
                )
            }
            case 'selectGenerationByText': {
                return (
                    <SelectGenerationByTextConfigView
                        config={field as SelectGenerationByTextField}
                        dataSource={dataSource}
                        dataSourceList={dataSourceList}
                        {...configViewCommonProps}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [isExtraConfig, handleCancel, handleOk, currentType, field, tableProps, schema, dataSource, dataSourceList])

    // When the type in extraViewKeys, the internal operation button group is used instead of the external general button group
    const actionGroup = useMemo(() => {
        return (
            (!extraViewKeys.includes(currentType) || !isExtraConfig) && (
                <SC.ActionWrapper>
                    <Button style={{ marginRight: 8 }} onClick={handleCancel}>
                        取消
                    </Button>
                    <Button type="primary" onClick={handleDummyOk}>
                        确定
                    </Button>
                </SC.ActionWrapper>
            )
        )
    }, [currentType, handleCancel, handleDummyOk, isExtraConfig])

    return (
        <>
            {configView}
            {actionGroup}
        </>
    )
}

export default EditorConfigViewRenderer
