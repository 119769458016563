import { Enum } from '@lighthouse/tools'
import type { MakeADT } from 'ts-adt/MakeADT'

import type {
    ActionsProtocol,
    AlignMent,
    Arrangement,
    BaseBreakPointProtocol,
    BlockSize,
    ContainerBreakPointProtocol,
    DataPointerProtocol,
    Direction,
    DisplayProtocol,
    EdgeValue,
    EditableProtocol,
    ExportProtocol,
    GroupByProtocol,
    GroupConfigure,
    HighlightingProtocol,
    ImportProtocol,
    PaginableProtocol,
    PaginationProtocol,
    Position,
    PrintProtocol,
    RecordViewableProtocol,
    RequiredProtocol,
    SearchableProtocol,
    SearchProtocol,
    SortableProtocol,
    SortProtocol,
    TableRowHeightProtocol,
    ViewBreakPointProtocol,
    ViewFieldSettingProtocol,
    ViewFilterableProtocol,
    ViewRecordEditProtocol,
    ViewRecordOperateProtocol,
    ViewTypeProtocol
} from '../../protocols'
import type { TitleConfig } from '../Basic'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'
import type { BlockAbstract } from './Block'

/** ********************************* Table options ********************************* */
export const VIEW_DESIGN_STYLE = Enum('classic', 'simple', 'stripe')
export type ViewDesignStyle = Enum<typeof VIEW_DESIGN_STYLE>

export const VIEW_DESIGN_TYPE = Enum('default', 'card', 'colorBlock')
export type ViewDesignType = 'default' | 'card' | 'colorBlock'

export interface TableViewOptions {
    designStyle?: ViewDesignStyle
    designType?: ViewDesignType
    listAvatar?: string
    isShowListAvatar?: boolean
    avatarType?: 'round' | 'square'
    isShowEmphasizeText?: boolean
    emphasizeTextField?: string
    hiddenHeader?: boolean
}

/** ********************************* kanban options ********************************* */
/**
 * 看板视图配置
 * @date 9/7/2023 - 2:14:29 PM
 *
 * @export
 * @interface KanbanViewOptions
 * @typedef {KanbanViewOptions}
 */
export interface KanbanViewOptions {
    /**
     * 看板分组字段
     * @date 10/26/2023 - 2:13:37 PM
     *
     * @type {?GroupConfigure}
     */
    kanbanGroupConfigure?: GroupConfigure

    /**
     * 允许设置看板
     * @date 9/7/2023 - 2:13:54 PM
     *
     * @type {?boolean}
     */
    canSetKanban?: boolean

    /**
     * 允许拖拽看板
     * @date 9/7/2023 - 2:14:08 PM
     *
     * @type {?boolean}
     */
    canSortKanban?: boolean
}

/** ********************************* calendar options ********************************* */
/**
 * 日历视图配置
 * @date 9/7/2023 - 2:11:17 PM
 *
 * @export
 * @interface CalendarViewOptions
 * @typedef {CalendarViewOptions}
 */
export interface CalendarViewOptions {
    /**
     * 日程标题字段
     * @date 9/7/2023 - 1:58:14 PM
     *
     * @type {?string}
     */
    scheduleTitleField?: string

    /**
     * 日程开始日期字段
     * @date 9/7/2023 - 1:58:56 PM
     *
     * @type {?string}
     */
    scheduleStartDateField?: string

    /**
     * 日程结束日期字段
     * @date 9/7/2023 - 1:59:52 PM
     *
     * @type {?string}
     */
    scheduleEndDateField?: string

    /**
     * 日程颜色展示方式 follow: 跟随字段选项值、或主题色, custom: 自定义颜色
     * @default follow
     * @date 9/7/2023 - 2:08:03 PM
     *
     * @type {?('follow' | 'custom')}
     */
    scheduleColorMode?: 'follow' | 'custom'

    /**
     * 日程颜色
     * @date 9/7/2023 - 2:04:04 PM
     *
     * @type {?string}
     */
    scheduleColor?: string
}

/** ********************************* custom view options ********************************* */
export const CUSTOM_VIEW_LAYOUT = Enum('waterfall', 'grid')
export type CustomViewLayout = Enum<typeof CUSTOM_VIEW_LAYOUT>

export interface ViewLayout {
    layout?: CustomViewLayout
    direction?: Direction
    arrangement?: Arrangement
    size?: Exclude<BlockSize, 'xs'>
    cols?: number // 每行显示
    gap?: number // 间距
    viewPadding?: EdgeValue  // 边距
}

/** ********************************* gallery options ********************************* */
export const GALLERY_AVATAR_SIZE = Enum('sm', 'md', 'lg', 'xl')
export type GalleryAvatarSize = Enum<typeof GALLERY_AVATAR_SIZE>

export const GALLERY_AVATAR_SHAPE = Enum('square', 'circle')
export type GalleryAvatarShape = Enum<typeof GALLERY_AVATAR_SHAPE>

export const GALLERY_CARD_STYLE = Enum('block', 'border', 'none')
export type GalleryCardStyle = Enum<typeof GALLERY_CARD_STYLE>

/** 伸缩方式 */
export interface ObjectFitConfig {
    objectFit?: Extract<React.CSSProperties['objectFit'], 'contain' | 'cover'>
}
export interface RatioConfig {
    ratio?: string
}
/** 摆放位置 */
export interface PositionConfig {
    position?: Exclude<Position, 'bottom'>
}

/** 字段基本设置 */
export interface BaseFieldConfig {
    /** 是否显示 */
    show?: boolean
    /** 展示字段 */
    field?: string
    /** 对齐方式 */
    align?: AlignMent
}

/** 字段显示方式 */
export type GroupFieldValue = {
    // showTitle: boolean
    wrap: boolean
    // title: string
    id: string
}

/**
 * 画廊视图配置
 * @date 9/7/2023 - 2:15:12 PM
 *
 * @export
 * @interface GalleryViewOptions
 * @typedef {GalleryViewOptions}
 */
export interface GalleryViewOptions {
    /** 封面 */
    headCover?: Omit<BaseFieldConfig, 'align'> & ObjectFitConfig & RatioConfig
    /** 头像 */
    headAvatar?: BaseFieldConfig &
        PositionConfig & {
            size?: GalleryAvatarSize
            shape?: GalleryAvatarShape
        }
    /** 标题 */
    headTitle?: BaseFieldConfig
    /** 标签 */
    headTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }
    /** 内容 */
    contentTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }
    /** 尾部 */
    footTags?: {
        show?: boolean
        align?: AlignMent
        fields?: GroupFieldValue[]
        /** 显示字段名 */
        showLabel?: boolean
    }

    /**
     * 卡片风格
     * @date 6/28/2023 - 5:17:05 PM
     *
     * @type {?GalleryCardStyle}
     */
    style?: GalleryCardStyle
}

/** 视图通用配置项 */
interface CommonViewOptions
    extends TitleConfig,
        DataPointerProtocol,
        GroupByProtocol,
        ViewFilterableProtocol,
        SortableProtocol,
        PaginationProtocol,
        ViewRecordOperateProtocol,
        EditableProtocol,
        RequiredProtocol,
        SearchableProtocol,
        ImportProtocol,
        ExportProtocol,
        PrintProtocol,
        HighlightingProtocol,
        SortProtocol,
        DisplayProtocol,
        PaginableProtocol,
        ViewFieldSettingProtocol,
        SearchProtocol,
        RecordViewableProtocol,
        ViewRecordEditProtocol,
        ViewTypeProtocol,
        ActionsProtocol {
    /** 表格行高 */
    tableRowHeight?: TableRowHeightProtocol
}

export type TableViewConfig = CommonViewOptions & TableViewOptions
export type ListViewConfig = CommonViewOptions & TableViewOptions
export type AdvancedTableViewViewConfig = CommonViewOptions
export type GalleryViewConfig = CommonViewOptions & GalleryViewOptions & ViewBreakPointProtocol
export type KanbanViewConfig = CommonViewOptions & KanbanViewOptions
export type CalendarViewConfig = CommonViewOptions & CalendarViewOptions
export type CustomViewConfig = CommonViewOptions & ViewBreakPointProtocol

// export type ViewOptions = MakeADT<
//     'viewType',
//     {
//         table: TableViewConfig & BaseBreakPointProtocol
//         list: ListViewConfig & BaseBreakPointProtocol
//         advancedTable: AdvancedTableViewViewConfig & BaseBreakPointProtocol
//         gallery: GalleryViewConfig & BaseBreakPointProtocol
//         kanban: KanbanViewConfig & BaseBreakPointProtocol
//         calendar: CalendarViewConfig & BaseBreakPointProtocol
//         custom: CustomViewConfig & ContainerBreakPointProtocol
//     }
// >
// 'table' | 'advancedTable' | 'list' | 'gallery' | 'kanban' | 'calendar' | 'custom'

export interface ViewOptions
    extends CommonViewOptions,
        TableViewOptions,
        GalleryViewOptions,
        KanbanViewOptions,
        CalendarViewOptions,
        ViewBreakPointProtocol {}

export abstract class ViewBlockAbstract extends BaseBlock {
    type = BlockType.view

    abstract override config: ViewOptions

    abstract children?: BlockAbstract[]
}
