import type { DTFile, FieldProtocol, FileValue } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { map } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../../../contexts'
import type { WithStatusFile } from '../../../../../types'
import { getFileNameByUrl, getFileSizeByUrl, getFileTypeByUrl } from '../../../../../utils/helper'
import { FileUploader } from '../../../../FileUploader'
import type { FieldBaseProps } from '../../../types'

interface FileDrawerFieldProps extends FieldBaseProps {
    value: FileValue
    fileField?: FieldProtocol
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--font-size-normal);
`

export const FileDrawerField: React.FunctionComponent<FileDrawerFieldProps> = props => {
    const {
        value: data,
        fileField,
        config: { uploadyOptions, title, videoUploadyOption, previewType },
        readOnly,
        onCellChange
    } = props
    const { isBuilder } = useApplicationContext()
    const enableItemActions = useMemo(
        () => ({
            canDelete: !readOnly,
            canDownload: fileField?.type !== 'video',
            canPreview: true,
            canRename: !readOnly
        }),
        [fileField?.type, readOnly]
    )

    const usedValue: WithStatusFile[] = useMemo(
        () =>
            map(url => {
                const name = getFileNameByUrl(url) || ''
                const type = getFileTypeByUrl(url)
                const size = getFileSizeByUrl(url)
                return { name, type, size, url, value: url, status: 'success', uid: nanoid(12) }
            }, data),
        [data]
    )

    const fileValue = useMemo(() => usedValue.map(item => item.url), [usedValue])

    const handleChange = useCallback(
        (files: DTFile[]) => {
            onCellChange?.({ value: fileField?.id === 'AVATAR' ? files.slice(-1) : files, type: 'file' })
        },
        [fileField?.id, onCellChange]
    )

    return (
        <>
            <SCxContainer>
                {uploadyOptions && videoUploadyOption && (
                    <FileUploader
                        isPexels={isBuilder}
                        withoutPopper
                        autoOpenWhenEmpty={false}
                        uploadyOptions={fileField?.type === 'video' ? videoUploadyOption : uploadyOptions}
                        onChange={handleChange}
                        value={fileValue}
                        previewType={previewType}
                        uploadFileType={fileField?.type === 'video' ? 'video' : 'file'}
                        enableItemActions={enableItemActions}
                        disableUpload={readOnly}
                    />
                )}
            </SCxContainer>
        </>
    )
}
