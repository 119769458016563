import type { AnyObject } from 'immer/dist/internal'

import type { DataSourceAbstract } from '../abstracts'
import type { RecordLikeProtocol } from './record'
import type { VariableADTvalue } from './variable'

export enum FilterStatus {
    init = 'init',
    pass = 'pass',
    noPass = 'noPass'
}

export interface FilterOption {
    label: string
    value: string
    children?: FilterOption[]
}
/**
 * 过滤器
 * @export
 * @interface Filter
 */
export interface FilterCommonCondition {
    idVariable?: VariableADTvalue
    operator?: string
    paramList?: VariableADTvalue[]
}

export type Conditions = AnyObject[]

export interface FilterGroupTypeFixedReactHookFormTypeBug {
    where?: 'OR' | 'AND'
    conditions?: []
}

export interface FilterGroupType {
    where?: 'OR' | 'AND'
    conditions?: Conditions
}

export type FilterConditions = FilterCommonCondition | FilterGroupType

export interface FilterFormType {
    expression?: FilterGroupType
}

// export type CreateFilter = FilterFormType & {
//     activeFilterId?: string
// }

// export interface FilterType {
//     activeFilterId: string
//     filter: FilterFormType[]
// }
export type HighLightArea = 'row' | 'cell'

export type HighLightCommonCondition = {
    area?: HighLightArea
    color?: string
} & FilterCommonCondition

export type HighLightConditions = HighLightCommonCondition[]

export interface MenuItem {
    icon: string
    label: string
    value: string
}

export type FilterInputMode =
    | 'input'
    | 'select'
    | 'multiSelect'
    | 'date'
    | 'rangeDate'
    | 'cascade'
    | ''
    | 'person'
    | 'multiPerson'
    | 'checkbox'
/** 包含关系 */
export type InclusionRelation =
    | '<'
    | '<='
    | '='
    | '>'
    | '>='
    | '!='
    | 'contains'
    | 'notContains'
    | 'isEmpty'
    | 'isNotEmpty'
    | 'startWith'
    | 'endWith'
    | 'between'
    | ''

/** 逻辑操作符 */
export type LogicalOperator = 'and' | 'or'

/** value */
export type QuantityDescriptors = string

/** 条件options */
export interface OperatorOptions {
    label: string
    value: InclusionRelation
}

/**
 * 过滤器
 * @export
 * @interface Filter
 */
// export interface Filter {
//     id: string
//     /** filter 之间的逻辑关系 */
//     logic: LogicalOperator // or and
//     // type: PropertyType
//     property?: string // column
//     // options?: OperatorOptions[],
//     inclusionRelation?: InclusionRelation | ''
//     // mode?: FilterMode
//     value?: QuantityDescriptors

//     group?: Filter[]
// }

export interface ListItems {
    label: string
    value: string
}

export interface QuickFilterConfig {
    mode?: 'normal' | 'tile' | 'disable'
    fieldIds: string[]
}

export interface QuickFilterData {
    mode?: 'normal' | 'tile' | 'disable'
    rules: FilterCommonCondition[]
}

export interface RecordData {
    datasource?: DataSourceAbstract
    record?: RecordLikeProtocol
}
