import type { ActionFlowNode, StationMessageActionConfig } from '@lighthouse/shared'
import { generateVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleStationMessage = async (node: ActionFlowNode<StationMessageActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { getCurrentPageDeps } = excParams
    const pageId = getCurrentPageDeps()?.pageId ?? ''

    if (!config) {
        return excParams
    }

    const { title, content, personList } = config

    const titleValue = generateVariableValue({  innerType: 'TEXT',jsonContent: title, extraParams: excParams })
    const contentValue = generateVariableValue({ innerType: 'TEXT', jsonContent: content, extraParams: excParams })

    await srv.activeStationMessageAction({
        personList,
        title: Array.isArray(titleValue) ? '' : String(titleValue),
        content: Array.isArray(contentValue) ? '' : String(contentValue),
        pageId
    })

    return {
        title: titleValue,
        content: contentValue,
        personList
    }
}
