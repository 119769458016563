import type { ApplicationSettingThemeColor, BackgroundProtocol, GradientProtocol } from '@lighthouse/core'
import { BACKGROUND_TYPE, FIT, GRADIENT_MODE, VariableType } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import chroma from 'chroma-js'

import { APP_MAIN_COLOR_VARIABLE } from '../../constants'
import type { EditBackgroundProtocol, HsvaColor } from './type'

export const isHsvaColor = (color: unknown): color is HsvaColor => {
    return (
        typeof color === 'object' &&
        color !== null &&
        Object.hasOwn(color, 'h') &&
        Object.hasOwn(color, 's') &&
        Object.hasOwn(color, 'v') &&
        Object.hasOwn(color, 'a')
    )
}

export const isHexColor = (color: string) => {
    let isHex = false
    try {
        chroma.hex(color)
        isHex = true
    } catch {
        isHex = false
    }

    return isHex
}

export const applyFillPickerValue4CssColorValue = (value: string | undefined, palettes: ApplicationSettingThemeColor[]) => {
    if (value === APP_MAIN_COLOR_VARIABLE) {
        return `var(${APP_MAIN_COLOR_VARIABLE})`
    }

    if (!value) {
        return
    }

    if (isHexColor(value)) {
        return value
    }

    const paletteColor = palettes.find(item => item.id === value)
    if (paletteColor) {
        return paletteColor.value
    }
}

/** 给对应的颜色类型填充默认值 */
export const padInitialBackground = (value?: BackgroundProtocol): EditBackgroundProtocol => {
    switch (value?.type) {
        case BACKGROUND_TYPE.gradient: {
            return checkKeyForStop(value)
        }

        case BACKGROUND_TYPE.image: {
            return {
                image: {
                    resource: {
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'file',
                            value: []
                        }
                    },
                    fit: FIT.cover
                },
                ...checkKeyForStop(value)
            }
        }

        default: {
            return {
                ...(value ? checkKeyForStop(value) : {}),
                color: value?.color || '#ffffff',
                type: BACKGROUND_TYPE.color
            }
        }
    }
}

/** 填充渐变色stop的key */
const checkKeyForStop = (value: BackgroundProtocol | EditBackgroundProtocol): EditBackgroundProtocol => {
    const stops = value.gradient?.stops || [
        { color: '#2D01FF', percentage: 0, key: '#2D01FF' },
        { color: '#F00000', percentage: 100, key: '#F00000' }
    ]
    return {
        ...value,
        gradient: {
            mode: GRADIENT_MODE.linear,
            angle: 90,
            width: 50,
            height: 50,
            ...value.gradient,
            stops: stops.map(item => ({ ...item, key: 'key' in item ? (item.key as string) : nanoid() }))
        }
    }
}

// /** 获取hex或者转换css变量为hex */
// export function applyCssVariableColor(color: string) {
//     console.log("🚀 ~ applyCssVariableColor ~ color:", color)
//     return color === APP_MAIN_COLOR_VARIABLE
//         ? window.getComputedStyle(document.body).getPropertyValue(color)
//         : isHexColor(color)
//         ? color
//         : '#000'
// }

/** 获取渐变色css */
export const getGradientCssText = (value: GradientProtocol, palettes: ApplicationSettingThemeColor[]) => {
    let stops = ''
    let position = ''

    switch (value.mode) {
        case GRADIENT_MODE.linear: {
            stops = value.stops.map(item => `${applyFillPickerValue4CssColorValue(item.color, palettes)} ${item.percentage}%`).join(', ')
            position = `${value.angle}deg, `
            break
        }

        case GRADIENT_MODE.radial: {
            stops = value.stops.map(item => `${applyFillPickerValue4CssColorValue(item.color, palettes)} ${item.percentage}%`).join(', ')
            position = `${value.width ?? 50}% ${value.height ?? 50}% at 50% 50%, `
            break
        }

        case GRADIENT_MODE.conic: {
            stops = value.stops
                .map(item => `${applyFillPickerValue4CssColorValue(item.color, palettes)} ${(item.percentage / 100) * 360}deg`)
                .join(', ')
            position = `from ${value.angle}deg at 50% 50%, `
            break
        }

        default: {
            break
        }
    }

    return `${value.mode}-gradient(${position}${stops})`
}
