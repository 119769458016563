export const interceptFormulaReverseText = (str: string, endRule: Set<string>) => {
    let interceptText = ''
    for (const char of str) {
        const isEnd = endRule.has(char)
        // const isMatch = endMatch.has(char)
        if (isEnd) {
            break
        }
        interceptText += char
    }
    return [...interceptText].reverse().join('')
}


export const interceptFormulaText = (str: string, endRule: Set<string>) => {
    let interceptText = ''
    for (const char of str) {
        const isEnd = endRule.has(char)
        // const isMatch = endMatch.has(char)
        if (isEnd) {
            break
        }
        interceptText += char
    }
    return [...interceptText].join('')
}

export  const replaceText = (text: string) => {
    const bracketIndex = text.lastIndexOf('(')

    if (bracketIndex >= 0 && bracketIndex !== text.length - 1) {
        return text.slice(bracketIndex + 1)
    }
    if (text.startsWith('.')) {
        return text.slice(1)
    }
    return text
}
