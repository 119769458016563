import type { VariableADTvalue } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import {
    combineBackgroundStyle,
    getBackgroundStyle,
    getBorderStyle,
    getLayoutStyle,
    getPaddingStyle,
    getRadiusStyle,
    getShadowStyle,
    getVeinsStyle,
    useApplicationContext,
    useContainerLayoutContext,
    useFillPickerContext,
    useFlowLayoutContext
} from '@lighthouse/shared'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { useCustomViewBlockContext } from '../../blocks'
import { EmptyContainer } from '../../components/EmptyContainer'

const Root = styled.div`
    /* flex: 1 0 0;
    width: 100%;
    height: 100%; */
    min-width: inherit;
    min-height: inherit;
`

interface ContainerLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    node: FlowLayoutNode
    extraSlot?: React.ReactNode
}
export const ContainerLayout = forwardRef<HTMLDivElement, ContainerLayoutProps>(
    ({ disabled: propDisabled, node, children, extraSlot, style, ...rest }, ref) => {
        const { appId } = useApplicationContext()
        const { layout, size, design } = node.data ?? {}

        const { padding } = layout ?? {}
        const { overflow } = size ?? {}
        const { background, veins, border, shadow, blur, radius } = design ?? {}

        const { disabled: globalDisabled, parseBackgroundVariableImage } = useFlowLayoutContext()
        const disabled = propDisabled ?? globalDisabled
        const noChildren = !node.children || node.children.length === 0

        const { palettes } = useFillPickerContext()

        const { record } = useCustomViewBlockContext()

        const { layout: parentLayout } = useContainerLayoutContext()
        const parentDirection = parentLayout?.align?.direction
        const parseBackground = parseBackgroundVariableImage && ((v: VariableADTvalue) => parseBackgroundVariableImage(v, record))

        return (
            <Root
                ref={ref}
                style={{
                    ...style,
                    ...(parentDirection === DIRECTION.vertical
                        ? {
                              width: '100%',
                              flex: 1
                          }
                        : {
                              height: '100%',
                              flex: 1
                          }),

                    // ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction),
                    ...getPaddingStyle(padding),
                    ...getLayoutStyle(layout),
                    overflow,
                    backdropFilter: blur ? `blur(${blur}px)` : undefined,
                    ...combineBackgroundStyle([
                        getBackgroundStyle(appId, background, parseBackground, palettes),
                        getVeinsStyle(veins, palettes)
                    ]),
                    ...getRadiusStyle(radius),
                    ...getBorderStyle(border, palettes),
                    ...getShadowStyle(shadow, palettes)
                }}
                {...rest}
            >
                {children}
                {!disabled && noChildren && <EmptyContainer />}
                {extraSlot}
            </Root>
        )
    }
)
