import { useResizeObserver } from '@byecode/ui'
import { OSS_USER_DATA_PREFIX } from '@lighthouse/assets'
import type {
    AiFieldStatus,
    BaseFieldConfig,
    ButtonAction,
    Field,
    FieldADTValue,
    FieldValue,
    GalleryViewOptions,
    GroupFieldValue,
    HighLightConditions,
    ObjectFitConfig,
    RatioConfig,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    ViewField,
    ViewOptions
} from '@lighthouse/core'
import { GALLERY_AVATAR_SHAPE, GALLERY_AVATAR_SIZE, GALLERY_CARD_STYLE } from '@lighthouse/core'
import {
    ActionButton,
    fieldConvertValue,
    getImageFullUrlInApplication,
    hasIconFieldType,
    RowHighlighter,
    useActionRunningLoadings,
    useApplicationContext,
    ViewFieldStatusPreviewer
} from '@lighthouse/shared'
import { BreakPointSize, mergeRefs, useBreakpoint } from '@lighthouse/tools'
import { Avatar, Box, Card, Group, Image, Stack } from '@mantine/core'
import type { atomWithImmer } from 'jotai-immer'
import type { CSSProperties, ReactNode } from 'react'
import React, { forwardRef, Fragment, useCallback, useMemo } from 'react'

import { RecordOperator } from '../../components/RecordOperator'
import { getImageFromField } from '../../utils'
import { GalleryChecker } from './GalleryChecker'
import * as SC from './styles'
import {
    AVATAR_RADIUS,
    AVATAR_SIZES,
    isAvatarVisible,
    isBasicFieldVisible,
    isContentVisible,
    isFooterVisible,
    isHeadVisible
} from './utils'

interface GalleryRecordProps {
    blockId: string
    record: RecordLikeProtocol
    canViewRecord?: boolean
    headCover?: Omit<BaseFieldConfig, 'align'> & ObjectFitConfig & RatioConfig
    schema: SchemaProtocol['schema']
    columns: ViewField[]
    rowStyleConditions: Record<string, HighLightConditions>
    selectedRecords: string[]
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    galleryItemCheckable?: boolean
    headAvatar: GalleryViewOptions['headAvatar']
    avatarAlignSelf?: CSSProperties['alignSelf']
    headTitle?: BaseFieldConfig
    headTags: GalleryViewOptions['headTags']
    contentTags: GalleryViewOptions['contentTags']
    footTags: GalleryViewOptions['footTags']
    recordStyle: GalleryViewOptions['style']
    actions: ViewOptions['actions']
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    renderItem?: (record: RecordLikeProtocol) => ReactNode
    onClickItem?: (recordId: string) => void
    onSelectRecord?: (checked: boolean, id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (params: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

function scaleOssImg(src: string | undefined) {
    if (!src) {
        return
    }

    if (src.startsWith(OSS_USER_DATA_PREFIX)) {
        return `${src}&x-oss-process=image/resize,l_1200`
    }

    return src
}

export const GalleryRecord = forwardRef<HTMLDivElement, GalleryRecordProps>(
    (
        {
            blockId,
            record,
            canViewRecord,
            headCover,
            schema,
            columns,
            rowStyleConditions,
            selectedRecords,
            galleryItemCheckable,
            headAvatar,
            headTitle,
            headTags,
            contentTags,
            footTags,
            recordStyle,
            recordEditOpenable,
            recordDeleteAble,
            actions,
            aiFieldStatusListAtom,
            renderItem,
            onClickItem,
            onRecordEdit,
            onSelectRecord,
            onRecordDelete,
            onAiGeneration,
            onRecordOperatorActionTrigger,
            onRecordClickedActionTrigger,
            onRenderButtonTitle
        },
        ref
    ) => {
        const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
        const [footerActionsContainerRef, rect] = useResizeObserver<HTMLDivElement>()
        const isRecordSelecting = selectedRecords.length > 0
        const cardBreakPoint = useMemo(() => {
            return rect.width < 220 ? 'sm' : 'md'
        }, [rect.width])
        const { appId } = useApplicationContext()
        const showHeadTitle = !!headTitle?.show && !!headTitle?.field
        const showHeadTag = !!headTags?.show && !!headTags.fields && headTags.fields.length > 0

        const { ref: innerRef, breakPoint, width } = useBreakpoint()

        const avatarAlignSelf = useMemo(() => {
            if (headAvatar?.position !== 'top') {
                return 'flex-start'
            }
            if (!headAvatar?.align || headAvatar?.align === 'left') {
                return 'flex-start'
            }
            if (headAvatar?.align === 'right') {
                return 'flex-end'
            }
            return 'center'
        }, [headAvatar?.position, headAvatar?.align])

        const coverRatio = useMemo(() => {
            if (!headCover?.ratio) {
                return 4 / 3
            }
            const [x, y] = headCover.ratio.split(':')
            return Number(x) / Number(y)
        }, [headCover?.ratio])

        const { content: recordContent, id, dsId } = record

        const [recordOperatorFooterActions, recordOperatorDropdownActions] = useMemo(() => {
            if (!actions) {
                return [undefined, undefined]
            }
            const { recordOperator } = actions
            if (!recordOperator.customized || !recordOperator?.actionGroup?.group) {
                return [undefined, undefined]
            }
            const { group } = recordOperator.actionGroup
            return [group.slice(0, 2), group.slice(2)]
        }, [actions])

        const getFieldValue = useCallback((filed?: Field, value?: FieldValue['value']) => {
            if (!filed || !value) {
                return ''
            }
            const fieldADTValue = { ...filed, value } as FieldADTValue
            return fieldConvertValue(fieldADTValue)
        }, [])

        const handleAiGeneration = useCallback(
            (fieldId: string) => {
                if (!onAiGeneration) {
                    return Promise.resolve(false)
                }
                return onAiGeneration(id, fieldId)
            },
            [id, onAiGeneration]
        )

        const handleRecordClick = useCallback(() => {
            const recordClickedActionParams = actions?.recordClicked
            if (recordClickedActionParams?.customized && recordClickedActionParams?.action) {
                handleActionTriggerWithLoading({
                    id,
                    type: 'click',
                    action: recordClickedActionParams.action,
                    record,
                    trigger: onRecordClickedActionTrigger
                })
                return
            }
            canViewRecord && onClickItem?.(id)
        }, [actions?.recordClicked, canViewRecord, handleActionTriggerWithLoading, id, onClickItem, onRecordClickedActionTrigger, record])

        // 渲染带有控制是否不换行并超出显示省略号的下拉选项
        const renderGroupSelectField = useCallback(
            (config: GroupFieldValue, record: RecordLikeProtocol) => {
                const { content, id: recordId } = record
                const { id, wrap } = config
                const field = schema[id]
                const data = content?.[id]?.value
                const fieldValue = { ...field, value: data } as FieldADTValue
                const realValue = fieldConvertValue(fieldValue)
                const isRender = field && (realValue || hasIconFieldType.has(field.type))
                return (
                    isRender && (
                        <ViewFieldStatusPreviewer
                            dsId={dsId}
                            recordId={recordId}
                            data={data}
                            isWrap={wrap}
                            field={field}
                            disabled
                            width={width}
                            previewHost="gallery"
                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                            onAiGeneration={handleAiGeneration}
                        />
                    )
                )
            },
            [schema, dsId, width, aiFieldStatusListAtom, handleAiGeneration]
        )

        const galleryExtraStyles = useMemo<React.CSSProperties>(() => {
            if (loadings[id]) {
                return {
                    cursor: 'not-allowed'
                }
            }
            return { cursor: 'pointer' }
        }, [id, loadings])

        if (!recordContent) {
            return null
        }

        if (renderItem) {
            return <>{renderItem(record) || ''}</>
        }

        const isBorderStyle = recordStyle === GALLERY_CARD_STYLE.border

        const hasContent =
            isAvatarVisible(headAvatar) ||
            isBasicFieldVisible(headTitle) ||
            isHeadVisible(headTags) ||
            isContentVisible(contentTags) ||
            isFooterVisible(footTags)

        return (
            <SC.GalleryItemCard
                key={id}
                ref={mergeRefs([ref, innerRef])}
                data-key={`${id}&${record.id}`}
                onClick={handleRecordClick}
                recordStyle={recordStyle}
                enableHoverStyle={canViewRecord}
                radius={16}
                withBorder={isBorderStyle}
                style={galleryExtraStyles}
                sx={{
                    borderColor: selectedRecords.includes(id) ? 'var(--color-app-main)' : undefined
                }}
            >
                <RowHighlighter viewFields={columns} recordContent={recordContent} recordConditions={rowStyleConditions} zIndex={-1} />
                {isRecordSelecting ? (
                    <GalleryChecker
                        checked={selectedRecords.includes(id)}
                        checkable={galleryItemCheckable}
                        isRecordSelecting={selectedRecords.length > 0}
                        disableCheck={!canViewRecord}
                        onChange={checked => onSelectRecord?.(checked, id)}
                    />
                ) : (
                    <SC.GalleryOperatorWrapper>
                        <RecordOperator
                            blockId={blockId}
                            dsId={record.dsId}
                            recordId={record.id}
                            record={record}
                            config={{ canEdit: recordEditOpenable, canDelete: recordDeleteAble }}
                            customized={actions?.recordOperator?.customized}
                            actions={recordOperatorDropdownActions}
                            onRecordDelete={onRecordDelete}
                            onRecordEdit={onRecordEdit}
                            // eslint-disable-next-line no-return-await
                            onActionTrigger={async action => await onRecordOperatorActionTrigger?.(action, record)}
                            onRenderButtonTitle={v => onRenderButtonTitle(v)}
                        />
                    </SC.GalleryOperatorWrapper>
                )}

                {/* 2023-09-06 14:06:36 灿白说注释，后面只有表格和高级表格有 CheckBox */}
                {/* {galleryItemCheckable && (
                <SC.GalleryCheckWrapper
                    checked={selectedRecords.includes(id)}
                    onClick={e => {
                        e.stopPropagation()
                        onSelectRecord?.(!selectedRecords.includes(id), id)
                    }}
                >
                    <Checkbox
                        size="xs"
                        styles={{
                            input: {
                                '&:checked': {
                                    backgroundColor: 'var(--color-app-main)',
                                    borderColor: 'var(--color-app-main)'
                                }
                            }
                        }}
                        checked={selectedRecords.includes(id)}
                        onChange={e => onSelectRecord?.(!selectedRecords.includes(id), id)}
                    />
                </SC.GalleryCheckWrapper>
            )} */}
                {/* 封面 */}
                {!!headCover?.show && (
                    <Card.Section style={{ margin: 0, padding: 0, paddingBottom: hasContent ? 8 : 16 }}>
                        <div
                            style={{
                                width: '100%',
                                borderRadius: 12,
                                aspectRatio: `${coverRatio.toString()}`,
                                background:
                                    headCover.field && getImageFromField(recordContent[headCover.field]) ? undefined : 'var(--color-white)'
                            }}
                        >
                            {headCover.field && getImageFromField(recordContent[headCover.field]) && (
                                <img
                                    src={scaleOssImg(getImageFullUrlInApplication(appId, getImageFromField(recordContent[headCover.field])))}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        borderRadius: 12,
                                        objectFit: headCover.objectFit,
                                        aspectRatio: `${coverRatio.toString()}`
                                    }}
                                />
                            )}
                        </div>
                    </Card.Section>
                )}

                {hasContent && (
                    <SC.GalleryBody sx={{ flex: 1, padding: 8 }} avatarPosition={headAvatar?.position}>
                        {/* 头像 */}
                        {!!headAvatar?.show && (
                            <Avatar
                                size={AVATAR_SIZES.get(headAvatar.size ?? GALLERY_AVATAR_SIZE.md)}
                                src={headAvatar.field && scaleOssImg(getImageFullUrlInApplication(appId, getImageFromField(recordContent[headAvatar.field])))}
                                radius={
                                    headAvatar.shape === GALLERY_AVATAR_SHAPE.square
                                        ? AVATAR_RADIUS.get(headAvatar.size ?? GALLERY_AVATAR_SIZE.md)
                                        : AVATAR_SIZES.get(headAvatar.size ?? GALLERY_AVATAR_SIZE.md)
                                }
                                style={{ alignSelf: avatarAlignSelf }}
                            >
                                <Image />
                            </Avatar>
                        )}
                        <SC.GalleryBody sx={{ flex: 1 }}>
                            {/* 头部部分 */}
                            {(showHeadTitle || showHeadTag) && (
                                <SC.GalleryBody>
                                    {/* 头部标题及标签 */}
                                    <SC.GalleryHeader>
                                        {isBasicFieldVisible(headTitle) && (
                                            <div style={{ fontSize: 16, fontWeight: 600, textAlign: headTitle.align }}>
                                                {getFieldValue(schema[headTitle.field], recordContent[headTitle.field]?.value)}
                                            </div>
                                        )}

                                        {isHeadVisible(headTags) ? (
                                            <Group noWrap spacing={6} position={headTags.align}>
                                                {(() => {
                                                    const { fields, showLabel, align } = headTags
                                                    return fields.map((field, index, array) => (
                                                        <SC.GalleryHeadTag
                                                            key={field.id}
                                                            style={{
                                                                textAlign: align,
                                                                maxWidth: `${100 / fields.length}%`
                                                            }}
                                                        >
                                                            {showLabel ? (
                                                                <SC.Label>
                                                                    {columns.find(item => item.fieldId === field.id)?.title}
                                                                </SC.Label>
                                                            ) : null}
                                                            {renderGroupSelectField(field, record)}
                                                            {index !== array.length - 1 && (
                                                                <SC.WithTextDivider orientation="vertical" size={2} />
                                                            )}
                                                        </SC.GalleryHeadTag>
                                                    ))
                                                })()}
                                            </Group>
                                        ) : null}
                                    </SC.GalleryHeader>
                                </SC.GalleryBody>
                            )}

                            {/* 内容部分 */}
                            {isContentVisible(contentTags) && (
                                <Stack spacing={16} sx={{ flex: 1 }}>
                                    {contentTags.fields.map(field => (
                                        <Box
                                            key={field.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: breakPoint === BreakPointSize.xs ? 'column' : 'row',
                                                justifyContent: contentTags.align,
                                                overflow: 'hidden',
                                                gap: 8,
                                                alignItems:
                                                    breakPoint === BreakPointSize.xs
                                                        ? contentTags.align === 'right'
                                                            ? 'flex-end'
                                                            : contentTags.align
                                                        : 'baseline'
                                            }}
                                        >
                                            {contentTags.showLabel ? (
                                                <SC.Label
                                                    style={{
                                                        flex: breakPoint === BreakPointSize.xs ? undefined : '0 0 30%'
                                                    }}
                                                >
                                                    {columns.find(item => item.fieldId === field.id)?.title}
                                                </SC.Label>
                                            ) : null}
                                            <div>{renderGroupSelectField(field, record)}</div>
                                        </Box>
                                    ))}
                                </Stack>
                            )}
                            {/* 底部 */}
                            {isFooterVisible(footTags) && (
                                <Group noWrap spacing={6} position={footTags.align} sx={{ marginTop: 'auto', paddingTop: 24 }}>
                                    {footTags.fields.map((field, index, array) => (
                                        <Fragment key={field.id}>
                                            {footTags.showLabel ? (
                                                <SC.Label style={{ fontSize: 12 }}>
                                                    {columns.find(item => item.fieldId === field.id)?.title}:
                                                </SC.Label>
                                            ) : null}
                                            <SC.CanEllipsisText size="xs" color="var(--color-gray-500)" ellipsis={!field.wrap}>
                                                {getFieldValue(schema[field.id], recordContent[field.id]?.value)}
                                            </SC.CanEllipsisText>
                                            {index !== array.length - 1 && <SC.WithTextDivider orientation="vertical" size={2} />}
                                        </Fragment>
                                    ))}
                                </Group>
                            )}
                        </SC.GalleryBody>
                    </SC.GalleryBody>
                )}
                {recordOperatorFooterActions && (
                    <SC.GalleryActionWrapper
                        ref={footerActionsContainerRef}
                        style={
                            cardBreakPoint === 'sm'
                                ? {
                                      flexDirection: 'column'
                                  }
                                : {
                                      flexDirection: 'row'
                                  }
                        }
                    >
                        {recordOperatorFooterActions.map(item => {
                            return (
                                <ActionButton
                                    key={item.id}
                                    radius="8"
                                    // loading={loadings[item.id]}
                                    disabled={loadings[item.id]}
                                    config={{ ...item, fillWay: cardBreakPoint === 'sm' ? 'auto' : item.fillWay }}
                                    onClick={ev => {
                                        ev.stopPropagation()
                                        handleActionTriggerWithLoading({
                                            id: item.id,
                                            type: 'click',
                                            action: item.action,
                                            record,
                                            trigger: onRecordOperatorActionTrigger
                                        })
                                    }}
                                    onRenderTitle={v => onRenderButtonTitle(v, record)}
                                />
                            )
                        })}
                    </SC.GalleryActionWrapper>
                )}
            </SC.GalleryItemCard>
        )

        // 补偿非卡片风格时的6px outline
        // return isBorderStyle ? dom : <div style={{ padding: 6, height: '100%' }}>{dom}</div>
    }
)
