import { Message, scannerEmitter, Toast } from '@byecode/ui'
import type { Field, SimpleTextValue, TextConfig } from '@lighthouse/core'
import { isWechatBrowser } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import wx from 'weixin-js-sdk'

import { useApplicationContext } from '../../../contexts'
import { TagIcon } from '../../TagIcon'
import BaseInput from '../BaseInput'
import { inputHeightMap } from '../constant'
import type { FieldBaseProps } from '../types'

interface TextFieldProps extends FieldBaseProps {
    // field: Field
    textConfig: TextConfig
    isMobile?: boolean
    value: SimpleTextValue
}
const size = 'middle'

const SCxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: none !important;
`
const TextField: React.FunctionComponent<TextFieldProps> = props => {
    const { onCellChange, onSaveCellChange, value, isMobile, textConfig, readOnly, isControlled } = props
    const { placeholder, text: textConfigure, inputHeight = 'small' } = textConfig ?? {}
    const { disableInputs = [], scannerList = [], enableWxScanner } = textConfigure ?? {}
    const [text, setText] = useState(value)
    const [isFocus, setIsFocus] = useState(false)
    const { isBuilder } = useApplicationContext()
    const scannerReadOnly = disableInputs.includes('album')
    const inputReadOnly = readOnly || disableInputs.includes('input')

    useUpdateEffect(() => {
        setText(value)
    }, [value])

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            const outputValue = ev.target.value
            setText(outputValue)
            onSaveCellChange?.({ type: 'text', value: outputValue })
        },
        [onSaveCellChange]
    )

    const handleBlur = useCallback(() => {
        setIsFocus(false)
        onCellChange?.({ value: text, type: 'text' })
    }, [onCellChange, text])

    const handleScanner = useCallback(() => {
        if (isBuilder) {
            Toast.warning('请前往移动端应用界面中扫码')
            return
        }
        const isWechat = isWechatBrowser()
        if (isWechat && enableWxScanner) {
            const isOpenDebug = window.location.href.includes('byecode-debug')
            wx.ready(() => {
                wx.checkJsApi({
                    jsApiList: ['scanQRCode'],
                    // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success(info) {
                        isOpenDebug && Message.success({ title: `checkJsApi: ${JSON.stringify(info)}` })
                        wx.scanQRCode({
                            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                            scanType: scannerList, // 可以指定扫二维码还是一维码，默认二者都有
                            success(res) {
                                const outputValue = res.resultStr
                                if (outputValue) {
                                    setText(outputValue)
                                    onSaveCellChange?.({ type: 'text', value: outputValue })
                                }
                            },
                            fail(err) {
                                isOpenDebug && Toast.error(`scanQRCode: ${err?.errMsg}`)
                            }
                        })
                    },
                    fail(err) {
                        isOpenDebug && Toast.error(`checkJsApi: ${err?.errMsg}`)
                    }
                })
            })

            return
        }
        scannerEmitter.emit('openScanner', {
            option: {
                scanType: scannerList,
                enableUpload: !scannerReadOnly
            },
            success: v => {
                setText(v)
                onSaveCellChange?.({ value: v, type: 'text' })
            }
        })
    }, [isBuilder, enableWxScanner, scannerList, scannerReadOnly, onSaveCellChange])

    return (
        <SCxContainer>
            <BaseInput
                value={text}
                size={size}
                placeholder={placeholder}
                onFocus={() => setIsFocus(true)}
                onBlur={handleBlur}
                onChange={handleChange}
                data-field-border={isFocus}
                data-field-background
                readOnly={inputReadOnly}
                style={{ minHeight: inputHeightMap[inputHeight] - 2 }}
            />
            {scannerList?.length > 0 && isMobile && (
                <TagIcon
                    icon="Scanner"
                    disabled={scannerReadOnly}
                    size={40}
                    radius={7}
                    iconColor="var(--color-gray-400)"
                    data-field-background
                    onClick={handleScanner}
                    style={{ border: '1px solid var(--color-gray-200)', flexShrink: 0 }}
                />
            )}
        </SCxContainer>
    )
}

export default TextField
