import { Popover, Toast } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import type { DTFile } from '@lighthouse/core'
import { arrayMove } from '@lighthouse/tools'
import { FILE_STATES, useAbortAll } from '@rpldy/uploady'
// import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { clone, concat, findIndex, isEmpty } from 'rambda'
import React, { useCallback, useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import { useImmer } from 'use-immer'

import { useApplicationContext } from '../../../contexts'
import type { ApplicationPreviewEnum, EnableActionsParams, WithStatusFile } from '../../../types'
import { getBatchToInnerFile, getImageFullUrlInApplication } from '../../../utils'
import { getFileNameByUrl, getFileSizeByUrl, getFileTypeByUrl } from '../../../utils/helper'
import { FilePreviewer } from '../../FilePreviewer'
import { List } from '../../List'
import type { UseUploadFileSParameter } from '../../UploadManage'
import { useUploadBatchError, useUploadBatchFinished, useUploadBatchUploading } from '../../UploadManage'
import { useUploadBatch } from '../../UploadManage/hooks/useUploadBatch'
import { FileUploader } from '../FileUploader'
import FileListItem from './FileListItem'
import * as SC from './styles'

export interface FileListProps {
    id?: string
    children?: React.ReactNode
    accept?: string
    isPexels?: boolean
    enableItemActions?: EnableActionsParams
    files: DTFile[]
    autoOpenWhenEmpty?: boolean
    previewType: ApplicationPreviewEnum
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    disableUpload?: boolean
    disableDownload?: boolean
    isUsedLink?: boolean
    popoverMainAxis?: boolean
    multiple?: boolean
    onChange?: (files: DTFile[]) => void
}

export const FileListPreviewer: React.FC<FileListProps> = ({
    id,
    accept,
    isPexels,
    files,
    enableItemActions,
    previewType,
    autoOpenWhenEmpty,
    disableUpload,
    uploadyOptions,
    disableDownload,
    isUsedLink,
    multiple,
    onChange
}) => {
    const [defaultIndex, setDefaultIndex] = useState(0)
    const [opened, setOpened] = useState(false)
    const [popoverOpen, setPopoverOpen] = useState(false)
    const { appId } = useApplicationContext()
    const { t } = useTranslation()
    const uId = useId()
    const targetId = uId
    const uploadDropId = id || uId
    const { batchItems, removeBatchItem } = useUploadBatch(uploadDropId, FILE_STATES.UPLOADING)
    const listData = useMemo(() => {
        const list = batchItems?.map(batchItem => getBatchToInnerFile(batchItem)) || []
        return [
            ...files.map(url => {
                const name = getFileNameByUrl(url) || ''
                const type = getFileTypeByUrl(url)
                const size = getFileSizeByUrl(url)
                return {
                    uid: url,
                    label: name,
                    value: url,
                    size: Number(size),
                    type,
                    url: getImageFullUrlInApplication(appId, url),
                    name: name ?? '',
                    status: 'success',
                    percent: 100
                }
            }),
            ...list
        ]
    }, [appId, batchItems, files])
    const [innerFiles, setInnerFiles] = useImmer(listData)
    const isMounted = useRef({
        mount: true,
        files: innerFiles.map(item => item.url).filter(Boolean)
    }) // 初始化为 true，表示组件已挂载
    // useUpdateEffect(() => {
    //     setInnerFiles(listData)
    // }, [listData])

    const newUploadyOptions = useMemo(
        () => ({ ...uploadyOptions, info: { ...uploadyOptions.info, id: uploadDropId } }),
        [uploadDropId, uploadyOptions]
    )

    useEffect(() => {
        // 在组件卸载时更新 isMounted 的值
        return () => {
            isMounted.current = {
                mount: false,
                files: innerFiles.map(item => item.url).filter(Boolean)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const abortAll = useAbortAll()
    // const abortBatch = useAbortBatch()
    useUploadBatchUploading(
        uploadDropId,
        (batchItems, batchId, targetId) => {
            const hasNotAllowFileType =
                accept && accept !== '*'
                    ? batchItems.some(item => {
                          const { file } = item
                          const fileType = getFileTypeByUrl(file.name)
                          return !accept.includes(fileType)
                      })
                    : false
            if (hasNotAllowFileType) {
                Toast.info(t('unsupportedFormatOnlyImageUploads'))
                abortAll()
                return
            }
            const list = batchItems.map(item => {
                const { file, id, completed } = item
                const { name, type, size } = file
                const fileType = getFileTypeByUrl(name)
                return {
                    uid: id,
                    name,
                    label: name,
                    value: id,
                    type: fileType,
                    status: 'uploading',
                    url: '',
                    size,
                    percent: completed
                }
            })
            const items = multiple ? list : list.slice(0, 1)
            setInnerFiles(draft => {
                items.forEach(item => {
                    const index = findIndex(file => file.uid === item.uid, draft)
                    if (index >= 0) {
                        draft[index] = item
                        return
                    }
                    draft.push(item)
                })
            })
        },
        targetId
    )

    useUploadBatchError(uploadDropId, batchItems => {
        setInnerFiles(draft => {
            return draft.map(item => {
                const { uid } = item
                const uploadingFileIndex = findIndex(({ id }) => uid === id, batchItems)
                if (uploadingFileIndex !== -1) {
                    const uploadFile = draft[uploadingFileIndex]
                    if (uploadFile) {
                        uploadFile.value = uid
                        uploadFile.status = 'error'
                        uploadFile.percent = 100
                    }
                }
                return item
            })
        })
    })

    // useUploadBatchProgress(uploadDropId, batchItems => {
    //     setInnerFiles(draft => {
    //         draft.forEach(item => {
    //             const { uid } = item
    //             const uploadingFile = find(({ id }) => uid === id, batchItems)
    //             if (uploadingFile) {
    //                 const uploadFile = find(file => file.uid === uploadingFile.id, draft)
    //                 if (uploadFile) {
    //                     uploadFile.status = 'uploading'
    //                     uploadFile.percent = uploadingFile.completed
    //                 }
    //             }
    //         })
    //     })
    // })

    useUploadBatchFinished(
        uploadDropId,
        (batchItems, _, tId) => {
            if (tId !== targetId) {
                return
            }
            if (!isMounted.current.mount) {
                const uploadFileUrls = batchItems
                    .map(item => {
                        const { uploadResponse } = item
                        return uploadResponse.data?.content?.url as string
                    })
                    .filter(Boolean)
                const fileUrls = [...isMounted.current.files, ...uploadFileUrls]
                removeBatchItem(uploadDropId, uploadFileUrls)
                onChange?.(fileUrls.filter(Boolean))
                return
            }
            setInnerFiles(draft => {
                batchItems.forEach(batch => {
                    const { uploadResponse, id } = batch
                    const uploadingFileIndex = findIndex(({ uid }) => uid === id, draft)
                    if (uploadingFileIndex >= 0) {
                        const uploadFile = draft[uploadingFileIndex]
                        const url = uploadResponse.data?.content ? getImageFullUrlInApplication(appId, uploadResponse.data.content.url) : ''
                        uploadFile.percent = 100
                        uploadFile.value = url || id
                        uploadFile.url = url
                        uploadFile.status = uploadResponse.data?.content ? 'success' : 'error'
                    }
                })
                onChange?.(draft.map(v => v.url).filter(Boolean))
            })
        },
        targetId
    )
    const handleFileAdd = useCallback(
        (file: WithStatusFile) => {
            setInnerFiles(draft => {
                const newFile = { ...file, label: file.name, uid: file.name, value: file.name, size: Number(file.size), percent: 100 }
                draft.push(newFile)
                onChange?.(concat(files, [newFile.url]))
            })
            setPopoverOpen(false)
        },
        [files, onChange, setInnerFiles]
    )

    const handleFileSort = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }

            const sourceIndex = findIndex(file => file.value === activeId, innerFiles)
            const targetIndex = findIndex(file => file.value === overId, innerFiles)

            const sortedFiles = arrayMove(innerFiles, sourceIndex, targetIndex)

            setInnerFiles(sortedFiles)

            onChange?.(sortedFiles.map(file => file.url).filter(Boolean))
        },
        [innerFiles, onChange, setInnerFiles]
    )

    const handleFileRemove = useCallback(
        (index: number) => {
            setInnerFiles(draft => {
                removeBatchItem(uploadDropId, [draft[index].uid])
                draft.splice(index, 1)
                onChange?.(clone(draft).map(item => item.url))
            })
        },
        [onChange, removeBatchItem, setInnerFiles, uploadDropId]
    )

    const handleFileEdit = useCallback(
        (index: number, name: string) => {
            setInnerFiles(draft => {
                const file = draft[index]
                if (file) {
                    draft.splice(index, 1, { ...file, name })
                    onChange?.(clone(draft).map(item => item.url))
                }
            })
        },
        [onChange, setInnerFiles]
    )

    // 如果没有文件，则默认打开文件上传器
    useMount(() => {
        if (autoOpenWhenEmpty && isEmpty(innerFiles)) {
            setPopoverOpen(true)
        }
    })

    useEffect(() => {
        return () => {
            setPopoverOpen(false)
        }
    }, [])

    const renderFileListItem = useCallback(
        (item: WithStatusFile, index: number) => {
            return (
                <FileListItem
                    enableActions={enableItemActions}
                    data={item}
                    previewType={previewType}
                    disableDownload={disableDownload}
                    onRemove={() => {
                        handleFileRemove(index)
                    }}
                    onChange={file => {
                        handleFileEdit(index, file)
                    }}
                    onPreview={() => {
                        setOpened(true)
                        setDefaultIndex(index)
                    }}
                />
            )
        },
        [disableDownload, enableItemActions, handleFileEdit, handleFileRemove, previewType]
    )

    const listContent = useMemo(() => {
        return innerFiles.length === 0 ? (
            <></>
        ) : (
            <List
                sortable
                rowKey="value"
                onSortEnd={handleFileSort}
                data={innerFiles}
                itemRender={(item, index) => renderFileListItem(item as WithStatusFile, index)}
            />
        )
    }, [handleFileSort, innerFiles, renderFileListItem])

    return (
        <SC.FileListWrapper>
            <SC.FileListContainer>{listContent}</SC.FileListContainer>
            {!disableUpload && (
                <Popover width="auto" withinPortal opened={popoverOpen} onChange={setPopoverOpen}>
                    <Popover.Target>
                        <SC.AddFileBtn>
                            <SC.AddIcon type="Add" />
                            <SC.AddBtnText>
                                添加文件<SC.UploadTip>（仅支持上传 300M 大小以内文件）</SC.UploadTip>
                            </SC.AddBtnText>
                        </SC.AddFileBtn>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <FileUploader
                            accept={accept}
                            multiple={multiple}
                            isPexels={isPexels}
                            isUsedLink={isUsedLink}
                            uploadyOptions={newUploadyOptions}
                            onImageLinkSave={handleFileAdd}
                        />
                    </Popover.Dropdown>
                </Popover>
            )}

            {/* <Popover
                {...bindPopover(popoverState)}
                // componentsProps={{
                //     backdrop: {
                //         style: { zIndex: 9999 }
                //     }
                // }}
                style={{ zIndex: 10_000 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >

            </Popover> */}
            <FilePreviewer defaultIndex={defaultIndex} fileList={innerFiles} opened={opened} onClose={() => setOpened(false)} />
        </SC.FileListWrapper>
    )
}
