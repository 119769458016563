import { Enum } from '@lighthouse/tools'

import type { AlignMent, BackgroundNoImageProtocol, ButtonProtocols, RichTextContentProtocol, Shape } from '../../protocols'
import type { ActionItemFitType, ActionItemIconPosition, ActionItemPattern } from '../../protocols/actions'
import type { BaseBreakPointConfigProtocol, BaseBreakPointProtocol } from '../../protocols/blocks'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export type ButtonBlockTriggerEvent = 'hover' | 'click'

export type ButtonBlockHandleEvent =
    | 'none'
    | 'openPage'
    | 'openFormPage'
    | 'openRecordPage'
    | 'openRecordEditPage'
    | 'openLink'
    | 'scrollTo'
    | 'control'
    | 'goBack'
    | 'notification'
    | 'copy'
    | 'createRecord'
    | 'updateRecord'
    | 'deleteRecord'
    | 'shareToWechat'
    | 'downloadFile'
    | 'iClick'
    | 'aliyunMessage'
    | 'call'
    | 'wechatPay'
    | 'wechatTemplateMsg'
    | 'stripe'
    | 'refreshPage'

export type ButtonBlockType = 'icon-name' | 'icon' | 'name'

export const ButtonSize = Enum('xs', 'sm', 'md', 'lg', 'xl', 'xxl')
export type ButtonSizeType = Enum<typeof ButtonSize>

export type ButtonItem = {
    id: string
    iconPosition: ActionItemIconPosition
    color?: BackgroundNoImageProtocol
    iconColor?: BackgroundNoImageProtocol
    backgroundColor?: BackgroundNoImageProtocol
    borderColor?: BackgroundNoImageProtocol
    fillWay: ActionItemFitType
    pattern: ActionItemPattern
    name: RichTextContentProtocol
} & Omit<ButtonProtocols, 'pattern' | 'name'>

export interface ButtonBlockConfig extends BaseBreakPointProtocol {
    size: ButtonSizeType

    align: AlignMent

    shape: Shape

    radius: string

    group: ButtonItem[]
}

export abstract class ButtonBlockAbstract extends BaseBlock {
    type = BlockType.buttonGroup

    abstract override config: ButtonBlockConfig
}
