import type { BlockAbstract } from '@lighthouse/core'
import type { NodeRenderProps } from '@lighthouse/shared'
import { findBlockById, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { updateBlockAtom } from '@/atoms/page/action'
import { pageBlocksAtom } from '@/atoms/page/state'
import BlockRenderController from '@/components/BlockControllers'
import { useCurrentPageContext } from '@/context/PageContext'
import { useCurrentAppId } from '@/hooks/useApplication'

export const BlockRender = ({ node, children }: NodeRenderProps) => {
    const { pageId } = useCurrentPageContext()
    const currentAppId = useCurrentAppId()
    const block = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(s => findBlockById(node.id, s), [node.id])
    )

    const { run: updateBlock } = useAtomAction(updateBlockAtom)

    const onUpdateBlock = (block: BlockAbstract) => {
        updateBlock({
            appId: currentAppId,
            block,
            pageId
        })
    }

    if (!block) {
        return children as JSX.Element
    }

    return (
        <BlockRenderController blockData={block} node={node} onBlockChange={onUpdateBlock}>
            {children}
        </BlockRenderController>
    )
}
