import type { ApplicationWebsiteSetting, ButtonPattern, NavigationShowMode } from '@lighthouse/core'
import { ApplicationSettingPerfectMode, ApplicationSettingThemeNavBarMode, MAX_CONTAINER_WIDTH_SIZE, SMS_TYPE } from '@lighthouse/core'

import type { ApplicationSettingThemeNavBarModeColor, Mode, NavBarIsShowConfig } from './type'

export const defaultConfig: ApplicationWebsiteSetting = {
    navbar: {
        isShow: true,
        showMode: 1,
        linkList: {
            list: {},
            child: []
        },
        padding: [0,0,0,0],
        radius: [0,0,0,0],
        buttonList: [],
        logo: {
            logoType: 0, // 0为默认取应用设置  1为自定义
            imgUrl: {
                square: ''
            },
            jumpType: 1,
            value: ''
        },
        maxWidth: MAX_CONTAINER_WIDTH_SIZE.unlimited,
        backgroundPadding: 0,
        themeMode: ApplicationSettingThemeNavBarMode.custom,
        width: 14.75,
        height: 3.625,
        isSticky: false,
        backgroundBlur: false,
        leftPadding: 0,
        topPadding: 0,
        leftAndRightPadding: 0,
        topAndDownPadding: 0,
        backgroundOverlay: false,
        align: 'flex-start'
    },
    authentication: {
        logo: {
            type: 'icon'
        },
        name: '',
        describe: '',
        login: {
            phone: {
                isOpened: false
            },
            email: {
                isOpened: false
            },
            weChat: {
                isOpened: false,
                isBindPhone: true,
                // isOpenedH5Auth: false,
                isOpenedH5QrcodeLogin: false
            },
            google: {
                isOpened: false,
                accountId: 'xxx'
            }
        },
        register: {
            allowRegister: true,
            isReviewed: false
        },
        perfect: {
            title: '',
            describe: '',
            selectRoleDescribe: '',
            selectRoleTitle: '',
            mode: ApplicationSettingPerfectMode.COMMON,
            isOpened: false,
            roleSetting: [],
            commonSetting: {
                fields: []
            }
        }
    },
    language: {
        list: []
    },
    sms: {
        smsType: SMS_TYPE.SYSTEM
    },
    webTag: {
        logo: '',
        title: '灯塔科技',
        backgroundColor: '#FFFFFF'
    },
    seo: {
        baidu: {
            isOpened: false,
            verificationInfo: 'xxxx',
            secret: 'xxx',
            quota: 100,
            usage: 1
        },
        google: {
            isOpened: false,
            verificationInfo: 'xxxx',
            secret: 'xxx',
            quota: 100,
            usage: 1
        },
        bing: {
            isOpened: false,
            verificationInfo: 'xxxx',
            secret: 'xxx',
            quota: 100,
            usage: 1
        }
    },
    theme: {
        primaryPointer: '',
        palettes: [],
        textPresetList: []
    },
    domain: {
        isUsedCustomDomain: false,
        domainPrefix: '',
        customDomain: ''
        // isShareWeChatWithCustomDomain: false
        // copyrightInfo: '',
        // MIITfiling: '',
        // PRCfiling: ''
    },
    advertising: {
        isHideLogo: false
    },
    webApp: {
        isPublishApp: false
    },
    privateTemplate: {
        canPrivateTemplate: false
    }
}

export const navbarModeMap = new Map<NavigationShowMode, Mode>([
    [1, 'horizontal'],
    [2, 'verticalWide'],
    [3, 'verticalNarrow']
])

// 导航栏可展示的元素
export const modeShowConfig: Record<Mode, NavBarIsShowConfig> = {
    horizontal: {
        logoName: true,
        linkListName: true,
        buttonListName: true,
        linkListIcon: false
    },
    verticalNarrow: {
        logoName: false,
        linkListName: true,
        buttonListName: true,
        linkListIcon: true
    },
    verticalWide: {
        logoName: true,
        linkListName: true,
        buttonListName: true,
        linkListIcon: true
    }
}

export const navbarButtonThemeStyleMap = new Map<
    ApplicationSettingThemeNavBarMode,
    Record<ButtonPattern | 'onlyIcon', Pick<ApplicationSettingThemeNavBarModeColor, 'borderColor' | 'backgroundColor' | 'color'>>
>([
    [
        ApplicationSettingThemeNavBarMode.custom,
        {
            0: {
                backgroundColor: 'var(--color-white)',
                color: 'var(--color-app-main)',
                borderColor: 'var(--color-white)'
            },
            1: {
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            2: {
                // 暂时处理
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            onlyIcon: {
                backgroundColor: 'transparent',
                color: 'var(--color-white)',
                borderColor: 'var(--color-app-main)'
            }
        }
    ],
    [
        ApplicationSettingThemeNavBarMode.light,
        {
            0: {
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-app-main)'
            },
            1: {
                backgroundColor: 'var(--color-white)',
                color: 'var(--color-black)',
                borderColor: 'var(--color-gray-200)'
            },
            2: {
                // 暂时处理
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            onlyIcon: {
                backgroundColor: 'transparent',
                color: 'var(--color-black)',
                borderColor: 'var(--color-white)'
            }
        }
    ],

    [
        ApplicationSettingThemeNavBarMode.transparent,
        {
            0: {
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-app-main)'
            },
            1: {
                backgroundColor: 'var(--color-white)',
                color: 'var(--color-black)',
                borderColor: 'var(--color-gray-200)'
            },
            2: {
                // 暂时处理
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            onlyIcon: {
                backgroundColor: 'transparent',
                color: 'var(--color-black)',
                borderColor: 'var(--color-white)'
            }
        }
    ],
    [
        ApplicationSettingThemeNavBarMode.dark,
        {
            0: {
                backgroundColor: 'var(--color-white)',
                color: '#2D3240',
                borderColor: 'var(--color-white)'
            },
            1: {
                backgroundColor: '#2D3240',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            2: {
                // 暂时处理
                backgroundColor: 'var(--color-app-main)',
                color: 'var(--color-white)',
                borderColor: 'var(--color-white)'
            },
            onlyIcon: {
                backgroundColor: 'transparent',
                color: 'var(--color-white)',
                borderColor: '#2D3240'
            }
        }
    ]
])

export const verticalWideNavbarMinWidth = 720

export const iconButtonSize = 32

export const sizeRatio = 0.4613

export const RowMaxTabNum = 6

export const PADDING = 40

export const minSizeRatio = 0.6
