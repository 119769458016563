import { Enum } from '@lighthouse/tools'

import type {
    AlignMent,
    BackgroundNoImageProtocol,
    BackgroundProtocol,
    ButtonAction,
    DesignStyle,
    FilterFormType,
    TextBreakPointProtocol
} from '../../protocols'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export const TABS_TYPE = Enum('LINE', 'ROUND', 'CARD_SLOT', 'SEGMENTED')
export type TabsType = Enum<typeof TABS_TYPE>

export const TABS_SEGMENTED_RADIUS = Enum('xs', 'sm', 'md', 'xl')
export type TabsSegmentedRadius = Enum<typeof TABS_SEGMENTED_RADIUS>

export type TabsColorConfig = {
    color?: BackgroundNoImageProtocol
    iconColor?: BackgroundNoImageProtocol
    background?: BackgroundProtocol
    borderColor?: BackgroundNoImageProtocol
}

export interface TabBaseConfig {
    id: string
    name: string
    // associatedView: string
    icon: string
    iconPosition: Exclude<AlignMent, 'center'>
    // sizeFit: SizeFit
    visibilityFilter?: FilterFormType
    action: ButtonAction
}

export interface TabStyleConfig extends TabsColorConfig {
    id: string
    hover?: TabsColorConfig
    active?: TabsColorConfig
}

export type TabConfig = TabBaseConfig & TabStyleConfig

export type TabVariantConfig = Record<
    TabsType,
    {
        align: AlignMent
        styleList: TabStyleConfig[]
    }
>

export interface TabsBlockConfig extends TextBreakPointProtocol {
    variant: TabsType
    variantMap: TabVariantConfig

    baseList: TabBaseConfig[]

    // radius: TabsSegmentedRadius
    // associatedContainer: string
    // font?: TextProtocol & {
    //     advance: string
    //     align: AlignMent
    // }
}

export abstract class TabsBlockAbstract extends BaseBlock {
    type = BlockType.tabs

    abstract override config: TabsBlockConfig
}
