import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { getSizeStyle } from '../../../utils/layout'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode } from '../types'

type StyledProps = {
    disabled?: boolean
}

const StyledNode = styled.div<StyledProps>`
    position: relative;
    flex-shrink: 0;
    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutBaseNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    onDataDrawerVisible?: (val: boolean) => void
}

export const BaseNode = forwardRef<HTMLDivElement, FlowLayoutBaseNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, onDataDrawerVisible, children, ...rest } = props

    const { size } = data.data ?? {}

    const parentLayoutCtx = useContainerLayoutContext()

    const { selectedIds, highlight, disabled: globalDisabled, nodeRender: NodeRender = React.Fragment } = useFlowLayoutContext()
    const disabled = propDisabled ?? globalDisabled

    const isSelected = selectedIds?.includes(data.id)

    const mergeStyle: React.CSSProperties = {
        ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction),
        ...style
    }

    return (
        <StyledNode
            ref={ref}
            style={mergeStyle}
            data-type="node"
            data-selected={!disabled && isSelected}
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            {...rest}
        >
            <NodeRender node={data} />
            {children}
        </StyledNode>
    )
})
