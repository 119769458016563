import type { TextAdvanceOption } from '@lighthouse/core'

import { ApplicationPreviewEnum } from '../types'

/** 应用线上分支id */
export const APPLICATION_VERSION_PROD = 'PROD'

export const APPLICATION_ENV_PROD = 'PROD'

export const MAIN_BREAK_POINT = ApplicationPreviewEnum.desktop
/** 默认字体 */
export const TEXT_FONT_NORMAL = 'NORMAL'
/** 文本模板配置 */
export const TEXT_TEMPLATE_LIST: Omit<TextAdvanceOption, 'id'>[] = [
    {
        name: '超大标题',
        fontFamily: TEXT_FONT_NORMAL,
        fontSize: 3,
        letterSpacing: 0,
        lineHeight: 1.5
    },
    {
        name: '大标题',
        fontFamily: TEXT_FONT_NORMAL,
        fontSize: 2,
        letterSpacing: 0,
        lineHeight: 1.5
    },
    {
        name: '中标题',
        fontFamily: TEXT_FONT_NORMAL,
        fontSize: 1.5,
        letterSpacing: 0,
        lineHeight: 1.45
    },
    {
        name: '小标题',
        fontFamily: TEXT_FONT_NORMAL,
        fontSize: 1.25,
        letterSpacing: 0,
        lineHeight: 1.5
    },
    {
        name: '正文',
        fontFamily: TEXT_FONT_NORMAL,
        fontSize: 1,
        letterSpacing: 0,
        lineHeight: 1.5
    }
]
