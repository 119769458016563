import type { ApplicationSettingNavbar, PageAbstract } from '@lighthouse/core'
import {
    type ApplicationAbstract,
    type ApplicationSetting,
    type AppUser,
    type ButtonEvents,
    type NavigationBaseItem,
    NavigationShowMode
} from '@lighthouse/core'
import cls from 'classnames'
import React, { useMemo } from 'react'

import { PAGE_SCROLL_CONTAINER_HOST } from '../../constants'
import type { LoginAuthType } from '../AccountPage'
import { AppContainerContextProvider } from './AppContainerContext'
import { ApplicationHeaderHorizontal, ApplicationHeaderVertical } from './ApplicationHeader'
import ApplicationHeaderMobile from './ApplicationHeaderMobile'
import { ApplicationTabBar } from './ApplicationTabBar'
import ApplicationWrapper from './ApplicationWrapper'
import { AppNavbarStylesContextProvider } from './AppNavbarStylesContext'
import { defaultConfig } from './constant'
import { getAccessLinkList, getItemChildren, getNavbarThemeStyles, getNavigationStyle, updateNodeAccess } from './help'
import { LanguageBox } from './LanguageBox'
import * as SC from './styles'
import type { ApplicationContainerPreviewMode, ApplicationContainerStyles } from './type'
import { useCurrentShowMode } from './useCurrentShowMode'

interface ApplicationContainerProps {
    id?: string
    application: ApplicationAbstract | null
    userData?: AppUser
    activePage?: string
    selected?: boolean
    // style?: React.CSSProperties
    disableEvent?: boolean
    previewMode: ApplicationContainerPreviewMode
    topNavbar?: React.ReactNode
    notificationBox?: React.ReactNode
    pageList?: PageAbstract[]
    styles?: Partial<Record<ApplicationContainerStyles, React.CSSProperties>>
    language: string
    disabledNavBar?: boolean
    children?: React.ReactNode
    extraNode?: React.ReactNode

    onToLink?: (navigation?: NavigationBaseItem) => void
    onLogout?: () => void
    onCommonCallBack?: (params: ButtonEvents) => void
    onSelect?: (selected: boolean) => void
    onChangeLanguage: (v: string) => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

export const ApplicationContainer: React.FC<ApplicationContainerProps> = ({
    id,
    application,
    userData,
    activePage,
    selected,
    previewMode,
    disableEvent,
    topNavbar,
    notificationBox,
    pageList,
    language,
    disabledNavBar,
    extraNode,
    styles,
    children,
    onChangeLanguage,
    onToLink,
    onLogout,
    onCommonCallBack,
    onSelect,
    onUpdateBindAccount
}) => {
    const { config = defaultConfig } = application ?? {}
    const { theme, navbar } = config
    const { isSticky, height, showMode } = navbar

    const isMobile = previewMode === 'mobile' || previewMode === 'simulationMobile'
    const { ref, showMode: currentShowMode, height: containerHeight } = useCurrentShowMode(showMode, previewMode)

    const headerStyles = getNavbarThemeStyles(navbar, currentShowMode)

    const scale = useMemo(() => {
        if (previewMode !== 'simulationMobile') {
            return
        }
        if (containerHeight < 876) {
            return containerHeight / 876
        }
        return 1
    }, [previewMode, containerHeight])

    const newNavbar: ApplicationSettingNavbar = useMemo(() => {
        const {
            linkList: { child, list }
        } = navbar
        const newList = getAccessLinkList(list, pageList)
        return {
            ...navbar,
            linkList: {
                child: child.filter(v => {
                    return updateNodeAccess(newList[v], newList)
                }),
                list: newList
            }
        }
    }, [navbar, pageList])

    const direction = useMemo(() => getNavigationStyle(currentShowMode).direction, [currentShowMode])

    const languageBox = useMemo(() => {
        return (
            //  @灿白, 移动端全部隐藏切换语言的label
            <LanguageBox language={language} showLabel={!isMobile && !userData?.userId} isMobile={isMobile} onChange={onChangeLanguage} />
        )
    }, [isMobile, language, onChangeLanguage, userData?.userId])

    const contentEle = useMemo(() => {
        if (!application) {
            return null
        }
        return (
            <>
                {currentShowMode === NavigationShowMode.verticalWide && !isMobile && (
                    <ApplicationHeaderVertical
                        isSimulation={previewMode.includes('simulation')}
                        application={application}
                        userData={userData}
                        activePage={activePage}
                        theme={theme}
                        navbar={newNavbar}
                        showMode={currentShowMode}
                        selected={selected}
                        disableEvent={disableEvent}
                        style={styles?.desktopHeader}
                        notificationBox={notificationBox}
                        languageBox={languageBox}
                        onToLink={onToLink}
                        onLogout={onLogout}
                        onCommonCallBack={onCommonCallBack}
                        onSelect={onSelect}
                        onUpdateBindAccount={onUpdateBindAccount}
                    />
                )}
                <SC.PageContainer direction={direction} className="pageContainer">
                    <SC.ScrollArea
                        id={PAGE_SCROLL_CONTAINER_HOST}
                        className={currentShowMode === NavigationShowMode.horizontal && !isMobile ? 'enable' : undefined}
                    >
                        {!disabledNavBar && isMobile && (
                            <ApplicationHeaderMobile
                                application={application}
                                userData={userData}
                                activePage={activePage}
                                theme={theme}
                                navbar={newNavbar}
                                previewMode={previewMode}
                                selected={selected}
                                disableEvent={disableEvent}
                                style={styles?.mobileHeader}
                                notificationBox={notificationBox}
                                languageBox={languageBox}
                                onToLink={onToLink}
                                onLogout={onLogout}
                                onCommonCallBack={onCommonCallBack}
                                onSelect={onSelect}
                                onUpdateBindAccount={onUpdateBindAccount}
                            />
                        )}
                        {!isMobile && currentShowMode === NavigationShowMode.horizontal && (
                            <ApplicationHeaderHorizontal
                                isSimulation={previewMode.includes('simulation')}
                                application={application}
                                userData={userData}
                                activePage={activePage}
                                theme={theme}
                                navbar={newNavbar}
                                showMode={currentShowMode}
                                selected={selected}
                                disableEvent={disableEvent}
                                style={styles?.desktopHeader}
                                notificationBox={notificationBox}
                                languageBox={languageBox}
                                onToLink={onToLink}
                                onLogout={onLogout}
                                onCommonCallBack={onCommonCallBack}
                                onSelect={onSelect}
                                onUpdateBindAccount={onUpdateBindAccount}
                            />
                        )}
                        {children}
                        {(currentShowMode !== NavigationShowMode.horizontal || isMobile) && extraNode}
                    </SC.ScrollArea>
                    {!isMobile && currentShowMode === NavigationShowMode.horizontal && extraNode}
                </SC.PageContainer>
            </>
        )
    }, [
        application,
        currentShowMode,
        isMobile,
        previewMode,
        userData,
        activePage,
        theme,
        newNavbar,
        selected,
        disableEvent,
        styles?.desktopHeader,
        styles?.mobileHeader,
        notificationBox,
        languageBox,
        onToLink,
        onLogout,
        onCommonCallBack,
        onSelect,
        onUpdateBindAccount,
        direction,
        disabledNavBar,
        children,
        extraNode
    ])

    if (!application) {
        return null
    }

    return (
        <AppContainerContextProvider
            scale={scale}
            height={height}
            isSticky={isSticky}
            showMode={currentShowMode}
            disabledPageScroll={currentShowMode === NavigationShowMode.horizontal && !navbar.isSticky}
        >
            <AppNavbarStylesContextProvider navbar={navbar} showMode={currentShowMode}>
                <SC.Container
                    style={styles?.container}
                    themeColor={headerStyles?.backgroundColor}
                    className={cls(previewMode, 'applicationContainer')}
                    ref={ref}
                >
                    <ApplicationWrapper previewMode={previewMode} topChildren={topNavbar} scale={scale} style={styles?.wrapper}>
                        <SC.Layout className={cls({ mobile: isMobile, appLayout: true })} id={id} style={styles?.layout}>
                            {contentEle}
                            {isMobile && (
                                <ApplicationTabBar
                                    onCommonCallBack={onCommonCallBack}
                                    onSelect={onSelect}
                                    activePage={activePage}
                                    navbar={newNavbar}
                                    theme={theme}
                                    selected={selected}
                                    previewMode={previewMode}
                                    disableEvent={disableEvent}
                                />
                            )}
                        </SC.Layout>
                    </ApplicationWrapper>
                </SC.Container>
            </AppNavbarStylesContextProvider>
        </AppContainerContextProvider>
    )
}
