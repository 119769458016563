import { Toast } from '@byecode/ui'
import type { ChartBlockAbstract, ChartLikeProtocol } from '@lighthouse/core'
import { useBreakpoint } from '@lighthouse/tools'
import React, { useCallback, useMemo } from 'react'

import { ExportContent } from './ExportContent'
import { IndicatorChartViewContent } from './IndicatorChartViewContent'
import { SingleChartViewContent } from './SingleChartViewContent'
import * as SC from './styles'

interface ChartBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    loading?: boolean
    blockData: ChartBlockAbstract
    chartData?: ChartLikeProtocol
    onExport?: () => void
}

const ChartBlock: React.FC<ChartBlockProps> = ({ blockData, loading, chartData, onExport, ...rest }) => {
    const { id: blockID, title, config } = blockData
    const { ref, width } = useBreakpoint()
    const { chartType, pointer, canExport, showHeadTitle } = config

    const Content = useMemo(() => {
        if (chartType === 'indicator') {
            return (
                <SC.ChildChartViewContainer>
                    <IndicatorChartViewContent title={title} loading={loading} chartOption={config} chartData={chartData} />
                </SC.ChildChartViewContainer>
            )
        }

        return (
            <SingleChartViewContent
                width={width}
                loading={loading}
                pointer={pointer}
                title={title}
                chartOption={config}
                chartData={chartData}
            />
        )
    }, [chartData, chartType, config, loading, pointer, title, width])

    return (
        <SC.ChartViewBlockContainer id={`block-${blockID}`} data-stop-action-propagation data-block-type="chartView" ref={ref} {...rest}>
            {/* {showHeadTitle && <BlockTitle title={title} />} */}
            <SC.ChildContainer data-content-type="chartView">{Content}</SC.ChildContainer>
            {/* <BlockDragBox /> */}
            {canExport && <ExportContent onExport={onExport} />}
        </SC.ChartViewBlockContainer>
    )
}

export default ChartBlock
