import type {
    AppUser,
    Conditions,
    DataSourceAbstract,
    Field,
    FieldADTValue,
    FieldBlockWithDsId,
    FieldCellValue,
    FilterBlockValue,
    FilterCommonCondition,
    FilterConditions,
    FilterFieldADTValue,
    FilterFormType,
    FilterGroupType,
    FilterOption,
    InputValueItem,
    NullFieldValue,
    RecordData,
    RichTextContentProtocol,
    SystemVariable,
    TypeInstanceMap,
    ValueVariable,
    VariableADTvalue
} from '@lighthouse/core'
import {
    DateFieldUnit,
    FilterStatus,
    VariableType
} from '@lighthouse/core'
import { arrayContainsArray, getKeyByValue } from '@lighthouse/tools'
import {
    addDays,
    addWeeks,
    endOfDay,
    endOfMinute,
    endOfMonth,
    endOfYear,
    getDay,
    isSameDay,
    isSameMinute,
    isSameMonth,
    lightFormat,
    previousDay,
    startOfDay,
    startOfMinute,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths
} from 'date-fns'
import equal from 'fast-deep-equal'
import { clone, find, isEmpty, max } from 'rambda'
import type { MakeADT } from 'ts-adt/MakeADT'

import { generateText, isEmptyRichTextValue } from '../components'
import { defaultDateFormat, defaultFormat, USER_DATASOURCE, variableSystemNameMap, visibleFilterOperatorText } from '../constants'
import type { AppDepartment, AppRole, CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '../types'
import { resolveFilter } from './actionsEngine'
import type { PureBlockAbstract } from './block'
import { isIdsValue, isNumberValue } from './helper'
// id 为记录的ID字段的值 或 用户id
export type CustomViewVisibleData = {
    name: string
    dataSource: DataSourceAbstract

}
type TransformFieldValue = FilterFieldADTValue

const nullFieldValue: NullFieldValue = { id: '', dsId: '', name: 'null', type: 'null', innerType: 'NULL', value: null }


const filterNotSupport: Record<string, string[]> = {
    'array-text': ['>', '<', '>=', '<=', 'startWith', 'endWith'],
    'array-date': ['=', '!=', '>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'array-bool': ['>', '<', '>=', '<=', 'startWith', 'endWith'],
    'array-number': ['>', '<', '>=', '<=', 'startWith', 'endWith'],
    'array-null': ['>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'boolean-date': ['=', '!=', '>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'boolean-boolean': ['startWith', 'endWith', 'contains', 'notContains'],
    'boolean-null': ['>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'date-date': ['startWith', 'endWith', 'contains', 'notContains'],
    'date-text': ['=', '!=', '>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'date-null': ['>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'date-number': ['=', '!=', '>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
    'text-text': ['>', '<', '>=', '<='],
    'number-number': ['startWith', 'endWith', 'contains', 'notContains'],
    'number-null': ['=', '!=', '>', '<', '>=', '<=', 'startWith', 'endWith', 'contains', 'notContains'],
}

// 数组 >  文本 > 布尔 > 数值 > 日期
// type DataType = 'array' | 'text' | 'boolean' | 'number' | 'null'
const dataLevel: Record<TypeInstanceMap, number> = {
    ARRAY: 5,
    TEXT: 4,
    BOOL: 3,
    NUMBER: 2,
    DATE: 1,
    NULL: 0,
    RUNTIME: 0,
    RICH_TEXT: 6
    // date: 1
}



type dataADTValue = MakeADT<
    'innerType',
    {
        TEXT: { value: string, param: string }
        ARRAY: { value: string[], param: string[] }
        NUMBER: { value: number, param: number }
        BOOLEAN: { value: boolean, param: boolean }
    }
>


export const getDataLevel = (innerType: TypeInstanceMap): number => {
    return dataLevel[innerType]
}

export const getMaxDataLevel = (valueInnerType: TypeInstanceMap, paramInnerType: TypeInstanceMap = 'RUNTIME') => {
    const valueLevel = getDataLevel(valueInnerType)
    const paramLevel = getDataLevel(paramInnerType)
    const maxLevel = max(valueLevel, paramLevel)
    return {
        valueLevel,
        paramLevel,
        maxLevel
    }
}

export const upgradeDataLevel = (filterValue: FilterFieldADTValue | undefined, targetLevel: number) => {
    const value = filterValue?.value
    switch (targetLevel) {
        case 5: {
            if (value === undefined || value === null) {
                return []
            }
            return String(value).split(',')
        }
        case 4: {
            if (filterValue?.innerType === 'DATE' && typeof value === 'number') {
                const format = filterValue.type === 'date' ? filterValue.date.format || defaultFormat : defaultFormat
                return lightFormat(value, format)
            }
            if (value === undefined || value === null) {
                return ''
            }
            return String(value).trim()
        }
        case 3: {
            return Boolean(value)
        }
        case 2: {
            if (value === undefined || value === '' || value === null) {
                return Number.NaN
            }
            return Number(value)
        }
        default: {
            return undefined
        }
    }
}



export const upgradeSameLevel = (valueValue: TransformFieldValue, paramValue?: TransformFieldValue) => {
    if (valueValue.innerType === 'RUNTIME' && paramValue?.innerType === 'RUNTIME') {
        const data = {
            innerType: 'TEXT',
            value: valueValue.value,
            param: paramValue?.value
        } as dataADTValue
        return { data, level: 4 }
    }
    const { valueLevel, paramLevel } = getMaxDataLevel(valueValue.innerType, paramValue?.innerType)
    const maxLevel = valueLevel
    const innerType = getKeyByValue(maxLevel, dataLevel)
    const data = {
        innerType,
        value: upgradeDataLevel(valueValue, maxLevel),
        param: upgradeDataLevel(paramValue, maxLevel),
    } as dataADTValue
    return {
        data, level: maxLevel
    }
}


const getCompareWay = (valueInnerType: TypeInstanceMap, paramInnerType: TypeInstanceMap) => {
    if (valueInnerType === 'RUNTIME' && paramInnerType === 'RUNTIME') {
        return 'TEXT-TEXT'
    }
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const compareWayList = [valueInnerType, paramInnerType].sort()
    return compareWayList.join('-')
}

const getIsSupport = (valueInnerType: TypeInstanceMap, paramInnerType: TypeInstanceMap, operator: string) => {
    if (operator === 'isEmpty'|| operator === 'isNotEmpty') {
        return true
    }
    const compareWay = getCompareWay(valueInnerType, paramInnerType)
    const notSupportOperators: string[] | undefined = filterNotSupport[compareWay]
    return !notSupportOperators || !notSupportOperators.includes(operator)
}

export const getDateUnitByFormat = (format: string) => {
    if (format?.includes('mm')) {
        return { unit: DateFieldUnit.MINUTE, format }
    }
    if (format?.includes('dd')) {
        return { unit: DateFieldUnit.DAY, format }
    }
    return { unit: DateFieldUnit.MONTH, format }
}

export const getDateConfig = (field: Field | undefined) => {
    if (field?.type === 'date') {
        const format = field.date?.format || defaultDateFormat
        return getDateUnitByFormat(format)
    }
    return { unit: DateFieldUnit.MINUTE, format: defaultDateFormat }
}



const getRecordCondition: (conditions: Conditions, id?: string) => Conditions = (conditions, id) => {
    return conditions.map(item => {
        // const condition = item as FilterConditions
        if (item.conditions) {
            return {
                ...item,
                conditions: getRecordCondition(item.conditions, id)
            }
        }
        return {
            ...item,
            params: []
        }
    })
}

export const getNumber = (value: string | number) => {
    return value || value === 0 ? Number(value) : undefined
}

export const getDateNumber = (value: string | number, format: string) => {
    const num = getNumber(value)
    if (num === undefined) {
        return num
    }
    return num
    // const dateStr = lightFormat(num, format)

}

export const booleanToFilterStatus = (bool: boolean) => {
    if (bool) {
        return FilterStatus.pass
    }
    return FilterStatus.noPass
}

export const getRecordPageFilter = (filter?: FilterFormType, id?: string): FilterFormType | undefined => {
    if (!filter) {
        return filter
    }
    if (filter?.expression && filter.expression?.conditions) {
        const newConditions = getRecordCondition(filter.expression.conditions, id)
        return {
            expression: {
                where: filter.expression?.where || 'AND',
                conditions: newConditions
            }
        }
    }
    return filter
}

export const getDateSystemValue: (systemVariable: SystemVariable) => FieldADTValue = systemVariable => {
    const systemValue = systemVariable.systemVariable?.value

    return { id: '', value: systemValue, type: 'date', innerType: 'DATE' } as FieldADTValue
}

export const getDateByOperator = (operator: string, date: number, index: number) => {
    switch (operator) {
        case '=': {
            return date
        }
        case '<=': {
            return endOfDay(date).valueOf()
        }
        case '>=': {
            return startOfDay(date).valueOf()
        }
        case 'between': {
            if (index === 0) {
                return startOfDay(date).valueOf()
            }
            return endOfDay(date).valueOf()
        }
        default: {
            return date
        }
    }
}

type GetDateValuePayload = {
    firstParam: FieldCellValue
    secondParam?: FieldCellValue
}

export const getDateValue: (payload: GetDateValuePayload) => number | [number, number] = ({ firstParam, secondParam }) => {
    const today = Date.now()
    switch (firstParam) {
        case 'AFTER_SOMEWHAT_DAYS': {
            if (secondParam && Number.isNaN(Number(secondParam))) {
                const first = addDays(today, Number(secondParam)).valueOf()
                return [startOfDay(first).valueOf(), Infinity]
            }
            return Number.NaN
        }
        case 'BEFORE_SOMEWHAT_DAYS': {
            if (secondParam && Number.isNaN(Number(secondParam))) {
                const first = subDays(today, Number(secondParam)).valueOf()
                // return getDateByOperator(operator, first, index)
                return [0, first]
            }
            return Number.NaN
        }
        case 'NOW':
        case 'TODAY': {
            return [startOfDay(today).valueOf(), endOfDay(today).valueOf()]
            // return getDateByOperator(operator, today, index)
        }
        case 'TOMORROW': {
            const date = addDays(today, 1).valueOf()
            return [startOfDay(date).valueOf(), endOfDay(date).valueOf()]
        }
        case 'YESTERDAY': {
            const date = subDays(today, 1).valueOf()
            return [startOfDay(date).valueOf(), endOfDay(date).valueOf()]
        }
        case 'THIS_WEEK': {
            // nextDay
            const day = getDay(today)
            const weekStartDay = startOfDay(previousDay(today, -day + 1)).valueOf()
            const weekEndDay = endOfDay(addDays(today, 7 - day)).valueOf()
            return [weekStartDay, weekEndDay]
        }
        case 'LAST_WEEK': {
            const day = getDay(today)
            const prevDay = addWeeks(today, -1)
            const weekStartDay = startOfDay(previousDay(prevDay, -day + 1)).valueOf()
            const weekEndDay = endOfDay(addDays(prevDay, 7 - day)).valueOf()
            return [weekStartDay, weekEndDay]
        }
        case 'THIS_MONTH': {
            const start = startOfMonth(today).valueOf()
            const end = endOfMonth(today).valueOf()
            return [start, end]
        }
        case 'LAST_MONTH': {
            const prevMonthDay = subMonths(today, 1)
            const start = startOfMonth(prevMonthDay).valueOf()
            const end = endOfMonth(prevMonthDay).valueOf()
            return [start, end]
        }
        case 'THIS_YEAR': {
            const start = startOfYear(today).valueOf()

            const end = endOfYear(today).valueOf()
            return [start, end]
        }
        case 'PAST_SEVEN_DAYS': {
            const start = startOfDay(subDays(today, 7)).valueOf()
            // const end = endOfDay(subDays(today, 7)).valueOf()
            return [start, endOfDay(today).valueOf()]
        }
        case 'FUTURE_SEVEN_HEAVENS': {
            // const start = startOfDay(addDays(today, 7)).valueOf()
            const end = endOfDay(addDays(today, 7)).valueOf()
            return [startOfDay(today).valueOf(), end]
        }
        case 'PAST_THIRTY_DAYS': {
            const start = startOfDay(subDays(today, 30)).valueOf()
            // const end = endOfDay(subDays(today, 30)).valueOf()
            return [start, endOfDay(today).valueOf()]
        }
        case 'FUTURE_THIRTY_HEAVENS': {
            // const start = startOfDay(addDays(today, 30)).valueOf()
            const end = endOfDay(addDays(today, 30)).valueOf()
            return [startOfDay(today).valueOf(), end]
        }

        default: {
            return isNumberValue(firstParam) ? Number(firstParam) : Number.NaN
        }
    }
}

type GetVariableToFieldValuePayload = {
    idVariable: VariableADTvalue
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord?: RecordData
    pageStackFormState?: Record<string, InputValueItem>
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
}

export const getVariableToFieldValue: (payload: GetVariableToFieldValuePayload) => TransformFieldValue | undefined = ({
    idVariable,
    selfRecord,
    currentRecord,
    prevRecord,
    viewRecord,
    userRecord,
    pageStackFormState,
    currentPageLink,
    dataSourceList
}) => {
    if (idVariable.type === VariableType.FIELD_ID) {
        if (!idVariable.fieldIdVariable?.fieldId) {
            return undefined
        }
        const { fieldId } = idVariable.fieldIdVariable
        const selfRecordContent = selfRecord?.record?.content || {}
        const selfSchema = selfRecord?.datasource?.schema

        const field = selfSchema?.[fieldId]
        if (!field) {
            return undefined
        }
        return { ...field, value: selfRecordContent[fieldId]?.value } as FieldADTValue
    }
    if (idVariable.type === VariableType.VALUE) {
        const idVariableValue = idVariable.valueVariable?.value
        let isIgnore = false
        if (idVariableValue === null || idVariableValue === undefined || Number.isNaN(idVariableValue) || (typeof idVariableValue === 'string' && !idVariableValue.trim()) || (Array.isArray(idVariableValue) && idVariableValue.length === 0)) {
            isIgnore = true
        }
        const type = idVariable.valueVariable?.type || 'text'
        return { ...idVariable.valueVariable, value: idVariableValue, type, id: '', innerType: 'RUNTIME', isIgnore } as FilterFieldADTValue
    }

    if (idVariable.type === VariableType.RESOLVED) {
        const idVariableValue = idVariable.resolvedVariable
        const value = idVariableValue?.value

        const type = idVariableValue?.type || 'text'
        return { value, type, id: '', innerType: idVariableValue?.innerType } as FilterFieldADTValue
    }

    if (idVariable.type === VariableType.USER) {
        if (!userRecord) {
            return nullFieldValue
        }
        const { datasource, record } = userRecord
        if (datasource) {
            const { schema: userSchema } = datasource
            const { fieldId = '' } = idVariable.userVariable || {}
            const userField = userSchema?.[fieldId]
            if (!userField) {
                return undefined
            }
            const userFieldValue = record?.content?.[fieldId]?.value
            return { ...userField, value: userFieldValue } as FieldADTValue
        }
        return nullFieldValue
    }

    if (idVariable.type === VariableType.PAGE_LINK) {
        return { value: currentPageLink, type: 'url', innerType: 'TEXT', id: '' } as FieldADTValue
    }

    if (idVariable.type === VariableType.INPUT) {
        // dataSourceList

        const { blockId = '' } = idVariable.inputVariable ?? {}
        const blockValue = pageStackFormState?.[blockId]
        if (!blockValue) {
            return undefined
        }
        const { value, dsId, fieldId } = blockValue

        const ds = find(ds => ds.id === dsId, dataSourceList || [])
        if (!ds) {
            return undefined
        }
        const { schema } = ds
        const field = schema[fieldId]
        if (!field) {
            return undefined
        }
        return { value, ...field } as FieldADTValue
    }

    if (idVariable.type === VariableType.SYSTEM) {
        return getDateSystemValue(idVariable)
    }

    if (idVariable.type === VariableType.VIEW) {
        const fieldId = idVariable.viewVariable?.fieldId
        if (!fieldId) {
            return undefined
        }
        const viewRecordContent = viewRecord?.record?.content
        const viewSchema = viewRecord?.datasource?.schema
        const cellValue = viewRecordContent?.[fieldId]?.value
        const field = viewSchema?.[fieldId]
        if (!field) {
            return undefined
        }
        return { ...field, value: cellValue } as FieldADTValue
    }

    if (idVariable.type === VariableType.PAGE) {
        if (!idVariable.pageVariable?.fieldId) {
            return undefined
        }
        const currentRecordContent = currentRecord?.record?.content
        const currentSchema = currentRecord?.datasource?.schema

        const prevRecordContent = prevRecord?.record?.content
        const prevSchema = prevRecord?.datasource?.schema
        const { fieldId = '' } = idVariable.pageVariable

        const cellValue =
            idVariable.pageVariable.type === 'parentPage' ? prevRecordContent?.[fieldId]?.value : currentRecordContent?.[fieldId]?.value
        const field = idVariable.pageVariable.type === 'parentPage' ? prevSchema?.[fieldId] : currentSchema?.[fieldId]
        if (!field) {
            return undefined
        }
        return { ...field, value: cellValue } as FieldADTValue
    }
    return nullFieldValue
}


export const verifyFilter = (value: TransformFieldValue, operator: string, param?: TransformFieldValue) => {
    if (operator === 'isEmpty') {
        if (value.innerType === 'ARRAY') {
            return booleanToFilterStatus(isEmpty(value.value))
        }
        if (value.innerType === 'TEXT') {
            return booleanToFilterStatus(!value.value || (typeof value.value === 'string' && !value.value?.trim()))
        }
        if (value.innerType === 'NUMBER') {
            return booleanToFilterStatus((typeof value.value === 'string' && !value.value?.trim()) || Number.isNaN(Number(value.value)))
        }
        return booleanToFilterStatus(!value.value)
    }

    if (operator === 'isNotEmpty') {
        if (value.innerType === 'ARRAY') {
            return booleanToFilterStatus(!isEmpty(value.value))
        }
        if (value.innerType === 'TEXT') {
            return booleanToFilterStatus(typeof value.value === 'string' && !!value.value?.trim())
        }
        if (value.innerType === 'NUMBER') {
            return booleanToFilterStatus((typeof value.value === 'string' && !!value.value?.trim()) || !Number.isNaN(Number(value.value)))
        }
        return booleanToFilterStatus(!!value.value)
    }

    const { data, level } = upgradeSameLevel(value, param)

    // eslint-disable-next-line no-constant-binary-expression
    if (data.value === undefined || !data.param === undefined) {
        return FilterStatus.noPass
    }
    switch (operator) {
        case '=': {
            if (data.innerType === 'ARRAY') {

                // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
                return booleanToFilterStatus(equal(data.value.sort(), data.param.sort()))
            }
            return booleanToFilterStatus(data.value === data.param)
        }
        case '!=': {
            if (data.innerType === 'ARRAY') {

                // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
                return booleanToFilterStatus(!equal(data.value.sort(), data.param.sort()))
            }
            return booleanToFilterStatus(data.value !== data.param)
        }
        case '>': {
            if (data.innerType === 'NUMBER') {
                return booleanToFilterStatus(data.value > data.param)
            }
            return FilterStatus.noPass
        }
        case '>=': {
            if (data.innerType === 'NUMBER') {
                return booleanToFilterStatus(data.value >= data.param)
            }
            return FilterStatus.noPass
        }
        case '<': {
            if (data.innerType === 'NUMBER') {
                return booleanToFilterStatus(data.value < data.param)
            }
            return FilterStatus.noPass
        }
        case '<=': {
            if (data.innerType === 'NUMBER') {
                return booleanToFilterStatus(data.value <= data.param)
            }
            return FilterStatus.noPass
        }

        case 'contains': {
            if (data.innerType === 'ARRAY') {
                return booleanToFilterStatus(arrayContainsArray(data.value, data.param))
            }
            if (data.innerType === 'TEXT') {
                return booleanToFilterStatus(data.value.includes(data.param))
            }
            return FilterStatus.noPass
        }
        case 'notContains': {
            if (data.innerType === 'ARRAY') {
                return booleanToFilterStatus(!arrayContainsArray(data.value, data.param))
            }
            if (data.innerType === 'TEXT') {
                return booleanToFilterStatus(!data.value.includes(data.param))
            }
            return FilterStatus.noPass
        }
        case 'startWith': {
            if (data.innerType === 'TEXT') {
                return booleanToFilterStatus(!data.value.startsWith(data.param))
            }
            return FilterStatus.noPass
        }
        case 'endWith': {
            if (data.innerType === 'TEXT') {
                return booleanToFilterStatus(!data.value.endsWith(data.param))
            }
            return FilterStatus.noPass
        }


        default: {
            return FilterStatus.init
        }
    }
}

// export const verifyTextFilter = (value: FieldCellValue, operator: string, param: FieldCellValue) => {

//     // const maxLevel = getMaxDataLevel(value, param)

//     const { data, level } = upgradeSameLevel(value, param)

//     // const paramValue = Array.isArray(param) ? String(param.join(',')) : String(param)
//     // const textValue = Array.isArray(value) ? value.join(',') : value || ''

//     // const paramValue = Array.isArray(param) ? param.map(String) : String(param)
//     // const textValue = Array.isArray(value) ? value.join(',') : value || ''
//     switch (operator) {
//         case '=': {
//             if (data.type === 'array') {
//                 // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
//                 return booleanToFilterStatus(equal(data.value.sort(), data.param.sort()))
//             }
//             return booleanToFilterStatus(data.value === data.param)
//         }
//         case '!=': {
//             if (data.type === 'array') {
//                 // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
//                 return booleanToFilterStatus(!equal(data.value.sort(), data.param.sort()))
//             }
//             return booleanToFilterStatus(data.value !== data.param)
//         }
//         case 'contains': {
//             if (data.type === 'array') {
//                 return booleanToFilterStatus(arrayContainsArray(data.value, data.param))
//             }
//             if (data.type === 'text') {
//                 booleanToFilterStatus(data.value.includes(data.param))
//             }
//             return FilterStatus.noPass
//         }
//         case 'notContains': {
//             if (data.type === 'array') {
//                 return booleanToFilterStatus(!arrayContainsArray(data.value, data.param))
//             }
//             if (data.type === 'text') {
//                 booleanToFilterStatus(!data.value.includes(data.param))
//             }
//             return FilterStatus.noPass
//         }
//         case 'startWith': {
//             if (data.type === 'text') {
//                 booleanToFilterStatus(!data.value.startsWith(data.param))
//             }
//             return FilterStatus.noPass
//         }
//         case 'endWith': {
//             if (data.type === 'text') {
//                 booleanToFilterStatus(!data.value.endsWith(data.param))
//             }
//             return FilterStatus.noPass
//         }
//         case 'isEmpty': {
//             if (data.type === 'text') {
//                 return booleanToFilterStatus(!data.value.trim())
//             }
//             return booleanToFilterStatus(!data.value)
//         }
//         case 'isNotEmpty': {
//             return booleanToFilterStatus(!!data.value)
//         }
//         default: {
//             return FilterStatus.init
//         }
//     }
// }


export const verifyNoteFilter = (
    value: RichTextContentProtocol | RichTextContentProtocol[],
    operator: string,
    paramValue: FieldCellValue
) => {
    switch (operator) {
        case 'contains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.some(itemValue => {
                        const fieldValue = generateText(itemValue)
                        return fieldValue.includes(String(paramValue))
                    })
                )
            }
            const fieldValue = generateText(value)
            return booleanToFilterStatus(fieldValue.includes(String(paramValue)))
        }
        case 'notContains': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.every(itemValue => {
                        const fieldValue = generateText(itemValue)
                        return !fieldValue.includes(String(paramValue))
                    })
                )
            }
            const fieldValue = generateText(value)
            return booleanToFilterStatus(!fieldValue.includes(String(paramValue)))
        }
        case 'isEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.every(itemValue => {
                        return isEmptyRichTextValue(itemValue)
                    })
                )
            }
            return booleanToFilterStatus(isEmptyRichTextValue(value))
        }
        case 'isNotEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(
                    value.some(itemValue => {
                        return !isEmptyRichTextValue(itemValue)
                    })
                )
            }
            return booleanToFilterStatus(!isEmptyRichTextValue(value))
        }
        default: {
            return FilterStatus.init
        }
    }
}

// const getDateFieldValue = (value: DateValue, range: DateFieldUnit | undefined) => {
//     if()
// }

export const getRangeDateValueByUnit = (value: [number, number], unit: DateFieldUnit) => {
    if (unit === DateFieldUnit.MONTH) {
        const start = startOfMonth(value[0]).valueOf()
        const end = endOfMonth(value[1]).valueOf()
        return {
            start, end
        }
    }
    if (unit === DateFieldUnit.DAY) {
        const start = startOfDay(value[0]).valueOf()
        const end = endOfDay(value[1]).valueOf()
        return {
            start, end
        }
    }
    const start = startOfMinute(value[0]).valueOf()
    const end = endOfMinute(value[1]).valueOf()
    return {
        start, end
    }
}

export const getDateValueByUnit = (value: number, unit: DateFieldUnit) => {
    if (unit === DateFieldUnit.MONTH) {
        const start = startOfMonth(value).valueOf()
        const end = endOfMonth(value).valueOf()
        return {
            start, end
        }
    }
    if (unit === DateFieldUnit.DAY) {
        const start = startOfDay(value).valueOf()
        const end = endOfDay(value).valueOf()
        return {
            start, end
        }
    }
    const start = startOfMinute(value).valueOf()
    const end = endOfMinute(value).valueOf()
    return {
        start, end
    }
}

export const verifyDateFilter = (dateFieldValue: TransformFieldValue, operator: string, paramList: TransformFieldValue[]) => {
    const v = dateFieldValue.value
    if (operator === 'isEmpty') {
        const isEmptyArray = Array.isArray(v) ? v.length === 0 : false
        return booleanToFilterStatus((!v && v !== 0) || isEmptyArray)
    }

    if (operator === 'isNotEmpty') {
        const isEmptyArray = Array.isArray(v) ? v.length === 0 : false
        return booleanToFilterStatus((!!v || v === 0) && !isEmptyArray)
    }

    const firstParam = paramList?.[0]
    const secondParam = paramList?.[1]
    if (dateFieldValue.type === 'null' && firstParam.type === 'null') {
        return FilterStatus.pass
    }
    if (dateFieldValue.type !== 'date') {
        return FilterStatus.noPass
    }

    const { date, value } = dateFieldValue

    const leftFormat = date.format || defaultFormat
    const rightFormat = firstParam.type === 'date' ? firstParam.date?.format || defaultFormat : defaultFormat
    const format = leftFormat.length > rightFormat.length ? leftFormat : rightFormat
    // if (!firstParam) {
    //     return FilterStatus.init
    // }
    const { unit } = getDateUnitByFormat(format)
    // const num = getDateValue({ firstParam: value })
    const fieldValue = getDateValue({ firstParam: value })

    const paramValue = getDateValue({ firstParam: firstParam.value as FieldCellValue, secondParam: (secondParam?.value || Number.NaN) as FieldCellValue })
    if (!paramValue && operator !== 'isEmpty' && operator !== 'isNotEmpty') {
        return FilterStatus.noPass
    }

    if (Array.isArray(fieldValue) && Array.isArray(paramValue)) {
        return FilterStatus.noPass
    }
    switch (operator) {
        case '=': {
            if (Array.isArray(fieldValue)) {
                if (Array.isArray(paramValue)) {
                    return FilterStatus.noPass
                }
                const { start, end } = getRangeDateValueByUnit(fieldValue, unit)
                const { start: paramStart, end: paramEnd } = getDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(paramStart <= start && end <= paramEnd)
            }
            if (Array.isArray(paramValue)) {
                if (Array.isArray(fieldValue)) {
                    return FilterStatus.noPass
                }
                const { start: paramStart, end: paramEnd } = getRangeDateValueByUnit(paramValue, unit)
                const { start, end } = getDateValueByUnit(fieldValue, unit)
                return booleanToFilterStatus(paramStart <= start && end <= paramEnd)
            }
            if (unit === DateFieldUnit.MONTH) {
                return booleanToFilterStatus(isSameMonth(Number(value), Number(paramValue)))
            }
            if (unit === DateFieldUnit.DAY) {
                return booleanToFilterStatus(isSameDay(Number(value), Number(paramValue)))
            }
            return booleanToFilterStatus(isSameMinute(Number(value), Number(paramValue)))
        }
        // case 'between':
        // case 'contains': {
        //     if (Array.isArray(dateValue)) {
        //         if (unit === DateFieldUnit.MONTH) {
        //             const start = startOfMonth(numValue).valueOf()
        //             const end = endOfMonth(numValue).valueOf()
        //             return booleanToFilterStatus(dateValue[0] <= start && end <= dateValue[1])
        //         }
        //         return booleanToFilterStatus(dateValue[0] <= numValue && numValue <= dateValue[1])
        //     }
        //     if (unit === DateFieldUnit.MONTH) {
        //         const start = startOfMonth(numValue).valueOf()
        //         const end = endOfMonth(numValue).valueOf()
        //         return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
        //     }
        //     if (unit === DateFieldUnit.MINUTE) {
        //         const start = startOfMinute(numValue).valueOf()
        //         const end = endOfMinute(numValue).valueOf()
        //         return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
        //     }
        //     if (unit === DateFieldUnit.DAY) {
        //         const start = startOfDay(numValue).valueOf()
        //         const end = endOfDay(numValue).valueOf()
        //         return booleanToFilterStatus(start <= Number(dateValue) && Number(dateValue) <= end)
        //     }
        //     return booleanToFilterStatus(isSameDay(Number(value), Number(dateValue)))
        // }
        case '<=': {
            if (Array.isArray(fieldValue)) {
                if (Array.isArray(paramValue)) {
                    return FilterStatus.noPass
                }
                const { end } = getRangeDateValueByUnit(fieldValue, unit)
                const { start: paramStart } = getDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(end <= paramStart)
            }

            if (Array.isArray(paramValue)) {
                if (Array.isArray(fieldValue)) {
                    return FilterStatus.noPass
                }
                const { start, end } = getDateValueByUnit(fieldValue, unit)
                const { start: paramStart, end: paramEnd } = getRangeDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(end <= paramStart)
            }
            const { start, end } = getDateValueByUnit(fieldValue, unit)
            const { start: paramStart, end: paramEnd } = getDateValueByUnit(paramValue, unit)
            return booleanToFilterStatus(end <= paramStart)
        }
        case '>=': {
            if (Array.isArray(fieldValue)) {
                if (Array.isArray(paramValue)) {
                    return FilterStatus.noPass
                }
                const { start } = getRangeDateValueByUnit(fieldValue, unit)
                const { end: paramEnd } = getDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(start >= paramEnd)
            }

            if (Array.isArray(paramValue)) {
                if (Array.isArray(fieldValue)) {
                    return FilterStatus.noPass
                }
                const { start } = getDateValueByUnit(fieldValue, unit)
                const { end: paramEnd } = getRangeDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(start >= paramEnd)
            }
            const { start } = getDateValueByUnit(fieldValue, unit)
            const { end: paramEnd } = getDateValueByUnit(paramValue, unit)
            return booleanToFilterStatus(start >= paramEnd)
        }
        case '<': {
            if (Array.isArray(fieldValue)) {
                if (Array.isArray(paramValue)) {
                    return FilterStatus.noPass
                }
                const { end } = getRangeDateValueByUnit(fieldValue, unit)
                const { start: paramStart } = getDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(end < paramStart)
            }

            if (Array.isArray(paramValue)) {
                if (Array.isArray(fieldValue)) {
                    return FilterStatus.noPass
                }
                const { start, end } = getDateValueByUnit(fieldValue, unit)
                const { start: paramStart, end: paramEnd } = getRangeDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(end < paramStart)
            }
            const { start, end } = getDateValueByUnit(fieldValue, unit)
            const { start: paramStart, end: paramEnd } = getDateValueByUnit(paramValue, unit)
            return booleanToFilterStatus(end < paramStart)
        }
        case '>': {
            if (Array.isArray(fieldValue)) {
                if (Array.isArray(paramValue)) {
                    return FilterStatus.noPass
                }
                const { start } = getRangeDateValueByUnit(fieldValue, unit)
                const { end: paramEnd } = getDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(start > paramEnd)
            }

            if (Array.isArray(paramValue)) {
                if (Array.isArray(fieldValue)) {
                    return FilterStatus.noPass
                }
                const { start } = getDateValueByUnit(fieldValue, unit)
                const { end: paramEnd } = getRangeDateValueByUnit(paramValue, unit)
                return booleanToFilterStatus(start > paramEnd)
            }
            const { start } = getDateValueByUnit(fieldValue, unit)
            const { end: paramEnd } = getDateValueByUnit(paramValue, unit)
            return booleanToFilterStatus(start > paramEnd)
        }

        default: {
            return FilterStatus.init
        }
    }
}

export const verifyDefaultFilter = (value: FieldCellValue, operator: string) => {
    switch (operator) {
        case 'isEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(value.length === 0)
            }
            return booleanToFilterStatus(!value)
        }
        case 'isNotEmpty': {
            if (Array.isArray(value)) {
                return booleanToFilterStatus(value.length > 0)
            }
            return booleanToFilterStatus(!!value)
        }
        default: {
            return FilterStatus.init
        }
    }
}

type CheckOperatorPayload = {
    userRecord: RecordData
    fieldValue: TransformFieldValue
    operator: string
    params: TransformFieldValue[]
}

export const checkOperator: (payload: CheckOperatorPayload) => FilterStatus = payload => {
    const {
        userRecord,
        fieldValue,
        operator,
        params,
    } = payload
    // const isHasParams = isEmptyOperator(operator)

    const firstParamFieldValue = params?.[0]

    // if(fieldValue === undefined){
    //     return FilterStatus.noPass
    // }
    const fieldValueVariable = fieldValue
    const { innerType } = fieldValueVariable
    const isSupport = getIsSupport(innerType, firstParamFieldValue?.innerType || 'RUNTIME', operator)
    if (!isSupport) {
        return FilterStatus.noPass
    }
    const fieldInnerType = innerType === 'RUNTIME' ? firstParamFieldValue.innerType : innerType
    switch (fieldInnerType) {
        case 'TEXT':
        case 'NUMBER':
        case 'BOOL':
        case 'NULL': {
            return verifyFilter(fieldValueVariable, operator, firstParamFieldValue)
        }
        case 'ARRAY': {
            // const paramValue = firstParam.valueVariable?.value
            const data = isIdsValue(firstParamFieldValue?.value) ? firstParamFieldValue.value : []
            const ids = data.map(id => {
                if (id === '{currentUserId}' && userRecord.record) {
                    return userRecord.record.content?.['ID']?.value || id
                }
                return id
            })

            return verifyFilter(fieldValueVariable, operator, { type: 'person', value: ids, innerType: 'ARRAY' } as FieldADTValue)
        }
        case 'DATE': {
            if (!fieldValue) {
                return FilterStatus.noPass
            }
            return verifyDateFilter(fieldValue, operator, params)
        }

        default: {
            return verifyFilter(fieldValueVariable, operator, firstParamFieldValue)
        }
    }
}

export const getIsEmptyValueVariable = (data: ValueVariable[], operator: string | undefined) => {
    if (operator === 'between') {
        return data.length !== 2
    }
    return data.reduce((prev, cur) => {
        if (!cur.valueVariable?.value || isEmpty(cur.valueVariable?.value)) {
            prev = true
        }
        return prev
    }, false)
}


export const filterPassStatusToBoolean = (filterStatus: FilterStatus) => {
    return filterStatus === FilterStatus.pass
}

type FilterRecordUnmatchedPayload = {
    where: 'AND' | 'OR'
    conditions: FilterConditions[]
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    pageStackFormState: Record<string, InputValueItem> | undefined
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
    initStatus: boolean
}

type transformParamListPayload = {
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    pageStackFormState: Record<string, InputValueItem> | undefined
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
    paramList: VariableADTvalue[]
}

export const transformParamList = (payload: transformParamListPayload) => {
    const { paramList, selfRecord, currentRecord, prevRecord, viewRecord, userRecord, pageStackFormState, currentPageLink, dataSourceList } = payload
    const result: TransformFieldValue[] = []

    for (const item of paramList) {
        if (item.type === VariableType.FIELD_ID) {
            return FilterStatus.init
        }
        const filterValue = getVariableToFieldValue({
            idVariable: item,
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList
        })

        if (filterValue === undefined) {
            return FilterStatus.noPass
        }

        if (filterValue?.isIgnore) {
            return FilterStatus.init
        }
        // const format = filterValue.type === 'date' ? filterValue.date?.format : undefined
        result.push(filterValue)
    }
    return result
}

export const filterRecordUnmatched: (payload: FilterRecordUnmatchedPayload) => FilterStatus = ({
    where,
    conditions,
    selfRecord,
    currentRecord,
    prevRecord,
    userRecord,
    viewRecord,
    pageStackFormState,
    currentPageLink,
    dataSourceList,
    initStatus
}) => {
    if (conditions.length === 0) {
        return FilterStatus.init
    }
    const statusArr = conditions.reduce<FilterStatus[]>((prev, cur) => {
        const childrenConditions = (cur as FilterGroupType)?.conditions
        const childrenWhere = (cur as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            const status = filterRecordUnmatched({
                where: childrenWhere,
                conditions: childrenConditions,
                currentRecord,
                prevRecord,
                viewRecord,
                userRecord,
                pageStackFormState,
                dataSourceList,
                initStatus
            })
            if (status !== FilterStatus.init) {
                prev.push(status)
            }
            return prev
        }
        const condition = cur as FilterCommonCondition
        const { idVariable, operator, paramList } = condition
        if (!idVariable || !operator || !paramList) {
            return prev
        }
        const data = transformParamList({
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList,
            paramList
        })
        console.log('🚀 ~ file: filter.ts ~ line 1222 ~ statusArr ~ data', data)

        if (operator !== 'isEmpty' && operator !== 'isNotEmpty') {
            if (data === FilterStatus.init) {
                return prev
            }

            if (data === FilterStatus.noPass) {
                prev.push(data)
                return prev
            }
        }

        const fieldValue = getVariableToFieldValue({
            idVariable,
            selfRecord,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList
        })
        console.log('🚀 ~ file: filter.ts ~ line 1245 ~ statusArr ~ fieldValue', fieldValue)
        if (fieldValue === undefined) {
            prev.push(FilterStatus.noPass)
            return prev
        }

        if (fieldValue?.isIgnore) {
            // FilterStatus.init
            return prev
        }

        const params = data === FilterStatus.init || data === FilterStatus.noPass ? [] : data

        const verify = checkOperator({
            userRecord,
            fieldValue,
            operator,
            params,
        })
        console.log('🚀 ~ file: filter.ts ~ line 1263 ~ statusArr ~ verify', verify)

        if (initStatus && verify !== FilterStatus.init) {
            prev.push(verify)
        }
        if (!initStatus) {
            prev.push(verify)
        }

        return prev
    }, [])

    if (where === 'OR') {
        return booleanToFilterStatus(
            statusArr.reduce((accumulator, currentValue) => accumulator || filterPassStatusToBoolean(currentValue), false)
        )
    }
    if (!initStatus && statusArr.length === 0) {
        return booleanToFilterStatus(false)
    }
    return booleanToFilterStatus(statusArr.reduce((accumulator, currentValue) => accumulator && filterPassStatusToBoolean(currentValue), true))
}

type FilterRecordCheckerPayload = {
    filter: FilterFormType
    selfRecord?: RecordData
    currentRecord?: RecordData
    prevRecord?: RecordData
    viewRecord?: RecordData
    userRecord: RecordData
    pageStackFormState?: Record<string, InputValueItem>
    currentPageLink?: string
    dataSourceList?: DataSourceAbstract[]
    initStatus?: boolean
}

export const filterRecordChecker = (payload: FilterRecordCheckerPayload) => {
    const { filter, currentRecord, prevRecord, viewRecord, userRecord, pageStackFormState, currentPageLink, dataSourceList, initStatus = true } = payload
    // const currentRecordContent = currentRecord?.content
    // const prevRecordContent = prevRecord?.content
    const conditions = filter.expression?.conditions
    const where = filter.expression?.where
    if (conditions && where) {
        const filterStatus = filterRecordUnmatched({
            where,
            conditions,
            currentRecord,
            prevRecord,
            viewRecord,
            userRecord,
            pageStackFormState,
            currentPageLink,
            dataSourceList,
            initStatus
        })
        return filterStatus === FilterStatus.init || filterStatus === FilterStatus.pass
    }
    return initStatus
}

type GetDataSourceByIdVariablePayload = {
    customViewData?: CustomViewVisibleData
    dataSourceList: DataSourceAbstract[]
    idVariable?: VariableADTvalue
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const getDataSourceByIdVariable = (payload: GetDataSourceByIdVariablePayload) => {
    const { customViewData, dataSourceList, idVariable, fieldBlocksWithDsId } = payload
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.FIELD_ID) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return
    }
    if (idVariable.type === VariableType.PAGE) {
        return find(item => item.id === idVariable.pageVariable?.dsId, dataSourceList)
    }
    if (idVariable.type === VariableType.VIEW) {
        return customViewData?.dataSource
    }
    if (idVariable.type === VariableType.USER) {
        return find(item => item.id === USER_DATASOURCE, dataSourceList)
    }
    if (idVariable.type === VariableType.INPUT) {
        const fieldBlockWithDsId = find(item => item.id === idVariable?.inputVariable?.blockId, fieldBlocksWithDsId)
        return find(item => item.id === fieldBlockWithDsId?.dsId, dataSourceList)
    }
    return undefined
}

export const getFieldByIdVariable = (
    dataSource: DataSourceAbstract | undefined,
    idVariable: VariableADTvalue | undefined,
    fieldBlocksWithDsId: FieldBlockWithDsId[]
) => {
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.FIELD_ID) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return { id: '', type: 'person' } as Field
    }
    if (idVariable.type === VariableType.PAGE) {
        const field = dataSource?.schema[idVariable.pageVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }
    if (idVariable.type === VariableType.VIEW) {
        const field = dataSource?.schema[idVariable.viewVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }
    if (idVariable.type === VariableType.USER) {
        const field = dataSource?.schema[idVariable.userVariable?.fieldId || '']
        if (!field) {
            return
        }
        return field
    }

    if (idVariable.type === VariableType.INPUT) {
        // idVariable.inputVariable.blockId
        const fieldBlock = find(item => item.id === idVariable.inputVariable?.blockId, fieldBlocksWithDsId)
        if (!fieldBlock) {
            return
        }
        const field = dataSource?.schema[fieldBlock.config?.fieldPointer || '']
        if (!field) {
            return
        }
        return field
    }
    return undefined
}

type IdVariableToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    idVariable: VariableADTvalue | undefined
}

export const idVariableToString = (payload: IdVariableToStringPayload) => {
    const { customViewData, currentPage, prevPage, dataSourceList, fieldBlocksWithDsId, idVariable } = payload
    if (!idVariable) {
        return
    }
    if (idVariable.type === VariableType.VALUE && idVariable.valueVariable?.value === '{currentUserId}') {
        return '当前登录用户'
    }
    if (idVariable.type === VariableType.PAGE_LINK && idVariable.pageLinkVariable?.value === 'CURRENT_PAGE') {
        return '当前页面链接'
    }
    if (idVariable.type === VariableType.USER) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (dataSource && field) {
            return `${dataSource.name}.${field.name}`
        }
    }
    if (idVariable.type === VariableType.PAGE) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        const prevPageName = prevPage.page?.name || '-'
        const currentPageName = currentPage.page?.name || '-'
        const name = idVariable.pageVariable?.type === 'parentPage' ? prevPageName : currentPageName
        if (dataSource && field) {
            return `${name}.${dataSource.name}.${field.name}`
        }
    }

    if (idVariable.type === VariableType.VIEW) {
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (dataSource && field) {
            return `${dataSource.name}.${field.name}`
        }
    }

    if (idVariable.type === VariableType.INPUT) {
        const { blockId } = idVariable?.inputVariable ?? {}
        const fieldBlock = find(item => item.id === blockId, fieldBlocksWithDsId)
        if (fieldBlock) {
            return `输入框${fieldBlock.title}`
        }
    }
    return undefined
}

export const paramToString: (field: Field, fieldValue: FieldCellValue, personOptions: AppUser[], roleOptions: AppRole[],
    departmentOptions: AppDepartment[]) => string = (
        field,
        fieldValue,
        personOptions,
        roleOptions,
        departmentOptions
    ) => {
        if (!fieldValue && typeof fieldValue !== 'boolean') {
            return ''
        }

        const { type } = field
        switch (type) {
            case 'textGeneration':
            case 'id':
            case 'text':
            case 'url':
            case 'phoneNumber':
            case 'email':
            case 'number':
            case 'notes': {
                return String(fieldValue)
            }
            case 'person': {
                if (!Array.isArray(fieldValue)) {
                    return ''
                }
                return fieldValue
                    .map(id => {
                        if (typeof id !== 'string') {
                            return ''
                        }
                        if (id === '{currentUserId}') {
                            return '当前用户'
                        }
                        const user = find(item => item.userId === id, personOptions)
                        return user?.username || ''
                    })
                    .filter(Boolean)
                    .join(',')
            }
            case 'role': {
                if (!Array.isArray(fieldValue)) {
                    return ''
                }
                return fieldValue
                    .map(id => {
                        if (typeof id !== 'string') {
                            return ''
                        }
                        const role = find(item => item.id === id, roleOptions)
                        return role?.name || ''
                    })
                    .filter(Boolean)
                    .join(',')
            }
            case 'department': {
                if (!Array.isArray(fieldValue)) {
                    return ''
                }
                return fieldValue
                    .map(id => {
                        if (typeof id !== 'string') {
                            return ''
                        }
                        const department = find(item => item.id === id, departmentOptions)
                        return department?.name || ''
                    })
                    .filter(Boolean)
                    .join(',')
            }
            case 'file':
            case 'video':
            case 'selectGenerationByText':
            case 'select': {
                if (!Array.isArray(fieldValue)) {
                    return ''
                }
                return fieldValue.join(',')
            }

            case 'date': {
                // const secondFieldValue = data?.[1]?.valueVariable?.value
                // const secondDate = secondFieldValue && typeof secondFieldValue === 'number' ? lightFormat(secondFieldValue, 'yyyy-MM-dd') : ''
                // if (operator === 'between' && !secondDate) {
                //     return ''
                // }
                return typeof fieldValue === 'number' ? lightFormat(fieldValue, 'yyyy-MM-dd') : ''
            }
            case 'aggregation':
            case 'formula': {
                if (Array.isArray(fieldValue)) {
                    return fieldValue.join(',')
                }
                return String(fieldValue)
            }

            case 'checkbox': {
                return fieldValue ? 'true' : 'false'
            }
            default: {
                return ''
            }
        }
    }

type ParamListToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    field: Field
    operator: string
    paramList: VariableADTvalue[]
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
}

export const paramListToString: (payload: ParamListToStringPayload) => string = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    fieldBlocksWithDsId,
    field,
    operator,
    paramList,
    personOptions,
    roleOptions,
    departmentOptions
}) => {
    const data = paramList
        .map(item => {
            if (item.type === VariableType.VALUE) {
                const variableValue = item.valueVariable?.value
                if (variableValue !== undefined) {
                    return paramToString(field, variableValue, personOptions, roleOptions,
                        departmentOptions)
                }
            }
            if (item.type === VariableType.FIELD_ID) {
                return ''
            }
            if (item.type === VariableType.SYSTEM) {
                return item.systemVariable?.value ? variableSystemNameMap?.[item.systemVariable.value] || '' : ''
            }
            return idVariableToString({
                customViewData,
                currentPage,
                prevPage,
                dataSourceList,
                fieldBlocksWithDsId,
                idVariable: item
            })
        })
        .filter(Boolean)

    if (operator === 'between' && data.length < 2) {
        return ''
    }
    return data.join(' ')
}

export const isEmptyOperator = (operator: string) => {
    return operator === 'isEmpty' || operator === 'isNotEmpty'
}

export const verifyParamsText = (operator: string, paramText: string) => {
    if (isEmptyOperator(operator)) {
        return true
    }
    return paramText
}

type ConditionToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    conditions: FilterConditions[]
    where: 'OR' | 'AND'
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
    level?: number
}

export const conditionToString: (payload: ConditionToStringPayload) => string[] = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    conditions,
    where,
    personOptions,
    roleOptions,
    departmentOptions,
    fieldBlocksWithDsId,
    level = 0
}) => {
    return conditions.reduce<string[]>((prev, cur) => {
        const childrenConditions = (cur as FilterGroupType)?.conditions
        const childrenWhere = (cur as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            const childrenText = conditionToString({
                customViewData,
                currentPage,
                prevPage,
                dataSourceList,
                conditions: childrenConditions,
                where: childrenWhere,
                personOptions,
                roleOptions,
                departmentOptions,
                fieldBlocksWithDsId,
                level: level + 1
            })
            return [...prev, ...childrenText]
        }
        const condition = cur as FilterCommonCondition
        const { idVariable, operator, paramList } = condition
        const fieldText = idVariableToString({ customViewData, currentPage, prevPage, dataSourceList, fieldBlocksWithDsId, idVariable })
        const dataSource = getDataSourceByIdVariable({ customViewData, dataSourceList, idVariable, fieldBlocksWithDsId })
        const field = getFieldByIdVariable(dataSource, idVariable, fieldBlocksWithDsId)
        if (!fieldText || !field || !operator || !paramList) {
            return prev
        }
        const OperatorText = visibleFilterOperatorText[field?.innerType || 'NULL']?.[operator]
        const paramText = paramListToString({
            customViewData,
            currentPage,
            prevPage,
            dataSourceList,
            fieldBlocksWithDsId,
            field,
            operator,
            paramList,
            personOptions,
            roleOptions,
            departmentOptions,
        })
        if (!OperatorText || !verifyParamsText(operator, paramText)) {
            return prev
        }
        const realParamText = isEmptyOperator(operator) ? '' : paramText
        const whereText = where === 'AND' ? '且' : '或'
        const levelArr = Array.from({ length: level })
        const levelText = levelArr.map(item => '    ').join('')
        prev.push(`${levelText}${whereText} ${fieldText} ${OperatorText} ${realParamText}`)
        return prev
    }, [])
}

type FilterToStringPayload = {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    filter: FilterFormType
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const filterToString = (payload: FilterToStringPayload) => {
    const { customViewData, currentPage, prevPage, dataSourceList, filter, personOptions, roleOptions, departmentOptions, fieldBlocksWithDsId } = payload
    const conditions = filter.expression?.conditions
    const where = filter.expression?.where
    if (!conditions || !where) {
        return
    }
    return conditionToString({
        customViewData,
        currentPage,
        prevPage,
        dataSourceList,
        conditions,
        where,
        personOptions,
        roleOptions,
        departmentOptions,
        fieldBlocksWithDsId
    })
}

export const getFilterOfDeepBlockIds = (filter?: FilterFormType): Set<string> => {
    const blockIds = new Set<string>()
    if (!filter) {
        return blockIds
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return blockIds
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.INPUT && idVariable.inputVariable?.blockId) {
            blockIds.add(idVariable.inputVariable.blockId)
        }
        if (paramList) {
            for (const param of paramList) {
                if (param.type === VariableType.INPUT && param.inputVariable?.blockId) {
                    blockIds.add(param.inputVariable.blockId)
                }
            }
        }
    }
    return blockIds
}

// export const transformExpressionToNormalExpression = (
//     expression: FilterConditions,
//     fieldInputValueMap?: Record<string, InputValueItem>
// ): FilterConditions => {
//     if ('conditions' in expression) {
//         const { conditions } = expression
//         if (!conditions) {
//             return expression
//         }
//         const newConditions = conditions.map(condition => transformExpressionToNormalExpression(condition, fieldInputValueMap))
//         return { ...expression, conditions: newConditions }
//     }
//     if ('idVariable' in expression) {
//         const { paramList, operator } = expression
//         const newOperator = operator
//         const newParamList = paramList?.map(param => {
//             if (param.type === VariableType.INPUT) {
//                 const { inputVariable } = param
//                 if (!inputVariable) {
//                     return { type: VariableType.RESOLVED }
//                 }
//                 const fieldBlockValue = fieldInputValueMap?.[inputVariable.blockId]
//                 // if (fieldBlockValue?.value === '' || (Array.isArray(fieldBlockValue?.value) && fieldBlockValue?.value?.length === 0)) {
//                 //     newOperator = 'isEmpty'
//                 // }

//                 return {
//                     type: VariableType.RESOLVED,
//                     resolvedVariable: {
//                         value: getValueVariableValueEmptyPlaceholder(fieldBlockValue?.value),
//                         type: fieldBlockValue?.fieldType
//                     }
//                 }
//             }
//             return param
//         }) as VariableADTvalue[]
//         return { ...expression, operator: newOperator, paramList: newParamList }
//     }
//     return expression
// }

// export const transformFilterNormalFilter = (
//     filter?: FilterFormType,
//     fieldInputValueMap?: Record<string, InputValueItem>
// ): FilterFormType | undefined => {
//     const newFilter = clone(filter)
//     if (!newFilter) {
//         return newFilter
//     }
//     const conditions = newFilter.expression?.conditions
//     const where = newFilter.expression?.where
//     if (!conditions || !where || !newFilter.expression) {
//         return newFilter
//     }
//     const newExpression = transformExpressionToNormalExpression(newFilter.expression, fieldInputValueMap)
//     if (!newExpression) {
//         return newFilter
//     }
//     return { ...newFilter, expression: newExpression as FilterGroupType }
// }

export const getFieldIdInFilter = (filter?: FilterFormType): Set<string> => {
    const fieldIds = new Set<string>()
    if (!filter) {
        return fieldIds
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return fieldIds
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.FIELD_ID && idVariable.fieldIdVariable?.fieldId) {
            fieldIds.add(idVariable.fieldIdVariable.fieldId)
        }
    }
    return fieldIds
}

export type FilterItemIds = {
    blockId: string
    itemId: string
}

export const getFilterBlockItemIdsInFilter = (filter?: FilterFormType) => {
    const filterBlockItemIds: FilterItemIds[] = []
    // const filterBlockIds: Set<string> = new Set()
    if (!filter) {
        return { filterBlockItemIds }
    }

    const conditions = clone(filter.expression?.conditions)
    const where = clone(filter.expression?.where)
    if (!conditions || !where) {
        return { filterBlockItemIds }
    }

    const queue: FilterConditions[] = conditions
    while (queue.length > 0) {
        const condition = queue.shift()
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            for (const childrenCondition of childrenConditions) {
                queue.push(childrenCondition as FilterConditions)
            }
        }
        const filterCondition = condition as FilterCommonCondition
        const { idVariable, paramList } = filterCondition ?? {}
        if (idVariable && idVariable.type === VariableType.FILTER && idVariable.filterVariable?.itemId) {
            filterBlockItemIds.push({
                blockId: idVariable.filterVariable.blockId,
                itemId: idVariable.filterVariable.itemId
            })
        }
        if (paramList) {
            paramList.forEach(param => {
                if (param && param.type === VariableType.FILTER && param.filterVariable?.itemId) {
                    filterBlockItemIds.push({
                        blockId: param.filterVariable.blockId,
                        itemId: param.filterVariable.itemId
                    })
                }
            })
        }
    }
    return { filterBlockItemIds }
}

type GenerateLinkFilterParams = {
    filterBlockItemIds: FilterItemIds[]
    filterOptions: Record<string, FilterOption[]>
    linkFilterController?: FilterFormType
    filterValue: {
        blockId: string
        data: FilterBlockValue
    }[]
    filterBlocks: PureBlockAbstract[]
}

export const generateLinkFilter = ({
    filterBlockItemIds,
    filterOptions,
    linkFilterController,
    filterValue,
    filterBlocks
}: GenerateLinkFilterParams) => {
    const usedFilterOptions = filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
        const id = `${filterItem.blockId}-${filterItem.itemId}`
        const options = filterOptions[id]
        if (options) {
            prev[id] = options
        }
        return prev
    }, {})
    return linkFilterController
        ? resolveFilter({
            filter: linkFilterController,
            extraParams: {
                filterBlockParams: {
                    filterValue,
                    filterOptions: usedFilterOptions,
                    filterBlocks
                }
            }
        })
        : undefined
}

// // 图层验证筛选条件
// type ConditionFilterEmptyPayload = {
//     customViewData?: CustomViewVisibleData
//     currentPage: CurrPageDatasourceForVariable
//     prevPage: PrevPageDatasourceForVariable
//     dataSourceList: DataSourceAbstract[]
//     filter: FilterFormType
//     personOptions: AppUser[]
//     fieldBlocksWithDsId: FieldBlockWithDsId[]
// }

// export const conditionFilterEmpty = (filter: FilterFormType) => {

// }

// export const getFilterBlockItemIdsInListFilter = (filterList?: FilterFormType[]) => {
//     const blockIds: Set<string> = new Set()
//     const itemIds: Set<string> = new Set()
//     if (!filterList) {
//         return { filterBlockIds: blockIds, filterItemIds: itemIds }
//     }
//     filterList.forEach(filter => {
//         const { filterItemIds} = getFilterBlockItemIdsInFilter(filter)
//         filterBlockIds.forEach(id => {
//             blockIds.add(id)
//         })
//         filterItemIds.forEach(id => {
//             itemIds.add(id)
//         })
//     })
//     return { filterBlockIds: blockIds, filterItemIds: itemIds }
// }

// export const getFilterBlockItemIdsInFilter = (filter?: FilterFormType) => {
//     const blockIdItemIds: Record<BlockId, Set<string>> = {}
//     if (!filter) {
//         return blockIdItemIds
//     }

//     const conditions = clone(filter.expression?.conditions)
//     const where = clone(filter.expression?.where)
//     if (!conditions || !where) {
//         return blockIdItemIds
//     }

//     const queue: FilterConditions[] = conditions
//     while (queue.length > 0) {
//         const condition = queue.shift()
//         const childrenConditions = (condition as FilterGroupType)?.conditions
//         const childrenWhere = (condition as FilterGroupType)?.where
//         if (childrenConditions && childrenWhere) {
//             for (const childrenCondition of childrenConditions) {
//                 queue.push(childrenCondition as FilterConditions)
//             }
//         }
//         const filterCondition = condition as FilterCommonCondition
//         const { idVariable, paramList } = filterCondition ?? {}
//         if (idVariable && idVariable.type === VariableType.FILTER && idVariable.filterVariable?.itemId) {
//             blockIdItemIds[idVariable.filterVariable.blockId].add(idVariable.filterVariable.itemId)
//         }
//         if (paramList) {
//             paramList.forEach(param => {
//                 if (param && param.type === VariableType.FILTER && param.filterVariable?.itemId) {
//                     if (blockIdItemIds?.[param.filterVariable.blockId]) {
//                         blockIdItemIds[param.filterVariable.blockId].add(param.filterVariable.itemId)
//                         return
//                     }
//                     blockIdItemIds[param.filterVariable.blockId] = new Set([param.filterVariable.itemId])

//                 }
//             })
//         }
//     }
//     return blockIdItemIds
// }
