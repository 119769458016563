import { Enum } from '@lighthouse/tools'

import type { Arrangement, BaseBreakPointProtocol, BlockSize, ButtonAction, RichTextContentProtocol } from '../../protocols'
import type { IconDecorationProtocol } from '../../protocols/icon'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export interface CardBlockItemConfig {
    id: string
    iconDecoration?: IconDecorationProtocol
    iconColor: string
    heading?: RichTextContentProtocol
    introduction?: RichTextContentProtocol
    action: ButtonAction
}

export const CARD_STYLE = Enum('default', 'block', 'card')
export type CardStyle = Enum<typeof CARD_STYLE>

export interface CardBlockConfig extends BaseBreakPointProtocol {
    layout?: Arrangement
    cols?: number
    style?: CardStyle
    size?: BlockSize
    direction: 'vertical' | 'horizontal'
    list: CardBlockItemConfig[]
}

export abstract class CardBlockAbstract extends BaseBlock {
    type = BlockType.card

    abstract override config: CardBlockConfig
}
