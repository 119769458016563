
import type { FILE_STATES } from '@rpldy/uploady'

import { useListenersContext } from '../UploadManage.context'

export const useUploadBatch = (id: string, type: FILE_STATES) => {
    const { batchListener } = useListenersContext()
    const uploadBatch = batchListener.getBatches(id, type)
    return { batchItems: uploadBatch?.batchItem, removeBatchItem: batchListener.removeBatchItem.bind(batchListener) }
}


