import { IconFont } from '@byecode/ui'
import type { BreadcrumbBlockAbstract } from '@lighthouse/core'
import { ApplicationPreviewEnum, getLayoutStyle, getPaddingStyle, TooltipText } from '@lighthouse/shared'
import { at } from '@lighthouse/tools'
import type { CSSObject } from '@mantine/core'
import { Breadcrumbs, Text, Tooltip } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

type Option = {
    label: string
    value: string
    jumpIndex: number
}
interface BreadcrumbBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    options: Option[]
    blockData: BreadcrumbBlockAbstract
    previewType: ApplicationPreviewEnum
    onLink?: (id: string, index: number) => void
}

const SCxContainer = styled.div`
    padding: var(--block-padding);
    display: flex;
    align-items: center;
    overflow: hidden;
`

const SCxItem = styled.div<{ isMaxWidth: boolean }>`
    line-height: 22px;
    max-width: ${({ isMaxWidth }) => (isMaxWidth ? '140px' : 'none')};
    cursor: pointer;
    white-space: break-spaces;
    font-size: var(--font-size-normal);

    :hover {
        > div {
            color: var(--color-black);
        }
    }
`

const SCxWall = styled.div`
    display: flex;
    align-items: center;
`

const SCxMobileBreadcrumb = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
`
const OMIT = { label: '...', value: 'OMIT', jumpIndex: -1 }

const HIDE = { label: '', value: 'HIDE', jumpIndex: -1 }

const BreadcrumbBlock: React.FunctionComponent<BreadcrumbBlockProps> = ({ options, blockData, previewType, onLink, ...rest }) => {
    const { config } = blockData
    const { breakPoint } = config
    const { layout } = breakPoint
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const platformOptions = useMemo(() => {
        const last = at(-1, options)
        if (options.length > 1) {
            if (!last) {
                return options
            }
            return options
        }
        return options
    }, [options])

    const [newOptions, hideOptions] = useMemo(() => {
        if (platformOptions.length > 3) {
            const first = platformOptions[0]
            const last = at(-1, platformOptions)
            const secondLast = platformOptions.at(-2)
            const hideList = platformOptions.filter(
                (option, index) => index && (index !== platformOptions.length - 1 || index !== platformOptions.length - 2)
            )
            if (!last || !secondLast) {
                return [[first, OMIT], hideList]
            }
            return [[first, OMIT, secondLast, last], hideList]
        }
        return [platformOptions, []]
    }, [platformOptions])

    const prevOption = useMemo(() => (options.length > 1 ? options?.[options.length - 2] : options[0]), [options])

    const handleLink = useCallback(
        (id: string, index: number) => {
            onLink?.(id, index)
        },
        [onLink]
    )

    const ele = useMemo(() => {
        if (isMobile && options.length > 1) {
            const option = newOptions.at(-1)
            return (
                <SCxMobileBreadcrumb onClick={() => handleLink?.(prevOption?.value, prevOption.jumpIndex)}>
                    <IconFont color="var(--color-gray-400)" size={12} type="ArrowLeftSmall" />
                    <Text lineClamp={1} size={14} color="var(--color-black)">
                        {option?.label}
                    </Text>
                </SCxMobileBreadcrumb>
            )
        }
        return (
            <Breadcrumbs
                styles={{
                    separator: {
                        margin: '0 6px'
                    },
                    root: {
                        ...(getLayoutStyle(layout) as CSSObject),
                        ...(getPaddingStyle(layout?.padding) as CSSObject)
                    }
                }}
                separator={
                    <SCxWall>
                        <IconFont color="var(--color-gray-400)" size={12} type={isMobile ? 'ArrowLeftSmall' : 'ArrowRightSmall'} />
                    </SCxWall>
                }
                data-stop-action-propagation
            >
                {newOptions.map(({ value, label, jumpIndex }, index) => {
                    const isLast = index === newOptions.length - 1
                    const disabled = value === 'OMIT' || value === 'HIDE'
                    return (
                        <SCxItem
                            key={value}
                            isMaxWidth={!isMobile && options.length > 1}
                            onClick={() => !disabled && !isLast && handleLink?.(value, jumpIndex)}
                        >
                            <TooltipText
                                title={label}
                                disabled={disabled}
                                render={ref => {
                                    return (
                                        <Text
                                            lineClamp={1}
                                            ref={ref}
                                            size={14}
                                            color={isLast ? 'var(--color-black)' : 'var(--color-gray-400)'}
                                        >
                                            {label}
                                        </Text>
                                    )
                                }}
                            />
                        </SCxItem>
                    )
                })}
            </Breadcrumbs>
        )
    }, [handleLink, isMobile, layout, newOptions, options.length, prevOption.jumpIndex, prevOption?.value])

    return <SCxContainer {...rest}>{ele}</SCxContainer>
}

export default BreadcrumbBlock
