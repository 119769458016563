import type { SelectItemProps } from '@byecode/ui'
import { type Option, Divider, SelectItem } from '@byecode/ui'
import type { ViewField } from '@lighthouse/core'
import { type Field } from '@lighthouse/core'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { fileOperatorOptions, propertyFilter } from '../../constants/filter'
import { DEFAULT_FILTER_VALUE_VARIABLE, FILTER_OPERATOR } from '../'
import * as SC from './styles'

interface OperatorProps {
    field?: Field
    prefixName: string
    style?: React.CSSProperties
    clearable?: boolean
    enableFilterVariable?: boolean
    disabled?: boolean
}

const OperatorOptionComponent: React.FC<SelectItemProps> = ({ ...rest }) => {
    if (rest.value === 'filterController') {
        return (
            <SC.SelectItemWrapper>
                <Divider style={{ margin: '8px 0' }} />
                <SelectItem {...rest} />
            </SC.SelectItemWrapper>
        )
    }
    return <SelectItem {...rest} />
}

export const getOperatorOption: (field: Field | ViewField | undefined, enableFilterVariable?: boolean) => Option[] = (
    field,
    enableFilterVariable
) => {
    if (!field || !field?.innerType) {
        return []
    }
    if (field.type === 'file' || field.type === 'video') {
        return fileOperatorOptions
    }
    if (enableFilterVariable) {
        return [...propertyFilter[field.innerType], { label: '使用筛选控制器', value: FILTER_OPERATOR }]
    }
    return propertyFilter[field.innerType]
}

export const Operator: React.FC<OperatorProps> = ({ field, prefixName, style, clearable = true, enableFilterVariable, disabled }) => {
    const { control, setValue, getValues } = useFormContext()

    const handleParams = useCallback(() => {
        if (!field) {
            return
        }
        if (field?.innerType === 'BOOL') {
            return
        }
        setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
    }, [field, prefixName, setValue])

    return (
        <Controller
            name={`${prefixName}.operator`}
            control={control}
            render={({ field: ctlField }) => {
                return (
                    <SC.ParamsSelect
                        style={style}
                        {...ctlField}
                        styles={{
                            root: {
                                width: 120
                            }
                        }}
                        optionComponent={OperatorOptionComponent}
                        disabled={!field || disabled}
                        clearable={clearable}
                        options={getOperatorOption(field, enableFilterVariable)}
                        onChange={val => {
                            if (val !== ctlField.value) {
                                ctlField.onChange(val)
                                handleParams()
                            }
                        }}
                    />
                )
            }}
        />
    )
}
