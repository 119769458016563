import { CascadeSelect } from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import { DateFieldUnit, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useApplicationContext } from '../../contexts'
import { getDateConfig, getDepartmentToTreeOptions, isCheckboxValue, isIdsValue, isNumberValue, isTextValue } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { PersonSelect } from '../PersonSelect'
import { BoolInput, BoolItem } from './BoolItem'
import { boolOption } from './constants'
import * as SC from './styles'

interface ParamsProps {
    field?: Field
    prefixName: string
    width?: string
    focusOutLine?: string
    enableCurrentUser?: boolean
    disabled?: boolean
    // cascadeOptions: CascadeSelectOption[]
}

export const Params: React.FC<ParamsProps> = ({
    field,
    prefixName,
    focusOutLine = 'var(--color-main)',
    width = 'auto',
    enableCurrentUser = true,
    disabled
}) => {
    const { control } = useFormContext()
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const options = useMemo(() => {
        if (!field) {
            return []
        }
        if (field.type === 'select' || field.type === 'singleSelect') {
            const options = field.type === 'select' ? field.select.options : field.singleSelect.options
            return options.map(item => ({
                label: item.label,
                value: item.label,
                color: item?.color
            }))
        }
        if (field.type === 'role') {
            return roleOptions.map(item => ({
                label: item.name,
                value: item.id,
                color: 'gray'
            }))
        }
        if (field.type === 'department') {
            return getDepartmentToTreeOptions(departmentOptions)
        }
        return []
    }, [departmentOptions, field, roleOptions])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            const firstValue = firstParam?.valueVariable?.value
            // const secondValue = secondParam?.valueVariable?.value

            if (field?.innerType === 'DATE') {
                const firstDateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                // const secondDateValue = isNumberValue(secondValue) ? new Date(Number(secondValue)) : undefined
                const dateInfo = getDateConfig(field)
                const unit = dateInfo?.unit
                const format = dateInfo?.format
                // const calendarMode = unit === DateFieldUnit.MONTH ? 'month' : 'day'
                const showTime = unit === DateFieldUnit.MINUTE

                return (
                    <SC.ParamsDatePicker
                        clearable
                        key={`${prefixName}.paramList`}
                        // {...ctlField}
                        // disablePortal
                        popoverProps={{
                            withinPortal: true
                        }}
                        disabled={!field || disabled}
                        style={{ width: '100%' }}
                        showTime={showTime}
                        // defaultMode={calendarMode}
                        format={format}
                        value={firstDateValue}
                        // placeholder="请选择"
                        onChange={val => {
                            if (!val) {
                                return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE])
                            }
                            ctlField.onChange([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: { type: field?.type, value: val.valueOf() }
                                }
                            ])
                        }}
                    />
                )
            }

            if (field?.innerType === 'BOOL') {
                const selectValue = isCheckboxValue(firstValue) ? (firstValue ? '1' : '0') : ''
                return (
                    <SC.ParamsSelect
                        clearable
                        disabled={!field || disabled}
                        key={`${prefixName}.paramList`}
                        style={{ width }}
                        optionComponent={BoolItem}
                        customInputValueRender={BoolInput}
                        styles={{
                            root: {
                                width: '100%',
                                height: '32px'
                            }
                        }}
                        value={selectValue}
                        onChange={val => {
                            ctlField.onChange([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: { type: field?.type, value: val === '' ? undefined : !!Number(val) }
                                }
                            ])
                        }}
                        options={boolOption}
                    />
                )
            }

            if (field?.innerType === 'ARRAY' && field.type !== 'formula' && field.type !== 'aggregation') {
                const originValue = isTextValue(firstValue) ? firstValue : ''
                // if (field.type === 'aggregation' || field.type === 'formula') {
                //     return (
                //         <SC.ParamsInput
                //             key={`${prefixName}.paramList`}
                //             width={width}
                //             {...ctlField}
                //             disabled={!realField || disabled}
                //             placeholder="请输入"
                //             value={originValue}
                //             type="text"
                //             onChange={ev => {
                //                 const { value: newValue } = ev.target
                //                 ctlField.onChange([
                //                     {
                //                         type: VariableType.VALUE,
                //                         valueVariable: { type: realField?.type, value: newValue }
                //                     }
                //                 ])
                //             }}
                //         />
                //     )
                // }

                const selectValue = isIdsValue(firstValue) ? firstValue : []
                if (field.type === 'person') {
                    return (
                        <PersonSelect
                            options={personOptions}
                            enableCurrentUser={enableCurrentUser}
                            color={focusOutLine}
                            isMultiple
                            tagClearable
                            withinPortal
                            placeholder="请选择"
                            focusOutLine={focusOutLine}
                            width={width}
                            disabled={!field || disabled}
                            // minWidth={width}
                            styles={{ container: { height: 32 } }}
                            isHiddenUserId
                            value={selectValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: field?.type, value: val }
                                    }
                                ])
                            }}
                        />
                    )
                }
                if (field.type === 'department') {
                    return (
                        <CascadeSelect
                            popoverWidth={424}
                            data={selectValue}
                            multiple
                            withinPortal
                            lastLevel={false}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: field?.type, value: val }
                                    }
                                ])
                            }}
                            options={options}
                        />
                    )
                }
                return (
                    <SC.ParamsMultiSelect
                        clearable
                        key={`${prefixName}.paramList`}
                        style={{ width, minWidth: 160 }}
                        placeholder="请选择"
                        isMultiple={field.type === 'select'}
                        overlayStyle={{ maxHeight: 300, overflowY: 'auto' }}
                        themeColor={focusOutLine}
                        withinPortal
                        disabled={!field || disabled}
                        value={selectValue}
                        onChange={val => {
                            ctlField.onChange([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: { type: field?.type, value: val }
                                }
                            ])
                        }}
                        onClear={() => ctlField.onChange([])}
                        options={options}
                    />
                )
            }

            const inputType = ['NUMBER'].includes(field?.innerType || '') ? 'number' : 'text'
            const originValue = isTextValue(firstValue) || isNumberValue(firstValue) ? firstValue : ''
            return (
                <SC.ParamsInput
                    key={`${prefixName}.paramList`}
                    width={width}
                    {...ctlField}
                    disabled={!field || disabled}
                    placeholder="请输入"
                    value={originValue}
                    type={inputType}
                    onChange={ev => {
                        const { value: newValue } = ev.target
                        const inputValue = inputType === 'number' && newValue ? Number(newValue) : newValue
                        ctlField.onChange([
                            {
                                type: VariableType.VALUE,
                                valueVariable: { type: field?.type, value: inputValue }
                            }
                        ])
                    }}
                />
            )
        },
        [field, prefixName, disabled, width, focusOutLine, options, personOptions, enableCurrentUser]
    )

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                const dummyComp = <div />
                if (operator === 'isEmpty' || operator === 'isNotEmpty') {
                    return dummyComp
                }
                // const timestamp = Number(ctlField.value)
                return <SC.Box key={`${prefixName}.paramList`}>{renderParams(ctlField)}</SC.Box>
            }}
        />
    )
}
