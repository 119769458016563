import { Flex } from '@byecode/ui'
import type {
    ApplicationAbstract,
    ApplicationSettingNavbar,
    ApplicationSettingNavbarButton,
    ApplicationSettingTheme,
    AppUser,
    ButtonEvents,
    NavigationBaseItem
} from '@lighthouse/core'
import { ButtonPattern, NavigationShowMode } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import { useElementSize } from '@mantine/hooks'
import cls from 'classnames'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { max, reduce, splitWhen } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import type { LoginAuthType } from '../../AccountPage'
import ApplicationHeaderLayout from '../ApplicationHeaderLayout'
import { ApplicationLinkItem } from '../ApplicationLinkItem'
import ApplicationLogo from '../ApplicationLogo'
import ApplicationMultistageCollapse from '../ApplicationMultistageCollapse'
import { ApplicationNavBarButton } from '../ApplicationNavBarButton'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import MultilevelSelect from '../MultilevelSelect'
import * as CM from '../styles'
import { UserCenter } from '../UserCenter'
import { excludePage } from './constant'
import * as SC from './styles'

interface PageHeaderProps {
    isSimulation?: boolean
    userData?: AppUser
    application?: ApplicationAbstract
    navbar: ApplicationSettingNavbar
    theme?: ApplicationSettingTheme
    style?: React.CSSProperties
    showMode: NavigationShowMode
    activePage?: string
    selected?: boolean
    disableEvent?: boolean
    notificationBox?: React.ReactNode
    languageBox?: React.ReactNode
    // collapseAtom: ReturnType<typeof atom<Record<string, boolean>>>

    onLogout?: () => void
    onCommonCallBack?: (params: ButtonEvents) => void
    onToLink?: (navigation?: NavigationBaseItem) => void
    onSelect?: (selected: boolean) => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

export const ApplicationHeaderHorizontal: React.FC<PageHeaderProps> = ({
    isSimulation = false,
    userData,
    application,
    theme,
    showMode = 0,
    selected = false,
    languageBox,
    navbar,
    // collapseAtom,
    activePage,
    disableEvent,
    style,
    onLogout,
    onCommonCallBack,
    onToLink,
    onSelect,
    onUpdateBindAccount,
    notificationBox
}) => {
    const { linkList, buttonList, backgroundOverlay, logo, backgroundBlur, align } = navbar

    const headerStyles = useAppNavbarStylesContext()
    const { backgroundColor, color } = headerStyles

    const leftBtnList = useMemo(
        () => ({ child: linkList?.child.filter(linkId => linkList?.list[linkId].isAccess) ?? [], list: linkList?.list ?? {} }),
        [linkList?.child, linkList?.list]
    )
    const { ref: navbarRef, width: navWid, height: navHei } = useElementSize<HTMLDivElement>()
    const { ref: rightBtnGroupRef, width: rightWid, height: rightHei } = useElementSize<HTMLDivElement>()
    const { ref: logoRef, width: logoWid, height: logoHei } = useElementSize<HTMLDivElement>()
    const { ref: navLinkRef, width: navLinkWidth } = useElementSize<HTMLDivElement>()

    const [LeftLinkSizeList, setLeftLinkSizeList] = useImmer<Record<string, { width: number; height: number; id: string }>>({})
    const [currentPage, setCurrentPage] = useState(activePage)

    useEffect(() => {
        setCurrentPage(activePage)
    }, [activePage, setCurrentPage])

    const [showList, hideList] = useMemo(() => {
        const leftPanelList = leftBtnList.child.map(v => LeftLinkSizeList[v] ?? { width: 0, height: 0, id: v })
        if (leftPanelList.every(e => e.width === 0)) {
            return [leftBtnList.child, []]
        }
        let countWidth = 0
        const maxWidth = max(logoWid, rightWid)
        const remainWidth = navWid - (align === 'center' ? maxWidth * 2 : logoWid + rightWid)
        const gap = 12
        return reduce<string, [string[], string[]]>(
            (p, c, i) => {
                if (!LeftLinkSizeList[c]) {
                    return p
                }
                const { width } = LeftLinkSizeList[c]
                countWidth = countWidth + gap + width
                if (countWidth > remainWidth) {
                    return [p[0], [...p[1], c]]
                }
                return [[...p[0], c], []]
            },
            [[], []],
            leftBtnList.child
        )
    }, [LeftLinkSizeList, align, leftBtnList.child, logoWid, navWid, rightWid])

    const isFold = hideList.length > 0

    const handleChangeSize = useCallback(
        (id: string, params: { width: number; height: number }) => {
            setLeftLinkSizeList(draft => {
                draft[id] = { ...params, id }
            })
        },
        [setLeftLinkSizeList]
    )

    const handleCommonCallback = useCallback(
        (buttonParams: ButtonEvents) => {
            const { handleEvent, params } = buttonParams
            if (handleEvent === 'openPage') {
                const pageId = params?.[0] ?? ''
                setCurrentPage(pageId)
            }
            onCommonCallBack?.(buttonParams)
        },
        [setCurrentPage, onCommonCallBack]
    )

    const renderLinkBtn = useMemo(() => {
        return (
            <>
                <SC.LinkGroup
                    style={
                        align === 'center'
                            ? {
                                  position: 'absolute',
                                  left: '50%',
                                  transform: 'translateX(-50%)',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  zIndex: 1
                              }
                            : undefined
                    }
                >
                    {showList.map((nodeId, i) => {
                        const value = leftBtnList.list[nodeId]
                        const { icon, showType, id, child = [] } = value
                        const isHideIcon = !icon || showType === 'name'
                        const disabled = child.every(i => !leftBtnList.list[i].isAccess)
                        return (
                            <MultilevelSelect
                                key={nodeId}
                                value={value}
                                activePage={currentPage}
                                level={0}
                                theme={theme}
                                dataList={leftBtnList.list}
                                position={showMode === NavigationShowMode.horizontal ? 'bottom' : 'right'}
                                dataNavLink={nodeId}
                                disabled={disabled}
                                onCommonCallBack={handleCommonCallback}
                                target={
                                    <ApplicationLinkItem
                                        value={{ ...value, child: disabled ? [] : child }}
                                        activePage={currentPage}
                                        onCommonCallBack={handleCommonCallback}
                                    />
                                }
                            />
                        )
                    })}
                    {isFold && (
                        <MultilevelSelect
                            theme={theme}
                            dataList={leftBtnList.list}
                            value={{ id: nanoid(16), child: hideList }}
                            position={showMode === NavigationShowMode.horizontal ? 'bottom' : 'right'}
                            level={0}
                            activePage={currentPage}
                            onCommonCallBack={handleCommonCallback}
                            target={<SC.Icon size={22} color={color} type="PickOn" />}
                        />
                    )}
                </SC.LinkGroup>
                {/* 隐藏显示导航栏左侧导航栏链接 */}
                <SC.LinkGroup ref={navLinkRef} style={{ visibility: 'hidden', position: 'absolute' }}>
                    {leftBtnList.child.map((nodeId, i) => {
                        const value = leftBtnList.list[nodeId]
                        const { icon, showType, id, child = [] } = value
                        const isHideIcon = !icon || showType === 'name'
                        const disabled = child.every(i => !leftBtnList.list[i].isAccess)
                        return (
                            <MultilevelSelect
                                key={nodeId}
                                value={value}
                                activePage={currentPage}
                                level={0}
                                theme={theme}
                                dataList={leftBtnList.list}
                                position="bottom"
                                dataNavLink={nodeId}
                                disabled={disabled}
                                onChangeSize={handleChangeSize}
                                onCommonCallBack={handleCommonCallback}
                                target={
                                    <ApplicationLinkItem
                                        value={{ ...value, child: disabled ? [] : child }}
                                        activePage={currentPage}
                                        onCommonCallBack={handleCommonCallback}
                                    />
                                }
                            />
                        )
                    })}
                </SC.LinkGroup>
            </>
        )
    }, [
        isFold,
        align,
        showList,
        theme,
        leftBtnList.list,
        leftBtnList.child,
        hideList,
        showMode,
        currentPage,
        handleCommonCallback,
        color,
        navLinkRef,
        handleChangeSize
    ])

    const renderBtn = useCallback(
        (params: ApplicationSettingNavbarButton) => {
            if (params.events.handleEvent === 'openPage' && excludePage.includes(params.events.params?.[0]) && userData?.userId) {
                return
            }
            return (
                <ApplicationNavBarButton
                    name={params.name}
                    theme={theme}
                    icon={showMode === NavigationShowMode.verticalNarrow ? '' : params.icon}
                    type={params.pattern ?? ButtonPattern.primary}
                    showType={params.showType}
                    onClick={() => onCommonCallBack?.(params.events)}
                />
            )
        },
        [onCommonCallBack, showMode, userData?.userId, theme]
    )

    const toolEle = useMemo(() => {
        if (!userData?.userId) {
            return languageBox
        }
        return (
            <SC.UserCenter>
                <Flex style={{ flex: 1, overflow: 'hidden' }} alignItems="center">
                    <UserCenter onLogout={onLogout} userData={userData} position="bottom-end" onUpdateBindAccount={onUpdateBindAccount} />
                    {showMode === NavigationShowMode.verticalWide && (
                        <CM.Text isOmit color={color} style={{ marginLeft: 8 }}>
                            {userData?.username}
                        </CM.Text>
                    )}
                </Flex>
                <Flex>
                    {languageBox}
                    {notificationBox}
                </Flex>
            </SC.UserCenter>
        )
    }, [color, languageBox, notificationBox, onLogout, showMode, userData, onUpdateBindAccount])

    return (
        <ApplicationHeaderLayout
            mode={showMode}
            navbar={navbar}
            selected={selected}
            style={style}
            disableEvent={disableEvent}
            onClick={() => onSelect?.(true)}
        >
            <SC.Background className={cls({ backgroundBlur })}>
                <SC.Header id="NAVBAR_HEADER" style={{ color }} ref={navbarRef}>
                    <SC.LeftGroup>
                        <ApplicationLogo
                            appIcon={application?.icon}
                            appName={application?.name}
                            showMode={showMode}
                            logo={logo}
                            onToLink={onToLink}
                            ref={logoRef}
                        />
                        <SC.BtnGroup>{renderLinkBtn}</SC.BtnGroup>
                    </SC.LeftGroup>
                    <SC.RightGroup ref={rightBtnGroupRef}>
                        {buttonList.map(item => (
                            <React.Fragment key={item.id}>{renderBtn(item)}</React.Fragment>
                        ))}
                        {toolEle}
                    </SC.RightGroup>
                </SC.Header>
            </SC.Background>
        </ApplicationHeaderLayout>
    )
}
