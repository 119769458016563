import { IconFont, Tooltip } from '@byecode/ui'
import type { AppUser, BlockVisibilityProtocol, DataSourceAbstract, FieldBlockWithDsId } from '@lighthouse/core'
import { isEmpty } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import type { AppDepartment, AppRole, CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '../../types'
import type { CustomViewVisibleData } from '../../utils'
import { filterToString } from '../../utils'

const SCxTooltipContainer = styled.div`
    padding: 0 4px;
    color: var(--color-white);
    background: var(--color-gray-900);
`

const SCxVisibleContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: 24px;
    border-radius: 6px;
    background-color: #1b1855;
`

const SCxVertical = styled.div`
    height: 100%;
    width: 1px;
    background-color: #403c85;
`

const SCxVisibleWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
`

const SCxText = styled.p`
    white-space: pre;
`

export interface VisibleDomProps {
    customViewData?: CustomViewVisibleData
    currentPage: CurrPageDatasourceForVariable
    prevPage: PrevPageDatasourceForVariable
    dataSourceList: DataSourceAbstract[]
    filter?: BlockVisibilityProtocol
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
    fieldBlocksWithDsId: FieldBlockWithDsId[]
}

export const VisibleDom: React.FC<VisibleDomProps> = ({
    customViewData,
    currentPage,
    prevPage,
    dataSourceList,
    filter,
    personOptions,
    roleOptions,
    departmentOptions,
    fieldBlocksWithDsId
}) => {
    const title = useMemo(
        () =>
            filter?.visible === 'FILTER' && filter.visibilityFilter
                ? filterToString({
                      customViewData,
                      currentPage,
                      prevPage,
                      dataSourceList,
                      filter: filter.visibilityFilter,
                      personOptions,
                      roleOptions,
                      departmentOptions,
                      fieldBlocksWithDsId
                  })
                : undefined,
        [
            currentPage,
            customViewData,
            dataSourceList,
            departmentOptions,
            fieldBlocksWithDsId,
            filter?.visibilityFilter,
            filter?.visible,
            personOptions,
            prevPage,
            roleOptions
        ]
    )

    const filterLabel = useMemo(
        () => (
            <SCxTooltipContainer>
                <SCxText>符合以下条件时显示该组件：</SCxText>
                {title && title.map((str, index) => <SCxText key={index}>{str}</SCxText>)}
            </SCxTooltipContainer>
        ),
        [title]
    )

    const isFilterVisible = useMemo(() => {
        return title && !isEmpty(title)
    }, [title])

    return (
        <SCxVisibleContainer>
            {isFilterVisible && (
                <Tooltip title={filterLabel}>
                    <SCxVisibleWrapper>
                        <IconFont fill="white" size={16} type="EyeOutline" />
                    </SCxVisibleWrapper>
                </Tooltip>
            )}
        </SCxVisibleContainer>
    )
}
