import { EDGE_MODE } from '@lighthouse/core'
import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { getSizeStyle } from '../../../utils/layout'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode, NodeRenderProps } from '../types'

type BoxProps = {
    disabled?: boolean
}

const StyledBox = styled.div<BoxProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutContainerNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    onDataDrawerVisible?: (val: boolean) => void
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
}

export const ContainerNode = forwardRef<HTMLDivElement, FlowLayoutContainerNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, onDataDrawerVisible, nodeRender, children, ...rest } = props
    const { size, layout, position, design } = data.data || {}

    const {
        type: borderType,
        color: borderColor,
        gradient: borderGradient,
        mode: borderMode,
        all: borderAll,
        each: borderEach
    } = design?.border ?? {}
    const [borderLeft = 0, borderTop = 0, borderRight = 0, borderBottom = 0] =
        borderMode === EDGE_MODE.each ? borderEach || [] : Array.from({ length: 4 }).map(() => borderAll || 0)

    const { selectedIds, highlight, disabled: globalDisabled, nodeRender: globalNodeRender = React.Fragment } = useFlowLayoutContext()
    const NodeRender = nodeRender ?? globalNodeRender

    const disabled = propDisabled ?? globalDisabled

    const isSelected = selectedIds?.includes(data.id)

    const parentLayoutCtx = useContainerLayoutContext()

    return (
        <StyledBox
            ref={ref}
            data-type="container"
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            data-selected={!disabled && isSelected}
            style={{
                ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction),
                ...style
            }}
            {...rest}
        >
            <NodeRender node={data} disabled={propDisabled}>
                {children}
            </NodeRender>
        </StyledBox>
    )
})
