import { getAssetUrl } from '@lighthouse/assets'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { TextBlockAbstract } from '@lighthouse/core'
import { type RichTextEditorProps, getBreakPointConfigure, TEXT_FONT_NORMAL, useApplicationContext, useAtomData } from '@lighthouse/shared'
import produce from 'immer'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'

import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { usePreview } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

const TextBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.TextBlock })))

interface TextBlockControllerProps {
    blockData: TextBlockAbstract
    onBlockChange?: (values: TextBlockAbstract, origin: TextBlockAbstract) => Promise<void> | void
}

const TextBlockController: React.FC<TextBlockControllerProps> = ({ blockData }) => {
    // const appId = useCurrentAppId()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    const previewType = usePreview()
    const { renderLabel } = useVariableValueRender(pageId, parentRecordId, recordId)
    const { record: viewRecord } = useCustomViewBlockContext()

    const breakPointConfigure = getBreakPointConfigure(previewType, blockData.config.breakPoint, blockData.config.breakPoints)

    const config: RichTextEditorProps['config'] = useMemo(() => {
        return {
            variable: {
                renderLabel: v => renderLabel(v.attrs.value, { personOptions, roleOptions, departmentOptions, viewRecord })
            }
        }
    }, [departmentOptions, personOptions, renderLabel, roleOptions, viewRecord])

    const textPresets = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.theme.textPresetList ?? [], [])
    )


    const currentBlockData: TextBlockAbstract = useMemo(() => ({
        ...blockData,
        config: {
            ...blockData.config,
            breakPoint: breakPointConfigure
        }
    }), [blockData, breakPointConfigure])

    const mergedBlockData = useMemo(() => {
        if (currentBlockData.config.breakPoint.font.advance) {
            const advanceOption = textPresets.find(item => item.id === currentBlockData.config.breakPoint.font.advance)
            if (advanceOption) {
                const { fontFamily, fontStyle, fontWeight, fontSize, letterSpacing, lineClamp, lineHeight } = advanceOption
                return produce(currentBlockData, draft => {
                    draft.config.breakPoint.font.fontFamily = fontFamily
                    draft.config.breakPoint.font.fontStyle = fontStyle
                    draft.config.breakPoint.font.fontWeight = fontWeight
                    draft.config.breakPoint.font.fontSize = fontSize
                    draft.config.breakPoint.font.letterSpacing = letterSpacing
                    draft.config.breakPoint.font.lineClamp = lineClamp
                    draft.config.breakPoint.font.lineHeight = lineHeight
                })
            }
        }

        return currentBlockData
    }, [currentBlockData, textPresets])


    useEffect(() => {
        const ff = mergedBlockData.config.breakPoint.font.fontFamily
        if (!ff || ff === TEXT_FONT_NORMAL) {
            return
        }
        document.fonts.ready
            .then(async ffs => {
                const fontFaceList = [...ffs.values()]
                const isLoaded = fontFaceList.some(item => item.family === ff)
                if (!isLoaded) {
                    const fontUrl = `url(${getAssetUrl('font', `${ff}.woff2`)})`
                    const newFont = new FontFace(ff, fontUrl)
                    await newFont.load()
                    ffs.add(newFont)
                }
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [mergedBlockData.config.breakPoint.font.fontFamily])

    return (
        <Suspense fallback={<div />}>
            <TextBlock blockData={mergedBlockData} config={config} />
        </Suspense>
    )
}

export default TextBlockController
