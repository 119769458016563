import { Enum, nanoid } from '@lighthouse/tools'

import {
    type ButtonAction,
    type ContainerBreakPointConfigProtocol,
    type FilterFormType,
    type IconicProtocol,
    type PartialExcludesIdAndName,
    type RichTextContentProtocol,
    type VariableADTvalue,
    DIRECTION,
    FLEX_ALIGN
} from '../protocols'
import type { IconDecorationProtocol } from '../protocols/icon'
// default 普通页面
// creator 表单创建页面
// edit 表单编辑页面
// document 详情页
export const PAGE_TYPE = Enum('default', 'creator', 'document', 'edit')
export type PageType = Enum<typeof PAGE_TYPE>

export const PAGE_COMPOSITION = Enum('container', 'fab', 'nav')
/** 页面组成结构 */
export type PageComposition = Enum<typeof PAGE_COMPOSITION>

/** 分享设置 */
export type PageShareConfig = {
    titleV2?: RichTextContentProtocol
    descriptionV2?: RichTextContentProtocol
    image?: VariableADTvalue
    keywords?: string[]
}

export const PAGE_FAB_SHAPE = Enum('round', 'rect')
export type PageFabShape = Enum<typeof PAGE_FAB_SHAPE>

export const PAGE_FAB_COMPOSITION = Enum('icon', 'label', 'icon_with_label')
export type PageFabComposition = Enum<typeof PAGE_FAB_COMPOSITION>

export const PAGE_FAB_COLOR = Enum('default', 'theme', 'custom')
export type PageFabColor = Enum<typeof PAGE_FAB_COLOR>

export enum PageOpenType {
    login,
    all,
    part
}

export type PageFabButtonConfig = {
    label?: string
    icon?: IconDecorationProtocol

    colorMode?: PageFabColor
    bgColor?: string
    textColor?: string

    action?: ButtonAction
}

/** 页面悬浮按钮 */
export interface PageFabConfig extends PageFabButtonConfig {
    shape?: PageFabShape
    composition?: PageFabComposition
    nested?: PageFabButtonConfig[]
    /** 可见性规则 */
    visibilityFilter?: FilterFormType
}

/**
 * 页面类型
 * @export
 * @abstract
 * @class PageAbstract
 * @implements {IconicProtocol}
 * @implements {UserGroupProtocols}
 */
export abstract class PageAbstract implements IconicProtocol {
    type: PageType = PAGE_TYPE.default

    id = nanoid(32)

    name = '未命名页面'

    appId = ''

    dsId = ''

    /** @deprecated */
    icon = 'default'

    /**
     * @description  0 登陆可见  1公开  2指定角色或部门
     */
    open = PageOpenType.all

    isHome = false

    /** @deprecated */
    showFooter = false

    roleIds: string[] = []

    departmentIds: string[] = []

    /** 分享设置 */
    shareConfig?: PageShareConfig

    /** @deprecated */
    /** 悬浮按钮 */
    fab?: PageFabConfig

    /** 页面是否可访问 */
    isAccess?: boolean

    // design?: DesignLayoutMapProtocol

    language = ''

    /** 自定义路由 */
    route?: string

    breakPoint: ContainerBreakPointConfigProtocol = {
        id: 'desktop',
        name: '桌面端',
        visibility: {},
        size: {
            width: {
                size: 'fill'
            },
            height: {
                size: 'auto'
            },
            maxWidth: {
                size: 1200,
                unit: 'px'
            },
            minHeight: {
                size: 100,
                unit: '%'
            },
            overflow: 'auto'
        },
        layout: {
            align: {
                direction: DIRECTION.vertical,
                alignX: FLEX_ALIGN['flex-start'],
                alignY: FLEX_ALIGN['flex-start']
            },
            gap: 8,
            padding: [24, 24, 24, 24]
        },
        design: {}
    }

    breakPoints: PartialExcludesIdAndName<ContainerBreakPointConfigProtocol>[] = [
        {
            id: 'mobile',
            name: '移动端'
        }
    ]
}
