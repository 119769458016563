import React, { forwardRef, useEffect, useId, useMemo, useRef, useState } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useMergedRef } from '../../hooks/useMergedRef'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import * as SC from './styles'
import type { SegmentedControlItem, SegmentedControlProps } from './types'

const defaultProps: Partial<SegmentedControlProps> = {
    size: 'md'
}

export const SegmentedControl = forwardRef<HTMLDivElement, SegmentedControlProps>(
    ({ size = 'md', fullWidth = false, data, disabled: _disabled, readOnly, value, defaultValue, onChange, ...rest }, ref) => {
        const id = useId()
        const { disabled } = useComponentConfig('segmentedControl', { disabled: _disabled })
        const refs = useRef<Record<string, HTMLLabelElement | null>>({})

        const _data = useMemo(
            () =>
                data.map(
                    (item: string | SegmentedControlItem): SegmentedControlItem =>
                        typeof item === 'string' ? { label: item, value: item } : item
                ),
            [data]
        )

        const internalDefaultValue = Array.isArray(_data) ? _data.find(item => !item.disabled)?.value ?? _data[0]?.value ?? null : null

        const [_value, handleValueChange] = useUncontrolled({
            value,
            defaultValue: defaultValue ?? internalDefaultValue,
            onChange
        })

        const [observerRef, containerRect] = useResizeObserver<HTMLDivElement>()

        const [activePosition, setActivePosition] = useState({
            width: 0,
            height: 0,
            translate: [0, 0]
        })

        useEffect(() => {
            if (_value && _value in refs.current && observerRef.current) {
                const element = refs.current[_value]

                if (!element || !element.parentElement) {
                    return
                }
                const elementRect = { width: element.clientWidth, height: element.clientHeight }
                const scaledValue = element.offsetWidth / elementRect.width
                const width = elementRect.width * scaledValue || 0
                const height = elementRect.height * scaledValue || 0

                const { offsetLeft, offsetTop } = element.parentElement

                setActivePosition({
                    width,
                    height,
                    translate: [offsetLeft, offsetTop]
                })
            }
        }, [_value, _data, observerRef, containerRect])

        const mergedRef = useMergedRef(observerRef, ref)

        const controls = _data.map((item, index) => {
            const { value, label, icon } = item
            const radioDisabled = disabled || item.disabled
            const iconElement = icon && React.isValidElement(icon) && React.cloneElement(icon)
            const itemStyle = fullWidth ? { flex: 1 } : {}
            const labelStyle = fullWidth ? {} : { padding: '0 16px' }
            return (
                <SC.SegmentedControlItem key={value} style={itemStyle}>
                    <SC.SegmentedControlItemInput
                        disabled={radioDisabled}
                        type="radio"
                        name={id}
                        value={value}
                        id={`${id}-${value}`}
                        checked={_value === value}
                        onChange={() => !readOnly && handleValueChange(value)}
                    />

                    <SC.SegmentedControlItemLabel
                        size={size}
                        disabled={radioDisabled}
                        fullWidth={fullWidth}
                        data-active={(_value === value && !(disabled || item.disabled)) || undefined}
                        data-disabled={disabled || item.disabled || undefined}
                        checked={(_value === value && !(disabled || item.disabled)) || false}
                        htmlFor={`${id}-${value}`}
                        ref={node => {
                            refs.current[value] = node
                        }}
                        style={labelStyle}
                    >
                        <SC.SegmentedControlItemContent>
                            {iconElement}
                            {label && <span style={{ overflow: 'hidden', /* lineHeight: 1, */ textOverflow: 'ellipsis' }}>{label}</span>}
                        </SC.SegmentedControlItemContent>
                    </SC.SegmentedControlItemLabel>
                </SC.SegmentedControlItem>
            )
        })

        const {
            width,
            height,
            translate: [tx, ty]
        } = activePosition

        const isInvalidValue = _data.every(item => item.value !== _value)

        return (
            <SC.SegmentedControlWrapper size={size} id={id} ref={mergedRef} fullWidth={fullWidth} {...rest}>
                {isInvalidValue ? null : (
                    <SC.SegmentedControlItemIndicator
                        size={size}
                        css={{
                            width,
                            height,
                            transform: `translate(${tx}px, ${ty}px)`
                        }}
                    />
                )}
                {controls}
            </SC.SegmentedControlWrapper>
        )
    }
)
