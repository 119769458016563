import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Empty, Toast } from '@byecode/ui'
import type { ButtonAction, ImageBlockAbstract, ImageSwiperItem } from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import {
    FilePreviewer,
    getImageFullUrlInApplication,
    RATIO_OPTIONS,
    useActionRunningLoadings,
    useApplicationContext
} from '@lighthouse/shared'
import { useElementSize, useSetState } from '@mantine/hooks'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper/modules'
import { type SwiperRef, Swiper, SwiperSlide } from 'swiper/react'

import FileItem from './FileItem'
import type { DragState } from './GuideLine'
import GuideLine from './GuideLine'
import * as SC from './styles'

interface ImageBlockProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'> {
    blockData: ImageBlockAbstract
    disabled?: boolean
    previewType: ApplicationPreviewEnum
    isDefault?: boolean
    value: ImageSwiperItem[]
    onClick?: (link: string) => void
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onBlockChange?: (values: ImageBlockAbstract, origin: ImageBlockAbstract) => void
}

interface State {
    deltaX: number
    eleWidth: string
    opened: boolean
    previewFileIndex: number
    isHover: boolean
}
export const minImageContentWidth = 10

export const deleteUnitToNumber = (param: string): number => {
    return Number(param.replace('px', '')) || 0
}

const ImageBlock: React.FC<ImageBlockProps> = ({
    blockData,
    disabled = false,
    isDefault = false,
    value,
    previewType,
    onClick,
    onActionTrigger,
    onBlockChange,
    ...rest
}) => {
    const { config, id } = blockData
    const {
        variant,
        sources = [],
        fitType,
        align,
        radius,
        ratio,
        width = '100%',
        shape,
        isPreview = true,
        sourceType = 'default',
        imageClickEvent = 'none',
        imageSource,
        gap,
        showType = 'single',
        action,
        direction = 'column',
        swipeSpeed = 2,
        autoLoop = false
    } = config

    const isSinglePicture = showType === 'single'
    const isMultiplePicture = showType === 'multiple'

    const [{ deltaX, eleWidth, opened, previewFileIndex, isHover }, setState] = useSetState<State>({
        deltaX: 0,
        eleWidth: width.replace('px', ''),
        opened: false,
        previewFileIndex: 0,
        isHover: false
    })
    const { appId } = useApplicationContext()
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const { ref: containRef, width: containerWidth } = useElementSize()
    const swiperRef = useRef<SwiperRef>(null)

    const alginItem = find(item => item.value === ratio, RATIO_OPTIONS)
    const fileList = useMemo(() => {
        return value.map(item => ({
            ...item,
            url: getImageFullUrlInApplication(appId, item.url)
        }))
    }, [appId, value])

    const computeWidth = useMemo(() => {
        if (eleWidth === '100%' || isMultiplePicture) {
            return containerWidth
        }
        const dragWidth = deleteUnitToNumber(eleWidth) + deltaX
        // 最大值设限
        if (dragWidth >= containerWidth) {
            return containerWidth
        }
        // 最小值设限
        if (dragWidth <= minImageContentWidth) {
            return minImageContentWidth
        }
        return dragWidth
    }, [containerWidth, deltaX, eleWidth, isMultiplePicture])

    const isFill = useMemo(() => containerWidth === computeWidth, [computeWidth, containerWidth])

    const currentHeight = useMemo(() => {
        if (alginItem?.value === '0') {
            return 'auto'
        }
        return computeWidth * (alginItem?.label?.split(':').reduce((preVal, curVal) => Number(curVal) / preVal, 1) ?? 1)
    }, [alginItem?.label, alginItem?.value, computeWidth])

    const imageContentBorderRadius = useMemo(() => {
        if (isMultiplePicture) {
            return
        }
        if (shape === 'round') {
            return '50%'
        }
        return `${radius?.toString()?.replaceAll('px', '') || 0}px`
    }, [isMultiplePicture, radius, shape])

    const isDrag = !disabled && value.length > 0

    const isEmpty = useMemo(() => value.length === 0 && isDefault, [isDefault, value.length])

    const alignStyles = useMemo(() => {
        switch (align) {
            case 'left': {
                return { marginRight: 'auto' }
            }
            case 'right': {
                return { marginLeft: 'auto' }
            }
            default: {
                return { margin: '0 auto' }
            }
        }
    }, [align])

    const handleDragStart = useCallback(() => {
        // 拖拽时需要计算位移， 因此转换为px单位进行计算
        setState({ eleWidth: isFill ? `${containerWidth}px` : eleWidth })
    }, [containerWidth, eleWidth, isFill, setState])

    const handleDragMove = useCallback(
        (state: DragState) => {
            const { delta } = state
            const isCenter = align === 'center'
            const newDeltaX = isCenter ? delta.x * 2 : delta.x
            const contentWidth = computeWidth
            if ((contentWidth >= containerWidth && newDeltaX > deltaX) || (contentWidth <= minImageContentWidth && newDeltaX < deltaX)) {
                return
            }
            setState({ deltaX: newDeltaX })
        },
        [align, computeWidth, containerWidth, deltaX, setState]
    )

    const handleDragEnd = useCallback(
        (state: DragState) => {
            setState({ eleWidth: isFill ? '100%' : computeWidth.toString(), deltaX: 0 })
            onBlockChange?.({ ...blockData, config: { ...config, width: isFill ? '100%' : computeWidth.toString() } }, blockData)
        },
        [blockData, computeWidth, config, isFill, onBlockChange, setState]
    )

    const handImageClick = useCallback(
        (index: number) => {
            switch (imageClickEvent) {
                case 'preview': {
                    setState({ opened: isPreview, previewFileIndex: index })

                    break
                }
                case 'custom': {
                    if (loadings[`${index}`]) {
                        return
                    }
                    handleActionTriggerWithLoading({
                        action,
                        id,
                        type: 'click',
                        trigger: onActionTrigger
                    })
                    // onActionTrigger?.(action)
                    break
                }
                case 'jump': {
                    onClick?.(value[index]?.link ?? '')
                    break
                }
                case 'none': {
                    break
                }
                default: {
                    break
                }
            }
        },
        [imageClickEvent, value, setState, isPreview, loadings, handleActionTriggerWithLoading, action, id, onActionTrigger, onClick]
    )

    const imageExtraStyles = useMemo<React.CSSProperties>(() => {
        if (loadings[id]) {
            return { cursor: 'not-allowed' }
        }
        return {}
    }, [id, loadings])

    useEffect(() => {
        swiperRef.current?.swiper.init()
    }, [autoLoop, swipeSpeed, fitType])

    const handleToggleAutoSwiper = useCallback((isHover: boolean) => {
        if (!swiperRef.current?.swiper || !autoLoop) {
            return
        }
        if (isHover) {
            swiperRef.current?.swiper.autoplay.stop()
            return
        }
        swiperRef.current?.swiper.autoplay.start()
    }, [autoLoop])

    const imageContent = useMemo(
        () =>
            variant === 'swiper' && isSinglePicture ? (
                <Swiper
                    grabCursor
                    effect="creative"
                    ref={swiperRef}
                    creativeEffect={{
                        prev: {
                            shadow: true,
                            translate: ['-120%', 0, -200]
                        },
                        next: {
                            shadow: true,
                            translate: ['120%', 0, -200]
                        }
                    }}
                    modules={[Autoplay, EffectCreative, Pagination, Navigation]}
                    pagination={{ el: `[data-block-id = ${id}] .swiperPagination` }}
                    navigation={{
                        prevEl: `[data-block-id = ${id}] .swiperNavigationLeft`,
                        nextEl: `[data-block-id = ${id}] .swiperNavigationRight`
                    }}
                    speed={600}
                    loop
                    // data-swiper-autoplay

                    autoplay={
                        autoLoop
                            ? {
                                  delay: swipeSpeed.toString() === '' ? undefined : Number(swipeSpeed) * 1000
                              }
                            : 3600 * 24 * 1000
                    }
                >
                    <SC.Pagination className="swiperPagination" />

                    <SC.Navigation>
                        <SC.NavigationButton className="swiperNavigationLeft" style={{ left: 23, right: '50%',  }}>
                            <SC.Icon type="ArrowLeftSmall" color="#fff" />
                        </SC.NavigationButton>
                        <SC.NavigationButton className="swiperNavigationRight" style={{ right: 23, left: '50%' }}>
                            <SC.Icon type="ArrowRightSmall" color="#fff" />
                        </SC.NavigationButton>
                    </SC.Navigation>

                    {value?.map((item, index) => (
                        <SwiperSlide
                            key={item.url}
                            style={{
                                width: eleWidth,
                                height: currentHeight
                            }}
                        >
                            <FileItem
                                {...item}
                                src={getImageFullUrlInApplication(appId, item.url)}
                                objectFit={fitType}
                                data-stop-action-propagation={
                                    imageClickEvent === 'none' || (imageClickEvent === 'custom' && action.type === 'none')
                                        ? undefined
                                        : true
                                }
                                onClick={() => handImageClick(index)}
                                alt={item.title}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <SC.ImageList style={{ flexDirection: direction, gap: `${gap}px` }}>
                    {value.map((v, i) => {
                        if (isSinglePicture && i > 0) {
                            return null
                        }
                        return (
                            <FileItem
                                {...v}
                                key={v.url}
                                style={{ height: currentHeight, borderRadius: isMultiplePicture && shape === 'round' ? '100%' : undefined }}
                                src={getImageFullUrlInApplication(appId, v?.url)}
                                aspectRatio={alginItem?.value === '0' ? 'auto' : alginItem?.label.replace(':', '/') ?? 'auto'}
                                objectFit={fitType}
                                data-stop-action-propagation={
                                    imageClickEvent === 'none' || (imageClickEvent === 'custom' && action.type === 'none')
                                        ? undefined
                                        : true
                                }
                                onClick={() => handImageClick(i)}
                            />
                        )
                    })}
                </SC.ImageList>
            ),
        [
            variant,
            isSinglePicture,
            id,
            autoLoop,
            swipeSpeed,
            value,
            direction,
            gap,
            eleWidth,
            currentHeight,
            isMultiplePicture,
            shape,
            appId,
            fitType,
            imageClickEvent,
            action.type,
            handImageClick,
            alginItem?.value,
            alginItem?.label
        ]
    )

    return (
        <SC.ImageContainer data-block-id={id} ref={containRef} {...rest}>
            {isEmpty ? (
                <Empty
                    styles={{
                        root: {
                            width: '100%',
                            // height: containerWidth / 4,
                            aspectRatio: '4 / 1',
                            backgroundColor: 'var(--color-gray-50)',
                            border: '1px solid var(--color-gray-200)',
                            borderRadius: 'inherit'
                        }
                    }}
                    icon="BlockImage"
                    description=""
                />
            ) : (
                value.length > 0 && (
                    <SC.ImageContent
                        borderRadius={imageContentBorderRadius}
                        style={{ ...alignStyles, ...imageExtraStyles, width: computeWidth }}
                    >
                        {align !== 'left' && isSinglePicture && isDrag && (
                            <GuideLine
                                position="left"
                                key="leftGuide"
                                onDragStart={handleDragStart}
                                onDragMove={handleDragMove}
                                onDragEnd={handleDragEnd}
                            />
                        )}
                        <SC.ImageWrapper
                            onMouseEnter={() => handleToggleAutoSwiper(true)}
                            onMouseLeave={() => handleToggleAutoSwiper(false)}
                            onTouchStart={() => handleToggleAutoSwiper(true)}
                            onTouchEnd={() => handleToggleAutoSwiper(false)}
                        >
                            {imageContent}
                        </SC.ImageWrapper>
                        {align !== 'right' && isSinglePicture && isDrag && (
                            <GuideLine
                                position="right"
                                key="rightGuide"
                                onDragStart={handleDragStart}
                                onDragMove={handleDragMove}
                                onDragEnd={handleDragEnd}
                            />
                        )}
                        {/* {fileList?.[slideIndex]?.title && showTitle && <SC.ImageTitle>{fileList[slideIndex]?.title}</SC.ImageTitle>} */}
                    </SC.ImageContent>
                )
            )}
            <FilePreviewer
                opened={opened}
                defaultIndex={previewFileIndex}
                onClose={() => setState({ opened: false })}
                fileList={fileList}
            />
        </SC.ImageContainer>
    )
}

export default ImageBlock
