import { Checkbox, Radio } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { directionMap } from '../../../constant'
import { Item } from './Item'

type Option = {
    label: string
    value: string
    color?: string
}

interface CheckBoxGroupProps {
    value?: string[]
    options: Option[]
    direction?: 'vertical' | 'horizontal'
    isMultiple?: boolean
    disabled?:boolean
    colorless?: boolean
    onChange: (v: string[]) => void
}

const SCxGroup = styled.div`
    display: flex;
    gap: 8px;
`

const SCxContainer = styled.div<Pick<React.CSSProperties, 'flexDirection'>>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ flexDirection }) => flexDirection};
    gap: ${({ flexDirection }) => (flexDirection === 'row' ? '8px 12px' : '8px')};
`
export const CheckBoxGroup: React.FunctionComponent<CheckBoxGroupProps> = ({
    value = [],
    options,
    direction = 'horizontal',
    isMultiple,
    colorless,
    disabled,
    onChange
}) => {
    const handleChange = useCallback(
        (v: string[]) => {
            onChange(isMultiple ? v : v.slice(-1))
        },
        [isMultiple, onChange]
    )

    return (
        <SCxContainer flexDirection={directionMap[direction]}>
            {options.map(option => {
                const checked = Boolean(value?.includes(option.value))
                return (
                    <SCxGroup
                        key={option.value}
                        onMouseDown={() => !disabled && handleChange(checked ? value?.filter(v => v !== option.value) : [...value, option.value])}
                    >
                        {isMultiple ? (
                            <Checkbox checked={checked} color="var(--color-app-main)" size="xs" value={option.value} />
                        ) : (
                            <Radio
                                size="xs"
                                styles={{
                                    radio: {
                                        backgroundColor: 'var(--color-white)',
                                        borderRadius: '100%'
                                    },
                                    input: {
                                        width: '16px!important',
                                        height: '16px!important',
                                        borderWidth: checked ? 2 : 1,
                                        borderColor: `${checked ? 'var(--color-app-main)' : 'var(--color-theme-4)'}!important`
                                    },
                                    icon: checked
                                        ? {
                                              width: '8px!important',
                                              height: '8px!important',
                                              left: '4px!important',
                                              top: '4px!important'
                                          }
                                        : {
                                              width: '14px!important',
                                              height: '14px!important',
                                              left: '1px!important',
                                              top: '1px!important'
                                          }
                                }}
                                label={colorless ? option.label : ''}
                                style={{ padding: colorless ? '0 8px' : 0, gap: colorless ? 8 : 0 }}
                                color={checked ? 'var(--color-app-main)' : 'var(--color-theme-2)'}
                                checked={checked}
                            />
                        )}
                        {!colorless && <Item key={option.value} colorless={colorless} data={option} />}
                    </SCxGroup>
                )
            })}
        </SCxContainer>
    )
}
