import { Button, Empty, Input, singleTextEllipsis, Text, tinyButtons } from '@byecode/ui'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import type { TreeFlatOption } from '../../types'
import { getTreeOptions } from '../../utils/tree'
import * as SC from './styles'
import { TreeSelectItem } from './TreeSelectItem'

const SCxDropDownHeader = styled.div`
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    background-color: var(--color-gray-50);
    border-radius: 8px 8px 0 0;
`

const SCxDropDownList = styled.div`
    margin: 8px 0;
    max-height: 500px;
    overflow-y: auto;
    ${tinyButtons}
`

const SCxTag = styled.div<{ isOmit?: boolean }>`
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 6px;
    height: 24px;
    border-radius: 4px;
    background-color: var(--color-gray-200);
    ${({ isOmit }) => isOmit && singleTextEllipsis}
`

interface TreeSelectDropDownProps {
    options: TreeFlatOption[]
    value: string[]
    search: string
    multiple?: boolean
    onSearchChange: React.ChangeEventHandler<HTMLInputElement>
    onChange: (value: string[]) => void
    onFinish: () => void
}

export const TreeSelectDropDown: React.FunctionComponent<TreeSelectDropDownProps> = ({
    options,
    value,
    search,
    multiple,
    onChange,
    onFinish,
    onSearchChange
}) => {
    const treeOptions = useMemo(() => getTreeOptions(options), [options])

    const tagList = useMemo(() => {
        return value.map(id => {
            return find(item => item.value === id, options)
        }).filter(Boolean) as TreeFlatOption[]
    }, [options, value])

    const handleMultiple = useCallback(
        (ids: string[]) => {
                  const id = ids[0]
            if (!multiple) {
                const newIds = value.includes(id) ?[] : [id]
                onChange(newIds)
                return
            }
            const newIds = value.includes(id) ? value.filter(item => item !== id) : [...value, id]
            onChange(newIds)
        },
        [multiple, onChange, value]
    )

    return (
        <SC.DropDown>
            <SCxDropDownHeader>
                <SC.DropDownHeaderLeft>
                    <SC.TagWrapper>
                        {tagList.map(tag => (
                            <SCxTag isOmit key={tag.value}>
                                <Text color="var(--color-black)" size={14} inline>
                                    {tag.label}
                                </Text>
                                <Text color="var(--color-gray-400)" whiteSpace="nowrap" ellipsis={false} size={14} inline>
                                    {tag.value}
                                </Text>
                            </SCxTag>
                        ))}
                        <Input
                            value={search}
                            styles={{
                                wrapper: {
                                    borderStyle: 'none',
                                    backgroundColor: 'transparent!important',
                                    flex: 1,
                                    minWidth: 70
                                }
                            }}
                            onChange={onSearchChange}
                            placeholder="搜索"
                            size="xs"
                        />
                    </SC.TagWrapper>
                </SC.DropDownHeaderLeft>
                <Button size="sm" type="primary" style={{ flexShrink: 0 }} onClick={onFinish}>
                    确认
                </Button>
            </SCxDropDownHeader>
            <SCxDropDownList>
                {options.length === 0 && <Empty styles={{ root: { height: 100 } }} icon="SpaceTeam" description="没有部门" />}
                {treeOptions.map(option => (
                    <TreeSelectItem
                        key={option.value}
                        data={option}
                        level={0}
                        onChange={handleMultiple}
                        renderRightSection={childOption => {
                            return value.includes(childOption.value) ? <SC.Icon type="Tick" color="var(--color-theme-7)" /> : null
                        }}
                    />
                ))}
            </SCxDropDownList>
        </SC.DropDown>
    )
}
