import React, { forwardRef } from 'react'

import type { FlowLayoutNode, NodeRenderProps } from '../types'
import type { FlowLayoutBaseNodeProps } from './BaseNode'
import { BaseNode } from './BaseNode'
import type { FlowLayoutContainerNodeProps } from './ContainerNode'
import { ContainerNode } from './ContainerNode'
import type { FlowLayoutCustomNodeProps } from './CustomNode'
import { CustomNode } from './CustomNode'

export interface FlowLayoutNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    userId?: string
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
}
export const Node = forwardRef<HTMLDivElement, FlowLayoutNodeProps>((props, ref) => {
    switch (props.data.type) {
        case 'container': {
            return <ContainerNode {...(props as FlowLayoutContainerNodeProps)} ref={ref} />
        }

        case 'block': {
            return <BaseNode {...(props as FlowLayoutBaseNodeProps)} ref={ref} />
        }

        case 'custom': {
            return <CustomNode {...(props as FlowLayoutCustomNodeProps)} ref={ref} />
        }

        default: {
            return null
        }
    }
})
