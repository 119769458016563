import { isIosPlatform } from '@lighthouse/tools'
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import { useAppContainerContext } from '../components'

type EditorPosition = {
    top: number
    left: number
}

type CellEditorPositionPayload = {
    rootElement: HTMLElement | null | undefined
    anchorElement: HTMLDivElement | undefined
    open: boolean
    onOpenChange: (val: boolean) => void
}

type AnchorPosition = {
    initTop: number
    initLeft: number
}

export const useCellEditorPosition = (payload: CellEditorPositionPayload) => {
    const { rootElement, anchorElement, open, onOpenChange } = payload
    const flipTopRef = useRef<number>(0)
    const flipLeftRef = useRef<number>(0)
    const scrollLeftRef = useRef<number>(0)
    const isScrollingRef = useRef<number | undefined>()
    const childHeightRef = useRef<number>(0)
    const popoverRef = useRef<HTMLDivElement>(null)
    const anchorPositionRef = useRef<AnchorPosition | undefined>()
    const isIos = isIosPlatform()
    const editorPositionRef = useRef<EditorPosition | undefined>()
    const { scale = 1 } = useAppContainerContext()

    const handleSetVisibility = useCallback((el: HTMLDivElement) => {
        el.style.opacity = '1'
    }, [])

    const handleClearAnchorPosition = useCallback(() => {
        editorPositionRef.current = undefined
        anchorPositionRef.current = undefined
        // initAnchorPositionRef.current = undefined
        flipTopRef.current = 0
    }, [])

    const getFlipLeft = (params: { rootRight: number; anchorRight: number; childrenWidth: number }) => {
        const { childrenWidth, rootRight, anchorRight } = params
        const spaceRight = childrenWidth - (rootRight - anchorRight)
        const right = spaceRight > 0 ? spaceRight : 0
        flipLeftRef.current = right > childrenWidth ? childrenWidth : right
    }

    const getFlipTop = (params: { rootTop: number, rootBottom: number; anchorBottom: number; anchorTop: number; childrenHeight: number }) => {
        const { rootTop, rootBottom, anchorBottom, anchorTop, childrenHeight } = params
        const bottom = rootBottom - anchorBottom
        const top = anchorTop - rootTop
        if (anchorTop < childrenHeight && childrenHeight >= bottom) {
            flipTopRef.current = 0
            childHeightRef.current = bottom
            return
        }
        if (childrenHeight >= bottom && flipTopRef.current === 0) {
            flipTopRef.current = childrenHeight
            childHeightRef.current = top
            return
        }

        if (bottom > childrenHeight && flipTopRef.current > 0) {
            flipTopRef.current = 0
        }
    }

    const handleSetTransform = useCallback(() => {
        // const { scrollTop, scrollLeft, initScrollLeft, initScrollTop } = position
        const { initLeft, initTop } = anchorPositionRef.current || { initLeft: 0, initTop: 0 }
        const childrenElement = popoverRef.current
        if (!anchorElement || !childrenElement || !rootElement) {
            return
        }
        const { right: rootRight } = rootElement.getBoundingClientRect()
        const { width: childrenWidth } = childrenElement.getBoundingClientRect()
        const { top, left, right, bottom } = anchorElement.getBoundingClientRect()
        // 虚拟滚动出可视区域后 关闭弹框
        if (right === 0 && left === 0 && top === 0 && bottom === 0) {
            onOpenChange(false)
            return
        }
        getFlipLeft({
            childrenWidth,
            rootRight,
            anchorRight: right
        })
        const x = (left - initLeft - flipLeftRef.current) / scale
        const y = isIos ? 0 : (top - initTop - flipTopRef.current) / scale
        childrenElement.style.transform = `translate3d(${x}px, ${y}px, 0px)`
        childrenElement.style.opacity = '1'
    }, [anchorElement, isIos, onOpenChange, rootElement, scale])

    const checkVisible = useCallback(() => {
        const childrenElement = popoverRef.current
        if (!childrenElement || !anchorElement || !rootElement) {
            return
        }
        const { bottom: rootBottom, top: rootTop, right: rootRight } = rootElement.getBoundingClientRect()
        const { top: anchorTop, bottom: anchorBottom, left: anchorLeft, right: anchorRight } = anchorElement.getBoundingClientRect()
        const { height: childrenHeight, width: childrenWidth } = childrenElement.getBoundingClientRect()

        // 纵向
        getFlipTop({ rootTop, rootBottom, anchorBottom, anchorTop, childrenHeight })
        handleSetTransform()
        handleSetVisibility(childrenElement)
    }, [anchorElement, handleSetTransform, handleSetVisibility, rootElement])

    const handleChangeAnchorPosition = useCallback((safeBottom = 0) => {
        if (!anchorElement || !rootElement) {
            return false
        }
        const { top, left, x, y } = anchorElement.getBoundingClientRect()
        const { top: containerTop, left: containerLeft } = rootElement.getBoundingClientRect()
        // const t =  top + safeBottom   // const safeBottom = Number.parseFloat(getComputedStyle(document.documentElement).getPropertyValue('env(safe-area-inset-bottom)')) || Number.parseFloat(getComputedStyle(document.documentElement).getPropertyValue('constant(safe-area-inset-bottom)'))  || 0
        // const safeBottom = 25
        // console.log('🚀 ~ file: useCellEditorPosition.ts ~ line 118 ~ handleChangeAnchorPosition ~ safeBottom', safeBottom)

        editorPositionRef.current = {
            top: (top - containerTop + safeBottom) / scale,
            left: (left - containerLeft) / scale
        }
        // initAnchorPositionRef.current = {
        //     top,
        //     left
        // }
        anchorPositionRef.current = {
            initLeft: left,
            initTop: top
        }
        // requestAnimationFrame(checkVisible)
        checkVisible()
    }, [anchorElement, checkVisible, rootElement, scale])

    const handleScroll = useCallback(
        (e: Event) => {
            if (!e.target) {
                return
            }
            const el = e.target as HTMLDivElement
            if (isScrollingRef.current) {
                cancelAnimationFrame(isScrollingRef.current)
            }
            scrollLeftRef.current = el.scrollLeft
            isScrollingRef.current = requestAnimationFrame(handleSetTransform)
        },
        [handleSetTransform]
    )

    const handleResize = useCallback(() => {
        if(!window.visualViewport){
            return
        }
        const keyboardHeight = window.visualViewport.height - document.documentElement.clientHeight;
        handleChangeAnchorPosition(keyboardHeight)
    }, [handleChangeAnchorPosition])

    useEffect(() => {
        if (!anchorElement || !open) {
            return
        }
        window.addEventListener('scroll', handleScroll, true)
        return () => {
            window.removeEventListener('scroll', handleScroll, true)
        }
    }, [handleScroll, open, anchorElement])

    useEffect(() => {
        const {visualViewport} = window
        if (visualViewport && isIos) {
            visualViewport.addEventListener('resize', handleResize);
            return () => {
                visualViewport.removeEventListener('resize', handleResize)
            }
        }

    }, [handleChangeAnchorPosition, handleResize, isIos])



    useEffect(() => {
        const childrenElement = popoverRef.current
        if (!childrenElement || !open) {
            return
        }
        const observer = new IntersectionObserver(checkVisible, {
            root: rootElement,
            threshold: 1
        })
        observer.observe(childrenElement)
        return () => {
            observer.disconnect()
        }
    }, [checkVisible, anchorElement, open, rootElement])

    useEffect(() => {
        if (anchorElement && !open) {
            // handleChangeAnchorPosition().then(r => {
            //     onOpenChange(true)
            // }).catch(err => console.log(err))
            onOpenChange(true)
            handleChangeAnchorPosition()
        }
        if (!anchorElement) {
            handleClearAnchorPosition()
            onOpenChange(false)
        }
    }, [handleChangeAnchorPosition, open, anchorElement, onOpenChange, handleClearAnchorPosition])

    return { popoverRef, handleClearAnchorPosition, position: editorPositionRef.current, height: childHeightRef.current }
}
