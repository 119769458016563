import type { ViewDesignStyle } from '@lighthouse/core'
import { VIEW_DESIGN_STYLE } from '@lighthouse/core'

interface TableStyle {
    // header
    isHeaderStripe: boolean
    // table
    isContentBorder: boolean
    isContentMargin: boolean

    // record
    isStripe: boolean
    isBorderTop: boolean
    isBorderRadius: boolean
    isExtraBorderTop: boolean
}

const pcAvatarWidth = 80
const mobileAvatarWidth = 50

export const getAvatarWidth = (isMobile?: boolean) => {
    return isMobile ? mobileAvatarWidth : pcAvatarWidth
}

export const getDesignStyleMap: (design: ViewDesignStyle | undefined) => TableStyle = design => {
    switch (design) {
        case VIEW_DESIGN_STYLE.simple: {
            return {
                isHeaderStripe: false,
                isContentBorder: false,
                isContentMargin: false,
                isStripe: false,
                isBorderTop: true,
                isBorderRadius: true,
                isExtraBorderTop: false
            }
        }

        case VIEW_DESIGN_STYLE.stripe: {
            return {
                isHeaderStripe: false,
                isContentBorder: false,
                isContentMargin: true,
                isStripe: true,
                isBorderTop: false,
                isBorderRadius: true,
                isExtraBorderTop: false
            }
        }
        // classic
        default: {
            return {
                isHeaderStripe: true,
                isContentBorder: true,
                isContentMargin: true,
                isStripe: false,
                isBorderTop: true,
                isBorderRadius: false,
                isExtraBorderTop: true
            }
        }
    }
}
