import type { ContainerBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { checkActionEvent, DEFAULT_ACTION, useAtomAction, useRegisterBlockListener } from '@lighthouse/shared'
import React, { Suspense, useCallback } from 'react'

import { pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'

const ContainerBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ContainerBlock })))

interface ContainerControllerProps {
    blockData: ContainerBlockAbstract
    node: FlowLayoutNode
    disabled?: boolean
    children?: React.ReactNode
    onBlockChange?: (values: ContainerBlockAbstract, origin: ContainerBlockAbstract) => Promise<void> | void
}

const ContainerController = ({ blockData, onBlockChange, ...rest }: ContainerControllerProps) => {
    const { id, config } = blockData
    const { action = DEFAULT_ACTION, shown } = config
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const { handleActionTrigger } = useActionTrigger()

    const onClickAction = (ev: React.MouseEvent<HTMLElement>) => {
        if (action.type === 'none') {
            return
        }

        if (checkActionEvent(ev)) {
            handleActionTrigger(action)
        }
    }

    const handleViewChange = useCallback(
        (view: string) => {
            setPageStack(draft => {
                const pageStack = draft.find(equalPageStack(pageId, stackId))
                if (!pageStack) {
                    return
                }

                pageStack.blockRuntimeState.container = {
                    ...pageStack.blockRuntimeState.container,
                    [id]: {
                        ...pageStack.blockRuntimeState.container?.[id],
                        currentView: view
                    }
                }
            })
        },
        [id, pageId, setPageStack, stackId]
    )

    useRegisterBlockListener(id, 'container', {
        switchPanel: payload => {
            payload && handleViewChange(payload.switchPanelId)
        },
        open: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: true } }
            onBlockChange?.(newBlockData, blockData)
        },
        close: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: false } }
            onBlockChange?.(newBlockData, blockData)
        }
    })

    return (
        <Suspense fallback={<div />}>
            <ContainerBlock
                {...rest}
                shown={shown}
                data-stop-action-propagation={action.type === 'none' ? undefined : true}
                onClick={onClickAction}
                style={{ cursor: action.type === 'none' ? undefined : 'pointer' }}
            />
        </Suspense>
    )
}

export default ContainerController
