import { Enum } from '@lighthouse/tools'

import type { Direction, Fit, FlexAlign, VariableADTvalue } from '../protocols'

/** 背景类型 */
export const BACKGROUND_TYPE = Enum('color', 'gradient', 'image')
export type BackgroundType = Enum<typeof BACKGROUND_TYPE>

export const GRADIENT_MODE = Enum('linear', 'radial', 'conic')
export type GradientMode = Enum<typeof GRADIENT_MODE>
/** 渐变节点 */
export type GradientStop = {
    color: string
    percentage: number
}
/** 渐变色协议 */
export interface GradientProtocol {
    mode: GradientMode
    angle: number
    width?: number
    height?: number
    stops: GradientStop[]
}

export interface BackgroundColorProtocol {
    color?: string
}
export interface BackgroundGradientProtocol {
    gradient?: GradientProtocol
}
export interface BackgroundImageProtocol {
    image?: {
        resource: VariableADTvalue
        fit: Fit
    }
}
export interface BackgroundProtocol extends BackgroundColorProtocol, BackgroundGradientProtocol, BackgroundImageProtocol {
    type: BackgroundType
}

export interface BackgroundNoImageProtocol extends BackgroundColorProtocol, BackgroundGradientProtocol {
    type: Exclude<BackgroundType, 'image'>
}

// 四个边/角的设置
export type EdgeValue = [number | undefined, number | undefined, number | undefined, number | undefined]
export const EDGE_MODE = Enum('all', 'each')
export type EdgeMode = Enum<typeof EDGE_MODE>
export type EdgeConfig = {
    mode?: EdgeMode
    all?: number
    each?: EdgeValue
}

/** 纹理 */
export type Veins = {
    value?: string
    color?: string
    strokeWidth?: number
    size?: number
}

/** 边框 */
export type Border = BackgroundNoImageProtocol & EdgeConfig

/** 阴影 */
export type Shadow = {
    color?: string
    x?: number
    y?: number
    blur?: number
    diffusion?: number
}

export interface DesignStyle {
    radius?: EdgeValue

    color?: BackgroundNoImageProtocol

    lineColor?: BackgroundNoImageProtocol

    textBorder?: Border

    background?: BackgroundProtocol

    veins?: Veins

    border?: Border

    shadow?: Shadow

    blur?: number
}

export type RectSize = number | 'auto' | 'fill'

export type RectSizeUnit = '%' | 'px' | 'vw' | 'vh'

export interface LayoutPosition {
    type?: 'relative' | 'absolute' | 'fixed'
    location: {
        horizontal?: 'left' | 'right'
        vertical?: 'top' | 'bottom'
        horizontalValue?: number
        verticalValue?: number
    }
}

export interface SizeConfigure {
    size: RectSize | undefined
    unit?: RectSizeUnit
}

export interface LayoutSize {
    /**
   * 宽度
   */
    width: SizeConfigure,
    /**
    * 高度
    */
    height: SizeConfigure,
    /**
  * 最小宽度
  */
    minWidth?: Partial<SizeConfigure>,
    /**
   * 最小高度
   */
    minHeight?: Partial<SizeConfigure>,
    /**
   * 最大宽度
   */
    maxWidth?: Partial<SizeConfigure>,
    /**
 * 最大高度
 */
    maxHeight?: Partial<SizeConfigure>,

    /**
     * 当内容超出
     */
    overflow?: 'hidden' | 'auto' | 'visible'
}

export interface AutoLayout {
    /**
     * 对齐相关
     */
    align?: {
        /**
         * 方向
         */
        direction?: Direction
        /**
         * 是否分布对齐
         */
        isDistributedAlignment?: boolean
        /**
         * 对齐方式
         */
        alignX?: FlexAlign
        alignY?: FlexAlign
    }

    /**
     * 间距
     */
    gap?: number
    /**
     * 内边距
     */
    padding?: EdgeValue
}
