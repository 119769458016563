import { IconFont, pointer } from '@byecode/ui'
import type { AppUser, FilterCommonCondition, InclusionRelation, SchemaProtocol, VariableADTvalue, ViewFieldProps } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { fileOperatorOptions, propertyFilter } from '../../constants'
import { getFieldIcon, getVariableFieldId } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { FilterParams } from './FilterParams'
import { OperatorDrawer } from './OperatorDrawer'
import { ParamsDrawer } from './ParamsDrawer'

interface FilterItemProps {
    filterItem: FilterCommonCondition
    columns: ViewFieldProps[]
    personOption: AppUser[]
    schema: SchemaProtocol['schema']
    target?: string
    onChange: (val: FilterCommonCondition) => void
}

interface ParamsOpenState {
    paramsOpen: boolean
    paramsIndex?: 0 | 1
}

const SCxFilterItemWrapper = styled.div`
    position: relative;
    margin-bottom: 16px;
    width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    padding: 12px 16px;
    background-color: var(--color-white);
`

const SCxFilterItemHeaderWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

const SCxFieldWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const SCxIcon = styled(IconFont)``

const SCxFieldText = styled.div`
    font-size: var(--font-size-normal);
    margin-left: 4px;
`

const SCxOperatorText = styled.div`
    font-size: var(--font-size-normal);
    margin-right: 4px;
`

const SCxPlaceholder = styled.div`
    font-size: var(--font-size-normal);
    margin-right: 4px;
    color: var(--color-gray-400);
`

const SCxOperator = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 6px;
    background-color: var(--color-gray-100);
    border-radius: 6px;

    ${pointer}
`

const SCxClear = styled.div`
    position: absolute;
    top: 6px;
    right: 12px;
`

const SCxFilterItemContent = styled.div`
    margin-top: 8px;
`

export const FilterItem: React.FC<FilterItemProps> = ({ target, personOption, filterItem, columns, schema, onChange }) => {
    const [operatorOpen, setOperatorOpen] = useState(false)
    const [paramsOpenState, setParamsOpenState] = useState<ParamsOpenState>({
        paramsOpen: false,
        paramsIndex: undefined
    })
    const { paramsOpen, paramsIndex } = paramsOpenState
    const field = schema[getVariableFieldId(filterItem.idVariable)]
    const operatorOptions = useMemo(() => {
        if (!field || !field?.innerType) {
            return []
        }
        if (field.type === 'file') {
            return fileOperatorOptions
        }
        return propertyFilter[field.innerType]
    }, [field])
    const operatorOption = find(item => item.value === filterItem.operator, operatorOptions)
    const operatorValue = operatorOption?.value || ''
    const icon = field ? getFieldIcon(field.id, field.type, field.innerType) : 'text'

    const selectOptions = useMemo(() => {
        if (field && field.type === 'select') {
            return field.select.options || []
        }
        return []
    }, [field])

    const handleOpenParams = useCallback((index?: 0 | 1) => {
        setParamsOpenState({
            paramsOpen: true,
            paramsIndex: index
        })
    }, [])

    const handleOpenOperator = useCallback(() => {
        setOperatorOpen(true)
    }, [])

    const handleFinishOperator = useCallback(
        (val: InclusionRelation) => {
            onChange?.({
                ...filterItem,
                operator: val
            })
            setOperatorOpen(false)
        },
        [filterItem, onChange]
    )

    const handleCloseParamsDrawer = useCallback(() => {
        setParamsOpenState({
            paramsOpen: false,
            paramsIndex: undefined
        })
    }, [])

    const handleFinishParams = useCallback(
        (val: VariableADTvalue[]) => {
            onChange?.({
                ...filterItem,
                paramList: val
            })

            handleCloseParamsDrawer()
        },
        [filterItem, handleCloseParamsDrawer, onChange]
    )

    const handleClear = useCallback(
        (index?: 0 | 1) => {
            if (!index && index !== 0) {
                onChange({
                    ...filterItem,
                    paramList: []
                })
                return
            }
            const value = filterItem.paramList
            if (index === 0) {
                onChange?.({
                    ...filterItem,
                    paramList: [DEFAULT_FILTER_VALUE_VARIABLE, value?.[1] || DEFAULT_FILTER_VALUE_VARIABLE]
                })
                return
            }
            onChange?.({
                ...filterItem,
                paramList: [value?.[0] || DEFAULT_FILTER_VALUE_VARIABLE, DEFAULT_FILTER_VALUE_VARIABLE]
            })
        },
        [filterItem, onChange]
    )

    return (
        <SCxFilterItemWrapper>
            <SCxFilterItemHeaderWrapper>
                <SCxFieldWrapper>
                    <SCxIcon type={icon} />
                    <SCxFieldText>{field?.name}</SCxFieldText>
                </SCxFieldWrapper>
                <SCxOperator onClick={handleOpenOperator}>
                    {operatorOption?.label ? (
                        <SCxOperatorText>{operatorOption?.label}</SCxOperatorText>
                    ) : (
                        <SCxPlaceholder>请选择</SCxPlaceholder>
                    )}
                    <SCxIcon type="ArrowDownSmallGray" />
                </SCxOperator>
                {field?.type === 'file' && operatorOption?.label && (
                    <SCxClear>
                        <IconFont type="CloseCircle" fill="var(--color-gray-400)" size={16} onClick={() => handleFinishOperator('')} />
                    </SCxClear>
                )}
            </SCxFilterItemHeaderWrapper>
            {operatorValue && operatorValue !== 'isEmpty' && operatorValue !== 'isNotEmpty' && (
                <SCxFilterItemContent>
                    <FilterParams
                        field={field}
                        selectOptions={selectOptions}
                        filterItem={filterItem}
                        onOpenParams={handleOpenParams}
                        onClear={handleClear}
                        operator={operatorValue}
                    />
                </SCxFilterItemContent>
            )}

            <OperatorDrawer
                target={target}
                value={operatorValue}
                opened={operatorOpen}
                onClose={() => setOperatorOpen(false)}
                operatorOptions={operatorOptions}
                onFinish={handleFinishOperator}
            />
            <ParamsDrawer
                target={target}
                opened={paramsOpen}
                paramsIndex={paramsIndex}
                onClose={handleCloseParamsDrawer}
                field={field}
                selectOptions={selectOptions}
                operator={operatorOption?.value || ''}
                value={filterItem.paramList || []}
                onFinish={handleFinishParams}
            />
        </SCxFilterItemWrapper>
    )
}
