import { findBlockById } from '@lighthouse/shared'
import { atom } from 'jotai'
import { debounce } from 'throttle-debounce'

import * as srv from '@/services'

import { blocksAtom, pageListAtom } from './state'
import type { FetchUpdateBlockAtomPayload, UpdateBlockAtomPayload } from './type'

const debounceUpdateBlock = debounce(500, (payload: FetchUpdateBlockAtomPayload) => {
    const { block, pageId } = payload
    return srv.updateBlock(block, pageId)
})

/** 更新页面block */
export const updateBlockAtom = atom(null, (_, set, payload: UpdateBlockAtomPayload) => {
    const { appId, block, pageId } = payload

    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(block.id, currentBlocks)
        if (!draftBlock) {
            return
        }
        Object.entries(block).forEach(([k, v]) => {
            Reflect.set(draftBlock, k, v)
        })
    })

    return debounceUpdateBlock({ appId, block, pageId })
})

/**
 * 获取页面列表
 */
export const fetchPageListAtom = atom(null, async (get, set) => {
    const data = await srv.getPages()
    set(pageListAtom, data)
    return data
})
