import { IconFont, pointer } from '@byecode/ui'
import type React from 'react'
import styled, { css } from 'styled-components'

export const RecordSelectWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 8px;
    overflow: hidden;
`

export const RecordSelector = styled.div`
    width: 100%;
    flex: 1;
    padding: 12px;
`

export const TagItem = styled.span`
    display: inline-flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
    height: 24px;
    padding: 2px 4px 2px 0;
    color: var(--color-black);
    background: var(--color-white);

    &:not(.plain-container) {
        /* background: #f4e7c3; */
        border-radius: 100px;
        padding: 2px 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
`

export const TagItemContent = styled.div`
    margin-right: 6px;
`

export const IconWrapper = styled.div<{ isOpen: boolean }>`
    color: var(--color-gray-800);
    transition: transform 0.2s ease;
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `}
`

export const Add = styled.div<Pick<React.CSSProperties, 'justifyContent'>>`
    width: 100%;
    height: 40px;
    padding: 0 16px;
    gap: 8px;
    background: var(--color-white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent};

    ${pointer}
`

export const Relation = styled.button<{ isWrap: boolean }>`
    white-space: ${({ isWrap }) => (isWrap ? 'normal' : 'nowrap')};
    word-break: break-word;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    padding: 5px 8px;
`
export const TagContain = styled.div`
    display: flex;
    width: calc(100% - 24px);
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    /* overflow: hidden; */
`
