import { type CalendarRenderParameter, Empty, useCalendarDays } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type {
    AiFieldStatus,
    ButtonAction,
    CalendarViewConfig,
    DataSourceAbstract,
    RecordLikeProtocol,
    RichTextContentProtocol,
    ViewBlockAbstract
} from '@lighthouse/core'
import {
    type MobileDrawerProps,
    ApplicationPreviewEnum,
    CenteredWrapper,
    getPrimaryDataSourceEnableFieldIds,
    getViewColumns,
    PRESET_PALETTES
} from '@lighthouse/shared'
import type { Vector2 } from '@use-gesture/react'
import { useDrag } from '@use-gesture/react'
import { eachDayOfInterval, isAfter, isSameDay } from 'date-fns'
import { produce } from 'immer'
import type { atomWithImmer } from 'jotai-immer'
import { merge } from 'rambda'
import React, { forwardRef, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { throttle } from 'throttle-debounce'

import * as SC from './CalendarBlock.style'
import { Cell, CELL_BORDER_BOTTOM_WIDTH, CELL_HEIGHT, DAY_MARGIN_BOTTOM, DAY_MARGIN_TOP, LINE_GAP } from './Cell'
import { Header } from './Header'
import { TimeLine } from './TimeLine'
import { checkDateField, checkField, getSortedWeeks, getTimeLines } from './utils'

export interface CalendarBlockProps {
    previewType: ApplicationPreviewEnum
    target?: MobileDrawerProps['target']
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    records?: RecordLikeProtocol[]
    dataSourceList: DataSourceAbstract[]
    selectedRecords: string[]
    onSelectedRecords: (recordIds: string[]) => void
    // onChangeSelectedMode: (val: SelectedMode) => void
    onUpdateRecord: (recordId: string, content: RecordLikeProtocol['content']) => Promise<unknown>
    onRecordClick?: (recordId: string) => void
    onRecordAdd?: (initialValue?: Record<string, string | number>) => void
    onRecordDelete?: (dsId: string, ids: string[]) => Promise<boolean>
    onRecordEdit?: (recordId: string) => void

    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onAiGeneration: (recordId: string, fieldId: string) => Promise<boolean>

    onRecordClickedActionTrigger?: (action: ButtonAction, record: RecordLikeProtocol) => void
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const CalendarBlock = forwardRef<HTMLDivElement, CalendarBlockProps>((props, ref) => {
    const {
        previewType,
        target,
        blockData,
        dataSource,
        records = [],
        dataSourceList,
        selectedRecords,
        onSelectedRecords,
        // onChangeSelectedMode,
        onUpdateRecord: propOnUpdateRecord,
        onRecordAdd,
        onRecordClick,
        onRecordDelete,
        onRecordEdit,

        aiFieldStatusListAtom,
        onAiGeneration,

        onRecordClickedActionTrigger,
        onRenderButtonTitle
    } = props
    const { id, config } = blockData
    const {
        actions,

        scheduleTitleField,
        scheduleStartDateField,
        scheduleEndDateField,
        scheduleColorMode = 'follow',
        scheduleColor = PRESET_PALETTES.purple,

        canCreateRecord,
        creatingConfig,
        canViewRecord,
        viewingConfig,
        canViewEdit,
        editingConfig,
        canDeleteRecord,
        viewFieldSettings
    } = config as CalendarViewConfig

    const {
        schema,
        viewOptions: { tableProps }
    } = dataSource

    const internalCanEdit = !!canViewEdit && !!editingConfig?.page
    const internalCanCreate = !!canCreateRecord && !!creatingConfig?.page
    const internalCanViewing = !!canViewRecord && !!viewingConfig?.page
    const internalCanDelete = !!canDeleteRecord

    const tableRef = useRef<HTMLTableElement | null>(null)

    const isMobile = useMemo(() => previewType === ApplicationPreviewEnum.mobile, [previewType])

    const [panelViewDate, setPanelViewDate] = useState(new Date())

    const [isSelecting, setIsSelecting] = useState(false)
    const [selectedDates, setSelectedDates] = useState<Date[]>([])

    const selectHandler = throttle(0, (initXy: Vector2, finalXy: Vector2) => {
        const tableEl = tableRef.current
        if (!tableEl) {
            return
        }

        const cells = tableEl.querySelectorAll('td > div')
        let firstCell: Element | null = null
        let lastCell: Element | null = null

        const [sx, sy, ex, ey] = initXy[1] < finalXy[1] ? [...initXy, ...finalXy] : [...finalXy, ...initXy]

        cells.forEach(el => {
            const rect = el.getBoundingClientRect()
            if (rect.right > sx && rect.bottom > sy && !firstCell) {
                firstCell = el
            }
            if (rect.left < ex && rect.top < ey) {
                lastCell = el
            }
        })

        if (!lastCell || !firstCell) {
            return
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        let startDate = new Date(firstCell.getAttribute('data-value'))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        let endDate = new Date(lastCell.getAttribute('data-value'))

        if (isAfter(startDate, endDate)) {
            ;[startDate, endDate] = [endDate, startDate]
        }

        const dates = eachDayOfInterval({ start: startDate, end: endDate })
        setSelectedDates(dates)
    })

    const clickHandler = useCallback(
        (e: MouseEvent) => {
            const paths = e.composedPath()
            if (paths.some(el => el instanceof HTMLElement && el.hasAttribute('data-ignore-tap'))) {
                return
            }
            const cell = paths.find(el => el instanceof HTMLElement && el.hasAttribute('data-value')) as HTMLElement | undefined

            if (!cell) {
                return
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const dateStr = cell.getAttribute('data-value')!

            const date = new Date(dateStr)

            onRecordAdd?.(
                merge(
                    scheduleStartDateField
                        ? {
                              [scheduleStartDateField]: date.valueOf()
                          }
                        : {},
                    scheduleEndDateField
                        ? {
                              [scheduleEndDateField]: date.valueOf()
                          }
                        : {}
                )
            )
        },
        [onRecordAdd, scheduleEndDateField, scheduleStartDateField]
    )

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    // PC端时的框选及点击事件
    useDrag(
        state => {
            if (state.tap) {
                clickHandler(state.event as MouseEvent)
                return
            }

            if (state.first) {
                setIsSelecting(true)
            }
            if (state.last) {
                setIsSelecting(false)

                if (selectedDates.length === 0) {
                    return
                }
                onRecordAdd?.(
                    merge(
                        scheduleStartDateField
                            ? {
                                  [scheduleStartDateField]: selectedDates[0].valueOf()
                              }
                            : {},
                        scheduleEndDateField
                            ? {
                                  [scheduleEndDateField]: selectedDates[selectedDates.length - 1].valueOf()
                              }
                            : {}
                    )
                )
                setSelectedDates([])
                return
            }

            selectHandler(state.initial, state.values)
        },
        {
            filterTaps: true,
            enabled:
                !isMobile &&
                internalCanCreate &&
                checkField(scheduleTitleField, schema) &&
                checkDateField({ key: scheduleStartDateField, schema, primaryDataSourceFieldIds }) &&
                checkDateField({ key: scheduleEndDateField, schema, primaryDataSourceFieldIds }),
            target: tableRef
        }
    )

    const [draftRecord, setDraftRecord] = useState<RecordLikeProtocol | null>(null)
    const localRecords = useMemo(() => [...records, draftRecord].filter(Boolean) as RecordLikeProtocol[], [draftRecord, records])

    const onUpdateLocalRecord = useCallback(
        (id: string, startDate: Date, endDate: Date) => {
            if (!scheduleStartDateField || !scheduleEndDateField) {
                return
            }

            const record = records.find(item => item.id === id)
            if (!record) {
                return
            }

            setDraftRecord(
                produce(record, draft => {
                    draft.id = `draft-${draft.id}`
                    draft.content[scheduleStartDateField] = { value: startDate.valueOf() }
                    draft.content[scheduleEndDateField] = { value: endDate.valueOf() }
                })
            )
        },
        [records, scheduleEndDateField, scheduleStartDateField]
    )

    const onUpdateRecord = useCallback(
        async (id: string, startDate: Date, endDate: Date) => {
            if (!scheduleStartDateField || !scheduleEndDateField) {
                return
            }

            await propOnUpdateRecord?.(id, {
                [scheduleStartDateField]: { value: startDate.valueOf() },
                [scheduleEndDateField]: { value: endDate.valueOf() }
            })
        },
        [propOnUpdateRecord, scheduleEndDateField, scheduleStartDateField]
    )

    // 移动端时的点击事件
    useLayoutEffect(() => {
        if (isMobile) {
            const table = tableRef.current
            if (!table) {
                return
            }

            table.addEventListener('click', clickHandler)

            return () => table.removeEventListener('click', clickHandler)
        }
    }, [clickHandler, isMobile])

    const [hoveredLine, setHoveredLine] = useState('')

    // const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    // const handleRecordDelete = useCallback(
    //     async (dsId: string, ids: string[]) => {
    //         const isDelete = await onRecordDelete?.(dsId, ids)
    //         setSelectedRecords([])
    //         return !!isDelete
    //     },
    //     [onRecordDelete]
    // )

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            onSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, onSelectedRecords, selectedRecords]
    )

    // 删除日程
    const onDeleteSchedule = useCallback(
        (id: string) => {
            if (!internalCanDelete) {
                return
            }
            handleRecordOperatorDelete(dataSource.id, [id])
        },
        [dataSource.id, handleRecordOperatorDelete, internalCanDelete]
    )

    // 打开日程
    const onClickSchedule = useCallback(
        (id: string, record: RecordLikeProtocol) => {
            const recordClickedActionParams = actions?.recordClicked
            if (recordClickedActionParams?.customized) {
                recordClickedActionParams?.action && onRecordClickedActionTrigger?.(recordClickedActionParams.action, record)
                return
            }

            if (!internalCanViewing) {
                return
            }
            onRecordClick?.(id)
        },
        [actions?.recordClicked, internalCanViewing, onRecordClick, onRecordClickedActionTrigger]
    )

    // 编辑日程
    const onEditSchedule = (id: string) => {
        if (!internalCanEdit) {
            return
        }
        onRecordEdit?.(id)
    }

    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId: id,
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [id, schema, tableProps, viewFieldSettings]
    )

    const columns = useMemo(() => settingColumns.filter(item => item.visible), [settingColumns])

    const timeLines = useMemo(
        () =>
            getTimeLines(localRecords, {
                dataSource,
                titleField: scheduleTitleField,
                startField: scheduleStartDateField,
                endField: scheduleEndDateField,
                colorMode: scheduleColorMode,
                color: scheduleColor
            }),
        [localRecords, dataSource, scheduleTitleField, scheduleStartDateField, scheduleEndDateField, scheduleColorMode, scheduleColor]
    )

    const calendarDays = useCalendarDays({ calendarDate: panelViewDate, firstDayOfWeek: 0 }).flat()

    const weeks = useMemo(() => getSortedWeeks(timeLines, calendarDays), [calendarDays, timeLines])

    const [resizingId, setResizingId] = useState('')
    const [draggingId, setDraggingId] = useState('')

    const [maxHeightOfIndexMap, setMaxHeightOfIndexMap] = useState<Record<string, number[]>>({})
    const maxCellHeight = useMemo(
        () =>
            Math.max(
                0,
                ...Object.values(maxHeightOfIndexMap).map(lines => {
                    return lines.reduce(
                        (prev, next, index) => prev + next + (index === 0 ? 0 : LINE_GAP),
                        CELL_HEIGHT + CELL_BORDER_BOTTOM_WIDTH + DAY_MARGIN_TOP + DAY_MARGIN_BOTTOM
                    )
                })
            ),
        [maxHeightOfIndexMap]
    )

    // 修正最大高度
    useEffect(() => {
        setMaxHeightOfIndexMap(s =>
            produce(s, draft => {
                Object.keys(draft).forEach(week => {
                    if (weeks.some(item => item[0] === week)) {
                        return
                    }
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete draft[week]
                })
                weeks.forEach(([week, lines]) => {
                    const maxIndex = Math.max(...lines.map(line => line.index))
                    if (!draft[week]) {
                        return
                    }
                    if (draft[week].length - 1 > maxIndex) {
                        draft[week].splice(maxIndex + 1)
                    }
                })
            })
        )
    }, [weeks])

    const _Cell = useMemo(() => {
        return ({ date, ...args }: CalendarRenderParameter) => {
            if (!date) {
                return null
            }

            return (
                <Cell
                    {...args}
                    style={{ height: maxCellHeight === 0 ? undefined : maxCellHeight }}
                    target={target}
                    date={date}
                    data={timeLines}
                    selected={selectedDates.some(item => isSameDay(item, date))}
                    isSelecting={isSelecting}
                    isMobile={isMobile}
                    canCreate={
                        internalCanCreate &&
                        checkField(scheduleTitleField, schema) &&
                        checkDateField({ key: scheduleStartDateField, schema, primaryDataSourceFieldIds }) &&
                        checkDateField({ key: scheduleEndDateField, schema, primaryDataSourceFieldIds })
                    }
                    onClickSchedule={onClickSchedule}
                />
            )
        }
    }, [
        maxCellHeight,
        target,
        timeLines,
        selectedDates,
        isSelecting,
        isMobile,
        internalCanCreate,
        scheduleTitleField,
        schema,
        scheduleStartDateField,
        primaryDataSourceFieldIds,
        scheduleEndDateField,
        onClickSchedule
    ])

    const _Header = useMemo(() => {
        return (props: CalendarRenderParameter) => <Header isMobile={isMobile} {...props} />
    }, [isMobile])

    return (
        <SC.Container style={{ userSelect: draggingId ? 'none' : undefined }}>
            {checkField(scheduleTitleField, schema) &&
            checkDateField({ key: scheduleStartDateField, schema, primaryDataSourceFieldIds }) &&
            checkDateField({ key: scheduleEndDateField, schema, primaryDataSourceFieldIds }) ? (
                <SC.StyledCalendar
                    ref={r => {
                        tableRef.current = r?.querySelector('table') || null
                    }}
                    styles={{
                        dayBody: {
                            table: {
                                touchAction: isMobile ? undefined : 'none'
                            }
                        }
                    }}
                    weekFormat={isMobile ? 'iiiiii' : 'iii'}
                    firstDayOfWeek={0}
                    onPanelDateChange={setPanelViewDate}
                    HeaderComponent={_Header}
                    FooterComponent={() => null}
                    CellComponent={_Cell}
                />
            ) : (
                <CenteredWrapper style={{ padding: 36 }}>
                    <Empty
                        icon={
                            <img
                                style={{ width: 140, height: 144 }}
                                src={getAssetUrl('empty', 'no_calendar_schedule.svg')}
                                alt="请在「右侧栏>配置」中选择日程标题及日期字段"
                            />
                        }
                        description="请在「右侧栏>配置」中选择日程标题及日期字段"
                    />
                </CenteredWrapper>
            )}

            {isMobile
                ? null
                : weeks.map(([week, item]) => {
                      return (
                          <Fragment key={week}>
                              {item.map(line => {
                                  const prevHeight = maxHeightOfIndexMap[week]?.reduce(
                                      (prev, next, index) => (index < line.index ? prev + next : prev),
                                      0
                                  )

                                  return (
                                      <TimeLine
                                          key={line.id}
                                          actions={actions}
                                          style={{
                                              minHeight: maxHeightOfIndexMap[week]?.[line.index],
                                              pointerEvents: !!resizingId || !!draggingId || isSelecting ? 'none' : undefined
                                          }}
                                          record={line.record}
                                          schema={schema}
                                          columns={columns}
                                          prevHeight={prevHeight}
                                          onHeightChange={v =>
                                              setMaxHeightOfIndexMap(s =>
                                                  produce(s, draft => {
                                                      if (!draft[week]) {
                                                          draft[week] = []
                                                      }
                                                      //   draft[week][line.index] = Math.max(draft[week][line.index] ?? 0, v)
                                                      draft[week][line.index] = v
                                                  })
                                              )
                                          }
                                          info={line}
                                          data={item}
                                          tableElement={tableRef.current}
                                          canEdit={internalCanEdit}
                                          canDelete={internalCanDelete}
                                          canView={internalCanViewing}
                                          onUpdateDate={onUpdateRecord}
                                          onUpdateLocalDate={onUpdateLocalRecord}
                                          onEdit={onEditSchedule}
                                          onDelete={onDeleteSchedule}
                                          onClickSchedule={onClickSchedule}
                                          hoveredLine={hoveredLine}
                                          onResizeStart={() => setResizingId(line.id)}
                                          onResizeEnd={() => {
                                              setResizingId('')
                                              setDraftRecord(null)
                                          }}
                                          onDragStart={() => {
                                              setDraggingId(line.id)
                                          }}
                                          onDragEnd={() => {
                                              setDraggingId('')
                                              setDraftRecord(null)
                                          }}
                                          onMouseOver={() => setHoveredLine(line.id)}
                                          onMouseOut={() => setHoveredLine('')}
                                          aiFieldStatusListAtom={aiFieldStatusListAtom}
                                          onAiGeneration={onAiGeneration}
                                      />
                                  )
                              })}
                          </Fragment>
                      )
                  })}
        </SC.Container>
    )
})
