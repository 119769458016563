import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    Field,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    TableColumnWidth,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import { ApplicationPreviewEnum } from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import React, { useMemo } from 'react'

import { ListContent } from './ListContent'

interface ListProps extends ActionsProtocol {
    blockId: string
    checkable?: boolean
    recordOpenable?: boolean
    canDeleteRecord?: boolean
    canRecordEdit?: boolean
    primaryField?: Field
    viewFields: ViewField[]
    schema: SchemaProtocol['schema']
    records: RecordLikeProtocol[]
    previewType: ApplicationPreviewEnum
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete?: (recordId: string) => void
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const List: React.FC<ListProps> = ({
    blockId,
    actions,
    checkable,
    recordOpenable,
    canDeleteRecord,
    canRecordEdit,
    primaryField,
    viewFields,
    schema,
    records,
    previewType,
    selectedRecords,
    highLightRules,
    designConfig,
    aiFieldStatusListAtom,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
}) => {
    const isMobile = useMemo(
        () => previewType === ApplicationPreviewEnum.mobile,
        [previewType]
    )
    return (
        <ListContent
            blockId={blockId}
            actions={actions}
            checkable={checkable}
            recordOpenable={recordOpenable}
            canDeleteRecord={canDeleteRecord}
            canRecordEdit={canRecordEdit}
            primaryField={primaryField}
            viewFields={viewFields}
            schema={schema}
            isMobile={isMobile}
            records={records}
            selectedRecords={selectedRecords}
            highLightRules={highLightRules}
            designConfig={designConfig}
            aiFieldStatusListAtom={aiFieldStatusListAtom}
            onRecordSelect={onRecordSelect}
            onRecordClick={onRecordClick}
            onRecordEdit={onRecordEdit}
            onRecordDelete={onRecordDelete}
            onAiGeneration={onAiGeneration}
            onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
            onRecordClickedActionTrigger={onRecordClickedActionTrigger}
            onRenderButtonTitle={onRenderButtonTitle}
        />
    )
}
