import { BaseModal } from '@byecode/ui'
import { getBrowser } from '@lighthouse/tools'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import { FilePreviewProvider } from './Context'
import { FileCarousel } from './FileCarousel'
import type { FilePreviewerProps } from './types'

const PreviewerModal = styled(BaseModal)<{ isMobile: boolean }>`
    .byecode-Modal-modal {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        border: none;
        box-shadow: none;
        ${({ isMobile }) =>
            isMobile &&
            css`
                background-color: var(--color-black);
            `}
    }

    .byecode-Modal-body {
        padding: 0;
    }
`

// 文件预览弹窗主体
export const FilePreviewer: FC<FilePreviewerProps> = ({
    fileList,
    defaultIndex = 0,
    disableDownload,
    opened: propOpened,
    target,
    onClose,
    withControls,
    ...rest
}) => {
    const [opened, setOpened] = useState(propOpened ?? false)

    useEffect(() => {
        setOpened(propOpened ?? false)
    }, [propOpened])

    const { isMobile } = getBrowser().parseUserAgent()

    const [currentIndex, setCurrentIndex] = useState(defaultIndex)

    const title = useMemo(() => {
        if (fileList.length === 0) {
            return null
        }

        if (isMobile) {
            return null
        }

        const filename = fileList[currentIndex]?.name || '未命名文件'
        const page = `(${currentIndex + 1}/${fileList.length})`
        return `${filename} ${page}`
    }, [currentIndex, fileList, isMobile])

    const context = useMemo(() => ({ onClose, isMobile }), [isMobile, onClose])

    return (
        <PreviewerModal
            isMobile={isMobile}
            target={target}
            zIndex={300}
            title={title}
            open={opened}
            onClose={onClose}
            withCloseIcon={!isMobile}
            onClick={isMobile ? onClose : undefined}
        >
            <FilePreviewProvider value={context}>
                <FileCarousel
                    withControls={!isMobile && withControls}
                    defaultIndex={defaultIndex}
                    currentIndex={currentIndex}
                    onSlideChange={setCurrentIndex}
                    fileList={fileList}
                />
            </FilePreviewProvider>
        </PreviewerModal>
    )
}
