import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

import { SCxButtonWrapper } from './ExportContent'

export const ChartViewBlockContainer = styled.div`
    position: relative;
    height: calc(100% - 8px);
    margin: var(--block-padding);
    min-height: 32px;
    border-radius: 8px;
    border: none;
    font-size: var(--font-size-normal);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:hover ${SCxButtonWrapper} {
        opacity: 1;
    }
`

export const ChildContainer = styled.div`
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    gap: 8px;
`

export const ChildChartViewContainer = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
`

// export const LoadingBox = styled.div`
//     width: 100%;
//     height: 280px;
// `
// export const Empty = styled.div`
//     border: 1px solid var(--color-gray-200);
//     border-radius: 4px;
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     color: var(--color-gray-400);
// `

export const EmptyIcon = styled(IconFont)`
    margin-bottom: 4px;
    color: var(--color-gray-400);
    font-size: var(--h2-font-size);
`

export const IndicatorChartViewContentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    /* min-height: 164px; */
    height: 100%;
    padding: 16px;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    background: var(--color-white);
    overflow: hidden;

    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 6px 0px rgba(56, 56, 56, 0.12);
    }
`

export const ChartViewContentWrapper = styled.div<{ height?: number }>`
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    padding: 16px;
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    background: var(--color-white);

    transition: all 0.2s ease-in-out;
`
