import type { RecordLikeProtocol } from '@lighthouse/core'
import type { StripeActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, getSearchParams } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'
import type { StripePaySearch } from '@/services/types'

import { stripePayEmitter } from '../event'

// import { openWechatAuthModal } from '@/utils'

export const handleStripePay = async (node: ActionFlowNode<StripeActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { actionEvents, getCurrentPageDeps, userRecord, isFormAction, dataSourceList } = excParams
    const pageId = getCurrentPageDeps()?.pageId ?? ''
    if (!config) {
        throw new Error('配置未完成')
    }
    if (!pageId) {
        throw new Error('页面id异常')
    }
    const { payConfig } = config
    const redirect = window.location.href
    if (!userRecord?.record?.id) {
        window.location.href = `/account/login?redirect=${encodeURIComponent(redirect)}`
        throw new Error('Stripe支付需要登录')
    }
    // Stripe支付
    const {
        amountFieldId,
        succeedFieldSettings,
        descFieldId,
        // failedFieldSettings,
        nodeId = '',
        productNameFieldId,
        autoRedirectAfterPayment = false,
        productPictureFieldId,
        currency,
        callbackWorkflow
    } = payConfig
    const record = excParams[nodeId]?.record
    if (!record) {
        throw new Error('上游节点配置未完善')
    }
    const res = await srv.activeStripePay({
        dsId: record.dsId,
        recordId: record.id,
        pageId,
        amountFieldId,
        descFieldId,
        currency,
        productPictureFieldId,
        productNameFieldId,
        succeedFieldSettings,
        callbackWorkflow
        // failedFieldSettings: failedFieldSettings.map(field => ({
        //     ...field,
        //     value: generateVariableValue({ innerType: schema[field.fieldId]?.innerType || 'TEXT', jsonContent: field.value, extraParams: excParams }) as AnyObject
        // }))
    })
    if (!res.outTradeNo) {
        throw new Error(`获取Stripe支付订单异常`)
    }
    const redirectUrl = window.location.href
    const { expiresAt, outTradeNo, clientSecret } = res
    const newSearch: StripePaySearch = {
        clientSecret,
        outTradeNo,
        expiresAt,
        pageId,
        autoRedirectAfterPayment: Number(autoRedirectAfterPayment),
        redirect: encodeURIComponent(redirectUrl)
    }
    const search = getSearchParams(newSearch)
    actionEvents.navigate(`/pay/stripe?${search}`)
    const newRecord = await new Promise<RecordLikeProtocol>((resolve, reject) => {
        stripePayEmitter.on(`stripePayCheck-${outTradeNo}`, async status => {
            const res = await srv.getRecord({ envId: record.envId, appId: record.appId, dsId: record.dsId, recordId: record.id, pageId })
            if (res.record) {
                resolve(res.record)
            }
            stripePayEmitter.off(`stripePayCheck-${outTradeNo}`)
        })
    })
    return { record: newRecord }
}
