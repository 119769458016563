import type { Batch, BatchItem } from '@rpldy/uploady'
import { FILE_STATES } from '@rpldy/uploady'
import { useEffect, useRef } from 'react'

import { useListenersContext } from '../UploadManage.context'

export const useUploadBatchUploading = (id: string, func: (data: BatchItem[], batchId: string, targetId: string) => void, targetId?: string) => {
    const { batchListener } = useListenersContext()
    const latestFunc = useRef<(data: BatchItem[], batchId: string, targetId: string) => void>(func)
    latestFunc.current = func

    useEffect(() => {
        batchListener.add(latestFunc.current, id, FILE_STATES.UPLOADING, targetId)
    }, [id, batchListener, targetId])
}
