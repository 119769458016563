import type { MakeADT, MakeADTMember } from 'ts-adt/MakeADT'
import { makeMatchI, makeMatchPI } from 'ts-adt/MakeADT'

import type {
    DTAggregate,
    DTCheckbox,
    DTDate,
    DTFile,
    DTFormula,
    DTId,
    DTNumber,
    DTPerson,
    DTSelect,
    DTSelectGenerationByText,
    DTTextGeneration,
    TypeInstanceMap
} from './data'
import type { RichTextContentProtocol } from './richtext'

export type FieldId = string

export enum DateFieldUnit {
    'MINUTE' = 'MINUTE',
    'MONTH' = 'MONTH',
    'DAY' = 'DAY'
}

export const fieldTypes = [
    'id',
    'number',
    'text',
    'url',
    'phoneNumber',
    'email',
    'file',
    'video',
    'select',
    'date',
    'person',
    'formula',
    'notes',
    'role',
    'checkbox',
    'department',
    'textGeneration',
    'textGenerationByAudio',
    'textGenerationByImage',
    'booleanGenerationByText',
    'selectGenerationByText',
    'dateGenerationByText',
    'numberGenerationByText',
    'textGenerationByFile',
    'aggregation',
    'singleSelect',
    'parentDepartment'
] as const

export type FieldType = typeof fieldTypes[number]
export interface FieldProtocol {
    id: string
    name: string
    type: FieldType
    dsId: string
    dsName?: string
    innerType: TypeInstanceMap

    // 连接表中字段
    join?: {
        joinDsId: string
        joinFieldId: string
    }
}

export type TextGenerationField = FieldProtocol & {
    textGeneration: DTTextGeneration
}

export type SelectGenerationByTextField = FieldProtocol & {
    selectGenerationByText: DTSelectGenerationByText
}

export type IdField = FieldProtocol & {
    idConfig: DTId
}

export type NumberField = FieldProtocol & {
    number: DTNumber
}

export type DateField = FieldProtocol & {
    date: DTDate
}

export type SelectField = FieldProtocol & {
    select: DTSelect
}

export type PersonField = FieldProtocol & {
    person: DTPerson
}

// export type DepartmentField = FieldProtocol & {
//     de: DTPerson
// }

export type FormulaField = FieldProtocol & {
    formula: DTFormula
}

export type CheckboxField = FieldProtocol & {
    checkbox: DTCheckbox
}

export type AggregateField = FieldProtocol & {
    aggregation?: DTAggregate
}

export type SingleSelectField = FieldProtocol & {
    singleSelect: DTSelect
}

export type Field = MakeADT<
    'type',
    {
        textGeneration: TextGenerationField
        selectGenerationByText: SelectGenerationByTextField
        id: IdField
        text: FieldProtocol
        url: FieldProtocol
        phoneNumber: FieldProtocol
        email: FieldProtocol
        file: FieldProtocol
        video: FieldProtocol
        number: NumberField
        select: SelectField
        date: DateField
        person: PersonField
        formula: FormulaField
        notes: FieldProtocol
        role: FieldProtocol
        checkbox: CheckboxField
        department: FieldProtocol
        aggregation: AggregateField
        singleSelect: SingleSelectField
        parentDepartment: FieldProtocol
    }
>

export type FieldMember<Type extends Field['type']> = MakeADTMember<'type', Field, Type>
export const matchFieldPI = makeMatchPI<'type'>('type')
export const matchFieldI = makeMatchI<'type'>('type')

export type FormulaSelectValue = {
    label: string
    value: string
}[]

export type SchemaProtocol = {
    schema: {
        [key: string]: Field
    }
}
export type DepartmentValue = string[]

export type RoleValue = string[]

export type PersonValue = string[]

export type SimpleTextValue = string

export type TextValue = string

export type NotesValue = RichTextContentProtocol

export type SelectValue = string[]

export type NumberValue = number | string

export type DateValue = number | string

export type FileValue = DTFile[]

export type BoolValue = boolean

export type NullValue = null

// export type BaseFieldCellValue = SimpleTextValue | TextValue | NumberValue | DateValue | BoolValue

export type ArrayFieldCellValue = SelectValue | PersonValue

export type FormulaValue =
    | TextValue
    | NumberValue
    | BoolValue
    | DateValue
    | ArrayFieldCellValue
    | TextValue[]
    | NumberValue[]
    | BoolValue[]
    | DateValue[]

export type AggregationValue =
    | TextValue
    | NumberValue
    | BoolValue
    | DateValue
    | ArrayFieldCellValue
    | TextValue[]
    | NumberValue[]
    | BoolValue[]
    | DateValue[]

export type FieldCellValue = NotesValue | FormulaValue

export type JoinValue = FieldCellValue

// export interface PersonItemValue {
//     avatar: string | undefined
//     userId: string
//     username: string
// }

export type NullFieldValue = {
    id: string
    name: string
    type: 'null'
    dsId: string
    value: NullValue
    innerType: TypeInstanceMap
    join?: {
        joinDsId: string
        joinFieldId: string
    }
}

export type FieldValue = {
    value?: FieldCellValue
    // typeInstance?: TypeInstanceMap
}

export type FieldADTValue = MakeADT<
    'type',
    {
        textGeneration: TextGenerationField & { value: SimpleTextValue }
        selectGenerationByText: SelectGenerationByTextField & { value: SelectValue }
        id: FieldProtocol & { value: SimpleTextValue }
        text: FieldProtocol & { value: SimpleTextValue }
        url: FieldProtocol & { value: SimpleTextValue }
        phoneNumber: FieldProtocol & { value: SimpleTextValue }
        email: FieldProtocol & { value: SimpleTextValue }
        file: FieldProtocol & { value: FileValue }
        video: FieldProtocol & { value: FileValue }
        person: PersonField & { value: PersonValue }
        number: NumberField & { value: NumberValue }
        select: SelectField & { value: SelectValue }
        date: DateField & { value: DateValue }
        formula: FormulaField & { value: FormulaValue }
        notes: FieldProtocol & { value: NotesValue }
        role: FieldProtocol & { value: RoleValue }
        checkbox: CheckboxField & { value: BoolValue }
        department: FieldProtocol & { value: DepartmentValue }
        aggregation: AggregateField & { value: AggregationValue }
        singleSelect: SingleSelectField & { value: SelectValue }
        parentDepartment: FieldProtocol & { value: SelectValue }
    }
>

export type FilterFieldADTValue = (NullFieldValue | FieldADTValue) & { isIgnore?: boolean }

/** 变量选择器Field */
export type VariableFieldADTValue = MakeADT<
    'type',
    {
        textGeneration: { value: SimpleTextValue }
        selectGenerationByText: { value: SelectValue }
        id: { value: SimpleTextValue }
        text: { value: SimpleTextValue }
        url: { value: SimpleTextValue }
        phoneNumber: { value: SimpleTextValue }
        email: { value: SimpleTextValue }
        file: { value: FileValue }
        video: { value: FileValue }
        formula: { value: FormulaValue }
        person: { value: PersonValue }
        number: { value: NumberValue }
        notes: { value: NotesValue }
        select: { value: SelectValue }
        date: { value: DateValue; format: string }
        role: { value: RoleValue }
        checkbox: { value: BoolValue }
        department: { value: DepartmentValue }
        aggregation: { value: AggregationValue }
        singleSelect: { value: SelectValue }
        parentDepartment: { value: SelectValue }
    }
>

export type TableCellProtocol = FieldADTValue & {
    appId: string
    recordId: string
}
