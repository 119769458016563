import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    TableRowHeightProtocol,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import { conversionHighLightRecord } from '@lighthouse/shared'
import { useUpdateEffect } from '@react-hookz/web'
import { useVirtualizer } from '@tanstack/react-virtual'
import chroma from 'chroma-js'
import type { atomWithImmer } from 'jotai-immer'
import { add } from 'rambda'
import React, { useEffect, useMemo, useState, useTransition } from 'react'
import styled from 'styled-components'

import TableRecord from './TableRecord'
import { getAvatarWidth } from './utils'

export interface TableContentProps extends ActionsProtocol {
    blockId: string
    records: RecordLikeProtocol[]
    viewFields: ViewField[]
    checkable?: boolean
    selectedRecords?: string[]
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    schema: SchemaProtocol['schema']
    recordOpenable?: boolean
    isMobile?: boolean
    highLightRules?: HighLightConditions
    blockWidth: number
    scrollRef: React.RefObject<HTMLDivElement>
    tableRowHeight?: TableRowHeightProtocol
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

const SCxTableContent = styled.div`
    min-width: 100%;
    width: fit-content;
`

const SCxRemain = styled.div`
    width: 8px;
    height: 100%;
`

export const TableContent: React.FC<TableContentProps> = ({
    blockId,
    actions,
    records,
    viewFields,
    checkable,
    selectedRecords = [],
    schema,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    isMobile,
    highLightRules = [],
    blockWidth,
    scrollRef,
    tableRowHeight,
    designConfig,
    aiFieldStatusListAtom,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null)
    const { isShowListAvatar, listAvatar } = designConfig
    const [_, startTransition] = useTransition()
    const { cell: cellStyleConditions, row: rowStyleConditions } = useMemo(
        () => conversionHighLightRecord(highLightRules),
        [highLightRules]
    )

    useEffect(() => {
        if(scrollRef.current){
            setScrollElement(scrollRef.current)
        }
    }, [scrollRef])

    const overscan = useMemo(() => {
        let width = blockWidth
        return viewFields.reduce<number>((prev, cur) => {
            if (width > 0) {
                width -= cur.width || 160
                return ++prev
            }
            return prev
        }, 0)
    }, [blockWidth, viewFields])
    const isAvatar = useMemo(() => isShowListAvatar && listAvatar && schema?.[listAvatar], [isShowListAvatar, listAvatar, schema])

    const columnPaddingStart = useMemo(() => {
        const avatarWidth = getAvatarWidth(isMobile)
        if (isMobile) {
            const width = isAvatar ? avatarWidth : 0
            return add(8, width)
        }
        const width = isAvatar ? avatarWidth : 0
        return add(68, width)
    }, [isAvatar, isMobile])

    const realViewFields = useMemo(() => {
        let fields: ViewField[] = viewFields
        if (isMobile) {
            fields = fields.slice(1)
        }
        if (isAvatar) {
            fields = fields.filter(item => item.fieldId !== listAvatar)
        }
        return fields
    }, [isAvatar, isMobile, listAvatar, viewFields])

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: realViewFields.length,
        getScrollElement: () => scrollElement,
        paddingStart: columnPaddingStart,
        paddingEnd: 44,
        estimateSize: index => realViewFields?.[index].width || 160,
        overscan
    })

    useUpdateEffect(() => {
        startTransition(() => {
            columnVirtualizer.measure()
        })
    }, [columnVirtualizer, viewFields, blockWidth])

    const documentStyles = getComputedStyle(document.body)
    const themeColor = documentStyles.getPropertyValue('--color-app-main').trim()
    const grayColor = documentStyles.getPropertyValue('--color-gray-50').trim()
    const hoverColor = chroma(themeColor).alpha(0.06)?.hex()
    const mixColor = chroma(themeColor).alpha(0.1).hex()
    const stripeHoverColor = chroma.mix(mixColor, grayColor).alpha(0.3).hex()

    const rowHeight = useMemo(() => {
        if (isMobile) {
            return 78
        }
        switch (tableRowHeight) {
            case 'small': {
                return 38
            }
            case 'middle': {
                return 53
            }
            case 'large': {
                return 57
            }
            default: {
                return 45
            }
        }
    }, [isMobile, tableRowHeight])

    return (
        <SCxTableContent
            style={{
                width: `${columnVirtualizer.getTotalSize()}px`,
                position: 'relative'
            }}
        >
            {records.map((record, index) => {
                const { id } = record
                return (
                    <TableRecord
                        key={id}
                        actions={actions}
                        index={index}
                        record={record}
                        schema={schema}
                        blockId={blockId}
                        rowHeight={rowHeight}
                        rowWidth={columnVirtualizer.getTotalSize()}
                        columnVirtualizer={columnVirtualizer}
                        checkable={checkable}
                        selectedRecords={selectedRecords}
                        recordOpenable={recordOpenable}
                        recordDeleteAble={recordDeleteAble}
                        recordEditOpenable={recordEditOpenable}
                        isMobile={isMobile}
                        cellStyleConditions={cellStyleConditions}
                        rowStyleConditions={rowStyleConditions}
                        viewFields={realViewFields}
                        primaryFieldId={viewFields?.[0]?.fieldId}
                        scrollRef={scrollRef}
                        blockWidth={blockWidth}
                        designConfig={designConfig}
                        hoverColor={hoverColor}
                        stripeHoverColor={stripeHoverColor}
                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                        onRecordSelect={onRecordSelect}
                        onRecordClick={onRecordClick}
                        onRecordEdit={onRecordEdit}
                        onRecordDelete={onRecordDelete}
                        onAiGeneration={onAiGeneration}
                        onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                        onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                        onRenderButtonTitle={onRenderButtonTitle}
                    />
                )
            })}
            <SCxRemain />
        </SCxTableContent>
    )
}
