import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

const boxShadow = (): FlattenSimpleInterpolation => css`
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
`

export const FieldContentContainer = styled.div<{ isBoxShadow: boolean }>`
    min-width: calc(100% - 120px);
    flex: 1;
    width: 100%;
    height: auto;
    align-items: center;
    border-radius: 6px;
    ${({ isBoxShadow }) => isBoxShadow && boxShadow}
`

export const FieldPreviewerContainer = styled.div<{
    isInput?: boolean
    background?: string
}>`
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    outline: none;
    // 修复 user-select: none在低版本safari中会锁定输入框
    cursor: pointer;

    &.readonly {
        cursor: not-allowed;
        .ProseMirror {
            cursor: not-allowed;
        }

        [disabled] {
            cursor: not-allowed;
        }
    }

    /* 作用于输入框类型 的field */
    &.border {
        box-shadow: 0 0 0 1px var(--color-gray-200);
        background: ${({ background }) => background};

        @media (any-hover: hover) {
            &:hover {
                box-shadow: 0 0 0 1px var(--color-gray-300);
            }
        }

        &.isInput {
            &:focus-within {
                border-radius: 6px;
                box-shadow: 0 0 0 2px var(--color-app-main);
            }
        }

        &.error {
            box-shadow: 0 0 0 2px var(--color-red-500);
        }

        [data-field-border='true'] {
            box-shadow: 0 0 0 2px var(--color-app-main);
        }
        &:not(.error) {
            [data-field-border='false'] {
                box-shadow: 0 0 0 1px var(--color-gray-200);
            }
        }
    }

    &.anchorBorder {
        /* 作用于非输入框输入的field */
        &.error {
            [data-field-border] {
                box-shadow: 0 0 0 2px var(--color-red-500);
            }
        }
        [data-field-background] {
            background: ${({ background }) => background};
        }
        [data-field-border] {
            background: ${({ background }) => background};
            border-radius: 6px;
        }
        [data-field-border='true'] {
            box-shadow: 0 0 0 2px var(--color-app-main);
        }
        [data-field-border='false'] {
            box-shadow: 0 0 0 1px var(--color-gray-200);
        }
    }
`
