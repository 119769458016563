import type { FlowLayoutNode } from '@lighthouse/shared'

export function setDeadCopyNode(nodes: FlowLayoutNode[], isAlive?: boolean, realParentId?: string): FlowLayoutNode[] {
    return nodes.map(item => {
        return {
            ...item,
            static: !isAlive,
            disabled: !isAlive,
            realParentId,
            children: item.type === 'block' ? undefined : setDeadCopyNode(item.children ?? [], isAlive)
        }
    })
}
