import { useResizeObserver } from '@byecode/ui'
import QRCode from 'qrcode.react'
import React from 'react'
import styled from 'styled-components'

interface QRCodeBoxProps extends React.ComponentPropsWithoutRef<'div'> {
    code: string
}

const SCxContainer = styled.div`
    position: relative;
    width: 100%;
`

export const QRCodeBox: React.FC<QRCodeBoxProps> = ({ code, ...rest }) => {
    const [observerRef, containerRect] = useResizeObserver<HTMLDivElement>()

    return (
        <SCxContainer ref={observerRef} {...rest}>
            <QRCode value={code} size={containerRect.width} renderAs="canvas" />
        </SCxContainer>
    )
}
