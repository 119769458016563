import React, { forwardRef, Fragment, useMemo } from 'react'

import { DEVELOPER } from '../../constants'
import type { VisibleDomProps } from '../VisibleDom'
import { VisibleDom } from '../VisibleDom'
import type { ContainerLayoutContextValue } from './Context'
import { ContainerLayoutProvider, useContainerLayoutContext, useFlowLayoutContext } from './Context'
import { DOM_DATA_LEVELS_NAME, DOM_DATA_NODE_NAME, DOM_DATA_PARENT_NAME } from './Interaction'
import { DOM_DATA_REAL_PARENT_ID, DOM_DATA_STATIC, DOM_DATA_VIRTUAL } from './Interaction/utils'
import type { FlowLayoutNodeProps } from './Node/Node'
import { Node } from './Node/Node'
import { NodeMask } from './Node/NodeMask'

interface Props extends FlowLayoutNodeProps {
    visibleParams?: VisibleDomProps
}

export const NodeRender = forwardRef<HTMLDivElement, Props>(({ data, style, userId, visibleParams, ...props }, ref) => {
    const { selectedIds, disabled: globalDisabled } = useFlowLayoutContext()
    const disabled = data.disabled ?? globalDisabled

    const { parentId, level } = useContainerLayoutContext()

    const mergeStyle: React.CSSProperties = {
        ...style,
        touchAction: 'manipulation',
        zIndex: level
    }

    const isSelected = selectedIds?.includes(data.id)
    const isContainer = data.type === 'container'
    const context = useMemo<ContainerLayoutContextValue>(
        () => ({ parentId: data.id, level: level + 1, ...data.data }),
        [data.data, data.id, level]
    )

    return (
        <Node
            tabIndex={0}
            disabled={disabled}
            {...(disabled
                ? { [DOM_DATA_PARENT_NAME]: parentId || 'root' }
                : {
                      [DOM_DATA_NODE_NAME]: data.id,
                      [DOM_DATA_LEVELS_NAME]: level,
                      [DOM_DATA_PARENT_NAME]: parentId || 'root',
                      [DOM_DATA_REAL_PARENT_ID]: data.realParentId,
                      [DOM_DATA_STATIC]: data.static || undefined,
                      [DOM_DATA_VIRTUAL]: data.virtual || undefined
                  })}
            ref={ref}
            data={data}
            style={mergeStyle}
            {...props}
        >
            {isContainer && (
                <>
                    {data.children && (
                        <ContainerLayoutProvider value={context}>
                            {data.children.map(item => (
                                <Fragment key={item.id}>
                                    <NodeRender data={item} userId={userId} visibleParams={visibleParams} />
                                </Fragment>
                            ))}
                        </ContainerLayoutProvider>
                    )}
                </>
            )}

            {!disabled && !isSelected && <NodeMask style={{ zIndex: 1 }} />}
            {userId === DEVELOPER && visibleParams && <VisibleDom {...visibleParams} filter={data.data?.visibility} />}
        </Node>
    )
})
