import { IconFont, Loading, Popover } from '@byecode/ui'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { COLORS_MAP, LIGHT_COLORS_MAP } from '../../constants/color'
import * as SC from './styles'

export interface Option {
    label: string
    value: string
    describe?: string
    icon?: string
    color?: string
    disable?: boolean
    shown?: boolean
}

export interface MultiSelectChangeEvent<T = string> {
    value: T
    name: string
}

export type MultiSelectItemValueType = string | number

export interface MultiSelectProps {
    children?: React.ReactNode
    loading?: boolean
    value?: MultiSelectItemValueType[]
    defaultValue?: MultiSelectItemValueType[]
    style?: React.CSSProperties
    overlayStyle?: React.CSSProperties
    themeColor?: string
    options?: Option[]
    disabled?: boolean
    isMultiple?: boolean
    onChange?: (value: MultiSelectItemValueType[]) => void
    onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void
    onClear?: (event: React.MouseEvent<HTMLSpanElement>) => void
    className?: string
    placeholder?: string
    withinPortal?: boolean
    downIcon?: string
    clearable?: boolean
}

export const MultiSelect = React.forwardRef<HTMLDivElement, MultiSelectProps>(
    (
        {
            loading = false,
            isMultiple = true,
            defaultValue = [],
            value,
            options = [],
            disabled,
            onChange,
            onFocus,
            onClear,
            style,
            overlayStyle,
            themeColor = 'var(--color-main)',
            className,
            placeholder,
            downIcon = 'ArrowDownSmall',
            clearable = false,
            withinPortal = true
        },
        ref
    ) => {
        const [open, setOpen] = useState(false)
        const [currentValue, setCurrentValue] = useState(value ? value.filter(Boolean) : defaultValue.filter(Boolean))

        const popoverState = usePopupState({
            variant: 'popover',
            popupId: 'select_list_popover'
        })

        const checkValueIndex = useCallback(
            (value: MultiSelectItemValueType) => {
                return findIndex(key => key === value, currentValue)
            },
            [currentValue]
        )

        const selectArr = useMemo(
            () =>
                currentValue.reduce<MultiSelectProps['options']>((prev, cur) => {
                    const item = find(item => item.value === cur, options)
                    if (item && prev) {
                        prev.push(item)
                    }
                    return prev
                }, []),
            [currentValue, options]
        )

        const optionMap = useMemo(
            () =>
                options.reduce(
                    (prev, cur) => {
                        const color = cur.color && COLORS_MAP[cur.color] ? COLORS_MAP[cur.color] : cur.color
                        const colorLight = cur.color && LIGHT_COLORS_MAP[cur.color] ? LIGHT_COLORS_MAP[cur.color] : cur.color
                        prev.options.set(cur.value, { label: cur.label, icon: cur.icon, color: cur.color })
                        if (color) {
                            prev.colors[0].set(cur.value, color)
                        }
                        if (colorLight) {
                            prev.colors[1].set(cur.value, colorLight)
                        }
                        return prev
                    },
                    {
                        options: new Map<string, { label: string; icon?: string; color?: string; disable?: boolean }>(),
                        colors: [new Map<string, string>(), new Map<string, string>()]
                    }
                ),
            [options]
        )

        const handleItemClick = useCallback(
            (value: MultiSelectItemValueType) => {
                const isExit = find(item => item === value, currentValue)
                if (isMultiple) {
                    const filterValue = currentValue.filter(userId => userId !== value)
                    const newValue = isExit ? filterValue : [...filterValue, value]
                    setCurrentValue(newValue)
                    onChange?.(newValue)

                    return
                }
                const newValue = isExit ? [] : [value]
                setCurrentValue(newValue)
                onChange?.(newValue)
                popoverState.close()
            },
            [currentValue, isMultiple, onChange, popoverState]
        )

        const renderList = () => {
            if (loading) {
                return (
                    <SC.NoData>
                        <Loading />
                    </SC.NoData>
                )
            }
            return (
                <SC.MultiSelectList>
                    {options.length > 0 ? (
                        options.map(({ label, value, icon, color }) => (
                            <SC.MultiSelectItem
                                key={value}
                                onClick={() => {
                                    handleItemClick(value)
                                }}
                            >
                                <SC.MultiSelectBox>
                                    <SC.MultiSelectLabel background={optionMap.colors[1].get(value)} color={optionMap.colors[0].get(value)}>
                                        {icon && <SC.MultiSelectItemIcon fill={`var(${optionMap.colors[0].get(value)})`} type={icon} />}
                                        {label}
                                    </SC.MultiSelectLabel>
                                </SC.MultiSelectBox>
                                {checkValueIndex(value) >= 0 ? (
                                    <IconFont type="Tick" color={themeColor} style={{ marginRight: 6 }} />
                                ) : (
                                    // <Checkbox />
                                    <SC.Indent />
                                    // <Checkbox />
                                )}
                            </SC.MultiSelectItem>
                        ))
                    ) : (
                        <SC.NoData>暂无数据</SC.NoData>
                    )}
                </SC.MultiSelectList>
            )
        }

        const handleClear = useCallback(
            (event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation()
                setCurrentValue([])
                onClear?.(event)
            },
            [onClear]
        )

        return (
            <Popover disabled={disabled} withinPortal={withinPortal} position="bottom-start" opened={open} onChange={setOpen}>
                <Popover.Target>
                    <SC.MultiSelectContent open={open} className={className} clearable={currentValue.length > 0 && clearable}>
                        <SC.MultiSelectedValue>
                            {selectArr?.length === 0 && <SC.Placeholder>{placeholder}</SC.Placeholder>}
                            {selectArr?.map(item => (
                                <SC.Tag
                                    key={item.value}
                                    background={optionMap.colors[1].get(item.value)}
                                    color={optionMap.colors[0].get(item.value)}
                                >
                                    {item.icon && <IconFont type={item.icon} style={{ marginRight: 6 }} />}
                                    {item.label}
                                </SC.Tag>
                            ))}
                        </SC.MultiSelectedValue>
                        <SC.IconWrapper>
                            <SC.ArrowIcon type={downIcon} size={16} fill="var(--color-gray-400)" />
                            <SC.ClearIcon onClick={handleClear} size={16} type="Close" fill="var(--color-gray-400)" />
                        </SC.IconWrapper>
                    </SC.MultiSelectContent>
                </Popover.Target>
                <Popover.Dropdown>
                    <SC.MultiSelectOverlay style={overlayStyle}>{renderList()}</SC.MultiSelectOverlay>
                </Popover.Dropdown>
            </Popover>
        )
    }
)
