import type { FlowLayoutNode } from '@lighthouse/shared'
import React, { forwardRef, useEffect } from 'react'

import { ContainerLayout } from '../../components/ContainerLayout'

interface ContainerBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    node: FlowLayoutNode
    shown?: boolean
}
const ContainerBlock = forwardRef<HTMLDivElement, ContainerBlockProps>(({ disabled, node, shown = true, ...rest }, ref) => {
    if (!shown) {
        return null
    }
    return <ContainerLayout ref={ref} node={node} disabled={disabled} {...rest} />
})

export default ContainerBlock
