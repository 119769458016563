import type { BaseFieldConfig, GalleryViewOptions } from '@lighthouse/core'
import { GALLERY_AVATAR_SIZE } from '@lighthouse/core'

export const AVATAR_SIZES = new Map([
    [GALLERY_AVATAR_SIZE.sm, 40],
    [GALLERY_AVATAR_SIZE.md, 56],
    [GALLERY_AVATAR_SIZE.lg, 92],
    [GALLERY_AVATAR_SIZE.xl, 120]
])

export const AVATAR_RADIUS = new Map([
    [GALLERY_AVATAR_SIZE.sm, 8],
    [GALLERY_AVATAR_SIZE.md, 10],
    [GALLERY_AVATAR_SIZE.lg, 12],
    [GALLERY_AVATAR_SIZE.xl, 16]
])

export const isBasicFieldVisible = (field: BaseFieldConfig | undefined): field is Required<BaseFieldConfig> => {
    return !!field && !!field.show && !!field.field
}
export const isAvatarVisible = (headAvatar: GalleryViewOptions['headAvatar']) => {
    return !!headAvatar && !!headAvatar.show && !!headAvatar.field
}
export const isHeadVisible = (
    headTags: GalleryViewOptions['headTags']
): headTags is Exclude<Required<GalleryViewOptions['headTags']>, undefined> => {
    return !!headTags && !!headTags.show && !!headTags.fields && headTags.fields.length > 0
}
export const isContentVisible = (
    contentTags: GalleryViewOptions['contentTags']
): contentTags is Exclude<Required<GalleryViewOptions['contentTags']>, undefined> => {
    return !!contentTags && !!contentTags.show && !!contentTags.fields && contentTags.fields.length > 0
}
export const isFooterVisible = (
    footTags: GalleryViewOptions['footTags']
): footTags is Exclude<Required<GalleryViewOptions['footTags']>, undefined> => {
    return !!footTags && !!footTags.show && !!footTags.fields && footTags.fields.length > 0
}
