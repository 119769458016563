import type {
    ApplicationSettingNavbar,
    ApplicationSettingNavbarButton,
    ApplicationSettingNavbarLinkList,
    ApplicationSettingTheme,
    NavigationLogo,
    PageAbstract
} from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode, NavigationShowMode } from '@lighthouse/core'
import { find } from 'rambda'
import type React from 'react'
import { useMemo } from 'react'

import { commonPageList } from '../../constants'
import type { ApplicationSettingThemeNavBarModeColor, LayoutStyle } from './type'

export const getNavbarLogo = (mode: NavigationShowMode, icon: NavigationLogo['imgUrl']): string => {
    return icon?.square ?? ''
    // switch (mode) {
    //     case NavigationShowMode.horizontal: {
    //         return (icon?.rectangle || icon?.square) ?? ''
    //     }
    //     case NavigationShowMode.verticalNarrow: {
    //         return (icon?.square || icon?.rectangle) ?? ''
    //     }
    //     case NavigationShowMode.verticalWide: {
    //         return (icon?.rectangle || icon?.square) ?? ''
    //     }
    //     default: {
    //         return ''
    //     }
    // }
}

export const getNavigationStyle = (mode: NavigationShowMode): LayoutStyle => {
    switch (mode) {
        case NavigationShowMode.horizontal: {
            return { direction: 'column', width: '100%' }
        }
        case NavigationShowMode.verticalNarrow: {
            return { direction: 'row', width: '66px' }
        }
        case NavigationShowMode.verticalWide: {
            return { direction: 'row', width: '240px' }
        }
        default: {
            return { direction: 'column', width: '100%' }
        }
    }
}

export type ApplicationNavbarHierarchyLevelButton = ApplicationSettingNavbarButton & {
    level: number
}

export const getNodeLevel = (id: string, list: Record<string, ApplicationSettingNavbarButton>, level = 0) => {
    const { parentId = '' } = list[id]
    let currentLevel = level
    if (parentId) {
        currentLevel = getNodeLevel(parentId, list, level + 1)
    }
    return currentLevel
}

export const getFlapChidList = (
    childList: string[],
    dataList: Record<string, ApplicationSettingNavbarButton>,
    curList: ApplicationNavbarHierarchyLevelButton[]
) => {
    let arrList = curList
    for (const nodeId of childList) {
        const node = dataList[nodeId]
        arrList = getFlapChidList(node.child ?? [], dataList, [...arrList, { ...node, level: getNodeLevel(node.id, dataList) }])
    }
    return arrList
}

export const getAccessLinkList = function (list: ApplicationSettingNavbarLinkList['list'], pageList?: PageAbstract[]) {
    const filter = function (btnParams?: ApplicationSettingNavbarButton) {
        if (!btnParams) {
            return false
        }
        const {
            events: { handleEvent, params },
            child
        } = btnParams
        if ((child ?? []).length > 0) {
            return true
        }
        if (handleEvent === 'openPage') {
            const pagePath = params[0]
            const pageId = pagePath.replace('/page/', '')
            if (commonPageList.some(page => page.value === pageId)) {
                return true
            }
            const page = pageList?.find(page => {
                return page.id === pageId
            })
            // 有参数但不是页面 或者 空值参数
            if (!pagePath.includes('/page/') || pageId === '' || !page) {
                return true
            }
            return page.isAccess
        }
        return true
    }
    return Object.fromEntries(Object.entries(list).map(([id, btnParams]) => [id, { ...btnParams, isAccess: filter(btnParams) }]))
}

export function getRealPx(v: number) {
    return `${v * 16}px`
}

export const getNavbarThemeStyles = function (
    navbar: ApplicationSettingNavbar,
    showMode?: NavigationShowMode
): ApplicationSettingThemeNavBarModeColor {
    const {
        width,
        height,
        isSticky = false,
        backgroundBlur,
        leftPadding = 0,
        topPadding = 0,
        leftAndRightPadding = 0,
        topAndDownPadding = 0,
        backgroundOverlay,
        align,
        themeMode
    } = navbar

    const padding =
        showMode === NavigationShowMode.horizontal
            ? `${getRealPx(topPadding)} ${getRealPx(leftAndRightPadding)} 0 ${getRealPx(leftAndRightPadding)}`
            : `${getRealPx(topAndDownPadding)}  0 ${getRealPx(topAndDownPadding)} ${getRealPx(leftPadding)}`
    if (themeMode === ApplicationSettingThemeNavBarMode.light) {
        return {
            padding,
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-black)',
            stressColor: 'var(--color-app-main)',
            borderColor: 'var(--color-gray-200)',
            logoColor: 'var(--color-white)',
            logoBgcolor: 'var(--color-app-main)'
        }
    }
    if (themeMode === ApplicationSettingThemeNavBarMode.dark) {
        return {
            padding,
            backgroundColor: '#2D3240',
            color: 'var(--color-white)',
            stressColor: 'var(--color-white)',
            borderColor: 'var(--color-white)',
            logoColor: '#2D3240',
            logoBgcolor: 'var(--color-white)'
        }
    }
    if (themeMode === ApplicationSettingThemeNavBarMode.transparent) {
        // if (showMode === NavigationShowMode.horizontal && backgroundOverlay) {
        //     return {
        //         padding,
        //         backgroundColor: 'var(--color-white)',
        //         color: 'var(--color-black)',
        //         stressColor: 'var(--color-app-main)',
        //         borderColor: 'var(--color-gray-200)',
        //         logoColor: 'var(--color-white)',
        //         logoBgcolor: 'var(--color-app-main)'
        //     }
        // }
        return {
            padding,
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-black)',
            stressColor: 'var(--color-app-main)',
            borderColor: 'var(--color-gray-200)',
            logoColor: 'var(--color-white)',
            logoBgcolor: 'var(--color-app-main)'
        }
    }

    return {
        padding,
        backgroundColor: 'var(--color-app-main)',
        color: 'var(--color-white)',
        stressColor: 'var(--color-white)',
        borderColor: 'var(--color-white)',
        logoColor: 'var(--color-app-main)',
        logoBgcolor: 'var(--color-white)'
    }
}

export const getNavBtnAccess = function (
    value: ApplicationSettingNavbarButton,
    dataList: Record<string, ApplicationSettingNavbarButton>
): boolean {
    const { child = [], isAccess } = value

    return isAccess ? (child.length > 0 ? child.map(nodeId => getNavBtnAccess(dataList[nodeId], dataList)).some(Boolean) : true) : false
}

// 递归展开函数
export function getItemChildren(data: Record<string, ApplicationSettingNavbarButton>, id: string) {
    const result: ApplicationSettingNavbarButton[] = []
    // 内部递归函数
    function traverse(id: string) {
        const current = data[id]
        if (current) {
            result.push(current) // 将当前对象加入结果
            if (current.child && current.child.length > 0) {
                current.child.forEach(traverse) // 递归处理子节点
            }
        }
    }
    // 遍历初始 ID 数组
    traverse(id)

    return result
}

/**
 * 更新父节点的 isAccess 属性
 * 如果所有子节点的 isAccess 都为 false，将父节点的 isAccess 设置为 false
 * @param node 当前节点
 * @returns boolean 返回当前节点的最终 isAccess 值
 */
/**
 * 更新节点及其子节点的 isAccess 属性
 * 如果所有子节点的 isAccess 为 false，将父节点的 isAccess 设置为 false
 * @param node 当前节点
 * @returns boolean 返回当前节点的最终 isAccess 值
 */
export function updateNodeAccess(node: ApplicationSettingNavbarButton, list: Record<string, ApplicationSettingNavbarButton>): boolean {
    // 如果没有子节点，直接返回当前节点的 isAccess
    if (!node.child || node.child.length === 0) {
        return node.isAccess ?? true // 默认为 true
    }

    // 递归处理所有子节点
    const allChildrenFalse = node.child.every(child => !updateNodeAccess(list[child], list))

    // 如果所有子节点 isAccess 为 false，则设置当前节点 isAccess 为 false
    if (allChildrenFalse) {
        node.isAccess = false
    }

    // 返回当前节点的 isAccess 状态
    return node.isAccess ?? true
}
