import type { AnyObject } from 'immer/dist/internal'
import type { LegacyRef, MutableRefObject, RefCallback } from 'react'
import ShortUniqueId from 'short-unique-id'

import type { FileUnit } from './types'

export const nanoid = (length = 32) => new ShortUniqueId().stamp(length)
// eslint-disable-next-line no-promise-executor-return
export const wait = (n: number) => new Promise(r => setTimeout(r, n))

const fileUnits: FileUnit[] = ['B', 'kB', 'MB', 'GB', 'TB']

// eslint-disable-next-line no-promise-executor-return
export const waitNextFrame = () => new Promise<number>(resolve => requestAnimationFrame(resolve))

export const waitNFrame = (n = 3): Promise<void> => {
    if (n <= 0) {
        return Promise.resolve()
    }
    return waitNextFrame().then(() => waitNFrame(n - 1))
}

/**
 * merge ref
 * @export
 * @template T
 * @param {(Array<MutableRefObject<T> | LegacyRef<T>>)} refs
 * @return {*}  {RefCallback<T>}
 */
export const mergeRefs = <T = unknown>(refs: (MutableRefObject<T> | LegacyRef<T>)[]): RefCallback<T> => {
    return value => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                ref(value)
            }
            if (ref !== null) {
                void ((ref as MutableRefObject<T | null>).current = value)
            }
        }
    }
}

/**
 * get file s ize with unit
 * @param size : 0;
 * @returns
 */
export const getFileSizeWithUnit = (size?: number) => {
    if (size === undefined) {
        return ''
    }
    if (size === 0) {
        return `0 ${fileUnits[0]}`
    }
    const unitIndex = Math.floor(Math.log(size) / Math.log(1024))
    const unitedSize = Number((size / 1024 ** unitIndex).toFixed(1)).toLocaleString()
    return `${unitedSize} ${fileUnits[unitIndex] ?? ''}`
}

/**
 * get file size with unit
 * @param size : 0;
 * @returns
 */
export const getFileSizeAccordanceBaseUnit = (size?: number, baseUnit?: FileUnit) => {
    if (size === undefined) {
        return ''
    }
    const unitIndex = fileUnits.indexOf(baseUnit ?? 'B')
    const powerNum = fileUnits.slice(unitIndex).length - 1
    const byte = size * 1024 ** powerNum
    return getFileSizeWithUnit(baseUnit === 'B' ? size : byte)
}

/**
 *
 * @param  names
 * @param name
 * 生成属性名
 * 场景1：xxx
 * 场景2：xxx的副本
 * 场景3：xxx的副本2
 * 场景4：创建默认属性名，属性
 */
export const generateName = (names: string[], name: string) => {
    const noOrderName = name.replace(/(\d+$)/gi, '')
    const reg = new RegExp(`${noOrderName}(\\d*)$`)
    const orderNames = names.filter(n => reg.test(n))
    const orders = [
        ...orderNames
            .map(orderName => {
                return Number(orderName.slice(noOrderName.length))
            })
            .filter(Number.isInteger)
    ].sort((a, b) => a - b)
    let order = 0
    // eslint-disable-next-line no-constant-condition
    while (true) {
        if (orders[order] !== order && orders[order + 1] !== order + 1) {
            break
        }
        order++
    }
    return `${noOrderName}${order === 0 ? '' : order}`
}

/**
 * 从数组从随机生成给定 条目数据
 * @param {(any[] | string[])} data
 * @return {*}
 */
export function randomData<T>(data: T[], count = 1): T[] {
    let i = 0
    const randomData: T[] = []
    // eslint-disable-next-line no-constant-condition
    while (true) {
        if (i >= count) {
            break
        }
        const index = Math.floor(Math.random() * data.length)
        const d = data[index]
        if (d && !randomData.includes(d)) {
            randomData.push(d)
            i++
        }
    }
    return randomData
}

/**
 * move array element from `fromIndex` to `toIndex`
 * @param array
 * @param fromIndex
 * @param toIndex
 * @returns
 */
export const arrayMove = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
    // immutable
    const newArray = [...array]

    // infer start in dex
    const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex

    // ensure index inside this array
    if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex
        const [item] = newArray.splice(fromIndex, 1)
        item && newArray.splice(endIndex, 0, item)
    }

    return newArray
}

function isStringArray(arr: unknown): arr is string[] {
    return Array.isArray(arr) && arr.every(item => typeof item === 'string')
}

type RequiredKeys<T> = { [K in keyof T]: undefined extends T[K] ? never : K }[keyof T]
type RequiredProp<T> = { [K in RequiredKeys<T>]: T[K] }

export function deepOmitNil<T>(obj: T): unknown {
    if (typeof obj !== 'object') {
        return obj
    }
    if (obj === null) {
        return null
    }
    if (isStringArray(obj)) {
        return obj
    }
    if (Array.isArray(obj)) {
        return obj.map(item => deepOmitNil(item))
    }

    return Object.entries(obj as Record<string, unknown>).reduce((prev, [k, v]) => {
        if (v !== undefined) {
            if (typeof v === 'object' && v !== null) {
                if (Array.isArray(v)) {
                    // @ts-expect-error fix data type error
                    prev[k] = v.map(item => deepOmitNil(item))
                    return prev
                }
                const value = deepOmitNil(v as Record<string, unknown> | unknown[])
                if (value !== undefined) {
                    Reflect.set(prev, k, value)
                    return prev
                }
            }
            Reflect.set(prev, k, v)
        }
        return prev
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {} as RequiredProp<T>)
}

export function safeJSONParse<T = unknown>(str: string, fallback: T): T {
    try {
        return JSON.parse(str)
    } catch {
        return fallback
    }
}

// 获取一个随机Icon
export function getRandomIcon() {
    const icons = [
        'CloudIconLibrary',
        'ShopIconLibrary',
        'ChartSquareIconLibrary',
        'CityIconLibrary',
        'ShieldIconLibrary',
        'PlateIconLibrary',
        'ReorderIconLibrary',
        'BoxMinimalisticIconLibrary',
        'DatabaseIconLibrary',
        'MonitorSmartphoneIconLibrary'
    ]
    const num = Math.floor(Math.random() * icons.length)
    return icons[num] ?? 'CloudIconLibrary'
}

/**
 * 从 localStore 中取数据
 * @export
 * @template T
 * @param {string} key
 * @param {T} defaultData
 * @return {*}  {T}
 */
export function getCachedItem<T>(key: string, defaultData: T): T {
    const cachedValue = localStorage.getItem(key)
    if (cachedValue) {
        try {
            return JSON.parse(cachedValue)
        } catch {
            return defaultData
        }
    }
    return defaultData
}

/**
 * 往 localStore 中存数据
 * @export
 * @param {string} key
 * @param {*} data
 * @return {*}  {(Error | void)}
 */
export function setCachedItem<T = AnyObject>(key: string, data: T): Error | undefined {
    try {
        localStorage.setItem(key, JSON.stringify(data, null, 0))
    } catch (error) {
        return error as Error
    }
    return undefined
}

/**
 * 往 localStore 中删除数据
 * @export
 * @param {string} key
 * @param {*} data
 * @return {*}  {(Error | void)}
 */
export function removeCachedItem<T = AnyObject>(key: string): Error | undefined {
    try {
        localStorage.removeItem(key)
    } catch (error) {
        return error as Error
    }
    return undefined
}

export function numberToFixed(num: string, n: number) {
    const data = String(num)
    if (data.includes('.')) {
        const arr = data.split('.')
        const integer = arr[0]
        const decimal = arr[1]
        const float = decimal.slice(0, Math.max(0, n))
        return `${integer}${String(float) ? `.${float}` : ''}`
    }
    return data
}

/**
 * 根据要删除的列表索引，返回下一个索引。如果删除的是最后一个，则返回上一个
 * @param currentIndex 当前删除的索引
 * @param list 当前的列表
 * @example
 * ```
 * getNextIndexByDeleteList(0, [1, 2, 3])
 * // return 1
 * getNextIndexByDeleteList(1, [1, 2, 3])
 * // return 2
 * getNextIndexByDeleteList(2, [1, 2, 3])
 * // return 1
 * getNextIndexByDeleteList(3, [1, 2, 3])
 * // return 0
 * ```
 * @returns number
 */
export function getNextIndexByDeleteList(currentIndex: number, list: unknown[]) {
    if (currentIndex === -1 || list.length <= 1 || currentIndex > list.length - 1) {
        return 0
    }

    // 删除的是最后一个元素，则指向上一个
    if (currentIndex === list.length - 1) {
        return currentIndex - 1
    }

    // 否则指向下一个
    return currentIndex + 1
}

export function download(file: File | { url: string; name: string; type: string }) {
    const link = document.createElement('a')
    link.download = file.name ?? 'download'
    link.target = '_blank'
    link.rel = 'noopener'
    const isNativeFile = file instanceof File
    const url = isNativeFile ? URL.createObjectURL(file) : file.url
    link.href = url
    link.click()
    if (isNativeFile) {
        URL.revokeObjectURL(url)
    }
}

export function at<T>(index: number, arr: T[]): T | undefined {
    if (!Array.isArray(arr)) {
        return
    }
    let n = Math.trunc(index) || 0
    if (n < 0) {
        n += arr.length
    }
    if (n < 0 || n >= arr.length) {
        return
    }
    return arr[n]
}

export const getStyle = function (target: HTMLElement, property: string) {
    const nodeStyle = window.getComputedStyle(target)
    return nodeStyle.getPropertyValue(property).replace('px', '')
}

export const getStyleToNumber = function (target: HTMLElement, property: string) {
    const nodeStyle = window.getComputedStyle(target)
    return Number.parseInt(nodeStyle.getPropertyValue(property).replace('px', ''))
}

export function getActualWidthOfChars(text: string, options: { size: number; family: string }) {
    const { size = 14, family = 'Microsoft YaHei' } = options
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx) {
        ctx.font = `${size}px ${family}`
        const metrics = ctx.measureText(text)
        // const actual = Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight)
        // return Math.max(metrics.width, actual)
        return Math.floor(metrics.width)
    }
    return 0
}

export function arrayDifference<T>(arr1: T[], arr2: T[]) {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    return [...set1, ...set2].reduce<{ added: T[]; removed: T[] }>(
        (acc, val) => {
            if (set1.has(val) && !set2.has(val)) {
                acc.removed.push(val)
            }
            if (!set1.has(val) && set2.has(val)) {
                acc.added.push(val)
            }
            return acc
        },
        { added: [], removed: [] }
    )
}

export function queryParamUrl(url: string, name: string) {
    const search = url.split('?')?.[1]
    if (!search) {
        return
    }
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i') // i 忽略大小写
    const res = search.match(reg) // 返回格式 0: "a=10&" 1: "" 2: "10" 3: "&" groups: undefined index: 0 input: "a=10&b=20&c=30"
    if (res === null) {
        return
    }
    return res[2]
}

/**
 * @description 获取文件名称且不含扩展名
 * @export
 * @param {*} path
 * @return {*}
 */
export function getFileNameWithoutExtension(path: string) {
    const url = new URL(path)
    const fileName = url.pathname.split('/').pop()
    return fileName?.split('.')[0] ?? ''
}
/**
 * @description
 * @export
 * @param {*} url
 * @return {*}
 */
export function removeURLParameters(url: string) {
    const urlObject = new URL(url)
    urlObject.search = '' // 清空参数部分
    return urlObject.toString()
}
/**
 * @description
 * @param {string} phoneNumber
 * @return {*}
 */
export function hidePhoneNumber(phoneNumber: string) {
    // 检查输入是否合法
    if (!phoneNumber || typeof phoneNumber !== 'string' || phoneNumber.length !== 11) {
        return phoneNumber
    }

    // 将手机号的中间四位用 '*' 替换
    return phoneNumber.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
}

/**
 * @description
 * @param {string[] | number[]} arr
 * @return {boolean}
 */

export function compareArrays<T extends string[] | number[]>(arr1: T, arr2: T) {
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const sortArr1 = [...arr1].sort()
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const sortArr2 = [...arr2].sort()
    return sortArr1.length === sortArr2.length && sortArr1.every((value, index) => value === sortArr2[index])
}

/**
 * 在一组 React 组件中插入指定的分隔符
 * @export
 * @template T
 * @template U
 * @param {T[]} arr
 * @param {U} gap
 * @return {*}  {((T | U)[])}
 */
export function interleave<T, U>(array: T[], gap: U): (T | U)[] {
    const result: (T | U)[] = []
    for (const item of array) {
        result.push(item, gap)
    }
    result.pop()
    return result
}

/**
 * 获取输入框粘贴验证码时正确的值
 * @param currentValue
 * @param newValue
 * @returns
 */
export function getPasteSmsCode(currentValue: string, newValue: string) {
    const diffLen = newValue.length - currentValue.length
    if (currentValue === newValue.slice(currentValue.length) && (diffLen === 4 || diffLen === 6)) {
        return currentValue
    }
    return newValue
}

/**
 * 生成自增数量名称
 *
 * @export
 * @param {string} name
 * @param {?string[]} [existNames]
 * @returns {string}
 */
export function generateNameByAutoIncrement(name: string, existNames?: string[]) {
    if (!existNames) {
        return name
    }
    let count = 1

    while (existNames.includes(`${name}${count}`)) {
        count++
    }

    return `${name}${count}`
}

/**
 * @description
 * @param {*} url
 * @param {*} parameter
 * @return {*}
 */
export function removeURLParameter(url: string, parameter: string | string[]) {
    try {
        // 使用URL构造函数解析URL
        const urlObj = new URL(url)
        // 删除指定的search参数
        if (typeof parameter === 'object') {
            for (const param of parameter) {
                urlObj.searchParams.delete(param)
            }
        } else {
            urlObj.searchParams.delete(parameter)
        }
        // 返回删除参数后的URL字符串
        return urlObj.toString()
    } catch {
        return ''
    }
}
/**
 * @description 数组插入
 * @template T
 * @param {number} indexToInsert
 * @param {T} valueToInsert
 * @param {T[]} array
 * @return {*}
 */
export function insert<T>(indexToInsert: number, valueToInsert: T, array: T[]) {
    return [...array.slice(0, indexToInsert), valueToInsert, ...array.slice(indexToInsert)]
}


/**
 * @description 数组包含数组
 * @template T
 * @param {array} arr
 * @param {array} subArr
 * @return {boolean}
 */
export function arrayContainsArray(mainArr: string[], subArr: string[]) {
    return subArr.every(item => mainArr.includes(item));
}

/**
 * @description keyvalue中通过value找到key
 * @template T
 * @param {string | number} value
 * @param {Record<string | number, string | number>} obj
 * @return {string | number}
 */
export function getKeyByValue(value: string | number, obj: Record<string | number, string | number>) {
    return Object.entries(obj).find(([key, val]) => val === value)?.[0]
}

/**
 * @description 随机生成8位
 * @param {number} length
 * @return {string}
 */
export function generateRandomString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}

