import { IconFont } from '@byecode/ui'
import { type FilterCommonCondition, VariableType } from '@lighthouse/core'
import { filter } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext } from '../../../contexts'
import { DepartmentItemPreviewer } from '../../FieldValuePreviewer/DepartmentValuePreviewer'
import { PersonItemPreviewer } from '../../FieldValuePreviewer/PersonValuePreviewer'

const SCxSelectParamsWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    padding: 0 8px;
    border-radius: 6px;
`

const SCxContent = styled.div`
    padding: 7px 0;
    height: 100%;
    color: var(--color-black);
    flex: 1;
    display: flex;
    gap: 6px;
    flex-flow: row nowrap;
    overflow: hidden;
`

const SCxIcon = styled(IconFont)`
    width: 20px;
`

interface DepartmentParamsProps {
    filterItem: FilterCommonCondition
    onClick?: () => void
}

export const DepartmentParams: React.FC<DepartmentParamsProps> = ({ filterItem, onClick }) => {
    // const value = filterItem?.params as string[] | undefined
    const value = useMemo(() => {
        const { paramList = [] } = filterItem
        return paramList.reduce<string[]>((prev, cur) => {
            if (cur.type === VariableType.VALUE && cur.valueVariable?.value && Array.isArray(cur.valueVariable?.value)) {
                prev = cur.valueVariable?.value as string[]
            }
            return prev
        }, [])
    }, [filterItem])
    const { departmentOptions } = useApplicationContext()
    const list = useMemo(() => filter(item => value.includes(item.id), departmentOptions), [departmentOptions, value])
    return (
        <SCxSelectParamsWrapper>
            <SCxContent onClick={onClick}>
                {list.map(item => (
                    <DepartmentItemPreviewer id={item.id} data={item} key={item.id} />
                ))}
            </SCxContent>
            <SCxIcon type="ArrowDownSmallGray" />
        </SCxSelectParamsWrapper>
    )
}
