import { Enum } from '@lighthouse/tools'
import type { AnyObject } from 'immer/dist/internal'

import type { BlockType, ViewLayout } from '../abstracts'
import type { AutoLayout, DesignStyle, LayoutPosition, LayoutSize } from './design'
import type { FilterFormType } from './filter'
import type { CreatableProtocol } from './general'
import type { AlignMent } from './layout'
import type { TextProtocol } from './text'

export type BlockId = string

/**
 * 类 Block 对象协议
 */
export type BlockLikeProtocol = CreatableProtocol & {
    id: string
    type: string
}

/**
 * Block 协议
 */
export interface BlockProtocol extends BlockLikeProtocol {
    type: BlockType
    title: string
    content?: unknown
    config?: AnyObject

    /** 与其他block绑定关系时用到的id，主要是同步组件影响导致需要的 */
    logicId?: string
}

export const BLOCK_SIZE = Enum('xs', 'sm', 'md', 'lg', 'xl')
export type BlockSize = Enum<typeof BLOCK_SIZE>

export const FilterVisibleModeEnum = Enum('HIDDEN', 'VISIBLE', 'FILTER')
export type FilterVisibleMode = Enum<typeof FilterVisibleModeEnum>

export interface BlockVisibilityProtocol {
    visibilityFilter?: FilterFormType
    visible?: FilterVisibleMode
}

// export interface DesignLayoutProtocol {
//     breakPoint: ApplicationPreviewEnum
//     layout: AutoLayout
//     size: LayoutSize
//     position?: LayoutPosition
// }

/** block公共的设计 布局配置 */
// export interface BreakPointDesignLayoutProtocol {
//     designLayoutMap: DesignLayoutProtocol[]
// }

// export interface BreakPointDesignProtocol {
//     design: {
//         desktop: DesignStyle
//         mobile: Partial<DesignStyle>
//     }
// }

// export interface BreakPointVisibilityProtocol {
//     visibility: {
//         desktop: BlockVisibilityProtocol
//         mobile: Partial<BlockVisibilityProtocol>
//     }
// }

export interface BaseBreakPointConfigProtocol {
    id: string
    name: string

    visibility?: BlockVisibilityProtocol

    size: LayoutSize
    layout?: AutoLayout
    position?: LayoutPosition

    breakKeys?: string[]
}

export interface ContainerBreakPointConfigProtocol extends BaseBreakPointConfigProtocol {
    design?: DesignStyle
}

export interface ViewBreakPointConfigProtocol extends BaseBreakPointConfigProtocol {
    viewLayout?: ViewLayout
    design?: DesignStyle
}

export interface TextBreakPointConfigProtocol extends BaseBreakPointConfigProtocol {
    design: DesignStyle
    font: TextProtocol & {
        advance?: string
        align?: AlignMent
    }
}

export type PartialExcludesIdAndName<T> = Partial<Omit<T, 'id' | 'name'>> & {
    id: string
    name: string
}

export interface ContainerBreakPointProtocol {
    breakPoint: ContainerBreakPointConfigProtocol
    breakPoints: PartialExcludesIdAndName<ContainerBreakPointConfigProtocol>[]

}

export interface ViewBreakPointProtocol {
    breakPoint: ViewBreakPointConfigProtocol
    breakPoints: PartialExcludesIdAndName<ViewBreakPointConfigProtocol>[]

}

export interface BaseBreakPointProtocol {
    /** 基础断点 */
    breakPoint: BaseBreakPointConfigProtocol
    /** 其他断点 */
    breakPoints: PartialExcludesIdAndName<BaseBreakPointConfigProtocol>[]
}

export interface TextBreakPointProtocol {
    breakPoint: TextBreakPointConfigProtocol
    breakPoints: PartialExcludesIdAndName<TextBreakPointConfigProtocol>[]
}

// export interface BlockCommonConfigProtocol extends BreakPointVisibilityProtocol, BreakPointDesignLayoutProtocol {}

