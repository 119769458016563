import type { AutoLayout, Direction, LayoutSize, SizeConfigure } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'

function getWidthSize(configure: SizeConfigure, parentDirection: Direction = DIRECTION.horizontal) {
    if (configure?.size === 'fill') {
        if (parentDirection === DIRECTION.horizontal) {
            return {
                flex: '1 0 0'
            }
        }

        return {
            width: '100%'
        }
    }

    if (configure?.size === 'auto') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '0 0 auto'
            }
        }
        return {}
    }

    return { width: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
}
function getHeightSize(configure: SizeConfigure, parentDirection: Direction = DIRECTION.horizontal) {
    if (configure?.size === 'fill') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '1 0 0'
            }
        }

        return {
            height: '100%'
        }
    }

    if (configure?.size === 'auto') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '0 0 auto'
            }
        }
        return {}
    }

    return { height: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
}

function getMinMaxSize(configure: Partial<SizeConfigure> | undefined) {
    if (configure?.size === 'auto') {
        return undefined
    }
    if (configure?.size === 'fill') {
        return '100%'
    }
    if (configure?.size) {
        return `${configure.size}${configure?.unit || 'px'}`
    }
    return undefined
}

export function getSizeStyle(layoutSize?: LayoutSize, parentDirection: Direction = DIRECTION.horizontal): React.CSSProperties {
    if (!layoutSize) {
        return {}
    }

    return {
        ...getWidthSize(layoutSize.width, parentDirection),
        minWidth: getMinMaxSize(layoutSize.minWidth) || 0,
        maxWidth: getMinMaxSize(layoutSize.maxWidth),
        ...getHeightSize(layoutSize.height, parentDirection),
        minHeight: getMinMaxSize(layoutSize.minHeight) || 0,
        maxHeight: getMinMaxSize(layoutSize.maxHeight)
    }
}

export function getLayoutStyle(layout?: AutoLayout): React.CSSProperties {
    if (!layout) {
        return {}
    }

    const isVertical = layout.align?.direction === DIRECTION.vertical
    const isDistributedAlignment = !!layout.align?.isDistributedAlignment

    return {
        display: 'flex',
        flexDirection: isVertical ? 'column' : 'row',
        justifyContent: isDistributedAlignment ? 'space-between' : isVertical ? layout.align?.alignY : layout.align?.alignX,
        alignItems: isVertical ? layout.align?.alignX : layout.align?.alignY,
        gap: layout.gap && `${layout.gap}px`
    }
}
