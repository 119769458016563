import { Empty, Flex } from '@byecode/ui'
import type { ButtonAction, DataSourceAbstract, RecordLikeProtocol, ViewBlockAbstract } from '@lighthouse/core'
import { ARRANGEMENT, BLOCK_SIZE, CUSTOM_VIEW_LAYOUT, DIRECTION } from '@lighthouse/core'
import type { ApplicationPreviewEnum, ContainerLayoutContextValue, FlowLayoutNode, VisibleDomProps } from '@lighthouse/shared'
import { ContainerLayoutProvider, EmptyNoRecordSvg, useContainerLayoutContext } from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import React, { forwardRef, useMemo } from 'react'

import { HorizontalScrollView } from './HorizontalScrollView'
import { VerticalView } from './VerticalView'

export interface CustomViewBlockProps {
    blockData: ViewBlockAbstract
    records?: RecordLikeProtocol[]
    dataSource: DataSourceAbstract
    node: FlowLayoutNode
    blockWidth: number
    breakPoint: BreakPointSize
    readonly?: boolean
    scale?: number
    previewType: ApplicationPreviewEnum
    visibleParams?: VisibleDomProps
    userId?: string
    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>
    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
}

export const CustomViewBlock = forwardRef<HTMLDivElement, CustomViewBlockProps>((props, ref) => {
    const {
        blockData,
        node,
        blockWidth,
        breakPoint,
        records,
        dataSource,
        readonly,
        scale,
        previewType,
        visibleParams,
        userId,
        onLoadMoreData,
        onFilterNode,
        onRecordClick,
        onRecordClickedActionTrigger
    } = props
    const { id, title, config } = blockData
    const { pagination, pointer, breakPoint: baseBreakPointConfig, actions } = config
    const showEmpty = !pointer || !dataSource || !records || records.length === 0
    const { viewLayout } = baseBreakPointConfig
    const {
        layout = CUSTOM_VIEW_LAYOUT.waterfall,
        arrangement = ARRANGEMENT.auto,
        cols,
        size = BLOCK_SIZE.md,
        gap = 0,
        direction: viewDirection = DIRECTION.vertical
    } = viewLayout || {}
    const autoCols = useMemo(() => {
        const xLargeSize = new Map([
            [BreakPointSize.lg, 2],
            [BreakPointSize.md, 1],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const largeSize = new Map([
            [BreakPointSize.lg, 3],
            [BreakPointSize.md, 2],
            [BreakPointSize.sm, 1],
            [BreakPointSize.xs, 1]
        ])
        const middleSize = new Map([
            [BreakPointSize.lg, 4],
            [BreakPointSize.md, 3],
            [BreakPointSize.sm, 2],
            [BreakPointSize.xs, 1]
        ])
        const smallSize = new Map([
            [BreakPointSize.lg, 5],
            [BreakPointSize.md, 4],
            [BreakPointSize.sm, 3],
            [BreakPointSize.xs, 2]
        ])

        const sizeMap = new Map([
            [BLOCK_SIZE.xl, xLargeSize],
            [BLOCK_SIZE.lg, largeSize],
            [BLOCK_SIZE.md, middleSize],
            [BLOCK_SIZE.sm, smallSize]
        ])

        return (breakPoint && sizeMap.get(size)?.get(breakPoint)) ?? 3
    }, [breakPoint, size])

    const isHorizontalGrid = layout === CUSTOM_VIEW_LAYOUT.grid && viewDirection === DIRECTION.horizontal
    const finalCols = arrangement === ARRANGEMENT.fixed && cols ? cols : isHorizontalGrid ? autoCols + 0.3 : autoCols

    const itemWidth = useMemo(() => (blockWidth - (finalCols - 1) * gap) / finalCols, [blockWidth, finalCols, gap])

    const { level } = useContainerLayoutContext()

    const contextValue: ContainerLayoutContextValue = useMemo(() => {
        return {
            parentId: id,
            level: level + 1,
            size: {
                width: {
                    size: blockWidth,
                    unit: 'px'
                },
                height: {
                    size: 'auto'
                }
            },
            layout: {
                ...baseBreakPointConfig.layout
            }
        }
    }, [id, level, blockWidth, baseBreakPointConfig.layout])

    if (showEmpty) {
        return (
            <Flex alignItems="center" justifyContent="center">
                <Empty icon={blockWidth ? <EmptyNoRecordSvg color="var(--color-app-main)" /> : undefined} description="未找到数据" />
            </Flex>
        )
    }

    return (
        <ContainerLayoutProvider value={contextValue}>
            {isHorizontalGrid ? (
                <HorizontalScrollView
                    blockData={blockData}
                    node={node}
                    itemWidth={itemWidth}
                    title={title}
                    viewId={id}
                    pointer={pointer}
                    dataSource={dataSource}
                    records={records}
                    cols={finalCols}
                    gap={gap}
                    layout={layout}
                    readonly={readonly}
                    pagination={pagination}
                    scale={scale}
                    actions={actions}
                    visibleParams={visibleParams}
                    userId={userId}
                    onLoadMoreData={onLoadMoreData}
                    onFilterNode={onFilterNode}
                    onRecordClick={onRecordClick}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                />
            ) : (
                <VerticalView
                    blockData={blockData}
                    node={node}
                    itemWidth={itemWidth}
                    title={title}
                    viewId={id}
                    pointer={pointer}
                    dataSource={dataSource}
                    records={records}
                    cols={finalCols}
                    gap={gap}
                    layout={layout}
                    readonly={readonly}
                    scale={scale}
                    actions={actions}
                    visibleParams={visibleParams}
                    userId={userId}
                    onFilterNode={onFilterNode}
                    onRecordClick={onRecordClick}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                />
            )}
        </ContainerLayoutProvider>
    )
})
