import { Empty } from '@byecode/ui'
import type {
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumns,
    TableColumnWidth,
    ViewBlockAbstract,
    ViewField
} from '@lighthouse/core'
import type { ApplicationPreviewEnum, UseUploadFileSParameter } from '@lighthouse/shared'
import { EmptyNoRecordSvg, getViewColumns } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import type { UploadyProps } from '@rpldy/uploady'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'

import { Table } from './AdvancedTable'
import * as SC from './styles'

interface AdvancedTableBlockProps {
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    blockData: ViewBlockAbstract
    tablePropsCache?: TableColumns
    records?: RecordLikeProtocol[]
    previewType: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords: string[]
    blockWidth?: number
    tableColumnCache?: TableColumnWidth
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onRecordClick?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[], selectedMode?: SelectedMode) => Promise<boolean>
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onRecordEdit: (recordId: string) => void
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onSelectedRecords: (recordIds: string[]) => void
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onSelectModeChange?: (mode?: SelectedMode) => void
    onTableColumnWidthChange: (val: TableColumnWidth) => void
}

/**
 *
 * @param param
 * @returns
 * blockData: {
 *   fields: { id: string; type: string; name: string }[]
 *   records: { id: string; content: Record<number, string> }[]
 * }
 */

const AdvancedTableBlock: React.FC<AdvancedTableBlockProps> = ({
    dataSource,
    dataSourceList,
    blockData,
    tablePropsCache,
    records,
    previewType,
    aiFieldStatusListAtom,
    selectedRecords,
    blockWidth,
    tableColumnCache,
    uploadOptions,
    richTextUploadOptions,
    videoUploadOptions,
    onRecordClick,
    onRecordDelete,
    onCellChange,
    onCellUpdate,
    onRecordEdit,
    onAiGeneration,
    onSelectedRecords,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
    onSelectModeChange,
    onTableColumnWidthChange
}) => {
    const tableBlockRef = useRef<HTMLDivElement>(null)
    const { schema, appId: dsAppId, viewOptions } = dataSource
    const { tableProps } = viewOptions
    const { id: blockId, title = '无标题', config } = blockData
    const isEmptyImg = blockWidth && blockWidth >= 200
    const {
        canDeleteRecord = false,
        canExport,
        canPrint,
        canViewEdit = false,
        canDisplay,
        canViewRecord,
        pointer,
        viewFieldSettings,
        highLightRules,
        actions
    } = config

    const tableRecordCheckable = !!canDeleteRecord || !!canExport || !!canPrint
    const noData = !pointer
    const noRecords = records && records.length === 0
    // const tableColumn = ViewSettingCache.getTableColumnWidthCache(blockId)

    // block 设置后的tableProps
    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId,
                tableProps,
                value: viewFieldSettings,
                schema,
                tableColumn: tableColumnCache
            }),
        [blockId, schema, tableColumnCache, tableProps, viewFieldSettings]
    )

    // 用户字段设置后的tableProps
    const [columns, userColumns] = useMemo(() => {
        if (!tablePropsCache || tablePropsCache.length === 0 || !canDisplay) {
            const list = settingColumns.filter(item => {
                return item.visible
            })
            return [list, list]
        }
        // const filterColumns = settingColumns.filter(item => item.visible)
        const columnsCache = tablePropsCache.reduce<ViewField[]>((prev, cur) => {
            const field = find(({ fieldId }) => fieldId === cur.id, settingColumns)
            if (field) {
                prev.push({
                    ...field,
                    visible: cur.visible
                })
            }
            return prev
        }, [])

        const columns = settingColumns.reduce<ViewField[]>((prev, cur) => {
            if (!cur.visible) {
                return prev
            }
            const field = find(({ fieldId }) => fieldId === cur.fieldId, prev)
            if (!field) {
                prev.push({
                    ...cur,
                    visible: false
                })
            }
            return prev
        }, columnsCache)

        const userColumns = columns.filter(item => item.visible)
        return [columns, userColumns]
    }, [tablePropsCache, canDisplay, settingColumns])

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            onSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, onSelectedRecords, selectedRecords]
    )

    const handleSelect = useCallback(
        (recordIds: string[]) => {
            onSelectedRecords(recordIds)
        },
        [onSelectedRecords]
    )

    return useMemo(() => {
        if (noData || !dataSource) {
            return (
                <SC.TableNoDataContent>
                    <Empty icon={<EmptyNoRecordSvg color="var(--color-app-main)" />} description="未找到数据" />
                </SC.TableNoDataContent>
            )
        }
        return (
            <SC.TableScrollerContent
                offsetScrollbars
                scrollbarSize={6}
                id={`content-${blockId}`}
                styles={{
                    root: {
                        overflowY: 'visible',
                        overflowX: 'hidden',
                    }
                }}
                data-content-type="Table"
                viewportRef={tableBlockRef}
            >
                <Table
                    id={blockId}
                    actions={actions}
                    checkable={tableRecordCheckable}
                    recordOpenable={canViewRecord}
                    recordDeleteAble={canDeleteRecord}
                    recordEditOpenable={canViewEdit}
                    dataSourceInfo={dataSource}
                    dataSourceList={dataSourceList}
                    highLightRules={highLightRules}
                    scrollRef={tableBlockRef}
                    viewFields={userColumns}
                    records={records || []}
                    blockWidth={blockWidth}
                    selectedRecords={selectedRecords}
                    previewType={previewType}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    uploadOptions={uploadOptions}
                    richTextUploadOptions={richTextUploadOptions}
                    videoUploadOptions={videoUploadOptions}
                    onRecordSelect={handleSelect}
                    onRecordClick={onRecordClick}
                    onCellChange={onCellChange}
                    onCellUpdate={onCellUpdate}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={handleRecordOperatorDelete}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                    onSelectModeChange={onSelectModeChange}
                    onTableColumnWidthChange={onTableColumnWidthChange}
                />
                {noRecords && (
                    <SC.TableNoDataContent width={blockWidth}>
                        <Empty
                            icon={isEmptyImg ? <EmptyNoRecordSvg color="var(--color-app-main)" /> : undefined}
                            description="未找到数据"
                        />
                    </SC.TableNoDataContent>
                )}
            </SC.TableScrollerContent>
        )
    }, [
        noData,
        dataSource,
        blockId,
        actions,
        tableRecordCheckable,
        canViewRecord,
        canDeleteRecord,
        canViewEdit,
        dataSourceList,
        highLightRules,
        userColumns,
        records,
        blockWidth,
        selectedRecords,
        previewType,
        aiFieldStatusListAtom,
        uploadOptions,
        richTextUploadOptions,
        videoUploadOptions,
        handleSelect,
        onRecordClick,
        onCellChange,
        onCellUpdate,
        onRecordEdit,
        handleRecordOperatorDelete,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        onRenderButtonTitle,
        onSelectModeChange,
        onTableColumnWidthChange,
        noRecords,
        isEmptyImg
    ])
}

export default AdvancedTableBlock
