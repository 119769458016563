import type {
    ActionsProtocol,
    ButtonAction,
    CustomViewLayout,
    DataSourceAbstract,
    RecordLikeProtocol,
    SizeConfigure,
    ViewBlockAbstract
} from '@lighthouse/core'
import { CUSTOM_VIEW_LAYOUT } from '@lighthouse/core'
import type { FlowLayoutNode, VisibleDomProps } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import styled, { css } from 'styled-components'

import { Item } from './Item'
import { setDeadCopyNode } from './utils'

const SHOULD_NOT_FORWARD_PROPS = new Set(['layout', 'cols', 'gap', 'records'])
type StyleProps = {
    layout: CustomViewLayout
    cols: number
    gap: number
}
const Root = styled.div.withConfig<StyleProps>({ shouldForwardProp: p => !SHOULD_NOT_FORWARD_PROPS.has(p) })`
    ${({ layout, cols, gap }) => {
        const isWaterfall = layout === CUSTOM_VIEW_LAYOUT.waterfall
        const isGrid = layout === CUSTOM_VIEW_LAYOUT.grid

        if (isWaterfall) {
            return css`
                display: flex;
                flex-flow: column wrap;
                align-content: flex-start;
                column-gap: ${gap / 2}px;
                row-gap: ${gap}px;
            `
        }

        if (isGrid) {
            return css`
                display: grid;
                grid-template-columns: repeat(${cols}, 1fr);
                gap: ${gap}px;
            `
        }
    }}
`

interface Props extends ActionsProtocol {
    blockData: ViewBlockAbstract
    node: FlowLayoutNode
    itemWidth: number
    title: string
    viewId: string
    pointer: string
    records: RecordLikeProtocol[]
    cols: number
    gap: number
    layout: CustomViewLayout
    readonly?: boolean
    dataSource: DataSourceAbstract
    visibleParams?: VisibleDomProps
    userId?: string
    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
    scale?: number
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
}

export const VerticalView = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        blockData,
        node,
        itemWidth,
        title,
        viewId,
        pointer,
        records,
        cols,
        gap,
        layout,
        readonly,
        scale = 1,
        actions,
        dataSource,
        visibleParams: propsVisibleParams,
        userId,
        onFilterNode,
        onRecordClick,
        onRecordClickedActionTrigger
    } = props

    const isWaterfall = layout === CUSTOM_VIEW_LAYOUT.waterfall

    const innerRef = useRef<HTMLDivElement | null>(null)

    const [maxHeightColumn, setMaxHeightColumn] = useState<number>()
    const handleResize = useCallback(() => {
        const rootEl = innerRef.current
        if (!rootEl || !isWaterfall) {
            return
        }
        const columnHeights = Array.from<number>({ length: cols }).fill(0)
        let broken = false
        const children = rootEl.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            if (broken || childNode.dataset.class === 'masonry-line-break') {
                return
            }
            const { height } = childNode.getBoundingClientRect()
            if (height === 0) {
                broken = true
                return
            }

            const minColumnIndex = columnHeights.indexOf(Math.min(...columnHeights))
            columnHeights[minColumnIndex] += height + gap
            childNode.style.order = `${1 + minColumnIndex}`
        })

        if (broken) {
            return
        }
        flushSync(() => {
            setMaxHeightColumn(Math.ceil(Math.max(...columnHeights)))
        })
    }, [cols, gap, isWaterfall])

    const customViewData = useMemo(
        () => ({
            name: blockData.title,
            dataSource
        }),
        [blockData.title, dataSource]
    )

    const visibleParams: VisibleDomProps | undefined = useMemo(
        () =>
            propsVisibleParams
                ? {
                      ...propsVisibleParams,
                      customViewData
                  }
                : undefined,
        [customViewData, propsVisibleParams]
    )
    // TODO:zjb ResizeObserver loop completed with undelivered notifications.
    useLayoutEffect(() => {
        const el = innerRef.current
        if (!el || !isWaterfall) {
            return
        }

        let raf: number
        const observer = new ResizeObserver(() => {
            // raf = requestAnimationFrame(handleResize)
            handleResize()
        })

        const children = el.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            observer.observe(childNode)
        })

        return () => {
            // if (raf) {
            //     cancelAnimationFrame(raf)
            // }
            if (observer) {
                observer.disconnect()
            }
        }
    }, [handleResize, isWaterfall, records])

    return (
        <Root
            ref={mergeRefs([innerRef, ref])}
            layout={layout}
            cols={cols}
            gap={gap}
            style={{ height: isWaterfall ? maxHeightColumn && `${(maxHeightColumn - gap) / scale}px` : undefined }}
        >
            {records.map((record, index) => {
                const minHeightConfigure =
                    node.children && node.children.length > 0
                        ? {}
                        : {
                              minHeight: {
                                  size: 80,
                                  unit: 'px'
                              } as SizeConfigure
                          }
                return (
                    <Item
                        key={record.id}
                        style={{ height: 'fit-content' }}
                        data={{
                            id: record.id,
                            type: 'container',
                            disabled: readonly || index !== 0,
                            static: true,
                            virtual: true,
                            data: node.data && {
                                design: node.data.design,
                                size: {
                                    width: {
                                        size: itemWidth,
                                        unit: 'px'
                                    },
                                    height: {
                                        size: 'auto'
                                    },
                                    overflow: 'hidden',
                                    ...minHeightConfigure
                                },
                                layout: node.data.layout,
                                position: node.data.position
                            },
                            children: readonly ? node.children : setDeadCopyNode(node.children || [], index === 0, viewId)
                        }}
                        title={title}
                        viewId={viewId}
                        pointer={pointer}
                        record={record}
                        records={records}
                        blockData={blockData}
                        userId={userId}
                        visibleParams={index === 0 ? visibleParams : undefined}
                        onRecordClick={onRecordClick}
                        onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                        onFilterNode={onFilterNode}
                    />
                )
            })}
            {isWaterfall &&
                Array.from({ length: cols - 1 }).map((_, index) => (
                    <span key={index} data-class="masonry-line-break" style={{ order: index + 1, flexBasis: '100%', width: 0 }} />
                ))}
        </Root>
    )
})
