import type { FieldValue } from '@lighthouse/core'
import { extraFieldTypes, findBlockById, getCascadeOption } from '@lighthouse/shared'
import { atom } from 'jotai'

import * as srv from '@/services'

import { addDataSourceAtom, addRecordAtom } from '../dataSource/action'
import { blocksAtom } from '../page/state'
import { isViewBlock } from '../utils/block'
import { filterBlockOptionsAtom, viewRecordListAtomFamily, viewRecordPoolAtom } from './state'
import type {
    AddViewRecordAtomPayload,
    FetchChartBlockPayload,
    FetchViewRecordsAtomPayload,
    HandleFetchFilterCascadeOptionsPayload,
    HandleFetchFilterOptionsPayload,
    updateViewSearchAtomPayload
} from './types'

/** 添加视图记录数据 */
export const addViewRecordAtom = atom(null, (_, set, payload: AddViewRecordAtomPayload) => {
    const { viewId, records, viewFieldIds } = payload
    const recordAtom = viewRecordListAtomFamily(viewId)
    const viewRecords = records.map(item => {
        const recordContent = Object.entries(item.content).reduce<[string, FieldValue][]>((prev, cur) => {
            const [key, value] = cur
            if (viewFieldIds.includes(key)) {
                prev.push([key, value])
            }
            return prev
        }, [])
        return {
            ...item,
            content: Object.fromEntries(recordContent)
        }
    })

    set(recordAtom, viewRecords)
})

export const fetchViewCountAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { viewId, search, params, pageId, abort } = payload
    const rowTotal = await srv.getViewCount({ viewId, pageId, search, ...params, abort })
    if (rowTotal === undefined) {
        return
    }
    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(viewId, currentBlocks)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }
        draftBlock.config.pagination.rowTotal = rowTotal
    })
})

/** 获取视图数据 */
export const fetchViewRecordsAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { appId, envId, viewId, pagination, search, params, pageId, disableFetchCount, abort } = payload
    const viewData = await srv.getViewByBlock({
        appId,
        envId,
        viewId,
        pageId,
        pagination,
        params,
        search,
        abort
    })

    if (!viewData) {
        return
    }
    const { datasource, records, config } = viewData
    // const rowTotal = await srv.getViewCount(viewId, quickFilters, search, id)
    if (!disableFetchCount) {
        set(fetchViewCountAtom, payload)
    }

    // const recordIds = records.map(item => item.id)
    // datasource.records = recordIds

    const extraViewFieldIds =
        config.view?.viewFieldSettings?.reduce<string[]>((ids, cur) => {
            if (extraFieldTypes.has(cur.type)) {
                ids.push(cur.fieldId)
            }
            return ids
        }, []) || []

    set(addDataSourceAtom, { dataSource: datasource })
    set(addRecordAtom, { records })

    set(addViewRecordAtom, { viewId, records, viewFieldIds: extraViewFieldIds })
    // set(setBlockRecordIdsAtom, { viewId, recordIds })
    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(viewId, currentBlocks)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }
        draftBlock.config.search = search
        draftBlock.config.pagination.currentPage = pagination.currentPage
    })
})

/** 加载视图记录数据 */
export const loadViewRecordAtom = atom(null, (_, set, payload: AddViewRecordAtomPayload) => {
    const { viewId, records } = payload
    set(viewRecordPoolAtom, draft => {
        records.forEach(record => {
            draft[viewId].push(record)
        })
    })
})

/** 加载视图数据 */
export const loadViewRecordsAtom = atom(null, async (_, set, payload: FetchViewRecordsAtomPayload) => {
    const { appId, envId, viewId, pagination, search, params, pageId, abort } = payload
    const viewData = await srv.getViewByBlock({
        appId,
        envId,
        pageId,
        viewId,
        pagination,
        params,
        search,
        abort
    })
    if (!viewData) {
        return []
    }
    const { records, config } = viewData
    const extraViewFieldIds =
        config.view?.viewFieldSettings?.reduce<string[]>((ids, cur) => {
            if (extraFieldTypes.has(cur.type)) {
                ids.push(cur.fieldId)
            }
            return ids
        }, []) || []
    set(addRecordAtom, { records })
    set(loadViewRecordAtom, { viewId, records, viewFieldIds: extraViewFieldIds })
    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(viewId, currentBlocks)
        if (!draftBlock) {
            return
        }
        if (!isViewBlock(draftBlock)) {
            return
        }

        draftBlock.config.search = search
        draftBlock.config.pagination.currentPage = pagination.currentPage
    })
    return records
})

export const updateViewSearchAtom = atom(null, (_, set, payload: updateViewSearchAtomPayload) => {
    const { pageId, viewId, search } = payload
    set(blocksAtom, draft => {
        const currentBlocks = draft[pageId]
        if (!currentBlocks) {
            return
        }

        const draftBlock = findBlockById(viewId, currentBlocks)
        if (!draftBlock) {
            return
        }

        if (!isViewBlock(draftBlock)) {
            return
        }

        draftBlock.config.search = search
    })
})

/** 获取图表数据 */
export const fetchChartBlockAtom = atom(null, async (_, set, payload: FetchChartBlockPayload) => {
    const data = await srv.getDsGraphView(payload)
    return data.chart
})

// 获取文本 成员筛选器 options
export const handleFetchFilterOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterOptionsPayload) => {
    const data = await srv.getRelativeOptions(payload)
    set(filterBlockOptionsAtom, draft => {
        draft[`${payload.blockId}-${payload.filterItemId}`] = data
    })
    return data
})

// 获取级联筛选器 options
export const handleFetchFilterCascadeOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterCascadeOptionsPayload) => {
    const { appId, envId, dsId, pageId, sortFieldPointer = '', fieldPointer, parentFieldPointer, blockId, filterItemId, pageSize, showFieldPointer } = payload
    const res = await srv.getDs({
        appId,
        envId,
        dsId,
        pageId,
        pagination: { currentPage: 1, pageSize },
        sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
    })
    const usedRecords = res?.records?.filter(record => record?.content?.[fieldPointer]?.value)
    const options =
        usedRecords
            ?.filter(record => !record?.content?.[parentFieldPointer]?.value)
            .map(record =>
                getCascadeOption({ parentFieldPointer, fieldPointer, showFieldPointer, record, records: usedRecords })
            )
            .filter(item => item.value) ?? []
    set(filterBlockOptionsAtom, draft => {
        draft[`${blockId}-${filterItemId}`] = options
    })
    return options
})
