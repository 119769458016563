import { IconFont, Text } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

const StyledRoot = styled.div`
    min-height: 80px;
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    gap: 6px;
    border-radius: inherit;
    background-color: rgba(85, 81, 255, 0.2);
`

export const EmptyContainer = (props: React.ComponentPropsWithoutRef<'div'>) => {
    return (
        <StyledRoot {...props}>
            <IconFont type="Add" color="var(--color-main)" />
            <Text size={14} lineHeight="20px" color="var(--color-main)">
                将组件拖到这里
            </Text>
        </StyledRoot>
    )
}
