import type { DataSourceAbstract, InputValueItem, PageAbstract } from '@lighthouse/core'

import type { FlowNode } from './flow'

// import type { VariableSourceType } from "../components"

export enum VariableSourceType {
    page = 'page',
    module = 'module',
    parentPage = 'parentPage',
    parentNode = 'parentNode',
    parentJoinNode = 'parentJoinNode',
    form = 'form'
}

export type VariableUpstreamSource = Record<'sourceType', VariableSourceType.parentNode> & {
    parentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
}

export type VariableFormUpstreamSource = Record<'sourceType', VariableSourceType.form> & {
    parentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
}

export type VariablePageSource = Record<'sourceType', VariableSourceType.page | VariableSourceType.parentPage> & {
    dataSource?: DataSourceAbstract
    page?: PageAbstract | null
}

export type VariableSource = VariablePageSource | VariableUpstreamSource | VariableFormUpstreamSource

// 当前和上游页面数据
export type PrevPageDatasourceForVariable = {
    page?: PageAbstract
    recordId?: string
    variableSourceType: VariableSourceType.parentPage
    datasource?: DataSourceAbstract
    formState?: Record<string, InputValueItem>
}

export type CurrPageDatasourceForVariable = {
    page?: PageAbstract
    recordId?: string
    variableSourceType: VariableSourceType.page
    datasource?: DataSourceAbstract
    formState?: Record<string, InputValueItem>
}
