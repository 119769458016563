import type { ButtonAction, RecordLikeProtocol, ViewBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode, NodeRenderProps, VisibleDomProps } from '@lighthouse/shared'
import { checkActionEvent, NodeRender, useActionRunningLoadings } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import { ContainerLayout } from '../../components/ContainerLayout'
import type { CustomViewBlockContextValue } from './Context'
import { CustomViewBlockProvider, useCustomViewBlockContext } from './Context'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode

    blockData: ViewBlockAbstract

    title: string
    viewId: string
    pointer: string
    record: RecordLikeProtocol
    records: RecordLikeProtocol[]
    visibleParams?: VisibleDomProps
    userId?: string
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
    (
        { blockData, data, onRecordClick, onRecordClickedActionTrigger, title, viewId, pointer, record, records, onFilterNode, ...rest },
        ref
    ) => {
        const innerRef = useRef<HTMLDivElement>(null)

        const node = useMemo(() => {
            if (!onFilterNode) {
                return data
            }
            return onFilterNode(data, record)
        }, [data, onFilterNode, record])

        const {
            config: {
                actions: {
                    recordClicked: { customized, action }
                },
                canViewRecord
            }
        } = blockData

        const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

        const handleRecordClick = useCallback(
            (e: React.MouseEvent) => {
                if (!checkActionEvent(e)) {
                    return
                }

                if (customized && action) {
                    if (loadings[record.id]) {
                        return
                    }
                    handleActionTriggerWithLoading({
                        type: 'click',
                        id: record.id,
                        action,
                        record,
                        trigger: onRecordClickedActionTrigger
                    })
                    return
                }

                canViewRecord && onRecordClick?.(record.id)
            },
            [
                action,
                canViewRecord,
                customized,
                handleActionTriggerWithLoading,
                loadings,
                onRecordClick,
                onRecordClickedActionTrigger,
                record
            ]
        )

        const customViewContextValue: CustomViewBlockContextValue = useMemo(
            () => ({ name: title, viewId, pointer, record, records, handleRecordClick }),
            [handleRecordClick, pointer, record, records, title, viewId]
        )

        return (
            <CustomViewBlockProvider value={customViewContextValue}>
                <NodeRender
                    style={{ cursor: 'pointer' }}
                    ref={mergeRefs([ref, innerRef])}
                    data={node}
                    nodeRender={VirtualContainer}
                    {...rest}
                />
            </CustomViewBlockProvider>
        )
    }
)

const VirtualContainer = (props: NodeRenderProps) => {
    const { handleRecordClick } = useCustomViewBlockContext()
    return <ContainerLayout {...props} style={{ position: 'relative', zIndex: 1 }} onClick={handleRecordClick} />
}
