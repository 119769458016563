// import { CardBlock } from '@lighthouse/block'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { CardBlockAbstract } from '@lighthouse/core'
import { type RichTextEditorProps, useApplicationContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { Suspense, useMemo } from 'react'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

const CardBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.CardBlock })))

interface CardBlockControllerProps {
    blockData: CardBlockAbstract
}

const CardBlockController: FC<CardBlockControllerProps> = ({ blockData }) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const {
        prev: { recordId: parentRecordId },
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()

    const { renderLabel } = useVariableValueRender(pageId, parentRecordId, recordId)

    const { record } = useCustomViewBlockContext()

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            variable: {
                renderLabel: v => renderLabel(v.attrs.value, { personOptions, roleOptions, departmentOptions, viewRecord: record })
            }
        }),
        [departmentOptions, personOptions, record, renderLabel, roleOptions]
    )

    const { handleActionTrigger } = useActionTrigger()

    return (
        <Suspense fallback={<div />}>
            <CardBlock blockData={blockData} config={config} onTriggerAction={handleActionTrigger} />
        </Suspense>
    )
}

export default CardBlockController
