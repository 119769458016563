import { backgroundTransitionOnClick, Divider, Flex, MobileModal, pointer } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { UserAvatar } from '../../../components/UserAvatar'
import { useApplicationContext } from '../../../contexts'
import { getColorById, getImageFullUrlInApplication } from '../../../utils'
import type { LoginAuthType } from '../../AccountPage'
import { TagIcon } from '../../TagIcon'
import { MobileBindUserInfo } from './BindUserInfo'

interface UserAvatarProps {
    userData: AppUser
    isMobile?: boolean
    style?: React.CSSProperties
    onLogout?: () => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const SCxUserMenuContainer = styled.div`
    width: 100%;
    background-color: var(--color-white);
`

const SCxTitleName = styled.span`
    font-weight: 500;
    font-size: 20px;
    color: var(--color-black);
    line-height: 28px;
    margin-top: 12px;
`

const SCxUserInfo = styled(Flex)`
    padding: 24px 0;
    flex-direction: column;
`

const SCxTitlePhone = styled.span`
    color: var(--color-gray-400);
    line-height: 22px;
    margin-top: 4px;
`

const SCxSettingItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    ${backgroundTransitionOnClick}
    ${pointer}
`

const SCxSettingName = styled.span`
    font-size: var(--font-size-base);
    color: var(--color-black);
`

export const ModalUserCenter: React.FunctionComponent<UserAvatarProps> = ({ userData, style, onLogout, onUpdateBindAccount }) => {
    const [userOpen, setUserOpen] = useState(false)
    const [userSettingOpen, setUserSettingOpen] = useState(false)
    const { pageTarget, appId } = useApplicationContext()
    const { t } = useTranslation()

    return (
        <>
            <SCxContainer style={style}>
                <UserAvatar
                    displayHover
                    id={userData.userId}
                    color="var(--color-white)"
                    avatar={getImageFullUrlInApplication(appId, userData.avatar)}
                    borderColor={userData.avatar ? 'var(--color-gray-200)' : getColorById(userData.uniqueUserId)}
                    background={getColorById(userData.uniqueUserId)}
                    name={userData.username}
                    size={36}
                    onClick={() => setUserOpen(true)}
                />
            </SCxContainer>
            <MobileModal
                target={pageTarget}
                styles={{
                    header: {
                        display: 'none'
                    },
                    modal: {
                        height: 'auto!important',
                        backgroundColor: 'var(--color-gray-50)'
                    },
                    body: {
                        padding: 0
                    }
                }}
                open={userOpen}
                onClose={() => setUserOpen(false)}
            >
                <SCxUserMenuContainer>
                    <SCxUserInfo justifyContent="center" alignItems="center">
                        <UserAvatar
                            id={userData.userId}
                            color="var(--color-white)"
                            avatar={getImageFullUrlInApplication(appId, userData.avatar)}
                            borderColor={userData.avatar ? 'var(--color-gray-200)' : getColorById(userData.uniqueUserId)}
                            background={getColorById(userData.uniqueUserId)}
                            name={userData.username}
                            size={86}
                            fontSize={36}
                        />
                        <SCxTitleName>{userData.username ?? '未命名'}</SCxTitleName>
                        <SCxTitlePhone>{userData.mobile}</SCxTitlePhone>
                    </SCxUserInfo>
                    <Divider />
                    <SCxSettingItem
                        onClick={() => {
                            setUserSettingOpen(true)
                            setUserOpen(false)
                        }}
                    >
                        <SCxSettingName>{t('accountSetting')}</SCxSettingName>
                    </SCxSettingItem>
                    <Divider />
                    <SCxSettingItem
                        onClick={() => {
                            onLogout?.()
                            setUserOpen(false)
                        }}
                    >
                        <SCxSettingName>{t('logout')}</SCxSettingName>
                    </SCxSettingItem>
                    <Divider color="var(--color-gray-50)" style={{ borderWidth: 8 }} />
                    <SCxSettingItem
                        onClick={() => {
                            setUserOpen(false)
                        }}
                    >
                        <SCxSettingName>{t('cancel')}</SCxSettingName>
                    </SCxSettingItem>
                </SCxUserMenuContainer>
            </MobileModal>
            <MobileModal
                target={pageTarget}
                styles={{
                    modal: {
                        height: '80%!important',
                        backgroundColor: 'var(--color-white)'
                    },
                    body: {
                        // padding: 0
                    },
                    actions: {
                        marginLeft: 'unset!important'
                    }
                }}
                open={userSettingOpen}
                leftSlot={
                    <TagIcon
                        icon="ArrowLeftSmall"
                        iconColor="var(--color-gray-400)"
                        size={28}
                        background="var(--color-gray-100)"
                        radius={100}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setUserSettingOpen(false)
                            setUserOpen(true)
                        }}
                    />
                }
                onClose={() => setUserSettingOpen(false)}
                title={t('accountSetting')}
            >
                <MobileBindUserInfo userData={userData} onUpdateBindAccount={onUpdateBindAccount} />
            </MobileModal>
        </>
    )
}
