import type { Option } from '@byecode/ui'
import { Button, IconFont, Menu, Text } from '@byecode/ui'
import type { ApplicationSettingNavbar, ApplicationSettingNavbarButton, ApplicationSettingTheme } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'
import React, { useMemo, useState } from 'react'

import { ApplicationLinkItem } from '../ApplicationLinkItem'

interface LanguageBoxProps {
    options: Option[]
    option?: Option
    value: string
    showLabel?: boolean
    color: string
    style?: React.CSSProperties
    onChange: (v: string) => void
}

export const LanguageBox: React.FunctionComponent<LanguageBoxProps> = ({ option, options, color, showLabel, style, onChange }) => {
    const [opened, setOpened] = useState(false)

    const btnConfig: ApplicationSettingNavbarButton = useMemo(
        () => ({
            id: nanoid(),
            name: typeof option?.label === 'string' ? option?.label : '',
            showType: showLabel ? 'icon-name' : 'icon',
            events: {
                handleEvent: 'openPage',
                triggerEvent: 'click',
                params: ['']
            },
            action: {
                type: 'none',
                params: {},
                trigger: 'click',
                data: {
                    none: {}
                }
            },
            child: [],
            icon: 'Earth'
        }),
        [option?.label, showLabel]
    )
    return (
        <Menu opened={opened} withinPortal closeOnItemClick width={200} onChange={setOpened}>
            <Menu.Target>
                <div style={{ width: 'auto' }}>
                    <ApplicationLinkItem
                        value={btnConfig}
                        showRightSection
                        opened={opened}
                        isAction={opened}
                        style={style}
                        onCommonCallBack={e => {
                            setOpened(true)
                        }}
                    />
                </div>
            </Menu.Target>
            <Menu.Dropdown>
                {options.map(item => {
                    return (
                        <Menu.Item
                            key={item.value}
                            rightSection={item.extra}
                            style={{ lineHeight: '32px', padding: '0px 16px', height: 32 }}
                            onClick={() => onChange(item.value)}
                        >
                            {item.label}
                        </Menu.Item>
                    )
                })}
            </Menu.Dropdown>
        </Menu>
    )
}
