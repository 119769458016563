// import { ImageBlock } from '@lighthouse/block'
import { useCustomViewBlockContext } from '@lighthouse/block'
import type { FileValue, ImageBlockAbstract } from '@lighthouse/core'
import {
    getFileNameByUrl,
    getFileSizeByUrl,
    getFileTypeByUrl,
    getVariableToFieldValue,
    urlRegex
} from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import { find } from 'rambda'
import React, { Suspense, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppId, useCurrentEnvId, useDefaultPageList, usePreview } from '@/hooks/useApplication'
import { useDataSource, useRecord } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'

const ImageBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.ImageBlock })))

interface ImageBlockControllerProps {
    blockData: ImageBlockAbstract
    onBlockChange?: (values: AnyObject, origin: ImageBlockAbstract) => Promise<void> | void
}

const ImageBlockController: React.FC<ImageBlockControllerProps> = ({ blockData }) => {
    const {
        config: { sources, imageSource, sourceType },
        id: blockId
    } = blockData
    const navigate = useNavigate()
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const previewType = usePreview()
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')
    const { record, pointer } = useCustomViewBlockContext()

    const viewDataSource = useDataSource(appId, envId, pointer)
    const pageList = useDefaultPageList()
    const userRecord = useUserRecord()

    const { handleActionTrigger } = useActionTrigger()

    const fileList = useMemo(() => {
        if (sourceType === 'field') {
            if (!imageSource) {
                return []
            }
            const cellValue = getVariableToFieldValue({
                idVariable: imageSource,
                currentRecord: {
                    datasource: curr.datasource,
                    record: currentRecord
                },
                prevRecord: {
                    datasource: prev.datasource,
                    record: prevRecord
                },
                viewRecord: {
                    record,
                    datasource: viewDataSource
                },
                userRecord
            })

            const fileValue: FileValue = cellValue && cellValue.type === 'file' ? cellValue.value : []
            return (
                fileValue?.map(item => {
                    const name = getFileNameByUrl(item) || ''
                    const type = getFileTypeByUrl(item)
                    const size = getFileSizeByUrl(item)
                    return { name, size, url: item, type, title: '', link: '' }
                }) ?? []
            )
        }
        return sources || []
    }, [curr.datasource, currentRecord, imageSource, prev.datasource, prevRecord, record, sourceType, sources, userRecord, viewDataSource])

    return (
        <Suspense fallback={<div />}>
            <ImageBlock
                blockData={blockData}
                disabled
                value={fileList}
                previewType={previewType}
                onActionTrigger={handleActionTrigger}
                onClick={link => {
                    if (!link) {
                        return
                    }
                    const isExitPage = find(item => item.id === link, pageList)
                    if (isExitPage) {
                        navigate({ pathname: isExitPage.route ? `/${isExitPage.route}` : `/P/${link}` })
                        return
                    }
                    const isUrl = urlRegex.test(link)
                    if (isUrl) {
                        const skipUrl = link.startsWith('http') ? link : `http://${link}`
                        window.open(skipUrl, '_blank')
                    }
                }}
            />
        </Suspense>
    )
}

export default ImageBlockController
