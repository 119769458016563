import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { getSizeStyle } from '../../../utils'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode } from '../types'

type StyledProps = {
    disabled?: boolean
}

const StyledNode = styled.div<StyledProps>`
    flex-shrink: 0;
    position: relative;

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutCustomNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    onDataDrawerVisible?: (val: boolean) => void
}

export const CustomNode = forwardRef<HTMLDivElement, FlowLayoutCustomNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, onDataDrawerVisible, children, ...rest } = props
    const { size } = data.data ?? {}

    const parentLayoutCtx = useContainerLayoutContext()

    const { selectedIds, highlight, disabled: globalDisabled, nodeRender: NodeRender = React.Fragment } = useFlowLayoutContext()
    const disabled = propDisabled ?? globalDisabled

    const isSelected = selectedIds?.includes(data.id)

    const mergeStyle: React.CSSProperties = {
        ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction),
        ...style
    }

    return (
        <StyledNode
            ref={ref}
            style={mergeStyle}
            data-type="custom"
            data-selected={!disabled && isSelected}
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            {...rest}
        >
            <NodeRender node={data}>{children}</NodeRender>
        </StyledNode>
    )
})
