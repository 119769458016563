import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumnWidth,
    ViewField
} from '@lighthouse/core'
import { type UseUploadFileSParameter, ApplicationPreviewEnum, getPrimaryDataSourceEnableFieldIds } from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import { useUpdateEffect } from '@react-hookz/web'
import type { UploadyProps } from '@rpldy/uploady'
import type { atomWithImmer } from 'jotai-immer'
import { equals, sortBy } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { AdvancedTableContent } from './AdvancedTableContent'
import AdvancedTableHeader from './AdvancedTableHeader'

interface TableProps extends ActionsProtocol {
    id: string
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    dataSourceInfo: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    viewFields: ViewField[]
    records: RecordLikeProtocol[]
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    scrollRef: React.RefObject<HTMLDivElement>
    blockWidth?: number
    previewType: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onRecordEdit?: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onSelectModeChange?: (mode?: SelectedMode) => void
    onTableColumnWidthChange: (val: TableColumnWidth) => void
}

const SCxTableContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: visible;
    background-color: #ffffff;
`

export const Table: React.FC<TableProps> = ({
    id: blockId,
    actions,
    checkable = true,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    dataSourceInfo,
    dataSourceList,
    viewFields,
    records,
    selectedRecords,
    highLightRules,
    scrollRef,
    blockWidth,
    previewType,
    aiFieldStatusListAtom,
    uploadOptions,
    richTextUploadOptions,
    videoUploadOptions,
    onRecordSelect,
    onRecordClick,
    onCellChange,
    onCellUpdate,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
    onSelectModeChange,
    onTableColumnWidthChange
}) => {
    const isMobile = useMemo(() => previewType === ApplicationPreviewEnum.mobile, [previewType])
    const noRecords = records.length === 0
    const recordIds = useMemo(() => records.map(record => record.id), [records])

    const primaryDataSourceFieldIds = useMemo(
        () => getPrimaryDataSourceEnableFieldIds(dataSourceInfo, dataSourceList),
        [dataSourceInfo, dataSourceList]
    )
    const allRecordSelected = useMemo(() => {
        return (
            selectedRecords &&
            !noRecords &&
            equals(
                sortBy(a => a, selectedRecords),
                sortBy(a => a, recordIds)
            )
        )
    }, [noRecords, recordIds, selectedRecords])

    const handleAllRecordSelect = useCallback(
        (selected: boolean) => {
            if (selected) {
                onRecordSelect?.(recordIds)
            } else {
                onRecordSelect?.([])
            }
        },
        [onRecordSelect, recordIds]
    )

    // const handleChangeTableProps = useCallback((columns: ViewField[]) => {
    //     setColumns(columns)
    // }, [])

    return (
        <SCxTableContainer className="tableContainer">
            <AdvancedTableHeader
                id={blockId}
                checkable={checkable}
                recordOpenable={recordOpenable}
                viewFields={viewFields}
                allRecordSelected={allRecordSelected}
                isMobile={isMobile}
                onAllRecordSelect={handleAllRecordSelect}
                onSelectModeChange={onSelectModeChange}
                onTableColumnWidthChange={onTableColumnWidthChange}
            />
            {!!blockWidth && (
                <AdvancedTableContent
                    actions={actions}
                    blockId={blockId}
                    checkable={checkable}
                    recordOpenable={recordOpenable}
                    recordDeleteAble={recordDeleteAble}
                    recordEditOpenable={recordEditOpenable}
                    dataSourceInfo={dataSourceInfo}
                    viewFields={viewFields}
                    records={records}
                    blockWidth={blockWidth}
                    scrollRef={scrollRef}
                    selectedRecords={selectedRecords}
                    highLightRules={highLightRules}
                    previewType={previewType}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                    uploadOptions={uploadOptions}
                    richTextUploadOptions={richTextUploadOptions}
                    videoUploadOptions={videoUploadOptions}
                    onRecordSelect={onRecordSelect}
                    onRecordClick={onRecordClick}
                    onCellChange={onCellChange}
                    onCellUpdate={onCellUpdate}
                    onRecordEdit={onRecordEdit}
                    onRecordDelete={onRecordDelete}
                    onAiGeneration={onAiGeneration}
                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                />
            )}
        </SCxTableContainer>
    )
}
