import { getBrowser } from '@lighthouse/tools'
import { useWindowSize } from 'react-use'

import { ApplicationPreviewEnum } from '../types'

export const getDeviceType = () => {
    const browser = getBrowser()

    const result = browser.parseUserAgent()

    return result.isMobile ? ApplicationPreviewEnum.mobile : ApplicationPreviewEnum.desktop
}


export const useApplicationPreviewType = () => {
    const { width } = useWindowSize()

    if (width <= 478) {
        return ApplicationPreviewEnum.mobile
    }
    return ApplicationPreviewEnum.desktop
}
