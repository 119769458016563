import { Tooltip } from '@byecode/ui'
import React from 'react'

import * as SC from './styles'

interface TooltipIconProps {
    label: string
    icon: string
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
}

export const TooltipIcon: React.FunctionComponent<TooltipIconProps> = ({ label, icon, disabled, onClick }) => {
    return (
        <Tooltip title={label} disabled={disabled}>
            <SC.MoreActionIcon type={icon} hoverColor="var(--color-black)" onClick={onClick} />
        </Tooltip>
    )
}
