import { isEmpty } from 'rambda'
import React from 'react'
import styled from 'styled-components'

import { useFlowLayoutContext } from './Context'
import { NodeGapIndicator } from './Node/NodeGapIndicator'
import { NodePaddingIndicator } from './Node/NodePaddingIndicator'

const Root = styled.div`
    position: absolute;
    z-index: 1;
    inset: 0;
    pointer-events: none;
`

export const HighlightOverlay = () => {
    const { highlight } = useFlowLayoutContext()

    if (!highlight || isEmpty(highlight)) {
        return null
    }

    return (
        <Root>
            {Object.entries(highlight).map(([id, highlight]) => {
                if (highlight?.gap) {
                    return <NodeGapIndicator key={id} id={id} />
                }

                if (highlight?.paddingLeft || highlight?.paddingTop || highlight?.paddingRight || highlight?.paddingBottom) {
                    return (
                        <NodePaddingIndicator
                            key={id}
                            id={id}
                            paddingLeft={highlight.paddingLeft}
                            paddingTop={highlight.paddingTop}
                            paddingRight={highlight.paddingRight}
                            paddingBottom={highlight.paddingBottom}
                        />
                    )
                }

                return null
            })}
        </Root>
    )
}
