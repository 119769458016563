import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

interface IndicatorStylesParams {
    color?: string
    size?: number
    radius?: number
    withBorder?: boolean
    offset?: number | string
}
const useStyles = createStyles((params: IndicatorStylesParams) => {
    return {
        root: css({
            position: 'relative'
        }),
        dot: css({
            background: params.color || '$colorPrimary',
            color: '$colorWhite',
            position: 'absolute',
            top: params.offset || 0,
            right: params.offset || 0,
            borderRadius: params.radius ? `${params.radius}px` : '50%',
            overflow: 'hidden',
            width: 'fit-content',
            padding: '3px',
            minWidth: params.size || 16,
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            transform: 'translate(50%, -50%)',
            border: params.withBorder ? '1px solid $colorWhite' : 'none'
        })
    }
})

export interface IndicatorProps
    extends IndicatorStylesParams,
        StyleComponentProps<Selectors<typeof useStyles>>,
        React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    label?: React.ReactNode
}

export const Indicator = forwardRef<HTMLDivElement, IndicatorProps>(
    (
        {
            children,
            disabled,
            label,
            color,
            radius,
            size,
            withBorder,
            offset,

            styles,
            className,
            unstyled,
            ...rest
        },
        ref
    ) => {
        const { classes } = useStyles({ color, size, radius, withBorder, offset }, { name: 'Indicator', styles, unstyled })

        return (
            <Box ref={ref} className={clsx(classes.root, className)} {...rest}>
                {children}
                {!disabled && <Box className={classes.dot}>{label}</Box>}
            </Box>
        )
    }
)
