import { styled } from '@byecode/ui/theme/stitches.config'
import React, { useMemo, useState } from 'react'
import type { Root } from 'react-dom/client'
import { createRoot } from 'react-dom/client'

import type { ButtonProps } from '../Button'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import type { BaseModalProps } from './base'
import { DesktopModal } from './desktop'

const ROOT = '__BUI__REACT__ROOT__'
type Container = Element & { [ROOT]?: Root }

const Title = styled('div', {
    marginBottom: 12,
    color: '$colorBlack',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600
})

const Footer = styled('div', {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px'
})

const FooterLeft = styled('div', {
    flex: 1
})

const FooterRight = styled('div', {
    display: 'flex',
    gap: '8px'
})

const ContentContainer = styled('div', {
    lineHeight: '22px',
    color: '$colorGray600'
})

export interface ConfirmProps extends Omit<BaseModalProps, 'children' | 'title' | 'open' | 'withCloseIcon' | 'footer'> {
    title: string
    content?: React.ReactNode
    okText?: React.ReactNode
    okStatus?: 'error' | 'primary'
    okProps?: ButtonProps
    cancelText?: React.ReactNode
    okDisable?: boolean
    checkBoxLabel?: React.ReactNode
    isCheckBoxConfirm?: boolean
    extra?: React.ReactNode
    footerExtra?: React.ReactNode | ((params: { onReject: () => void; onResolve: () => void; onUnmount?: () => void }) => React.ReactNode)
}

export interface ModalConfirmProps extends ConfirmProps {
    onReject: () => void
    onResolve: () => void
    onUnmount?: () => void
}

export const ModalConfirm: React.FC<ModalConfirmProps> = cfg => {
    const {
        content,
        title,
        okText,
        okStatus = 'error',
        okProps,
        cancelText,
        okDisable,
        checkBoxLabel,
        isCheckBoxConfirm,
        extra,
        footerExtra,
        onReject,
        onResolve,
        onUnmount,
        ...props
    } = cfg
    const [checked, setChecked] = useState(false)

    const okButtonProps = useMemo(() => (okStatus === 'error' ? { ...okProps, danger: true } : okProps), [okProps, okStatus])

    const disabled = useMemo(() => (!checked && isCheckBoxConfirm) || okDisable, [checked, isCheckBoxConfirm, okDisable])

    return (
        <DesktopModal
            open
            width={360}
            withCloseIcon={false}
            closeOnClickOverlay={false}
            onClose={onReject}
            styles={{
                body: {
                    padding: '20px 20px 0',
                    fontSize: 14,
                    color: '$colorGray600',
                    overflow: 'hidden'
                },
                footer: {
                    padding: '24px 20px'
                }
            }}
            footer={
                <Footer>
                    {footerExtra && (
                        <FooterLeft>{typeof footerExtra === 'function' ? footerExtra({ onResolve, onReject, onUnmount }) : footerExtra}</FooterLeft>
                    )}
                    <FooterRight>
                        <Button size="lg" onClick={onReject}>
                            {cancelText || '取消'}
                        </Button>
                        <Button size="lg" type="primary" {...okButtonProps} disabled={disabled} onClick={onResolve}>
                            {okText || '删除'}
                        </Button>
                    </FooterRight>
                </Footer>
            }
            {...props}
        >
            <Title>{title}</Title>
            <ContentContainer>{content}</ContentContainer>
            {extra}
            {isCheckBoxConfirm && (
                <Checkbox
                    style={{ marginTop: 12, color: 'var(--color-gray-500)' }}
                    checked={checked}
                    size="xs"
                    label={checkBoxLabel}
                    onChange={e => setChecked(e.target.checked)}
                />
            )}
        </DesktopModal>
    )
}

export const confirm = (cfg: ConfirmProps) => {
    const container = document.createDocumentFragment() as unknown as Container
    const root = container[ROOT] || createRoot(container)
    container[ROOT] = root

    const { content, title, okText, cancelText, checkBoxLabel, isCheckBoxConfirm, ...props } = cfg

    return new Promise<boolean>(resolve => {
        const handlerResolve = () => {
            root.unmount()
            resolve(true)
        }

        const handleReject = () => {
            root.unmount()
            resolve(false)
        }

        const handleUnmount = () => {
            root.unmount()
        }

        root.render(<ModalConfirm onReject={handleReject} onResolve={handlerResolve} onUnmount={handleUnmount} {...cfg} />)
    })
}
