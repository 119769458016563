import { Loading } from '@byecode/ui'
import { Flex } from '@byecode/ui/components/Flex'
import { getAssetUrl } from '@lighthouse/assets'
import type { BlockAbstract } from '@lighthouse/core'
import { BlockType, PAGE_TYPE } from '@lighthouse/core'
import type { ErrorCode } from '@lighthouse/shared'
import {
    findBlockById,
    getMainTableRecordId,
    initBlockRuntimeState,
    noAccessCodeList,
    NoFound,
    PAGE_URL_REG,
    useAtomAction,
    useAtomData,
    VIEW_CELL_DATA_KEY
} from '@lighthouse/shared'
import type { AxiosError } from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { addDataSourceAtom, addRecordAtom } from '@/atoms/dataSource/action'
import { blocksAtom, pageAtomFamily, pageBlocksAtom,pageStackAtom } from '@/atoms/page/state'
import { applyDraftPayload } from '@/atoms/utils/applyDraftPayload'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageContainer } from '@/containers/Page/PageContainer'
import { useCurrentEnvId } from '@/hooks/useApplication'
import { useViewRecords } from '@/hooks/useBlock'
import * as srv from '@/services'
import { activeRecordId, clearActiveRecordId } from '@/utils/web'
import NoAccess from '@/views/Page/NoAccess'

import { PageFooter } from './PageFooter'

interface NestedPageWrapperProps {
    stackId: string
    pageId: string
    appId?: string
    dsId?: string
    recordId?: string
    viewId?: string
    fromAction?: boolean
}

const SCxPageContainerWrapper = styled.div`
    height: calc(100% - 60px);
    overflow: hidden;
`

export const NestedPageWrapper: React.FC<NestedPageWrapperProps> = ({
    stackId,
    pageId,
    appId,
    dsId,
    recordId: initialRecordId,
    viewId,
    fromAction
}) => {
    const { run: setPage } = useAtomAction(pageAtomFamily(pageId))
    const { run: setPageBlocks } = useAtomAction(blocksAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: addRecord } = useAtomAction(addRecordAtom)
    const { run: addDataSource } = useAtomAction(addDataSourceAtom)
    const envId = useCurrentEnvId()
    const viewRecords = useViewRecords(viewId ?? '')
    const viewRecordIds = useMemo(() => (viewRecords || []).map(r => r.id), [viewRecords])
    const viewMainRecordIds = useMemo(() => (viewRecords || []).map(r => getMainTableRecordId(r.id)), [viewRecords])

    const showPagination = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(
            s => {
                if(!viewId){
                    return
                }
                const block = findBlockById(viewId, s)
                //  (Object.values(s).flat().filter(Boolean) ).find(item => item.id === viewId)
                if (!block || block.type !== BlockType.view) {
                    return
                }

                return block.config.viewingConfig?.showPagination
            },
            [viewId]
        )
    )

    const [currentPageIndex, setCurrentPageIndex] = useState(initialRecordId ? viewMainRecordIds.indexOf(initialRecordId) : -1)
    const recordId = useMemo(() => {
        if (viewMainRecordIds.length > 0) {
            return viewMainRecordIds[currentPageIndex]
        }
        return initialRecordId
    }, [currentPageIndex, initialRecordId, viewMainRecordIds])

    useUpdateEffect(() => {
        if (initialRecordId) {
            setCurrentPageIndex(viewMainRecordIds.indexOf(initialRecordId))
        }
    }, [viewMainRecordIds])

    const pageRes = useAsyncRetry(async () => {
        const res = await srv.getPageContent(pageId)
        if (!res.success) {
            return { code: res.code, id: '', success: res.success }
        }
        const { blocks, ...page } = res.content
        setPage(draft => applyDraftPayload(draft, page))
        setPageBlocks(draft => void (draft[pageId] = blocks))
        setPageStack(draft => {
            // const stack = draft.find(item => item.pageId === pageId)
            const stack = draft.find(equalPageStack(pageId, stackId))
            if (stack) {
                initBlockRuntimeState(stack, blocks)
            }
        })

        return res
    }, [pageId])

    const recordRes = useAsyncRetry(async () => {
        if (appId && dsId && recordId && pageId) {
            const { dataSource, record, fieldBlockCellVoMap } = await srv.getRecord({ appId, envId, dsId, recordId, pageId })
            addRecord({ records: [record] })
            // 如果存在匹配当前用户的逻辑，则将内容添加至数据源及记录池中
            if (fieldBlockCellVoMap) {
                const data = Object.entries(fieldBlockCellVoMap)
                for (const [, d] of data) {
                    const { dataSource, record } = d
                    addDataSource({ dataSource })
                    addRecord({ records: [record] })
                }
            }
            return { dataSource, record }
        }
        return null
    }, [pageId, appId, dsId, recordId])

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const refreshPageManually = useCallback(() => {
        pageRes.retry()
        recordRes.retry()
    }, [pageRes, recordRes])

    useEffect(() => {
        if (!viewId || !viewMainRecordIds) {
            return
        }
        clearActiveRecordId(VIEW_CELL_DATA_KEY)
        activeRecordId(viewId, viewRecordIds[currentPageIndex], VIEW_CELL_DATA_KEY)
        return () => {
            clearActiveRecordId(VIEW_CELL_DATA_KEY)
        }
    }, [currentPageIndex, viewId, viewRecordIds, viewMainRecordIds])

    const handleChangePageNum = useCallback(
        (pageNum: number) => {
            if (!viewId || !viewMainRecordIds) {
                return
            }
            setCurrentPageIndex(pageNum)

            const matchStackUrlArr = pathname.slice(1).match(PAGE_URL_REG)
            if (!matchStackUrlArr) {
                return
            }

            const last = matchStackUrlArr[matchStackUrlArr.length - 1]
            const newLastStackUrl = last.replace(/(\/r\/)\w+/, `$1${viewMainRecordIds[pageNum]}`)
            navigate(`/${matchStackUrlArr.slice(0, -1).join('/')}/${newLastStackUrl}`, { replace: true })
        },
        [viewId, viewMainRecordIds, pathname, navigate]
    )
    // const { ref, isScroll } = useElementIsScroll(!(pageRes.loading || recordRes.loading || pageRes.error) && !!pageRes.value)

    // 去除recordRes.loading,因为如果有弹窗页面时刷新页面,
    // 弹窗的pageContent走完后, 父级的view接口走完, viewRecords会更新
    // 导致recordId更新,又进入了loading
    if (pageRes.loading) {
        return <Loading />
    }

    if (pageRes.error) {
        if ((pageRes.error as AxiosError)?.response?.status === 401) {
            return null
        }
        return null
        // return <ErrorFallback error={pageRes.error} resetErrorBoundary={pageRes.retry} />
    }

    if (noAccessCodeList.includes(pageRes.value?.code as ErrorCode)) {
        return <NoAccess />
    }

    if (!pageRes.value || !pageRes.value.success) {
        return <NoFound image={getAssetUrl('empty', 'no_page.svg')} title="" desc="找不到页面，页面已被删除" btnText="" />
    }

    const { type: pageType } = pageRes.value.content
    const showPaginator = viewMainRecordIds.length > 0 && pageType === PAGE_TYPE.document && !!showPagination

    return showPaginator ? (
        <Flex direction="column" style={{ height: '100%' }}>
            <SCxPageContainerWrapper>
                <PageContainer pageId={pageId} stackId={stackId} refreshPageManually={refreshPageManually} />
            </SCxPageContainerWrapper>
            <PageFooter current={currentPageIndex} count={viewMainRecordIds.length - 1} onPageChange={handleChangePageNum} />
        </Flex>
    ) : (
        <PageContainer pageId={pageId} stackId={stackId} refreshPageManually={refreshPageManually} />
    )
}
