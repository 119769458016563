import type { Field } from '@lighthouse/core'
import { type DateValue, type DTSelectItem, type InclusionRelation, type VariableADTvalue, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'

import { defaultFormat, FieldNameMap } from '../../constants'
import { useApplicationContext } from '../../contexts'
import { getDepartmentToTreeOptions, isDateValue, isIdsValue } from '../../utils'
import { CascadeDrawer, DateDrawer, InputDrawer, PersonDrawer, RoleDrawer, SelectDrawer } from '../FieldDrawer'
import { AggregationInnerTypeToFieldType } from '../Variable'

interface ParamsDrawerProps {
    field?: Field
    operator: InclusionRelation
    opened: boolean
    value: VariableADTvalue[]
    paramsIndex?: 0 | 1
    selectOptions?: DTSelectItem[]
    target?: string
    onFinish?: (val: VariableADTvalue[]) => void
    onInputChange?: (val: string) => void
    onClose: () => void
}

export const ParamsDrawer: React.FC<ParamsDrawerProps> = ({
    field,
    operator,
    opened,
    value,
    paramsIndex,
    selectOptions = [],
    target,
    onClose,
    onInputChange,
    onFinish
}) => {
    const { personOptions, roleOptions, departmentOptions, pageTarget } = useApplicationContext()
    const realField = useMemo(() => {
        if (!field) {
            return
        }
        if ((field.type === 'formula' || field.type === 'aggregation') && field.innerType) {
            return {
                ...field,
                type: AggregationInnerTypeToFieldType[field.innerType]
            } as Field
        }
        return field
    }, [field])

    const title = useMemo(() => {
        if (field && field.type) {
            return FieldNameMap[field.type]
        }
        return '选择值'
    }, [field])

    const handleDateFinish = useCallback(
        (val: DateValue) => {
            if (paramsIndex === 0) {
                onFinish?.([
                    {
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'date',
                            value: val,
                            format: defaultFormat
                        }
                    },
                    value?.[1]
                ])
            }
            if (paramsIndex === 1) {
                onFinish?.([
                    value?.[0],
                    {
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: 'date',
                            value: val,
                            format: defaultFormat
                        }
                    }
                ])
            }
        },
        [onFinish, paramsIndex, value]
    )

    const content = useMemo(() => {
        const param = value?.[paramsIndex ?? 0] || {
            type: VariableType.VALUE
        }

        switch (realField?.innerType) {
            case 'TEXT': {
                const data = param.type === VariableType.VALUE ? param.valueVariable?.value : ''
                return (
                    <InputDrawer
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        value={typeof data === 'string' ? data : ''}
                        onChange={onInputChange}
                        onFinish={val =>
                            onFinish?.([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'text',
                                        value: val
                                    }
                                }
                            ])
                        }
                    />
                )
            }
            case 'ARRAY': {
                const data = param.type === VariableType.VALUE ? param.valueVariable?.value : []
                if (field?.type === 'person') {
                    return (
                        <PersonDrawer
                            selectOptions={personOptions}
                            target={target}
                            opened={opened}
                            isMultiple
                            title={title}
                            value={isIdsValue(data) ? data : []}
                            onClose={onClose}
                            onChange={val =>
                                onFinish?.([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: {
                                            type: 'person',
                                            value: val
                                        }
                                    }
                                ])
                            }
                        />
                    )
                }
                if (field?.type === 'role') {
                    return (
                        <RoleDrawer
                            options={roleOptions}
                            target={target}
                            opened={opened}
                            isMultiple
                            title={title}
                            value={isIdsValue(data) ? data : []}
                            onClose={onClose}
                            onChange={val =>
                                onFinish?.([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: {
                                            type: 'person',
                                            value: val
                                        }
                                    }
                                ])
                            }
                        />
                    )
                }
                if (field?.type === 'department') {
                    const optionTree = getDepartmentToTreeOptions(departmentOptions)
                    return (
                        <CascadeDrawer
                            options={optionTree}
                            value={isIdsValue(value) ? value : []}
                            isMultiple
                            title={field.name}
                            target={target}
                            opened={opened}
                            onClose={onClose}
                            onFinish={val =>
                                onFinish?.([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: {
                                            type: 'department',
                                            value: val
                                        }
                                    }
                                ])
                            }
                        />
                    )
                }

                return (
                    <SelectDrawer
                        target={target}
                        opened={opened}
                        title={title}
                        isMultiple
                        onClose={onClose}
                        value={isIdsValue(data) ? data : []}
                        selectOptions={selectOptions}
                        onChange={val =>
                            onFinish?.([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'select',
                                        value: val
                                    }
                                }
                            ])
                        }
                    />
                )
            }

            case 'DATE': {
                const data =
                    param.type === VariableType.VALUE ? (isDateValue(param.valueVariable?.value) ? [param.valueVariable?.value] : []) : []
                const showTime = field?.type === 'date' && !!field.date.format && field.date.format.includes('HH')
                return (
                    <DateDrawer
                        showTime={showTime}
                        target={target}
                        opened={opened}
                        onClose={onClose}
                        value={data?.[paramsIndex || 0]}
                        onChange={handleDateFinish}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [
        value,
        paramsIndex,
        realField?.innerType,
        target,
        opened,
        onClose,
        onInputChange,
        onFinish,
        field,
        title,
        selectOptions,
        personOptions,
        roleOptions,
        departmentOptions,
        handleDateFinish
    ])

    if (!opened) {
        return null
    }

    return content
}
