import type {
    BlockAbstract,
    ContainerBlockAbstract,
    FieldCellValue,
    FieldValue,
    FileValue,
    FormContainerBlockAbstract,
    ViewBlockAbstract
} from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import { getBlockChildren, getBreakPointConfigure, getFileTypeByUrl, hasChildrenBlock, VIEW_BLOCK_NAME_MAP } from '@lighthouse/shared'

import {
    BLOCK_NAME_MAP,
    BlockTypeIconMap,
    CHART_BLOCK_NAME_MAP,
    ChartTypeIconMap,
    CONTAINER_ICON_MAP,
    FIELD_BLOCK_ICON_MAP,
    FIELD_BLOCK_NAME_MAP,
    FILTER_BLOCK_ICON_MAP,
    QR_BARCODE_BLOCK_ICON_MAP,
    VIEW_BLOCK_ICON_MAP
} from './constants'

/** 是否为附件类型 */
function isFileFieldValue(value: FieldCellValue | unknown[]): value is FileValue {
    return Array.isArray(value) && value.some(k => typeof k === 'string')
}

/**
 * 根据字段获取image地址
 * @param {FieldValue} field 字段类型
 * @returns
 */
export function getImageFromField(field?: FieldValue) {
    if (!field || !field.value) {
        return ''
    }
    if (isFileFieldValue(field.value)) {
        return field.value.find(item => {
            const type = getFileTypeByUrl(item)
            return type === 'image'
        })
    }
    return ''
}

/**
 * 获取block icon
 *
 * 视图、字段及图表会根据内部类型而变化
 * @param block
 * @param dataSource
 */
export function getBlockIcon(block: BlockAbstract, previewType: ApplicationPreviewEnum) {
    switch (block.type) {
        case BlockType.container: {
            const breakPointConfigure = getBreakPointConfigure(previewType, block.config?.breakPoint, block.config?.breakPoints)
            return CONTAINER_ICON_MAP[breakPointConfigure?.layout?.align?.direction || DIRECTION.horizontal]
        }
        case BlockType.field: {
            const { inputType } = block.config

            return FIELD_BLOCK_ICON_MAP[inputType]
        }

        case BlockType.chart: {
            const { chartType } = block.config
            return ChartTypeIconMap[chartType]
        }

        case BlockType.view: {
            const { viewType } = block.config
            return VIEW_BLOCK_ICON_MAP[viewType]
        }

        case BlockType.qrBarcode: {
            const { codeType } = block.config
            return QR_BARCODE_BLOCK_ICON_MAP[codeType]
        }

        case BlockType.filter: {
            const { mode } = block.config
            return FILTER_BLOCK_ICON_MAP[mode]
        }

        default: {
            return BlockTypeIconMap[block.type]
        }
    }
}

/**
 * 获取block 名称
 *
 * 视图、字段及图表会根据内部类型而变化
 * @param block
 * @param dataSource
 * @returns
 */
export function getBlockName(block: BlockAbstract) {
    switch (block.type) {
        case BlockType.field: {
            const { inputType } = block.config
            return FIELD_BLOCK_NAME_MAP[inputType]
        }

        case BlockType.chart: {
            const { chartType } = block.config
            return CHART_BLOCK_NAME_MAP[chartType]
        }

        case BlockType.view: {
            const { viewType } = block.config
            return VIEW_BLOCK_NAME_MAP[viewType]
        }
        default: {
            return BLOCK_NAME_MAP[block.type]
        }
    }
}



export type PureBlockTree = { id: string; children?: PureBlockTree[] }
/** 获取block的布局信息 */
export function getPureBlockLayoutTree(block: BlockAbstract): PureBlockTree {
    if (!hasChildrenBlock(block)) {
        const { id } = block
        return { id }
    }

    const { id, type, children } = block
    if (type === BlockType.container) {
        return {
            id,
            children: children.map(view => ({
                id: view.id,
                children: view.children.map(getPureBlockLayoutTree)
            }))
        }
    }
    return { id, children: children?.map(getPureBlockLayoutTree) }
}

/** 收集block及子元素 */
export function collectBlockAndChildren(block: BlockAbstract): BlockAbstract[] {
    const children = getBlockChildren(block) ?? []

    return [
        block,
        ...children.reduce<BlockAbstract[]>((total, curr) => {
            return [...total, ...collectBlockAndChildren(curr)]
        }, [])
    ]
}


/** 收集block及子元素 */
export function getFlatAllBlock(block: BlockAbstract[]): BlockAbstract[] {
    return block.reduce<BlockAbstract[]>((total, curr) => {
        return [...total, ...collectBlockAndChildren(curr)]
    }, [])
}
