import { useCustomViewBlockContext } from '@lighthouse/block'
import type { TabsBlockAbstract, VariableADTvalue } from '@lighthouse/core'
import { useActionRunningLoadings, useAppContainerContext, useAtomData } from '@lighthouse/shared'
import produce from 'immer'
import React, { Suspense, useCallback, useMemo } from 'react'

import { pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppId, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'

const TabsBlock = React.lazy(() => import('@lighthouse/block').then(module => ({ default: module.TabsBlock })))

interface TabsControllerProps {
    blockData: TabsBlockAbstract
}

const TabsController = ({ blockData }: TabsControllerProps) => {
    const { baseList } = blockData.config

    const { scale } = useAppContainerContext()

    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const currentTab = useAtomData(
        pageStackAtomFamily({ pageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }

                const activeItem = baseList.find(item => {
                    if (item.action?.type === 'control') {
                        const { controlElementId, controlElementType, controlAction } = item.action.data.control
                        if (controlElementType === 'container' && controlAction?.type === 'switchPanel') {
                            const viewId = controlAction.params?.switchPanelId
                            return s.blockRuntimeState.container?.[controlElementId]?.currentView === viewId
                        }
                    }

                    return false
                })

                return activeItem?.id
            },
            [baseList]
        )
    )

    const { handleActionTrigger } = useActionTrigger()
    const { handleActionTriggerWithLoading } = useActionRunningLoadings()

    const handleTabsChange = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
            const action = baseList.find(item => item.id === id)?.action
            if (!action) {
                return
            }
            handleActionTriggerWithLoading({ type: 'click', action, id, trigger: () => handleActionTrigger(action) })
        },
        [baseList, handleActionTriggerWithLoading, handleActionTrigger]
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const visibilityFilter = useVisibilityFilter({ prev, curr })

    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const { record: customViewRecord, pointer } = useCustomViewBlockContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const visibleList = useMemo(
        () =>
            baseList.filter(item =>
                visibilityFilter({
                    visibility: {
                        visibilityFilter: item.visibilityFilter,
                        visible: 'FILTER'
                    },
                    viewRecord: {
                        record: customViewRecord,
                        datasource: dataSource
                    }
                })
            ),
        [baseList, visibilityFilter, customViewRecord, dataSource]
    )

    const newData = useMemo(
        () =>
            produce(blockData, draft => {
                draft.config.baseList = visibleList
            }),
        [blockData, visibleList]
    )

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const { renderLabel } = useVariableValueRender(pageId, prev.recordId, curr.recordId)
    const { record } = useCustomViewBlockContext()
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true, viewRecord: record }),
        [record, renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    return (
        <Suspense fallback={<div />}>
            <TabsBlock
                blockData={newData}
                scale={scale}
                value={currentTab}
                // onChange={handleTabsChange}
                onClickTab={handleTabsChange}
                parseVariableImage={parseBackgroundVariableImage}
            />
        </Suspense>
    )
}

export default TabsController
