export const errorCodeMap = {
    '0000000': '成功',
    '0011001': '用户名或密码错误',
    '0011002': '该用户未注册',
    '0011004': '应用端应用不存在',
    '0011005': '页面无访问权限',
    '0011009': '用户不存在',
    '0011010': '名称重复',
    '0011011': '验证码已失效',
    '0011013': '无法启用工作流，请检查配置后重试',
    '0011017': '应用已下线',
    '0011018': '域名已存在，请重新输入',
    '0011019': '用户已注册',
    '0011021': '您的帐号正在审核',
    '0011023': '测试账号连接失败',
    '0011024': '可发布应用数量达到上限',
    '0011025': '记录条数达到上限',
    // '0011026': '工作流执行次数超出限制',
    '0011027': '附件容量达到上限',
    '0011029': '应用无权限',
    '0011030': '手机号重复',
    '0011031': '部门名称重复',
    '0011032': '用户数量达到上限',
    '0011033': '绑定自有域名的应用达到上限',
    '0011036': '导出超过 500 条',
    '0011037': '导出超过 50MB',
    '0011039': '账号异常，请联系管理员',
    '0011042': '微信公众号未授权',
    '0011043': '邮箱重复',
    '0011044': '手机号已绑定',
    '0011045': '支付异常',
    '0011047': '访问流量超出配额',
    '0011048': '短信超出配额',
    '0011049': '找不到记录',
    '0011050': '域名格式错误',
    '0011052': '微信模板消息异常',
    '0011054': '环境存在异常，禁止合并',
    '0011055': '正式版本禁止修改数据',
    '0011057': '该应用用户已被其他应用绑定，无法再绑定其他应用',
    '0011058': '私人模板禁止操作',
    '0011059': '微信不可重复绑定',
    '0011060': '两个环境数据表不一致, 无法切换同步数据表环境',
    /**
     * 动作流相关节点异常 start
     */
    '0011061': '邮件发送异常',
    '0011062': '短信发送异常',
    '0011063': '钉钉机器人异常',
    '0011064': '微信机器人异常',
    '0011065': '该订单已过期，请重新创建订单',
    /**
     * 动作流相关节点异常 end
     */
    '0011066': '页面不存在',
    '0011068': 'UV超出限制',
    '0011069': '应用复制失败，应用中存在从其他应用同步的表或存在同步用户',
    '0011070': '应用模板不存在',
    '0011073': '验证码短信配置未完善',
    '0011074': '无法发布工作流，请检查配置后重试',
    '0020000': '页面不存在或页面未公开！', // FIXME: 临时解决频繁切换应用和页面报错 @keLin
    '0030000': '过滤异常，无需抛出'
}

export type ErrorCode = keyof typeof errorCodeMap

export const notFoundCodeList: ErrorCode[] = ['0011004', '0011029']

export const notFoundTemplateCodeList: ErrorCode[] = ['0011070']

export const applicationUnpublished: ErrorCode = '0011017'

export const noAccessCodeList: ErrorCode[] = ['0011005']

export const reviewCodeList: ErrorCode[] = ['0011021']

export const noUserCodeList: ErrorCode[] = ['0011009']

export const needRegisterCodeList: ErrorCode[] = ['0011002']

export const skipCodeList: ErrorCode[] = ['0020000', '0011042', '0030000', '0011066'] // 暂时解决报错问题，路由引起的报错，后续需要优化，APPID 和 pageId 无法对应

export const limitExceededCodeList: ErrorCode[] = ['0011024', '0011025', /** '0011026', */ '0011027', '0011032', '0011033', '0011047']

export const limitTraffic = '0011047'

export const wechatPayCodeList: ErrorCode[] = ['0011045']

export const useServiceMessageCodeList: string[] = ['0011067']

/** 弹出警告的提示消息 */
export const WARNING_CODE_LIST = ['0011032', '0011025', '0011048', '0011068'] satisfies ErrorCode[]
