import { type FieldADTValue, VIEW_DESIGN_STYLE } from '@lighthouse/core'
import { fieldConvertValue, getImageFullUrlInApplication, RowHighlighter, useActionRunningLoadings, useApplicationContext } from '@lighthouse/shared'
import { Checkbox, Image } from '@mantine/core'
import cls from 'classnames'
import React, { useCallback, useMemo } from 'react'

import { RecordOperator } from '../../components/RecordOperator'
import { getImageFromField } from '../../utils'
import { ListChecker } from './ListChecker'
import type { ListRecordProps } from './ListRecordStyle'
import {
    SCxDivider,
    SCxEmphasizeText,
    SCxHandle,
    SCxListAvatarWrapper,
    SCxListField,
    SCxListItemsWrapper,
    SCxListItemWrapper,
    SCxListMainItemWrapper,
    SCxListPrimaryItemWrapper,
    SCxListRecord,
    SCxListRecordContainer,
    SCxListRecordContent,
    SCxListSecondaryItem
} from './ListRecordStyle'
import { ListValue } from './ListValue'
import { getDesignStyleMap, getDesignTypeMap } from './utils'

export const ListRecord: React.FC<ListRecordProps> = ({
    actions,
    record,
    index,
    blockId,
    primaryField,
    viewFields,
    schema,
    isMobile,
    recordOpenable,
    canDeleteRecord,
    canRecordEdit,
    rowStyleConditions,
    selectedRecords,
    checkable,
    hoverColor,
    designConfig,
    aiFieldStatusListAtom,
    isLast,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle
}) => {
    const { id: recordId, content: recordContent, dsId } = record
    const handleSelectRecord = useCallback(
        (checked: boolean, id: string) => {
            if (!selectedRecords) {
                return
            }
            if (checked) {
                onRecordSelect?.([...selectedRecords, id])
            } else {
                onRecordSelect?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [onRecordSelect, selectedRecords]
    )
    const { appId } = useApplicationContext()

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const { designStyle, designType, isShowListAvatar, listAvatar = '', isShowEmphasizeText, emphasizeTextField, avatarType } = designConfig

    const designStyleOption = useMemo(() => getDesignStyleMap(designStyle, isMobile), [designStyle, isMobile])

    const designTypeOption = useMemo(() => getDesignTypeMap(designType), [designType])
    // const colorBlock = useMemo(() => {
    //     const documentStyles = getComputedStyle(document.body)
    //     const grayColor = documentStyles.getPropertyValue('--color-gray-50').trim()
    //     const color = chroma.mix(designTypeOption.background, grayColor).alpha(0.3).hex()
    // },[designTypeOption.background])
    // const isColumns = designStyle === 'classic' || designStyle === 'stripe' || isMobile
    // const isStripe = designStyle === 'stripe'
    // const isBorder = designStyle === 'classic' || designStyle === 'stripe'
    const primaryFieldCell = recordContent?.[primaryField?.id || '']

    // const imageSize = useMemo(() => {
    // }, [])
    const emphasizeText = useMemo(() => {
        if (isShowEmphasizeText && emphasizeTextField) {
            const text = recordContent?.[emphasizeTextField]?.value
            return typeof text === 'string' || typeof text === 'number' ? text : ''
        }
        return ''
    }, [emphasizeTextField, isShowEmphasizeText, recordContent])

    const fieldList = useMemo(() => {
        let list = viewFields
        if (isShowListAvatar && listAvatar) {
            list = list.filter(item => item.fieldId !== listAvatar)
        }
        if (isShowEmphasizeText && emphasizeTextField) {
            list = list.filter(item => item.fieldId !== emphasizeTextField)
        }
        return list
    }, [emphasizeTextField, isShowEmphasizeText, isShowListAvatar, listAvatar, viewFields])

    const handleRecordDelete = useCallback(
        (id: string) => {
            onRecordDelete?.(id)

            return Promise.resolve(true)
        },
        [onRecordDelete]
    )

    const handleAiGeneration = useCallback(
        (fieldId: string) => {
            if (!onAiGeneration) {
                return Promise.resolve(false)
            }
            return onAiGeneration(recordId, fieldId)
        },
        [recordId, onAiGeneration]
    )

    const handleRecordClick = useCallback(() => {
        const recordClickedActionParams = actions?.recordClicked
        if (recordClickedActionParams?.customized && recordClickedActionParams?.action) {
            handleActionTriggerWithLoading({
                type: 'click',
                id: recordId,
                action: recordClickedActionParams.action,
                record,
                trigger: onRecordClickedActionTrigger
            })
            return
        }
        recordOpenable && onRecordClick?.(recordId)
    }, [
        actions?.recordClicked,
        handleActionTriggerWithLoading,
        onRecordClick,
        onRecordClickedActionTrigger,
        record,
        recordId,
        recordOpenable
    ])

    const primaryFieldIsFull = useMemo(() => {
        return designStyleOption.isPrimaryAlone || fieldList.length === 1
    }, [designStyleOption.isPrimaryAlone, fieldList.length])

    const handleContent = useMemo(() => {
        if (!canRecordEdit && !canDeleteRecord && !actions?.recordOperator?.customized) {
            return null
        }
        return (
            <SCxHandle onClick={ev => ev.stopPropagation()}>
                {!designStyleOption.isNumberOrder && selectedRecords && selectedRecords.length > 0 ? (
                    <Checkbox
                        size="xs"
                        disabled={!checkable}
                        checked={selectedRecords.includes(recordId)}
                        onChange={ev => {
                            if (!checkable) {
                                return
                            }
                            handleSelectRecord(ev.target.checked, recordId)
                        }}
                    />
                ) : (
                    <RecordOperator
                        dsId={dsId}
                        blockId={blockId}
                        actions={actions?.recordOperator?.actionGroup?.group}
                        customized={actions?.recordOperator?.customized}
                        config={{ canEdit: !!canRecordEdit, canDelete: !!canDeleteRecord }}
                        isMobile={isMobile}
                        preShowCount={2}
                        onRecordDelete={() => handleRecordDelete(recordId)}
                        onRecordEdit={onRecordEdit}
                        recordId={recordId}
                        record={record}
                        // eslint-disable-next-line no-return-await
                        onActionTrigger={async action => await onRecordOperatorActionTrigger?.(action, record)}
                        onRenderButtonTitle={v => onRenderButtonTitle(v, record)}
                    />
                )}
            </SCxHandle>
        )
    }, [
        canRecordEdit,
        canDeleteRecord,
        actions?.recordOperator?.customized,
        actions?.recordOperator?.actionGroup?.group,
        designStyleOption.isNumberOrder,
        selectedRecords,
        checkable,
        recordId,
        dsId,
        blockId,
        isMobile,
        onRecordEdit,
        record,
        handleSelectRecord,
        handleRecordDelete,
        onRecordOperatorActionTrigger,
        onRenderButtonTitle
    ])

    return (
        <SCxListRecord style={{ cursor: loadings[recordId] ? 'wait' : 'pointer' }}>
            <SCxListRecordContainer
                className={cls({ 'record-active': selectedRecords?.includes(recordId) })}
                openable={recordOpenable}
                key={recordId}
                background={`var(${designTypeOption.background})`}
                hoverColor={hoverColor}
                data-key={`${blockId}&${recordId}`}
                onClick={handleRecordClick}
            >
                <RowHighlighter viewFields={viewFields} recordContent={recordContent} recordConditions={rowStyleConditions} />
                {designStyleOption.isNumberOrder && (
                    <ListChecker
                        checkable={checkable}
                        isRecordSelecting={selectedRecords && selectedRecords.length > 0}
                        index={index}
                        checked={selectedRecords?.includes(recordId)}
                        onChange={checked => handleSelectRecord(checked, recordId)}
                    />
                )}
                {isShowListAvatar && (
                    <SCxListAvatarWrapper margin={designStyleOption.avatarMargin}>
                        <Image
                            src={getImageFullUrlInApplication(appId, getImageFromField(recordContent[listAvatar]))}
                            radius={avatarType === 'round' ? 30 : 8}
                            width={designStyleOption.imageSize}
                            height={designStyleOption.imageSize}
                            alt="Norway"
                            withPlaceholder
                            placeholder={<div style={{ background: 'var(--color-gray-200)' }} />}
                        />
                    </SCxListAvatarWrapper>
                )}
                <SCxListRecordContent direction={designStyleOption.contentDirection}>
                    {designStyleOption.isPrimaryAlone && (
                        <SCxListMainItemWrapper padding={designStyleOption.titlePadding} isFullWidth={primaryFieldIsFull}>
                            {isShowEmphasizeText && emphasizeTextField && (
                                <SCxEmphasizeText
                                    lineHeight={designStyleOption.emphasizeTextLineHeight}
                                    size={designStyleOption.emphasizeTextSize}
                                    marginBottom={designStyleOption.emphasizeTextLineMarginBottom}
                                >
                                    {emphasizeText}
                                </SCxEmphasizeText>
                            )}
                            {primaryField && primaryFieldCell && (
                                <SCxListPrimaryItemWrapper style={{ padding: '2px 0' }}>
                                    <ListValue
                                        dsId={dsId}
                                        recordId={recordId}
                                        field={primaryField}
                                        isColumn={designStyle === VIEW_DESIGN_STYLE.simple}
                                        isBold={designStyleOption.titleBold}
                                        size={designStyleOption.titleSize}
                                        data={primaryFieldCell.value}
                                        aiFieldStatusListAtom={aiFieldStatusListAtom}
                                        onAiGeneration={handleAiGeneration}
                                    />
                                </SCxListPrimaryItemWrapper>
                            )}
                        </SCxListMainItemWrapper>
                    )}
                    <SCxListItemsWrapper direction={designStyleOption.wrapperDirection}>
                        {!designStyleOption.isPrimaryAlone && (
                            <SCxListItemWrapper isFullWidth={primaryFieldIsFull} isBorderBottom={designStyle !== VIEW_DESIGN_STYLE.simple}>
                                {isShowEmphasizeText && emphasizeTextField && (
                                    <SCxEmphasizeText
                                        lineHeight={designStyleOption.emphasizeTextLineHeight}
                                        size={designStyleOption.emphasizeTextSize}
                                        marginBottom={designStyleOption.emphasizeTextLineMarginBottom}
                                    >
                                        {emphasizeText}
                                    </SCxEmphasizeText>
                                )}
                                {primaryField && primaryFieldCell && (
                                    <SCxListPrimaryItemWrapper>
                                        <ListValue
                                            dsId={dsId}
                                            recordId={recordId}
                                            field={primaryField}
                                            isBold={designStyleOption.titleBold}
                                            size={designStyleOption.titleSize}
                                            data={primaryFieldCell.value}
                                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                                            onAiGeneration={handleAiGeneration}
                                        />
                                    </SCxListPrimaryItemWrapper>
                                )}
                            </SCxListItemWrapper>
                        )}
                        <SCxListField
                            direction={designStyleOption.listDirection}
                            justify={designStyleOption.listJustify}
                            isWidth={designStyleOption.listIsFullWidth}
                        >
                            {fieldList.map(curField => {
                                const cId = curField.fieldId
                                const field = schema[cId]
                                if (cId === primaryField?.id) {
                                    return null
                                }
                                const cellData = recordContent?.[cId]
                                const fieldValue = {
                                    ...field,
                                    value: cellData?.value
                                } as FieldADTValue
                                const dataIsEmpty = !fieldConvertValue(fieldValue)
                                if (!field || !curField.visible || dataIsEmpty) {
                                    return null
                                }

                                return (
                                    <SCxListSecondaryItem
                                        key={cId}
                                        marginBottom={designStyleOption.listItemMarginBottom}
                                        isWidth={designStyleOption.listItemIsFullWidth}
                                    >
                                        <ListValue
                                            dsId={dsId}
                                            recordId={recordId}
                                            field={field}
                                            data={cellData?.value}
                                            isColumn={designStyleOption.listItemIsWidth}
                                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                                            onAiGeneration={handleAiGeneration}
                                        />
                                    </SCxListSecondaryItem>
                                )
                            })}
                        </SCxListField>
                    </SCxListItemsWrapper>
                </SCxListRecordContent>
                {handleContent}
            </SCxListRecordContainer>
            {designTypeOption.isDivider && designStyleOption.isDivider && !isLast && (
                <SCxDivider margin={designTypeOption.dividerMargin} color="var(--color-gray-200)" />
            )}
        </SCxListRecord>
    )
}
