import type { DataSourceAbstract, Field, FieldCellValue, VariableADTvalue, ViewField } from '@lighthouse/core'
import { type FieldType, type HighLightArea, type HighLightConditions, VariableType } from '@lighthouse/core'
import { find } from 'rambda'

import { propertyFilter } from '../constants'

type HighLightAreaContent = Record<HighLightArea, Record<string, HighLightConditions>>

export const isEmptyOperatorField = new Set<FieldType>(['formula', 'file', 'video'])

export const getDefaultOperator = (field: Field | undefined) => {
    const operator = ''
    if (!field) {
        return operator
    }
    if (!field.innerType) {
        return operator
    }
    const op = propertyFilter[field.innerType]
    return op[0]?.value
}

export function getVariableFieldId(idVariable?: VariableADTvalue) {
    if (idVariable?.type === VariableType.FIELD_ID) {
        return idVariable.fieldIdVariable?.fieldId || ''
    }
    if (idVariable?.type === VariableType.UPSTREAM) {
        return idVariable.upstreamVariable?.fieldId || ''
    }
    return ''
}

export const conversionHighLightRecord = (highLightRules: HighLightConditions) => {
    const highLightArea: HighLightAreaContent = {
        row: {},
        cell: {}
    }

    if (highLightRules && highLightRules.length > 0) {
        return highLightRules.reduce((prev, current) => {
            const conditions = current
            const { idVariable, area = 'cell' } = conditions
            if (!idVariable) {
                return prev
            }
            const fieldId = getVariableFieldId(idVariable)
            if (prev[area]?.[fieldId]) {
                prev[area][fieldId].push(conditions)
                return prev
            }
            prev[area] = {
                ...prev[area],
                [fieldId]: [conditions]
            }
            return prev
        }, highLightArea)
    }
    return highLightArea
}

/**
 *
 * @param
 * @returns
 */
export const getIsEmptyInValueVariableValue = (value?: FieldCellValue) => {
    if (value === undefined || Number.isNaN(value)) {
        return true
    }
    if(typeof value === 'string' && value.trim() === ''){
        return true
    }
    return !!(Array.isArray(value) && value.length === 0);
}

export const getValueVariableValueEmptyPlaceholder = <T>(value?: T):T |string => {
    if (value === undefined || value === '') {
        return `\u0001\u0003\u0002\u0004\u0005\u0001\u0002\u0003`
    }

    if (Array.isArray(value) && value.length === 0) {
        return `\u0001\u0003\u0002\u0004\u0005\u0001\u0002\u0003`
    }
    return value
}
