import type { AnyObject } from 'immer/dist/internal'

import type { BlockProtocol } from '../../protocols'
import type { BlockType } from '../Basic'

export abstract class BaseBlock implements BlockProtocol {
    abstract type: BlockType

    abstract title: string

    abstract config: AnyObject

    abstract id: string

    abstract logicId?: string

    /** 关联的主同步组件 */
    abstract syncId?: string

    /** 是否是主同步组件 */
    abstract isSyncHost?: boolean
}
