import type {
    ActionsProtocol,
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    HighLightConditions,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumnWidth,
    TableRowHeightProtocol,
    TableViewOptions,
    ViewField
} from '@lighthouse/core'
import { ApplicationPreviewEnum } from '@lighthouse/shared'
import type { atomWithImmer } from 'jotai-immer'
import { equals, sortBy } from 'rambda'
import type { RefObject } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { TableContainer } from './TableContainer'
import TableHeader from './TableHeader'

interface TableProps extends ActionsProtocol {
    id: string
    checkable?: boolean
    recordOpenable?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    dataSourceInfo: DataSourceAbstract
    viewFields: ViewField[]
    records: RecordLikeProtocol[]
    selectedRecords?: string[]
    highLightRules?: HighLightConditions
    scrollContainerRef: RefObject<HTMLDivElement>
    blockWidth?: number
    tableRowHeight?: TableRowHeightProtocol
    designConfig: TableViewOptions
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    previewType: ApplicationPreviewEnum
    onRecordSelect?: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
    onSelectModeChange?: (mode?: SelectedMode) => void
    onTableColumnWidthChange?: (val: TableColumnWidth) => void
}

const SCxTableContainer = styled.div`
    background-color: var(--color-white);
`

export const Table: React.FC<TableProps> = ({
    id: blockId,
    checkable,
    actions,
    recordOpenable,
    recordEditOpenable,
    recordDeleteAble,
    dataSourceInfo,
    viewFields,
    records,
    selectedRecords,
    highLightRules,
    scrollContainerRef,
    blockWidth,
    tableRowHeight,
    designConfig,
    aiFieldStatusListAtom,
    previewType,
    onRecordSelect,
    onRecordClick,
    onRecordEdit,
    onRecordDelete,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
    onSelectModeChange,
    onTableColumnWidthChange
}) => {
    const { hiddenHeader } = designConfig
    const isMobile = useMemo(() => previewType === ApplicationPreviewEnum.mobile, [previewType])
    const noRecords = records.length === 0
    const { schema } = dataSourceInfo

    const allRecordSelected = useMemo(() => {
        return (
            selectedRecords &&
            !noRecords &&
            equals(
                sortBy(a => a, selectedRecords),
                sortBy(
                    a => a,
                    records.map(record => record.id)
                )
            )
        )
    }, [noRecords, records, selectedRecords])

    const handleAllRecordSelect = useCallback(
        (selected: boolean) => {
            if (selected) {
                onRecordSelect?.(records.map(record => record.id))
            } else {
                onRecordSelect?.([])
            }
        },
        [onRecordSelect, records]
    )

    return (
        <SCxTableContainer className="tableContainer">
            {!hiddenHeader && (
                <TableHeader
                    id={blockId}
                    checkable={checkable}
                    viewFields={viewFields}
                    isMobile={isMobile}
                    schema={schema}
                    // parentWidth={blockWidth}
                    // scrollLeft={scrollLeft}
                    allRecordSelected={allRecordSelected}
                    designConfig={designConfig}
                    onAllRecordSelect={handleAllRecordSelect}
                    onSelectModeChange={onSelectModeChange}
                    onTableColumnWidthChange={onTableColumnWidthChange}
                />
            )}

            <TableContainer
                blockId={blockId}
                checkable={checkable}
                actions={actions}
                recordOpenable={recordOpenable}
                recordEditOpenable={recordEditOpenable}
                recordDeleteAble={recordDeleteAble}
                schema={schema}
                viewFields={viewFields}
                isMobile={isMobile}
                records={records}
                blockWidth={blockWidth}
                scrollRef={scrollContainerRef}
                selectedRecords={selectedRecords}
                highLightRules={highLightRules}
                tableRowHeight={tableRowHeight}
                designConfig={designConfig}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                onRecordSelect={onRecordSelect}
                onRecordClick={onRecordClick}
                onRecordEdit={onRecordEdit}
                onRecordDelete={onRecordDelete}
                onAiGeneration={onAiGeneration}
                onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                onRenderButtonTitle={onRenderButtonTitle}
            />
        </SCxTableContainer>
    )
}
