import { DatePicker, IconFont } from '@byecode/ui'
import type { DateConfig, DateField as DateFieldConfig, DateValue } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { add, format, formatRelative, getDate, isDate, isValid, parse, parseISO } from 'date-fns'
import { clamp, max, min } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { isDateValue, isNumberValue } from '../../../../utils'
import { minPopoverWidth } from '../../constant'
import type { FieldBaseProps } from '../../types'
import { getRelativeDate, getTimestamp } from './help'

interface DateFieldProps extends FieldBaseProps {
    dateConfig: DateConfig
    value?: DateValue
    dateField?: DateFieldConfig
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: var(--font-size-normal);
`

const SCxClear = styled(IconFont)`
    color: var(--color-gray-400);
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        color: var(--color-gray-900);
    }
`

const SCxInput = styled.input`
    width: 100%;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
`

const SCxPreview = styled.div`
    width: 100%;
    padding: 8px 0;
    min-height: 38px;
    line-height: 20px;
    display: flex;
    align-items: center;

    * {
        line-height: 20px;
    }
`
const defaultFormat = 'yyyy-MM-dd HH:mm:ss'
const DateField: React.FC<DateFieldProps> = props => {
    const { readOnly, dateConfig, onCellChange, value = '', dateField, isControlled, language } = props

    const { date: fieldDate } = dateField ?? {}

    const { placeholder, date } = dateConfig ?? {}

    const { range = 'ALL', customDays } = date ?? {}

    const timestamp = useMemo(() => getTimestamp(value), [value])

    const containerRef = useRef<HTMLDivElement>(null)

    const inputRef = useRef<HTMLInputElement>(null)

    const [data, setData] = useState<DateValue>(timestamp)

    const [isHover, setIsHover] = useState(false)

    const [opened, setOpened] = useState(false)

    const [isFocus, setIsFocus] = useState(false)

    const inputWidth = containerRef.current?.getBoundingClientRect()?.width ?? 0

    const popoverWidth = useMemo(() => clamp(minPopoverWidth, 380, inputWidth), [inputWidth])

    const usedValue = useMemo(() => (isControlled ? value : data), [data, isControlled, value])

    const [minDate, maxDate] = useMemo(() => {
        switch (range) {
            case 'AFTER_TODAY': {
                return [new Date(), undefined]
            }
            case 'BEFORE_TODAY': {
                return [undefined, new Date()]
            }
            case 'AFTER_CUSTOM': {
                return [
                    customDays
                        ? add(new Date(), {
                              days: max(customDays, 0)
                          })
                        : undefined,
                    undefined
                ]
            }
            default: {
                return []
            }
        }
    }, [customDays, range])

    const handleChange = useCallback(
        (date?: Date) => {
            const newDate = date?.valueOf()
            setData(newDate ?? '')
            setIsHover(false)
            inputRef.current?.blur()
            onCellChange?.({ type: 'date', value: newDate ?? '' })
        },
        [onCellChange]
    )

    const valueDate = useMemo(() => {
        const currentValue = usedValue ? new Date(usedValue) : undefined
        if (!currentValue) {
            return
        }
        return isValid(currentValue) ? currentValue : undefined
    }, [usedValue])

    const handleClear = useCallback(() => {
        setData('')

        onCellChange?.({ type: 'date', value: '' })
    }, [onCellChange])

    useEffect(() => {
        if (opened && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef, opened])

    return (
        <SCxContainer
            // data-field-border={opened}
            onMouseEnter={() => {
                !readOnly && setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            ref={containerRef}
        >
            <DatePicker
                popoverProps={{
                    width: popoverWidth,
                    onChange: setOpened,
                    offsetOptions: {
                        mainAxis: 10,
                        crossAxis: -2
                    },
                    returnFocus: false
                }}
                minDate={minDate}
                maxDate={maxDate}
                inputRender={({ value, onChange }) => {
                    const currentTimestamp = parseISO(format(Date.now(), defaultFormat)).valueOf()
                    const timeStamp =
                        typeof value === 'string' && value
                            ? parse(value, fieldDate?.format ?? defaultFormat, Date.now()).getTime()
                            : undefined
                    const previewTimeStamp = isNumberValue(timeStamp) ? timeStamp.valueOf() : ''
                    const suffixText = previewTimeStamp ? getRelativeDate(new Date(previewTimeStamp), currentTimestamp, language) : ''
                    return (
                        <SCxPreview onClick={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
                            {isFocus ? (
                                <SCxInput ref={inputRef} disabled={readOnly} value={value} onChange={onChange} placeholder={placeholder} />
                            ) : (
                                <Text
                                    style={{ padding: ' 0 8px' }}
                                    color={previewTimeStamp ? 'var(--color-black)' : 'var(--color-gray-300)'}
                                >
                                    {value || placeholder}
                                    {`${suffixText}`}
                                </Text>
                            )}
                        </SCxPreview>
                    )
                }}
                disabled={readOnly}
                format={fieldDate?.format}
                showTime={fieldDate?.format?.includes('HH:mm')}
                disableSecond
                placeholder={placeholder}
                value={valueDate}
                onChange={handleChange}
            />
            {usedValue && (opened || isHover) && <SCxClear type="CloseCircle" size={18} onClick={handleClear} />}
        </SCxContainer>
    )
}

export default DateField
