// import { ErrorFallback, FallbackBlock } from '@lighthouse/block'
import type { BlockAbstract, BlockProtocol } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import BreadcrumbBlockController from './BreadcrumbBlockController'
import ButtonBlockController from './ButtonBlockController'
import CardBlockController from './CardBlockController'
import ChartBlockController from './ChartBlockController'
import CollapseBlockController from './CollapseBlockController'
import ContainerBlockController from './ContainerController'
import DividerBlockController from './DividerBlockController'
import FieldBlockController from './FieldBlockController'
import FieldGroupBlockController from './FieldGroupBlockController'
import FileBlockController from './FileBlockController'
import FilterBlockController from './FilterBlockController'
import FormContainerBlockController from './FormContainerController'
import IframeBlockController from './IframeBlockController'
import ImageBlockController from './ImageBlockController'
import QrBarcodeBlockController from './QrBarcodeBlockController'
import  SubFormController  from './SubFormBlockController'
import TabsBlockController from './TabsController'
import TextBlockController from './TextBlockController'
import VideoBlockController from './VideoBlockController'
import ViewBlockController from './ViewBlockController'

interface BlockRenderControllerProps {
    blockData: BlockProtocol
    node: FlowLayoutNode
    children?: React.ReactNode
    onBlockChange?: (values: BlockAbstract, origin: BlockAbstract) => Promise<void> | void
}

const BlockRenderController = ({ blockData, node, children, onBlockChange }: BlockRenderControllerProps) => {
    const BlockComp = useMemo(() => {
        const blocks = {
            image: ImageBlockController,
            field: FieldBlockController,
            chart: ChartBlockController,
            buttonGroup: ButtonBlockController,
            view: ViewBlockController,
            card: CardBlockController,
            divider: DividerBlockController,
            video: VideoBlockController,
            fieldGroup: FieldGroupBlockController,
            breadcrumb: BreadcrumbBlockController,
            iframe: IframeBlockController,
            collapse: CollapseBlockController,
            file: FileBlockController,
            container: ContainerBlockController,
            formContainer: FormContainerBlockController,
            tabs: TabsBlockController,
            text: TextBlockController,
            filter: FilterBlockController,
            qrBarcode: QrBarcodeBlockController,
            subForm: SubFormController
        }
        return (blocks[blockData.type as keyof typeof blocks] as React.FC<BlockRenderControllerProps>) || (() => <div />)
    }, [blockData.type])

    return (
        <ErrorBoundary FallbackComponent={() => <div />}>
            <BlockComp node={node} blockData={blockData} onBlockChange={onBlockChange}>
                {children}
            </BlockComp>
        </ErrorBoundary>
    )
}

export default BlockRenderController
