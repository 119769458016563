import { Enum } from '@lighthouse/tools'

export const ARRANGEMENT = Enum('auto', 'fixed')
export type Arrangement = Enum<typeof ARRANGEMENT>

export const DIRECTION = Enum('horizontal', 'vertical')
export type Direction = Enum<typeof DIRECTION>

export const ALIGNMENT = Enum('left', 'center', 'right')
export type AlignMent = Enum<typeof ALIGNMENT>

export const TEXT_ALIGNMENT = Enum('left', 'center', 'right', 'justify')
export type TextAlignment = Enum<typeof TEXT_ALIGNMENT>

export const FLEX_ALIGN = Enum('flex-start', 'center', 'flex-end', 'space-between')
export type FlexAlign = Enum<typeof FLEX_ALIGN>

export const AXIS = Enum('x', 'y')
export type Axis = Enum<typeof AXIS>

export const POSITION = Enum('top', 'bottom', 'left', 'right')
export type Position = Enum<typeof POSITION>

export const SHAPE = Enum('square', 'rectangle', 'round')
export type Shape = Enum<typeof SHAPE>

/** 媒体元素的object fit样式填充方式 */
export const FIT = Enum('contain', 'cover')
export type Fit = Enum<typeof FIT>

/** 元素宽度规则 */
export const SIZE_FIT = Enum('contain', 'fill')
export type SizeFit = Enum<typeof SIZE_FIT>

export type QuotaProtocols = {
    quota: number
    usage: number
}

export const MAX_CONTAINER_WIDTH_SIZE = Enum('unlimited', 'xl', 'lg', 'md', 'sm')
export type MaxContainerWidthSize = Enum<typeof MAX_CONTAINER_WIDTH_SIZE>
