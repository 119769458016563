import { DIRECTION } from '@lighthouse/core'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useFlowLayoutContext } from '../Context'
import { DOM_DATA_NODE_NAME, DOM_DATA_PARENT_NAME, findNodeById } from '../Interaction/utils'
import type { FlowLayoutNode } from '../types'

const Root = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(85, 81, 255, 0.12);
    pointer-events: none;
`

interface Props {
    id: string
}

export const NodeGapIndicator = ({ id }: Props) => {
    const { data, rootElement, scale = 1, layout: rootLayout } = useFlowLayoutContext()

    if (!rootElement) {
        return null
    }

    const isRoot = id === 'root'

    if (isRoot) {
        const rootRect = rootElement.getBoundingClientRect()

        const direction = rootLayout?.align?.direction ?? DIRECTION.horizontal
        const [pl = 0, pt = 0, pr = 0, pb = 0] = rootLayout?.padding ?? []
        const gap = rootLayout?.gap ?? 0

        return (
            <>
                {data.slice(0, data.length > 1 ? -1 : undefined).map(child => {
                    const nodeRect = rootElement.querySelector(`[${DOM_DATA_NODE_NAME}="${child.id}"]`)?.getBoundingClientRect()
                    if (!nodeRect) {
                        return null
                    }

                    return (
                        <Root
                            key={child.id}
                            style={
                                direction === DIRECTION.horizontal
                                    ? {
                                          width: gap / scale,
                                          height: (rootRect.height - pt - pb) / scale,
                                          transform: `translate3d(${(-rootRect.left + nodeRect.right) / scale}px, ${pt / scale}px, 0)`
                                      }
                                    : {
                                          width: (rootRect.width - pl - pr) / scale,
                                          height: gap / scale,
                                          transform: `translate3d(${pl / scale}px, ${(-rootRect.top + nodeRect.bottom) / scale}px, 0)`
                                      }
                            }
                        />
                    )
                })}
            </>
        )
    }

    return <DefaultGapIndicator id={id} data={data} root={rootElement} scale={scale} />
}

function getContainer(root: HTMLElement, node: FlowLayoutNode) {
    if (node.type === 'custom') {
        const viewElm = root.querySelector(`[${DOM_DATA_NODE_NAME}="${node.id}"]`)
        if (!viewElm) {
            return
        }

        return viewElm.querySelector(`[${DOM_DATA_PARENT_NAME}="${node.id}"]`)
    }

    return root.querySelector(`[${DOM_DATA_NODE_NAME}="${node.id}"]`)
}

function getLayout(node: FlowLayoutNode | undefined) {
    return {
        direction: node?.data?.layout?.align?.direction ?? DIRECTION.horizontal,
        gap: node?.data?.layout?.gap ?? 0,
        padding: node?.data?.layout?.padding
    }
}

function getChildren(root: HTMLElement, node: FlowLayoutNode) {
    if (!node.children) {
        return []
    }
    return node.children.map(child => root.querySelector(`[${DOM_DATA_NODE_NAME}="${child.id}"]`)).filter(Boolean) as HTMLElement[]
}

interface DefaultGapIndicatorProps extends Props {
    data: FlowLayoutNode[]
    root: HTMLElement
    scale: number
}

function DefaultGapIndicator({ id, root, data, scale }: DefaultGapIndicatorProps) {
    const node = useMemo(() => findNodeById(id)(data), [data, id])

    const [containerRect, setContainerRect] = useState<DOMRectReadOnly | null>(null)
    useLayoutEffect(() => {
        if (!node) {
            return
        }
        const container = getContainer(root, node)
        if (!container) {
            return
        }
        const observer = new ResizeObserver(([e]) => {
            setContainerRect(e.target.getBoundingClientRect())
        })

        observer.observe(container)

        return () => {
            observer.unobserve(container)
        }
    }, [node, root])

    if (!containerRect) {
        return null
    }

    const { direction, gap, padding } = getLayout(node)

    const [pl = 0, pt = 0, pr = 0, pb = 0] = padding ?? []

    if (!node) {
        return null
    }

    const children = getChildren(root, node)

    const rootRect = root.getBoundingClientRect()

    return (
        <>
            {children.slice(0, children.length > 1 ? -1 : undefined).map(child => {
                const nodeRect = child.getBoundingClientRect()
                const nodeId = child.getAttribute('data-node-id')
                if (!nodeId) {
                    return null
                }

                return (
                    <Root
                        key={nodeId}
                        style={
                            direction === DIRECTION.horizontal
                                ? {
                                      width: gap / scale,
                                      height: (containerRect.height - pt - pb) / scale,
                                      transform: `translate3d(${(-rootRect.left + nodeRect.right) / scale}px, ${
                                          (-rootRect.top + containerRect.top + pt) / scale
                                      }px, 0)`
                                  }
                                : {
                                      width: (containerRect.width - pl - pr) / scale,
                                      height: gap / scale,
                                      transform: `translate3d(${(-rootRect.left + containerRect.left + pl) / scale}px, ${
                                          (-rootRect.top + nodeRect.bottom) / scale
                                      }px, 0)`
                                  }
                        }
                    />
                )
            })}
        </>
    )
}
