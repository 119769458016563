import type {
    BaseBreakPointConfigProtocol,
    BlockRuntimeState,
    ContainerBlockAbstract,
    // BreakPointDesignLayoutProtocol,
    // DesignLayoutProtocol,
    FilterFormType,
    FormContainerBlockAbstract,
    PageAbstract,
    Sorter,
    ViewBlockAbstract
} from '@lighthouse/core'
import { type BlockAbstract, BlockType, ChartType } from '@lighthouse/core'
import { find } from 'rambda'

import { MAIN_BREAK_POINT } from '../constants'
import type { ApplicationPreviewEnum } from '../types'

export function isCustomViewBlock(block: BlockAbstract): block is ViewBlockAbstract {
    return block.type === BlockType.view && block.config.viewType === 'custom'
}

export function isContainerBlock(block: BlockAbstract): block is ContainerBlockAbstract {
    return block.type === BlockType.container
}

export function isFormContainerBlock(block: BlockAbstract): block is FormContainerBlockAbstract {
    return block.type === BlockType.formContainer
}

export function hasChildrenBlock(block: BlockAbstract): block is ViewBlockAbstract | ContainerBlockAbstract | FormContainerBlockAbstract {
    return isCustomViewBlock(block) || isContainerBlock(block) || isFormContainerBlock(block)
}

export type PureBlockAbstract = Omit<BlockAbstract, 'children'>
/** 获取block的配置信息 */
export function getPureBlockProperty(block: BlockAbstract): PureBlockAbstract {
    if (hasChildrenBlock(block)) {
        const { children, ...rest } = block
        return rest
    }
    //
    return block
}

type GetBlockDataSourceIdResult = {
    dsId?: string
    filter?: FilterFormType
    sorts?: Sorter[]
}
export const getBlockDataSourceId = (block: BlockAbstract, pointer: string | undefined): GetBlockDataSourceIdResult => {
    switch (block.type) {
        case BlockType.chart: {
            if (block.config.chartType === ChartType.indicator) {
                return { dsId: undefined }
            }
            return { dsId: block.config.pointer, filter: block.config.ruleFilter }
        }
        case BlockType.view: {
            return { dsId: block.config.pointer, filter: block.config.filter, sorts: block.config.sorts }
        }
        case BlockType.formContainer: {
            return { dsId: block.config.pointer || pointer }
        }
        case BlockType.fieldGroup:
        case BlockType.field: {
            return { dsId: pointer }
        }
        default: {
            return { dsId: pointer }
        }
    }
}

/** 获取当前的block children */
export function getCurrentBlockChildren(block: BlockAbstract, blockRuntimeState?: BlockRuntimeState) {
    if (block.type === BlockType.container) {
        const view = block.children.find(item => item.id === blockRuntimeState?.container?.[block.id].currentView)
        if (view) {
            return view.children
        }
    }

    if (block.type === BlockType.formContainer) {
        return block.children
    }

    if (block.type === BlockType.view && block.config.viewType === 'custom') {
        return block.children
    }
}

/** 获取block children，会获取容器下的所有view的children */
export function getBlockChildren(block: BlockAbstract): BlockAbstract[] | undefined {
    if (block.type === BlockType.container) {
        return block.children.reduce<BlockAbstract[]>((total, curr) => [...total, ...curr.children], [])
    }

    if (block.type === BlockType.formContainer) {
        return block.children
    }

    if (block.type === BlockType.view && block.config.viewType === 'custom') {
        return block.children
    }
}

/** 递归查找block */
// eslint-disable-next-line etc/no-misused-generics
export function findBlockById<T extends BlockAbstract = BlockAbstract>(id: string, blocks: BlockAbstract[]): T | undefined {
    const recursion = (tree: BlockAbstract[]): undefined | T => {
        for (const block of tree) {
            if (block.id === id) {
                return block as T
            }

            const children = getBlockChildren(block)
            if (children) {
                const res = recursion(children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion(blocks)
}

export function filterBlock(blocks: BlockAbstract[], filter: (val: BlockAbstract) => boolean): PureBlockAbstract[] {
    return blocks.reduce<PureBlockAbstract[]>((prev, cur) => {
        const isFilter = filter(cur)
        if (isFilter) {
            const block = getPureBlockProperty(cur)
            prev.push(block)
        }
        const children = getBlockChildren(cur)
        if (children) {
            prev = [...prev, ...filterBlock(children, filter)]
        }
        return prev
    }, [])
}

/** 深度遍历查找父级block */
// eslint-disable-next-line etc/no-misused-generics
export function findParentBlockById<T extends BlockAbstract = BlockAbstract>(id: string, blocks: BlockAbstract[]): T | undefined {
    let parent: BlockAbstract | undefined

    const recursion = (tree: BlockAbstract[]) => {
        for (const node of tree) {
            if (parent) {
                return
            }

            if (node.id === id) {
                return true
            }

            const children = getBlockChildren(node)
            if (children) {
                const res = recursion(children)
                if (res) {
                    if (!parent) {
                        parent = node
                    }

                    return true
                }
            }
        }
    }

    recursion(blocks)

    return parent as T | undefined
}

type FindParentBlockOptions = {
    id: string
    blocks: BlockAbstract[]
    blockRuntimeState?: BlockRuntimeState
}
/** 查找父级表单容器 */
export const findParentFormBlock = ({ id, blocks, blockRuntimeState }: FindParentBlockOptions) => {
    let formBlock: FormContainerBlockAbstract | undefined

    const recursion = (tree: BlockAbstract[]) => {
        for (const node of tree) {
            if (formBlock) {
                return
            }

            if (node.id === id) {
                return true
            }

            const children = getCurrentBlockChildren(node, blockRuntimeState)

            if (children) {
                const res = recursion(children)
                if (res) {
                    if (node.type === BlockType.formContainer) {
                        formBlock = node
                        return
                    }
                    return true
                }
            }
        }
    }

    recursion(blocks)

    return formBlock
}

/** 查找父级自定义视图 */
export const findParentCustomViewBlock = ({ id, blocks, blockRuntimeState }: FindParentBlockOptions) => {
    let customViewBlock: ViewBlockAbstract | undefined

    const recursion = (tree: BlockAbstract[]) => {
        for (const node of tree) {
            if (customViewBlock) {
                return
            }

            if (node.id === id) {
                return true
            }

            const children = getCurrentBlockChildren(node, blockRuntimeState)

            if (children) {
                const res = recursion(children)
                if (res) {
                    if (node.type === BlockType.view && node.config.viewType === 'custom') {
                        customViewBlock = node
                        return
                    }
                    return true
                }
            }
        }
    }

    recursion(blocks)

    return customViewBlock
}

/** block是否包含视图组件 */
export function blockHasViewBlockChildren(block: BlockAbstract): boolean {
    const children = getBlockChildren(block)
    if (!children) {
        return false
    }

    for (const block of children) {
        if (block.type === BlockType.view) {
            return true
        }

        const res = blockHasViewBlockChildren(block)
        if (res) {
            return res
        }
    }

    return false
}

/**
 * 滚动到某个节点
 * @param id
 */
export function scroll2FlowNode(id: string) {
    requestAnimationFrame(() => {
        document
            .querySelector(`div[data-node-id="${id}"]`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    })
    // const element = document.querySelector<HTMLElement>(`div[data-node-id="${id}"]`)
    // console.log('🚀 ~ file: block.ts ~ line 285 ~ scroll2FlowNode ~ element', element)
    // if (!element) {
    //     return
    // }

    // // element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

    // const rootScroller = document.querySelector(`#${PAGE_SCROLL_CONTAINER_HOST}`)
    // const rootFallbackScroller = document.querySelector(`#${PAGE_SCROLL_PARENT_CONTENT}`)

    // let targetScrollTop = 0

    // function scrollParentToVisible(parent: HTMLElement) {
    //     if (!element) {
    //         return
    //     }

    //     const isRoot = rootScroller === parent || rootFallbackScroller === parent
    //     const rect = parent.getBoundingClientRect()
    //     const elementRect = element.getBoundingClientRect()

    //     // 判断当前父容器是否可见, 如果是根，则加上导航栏的高度
    //     if (elementRect.top < rect.top + (isRoot ? 60 : 0) || elementRect.bottom > rect.bottom) {
    //         targetScrollTop = parent.scrollTop + elementRect.top - rect.top - (isRoot ? 60 : 0)
    //         if (!isRoot) {
    //             parent.addEventListener('scroll', onScrollEnd)
    //         }

    //         // 滚动父容器使元素可见
    //         parent.scrollTo({ top: targetScrollTop, behavior: 'smooth' })

    //         return
    //     }

    //     if (isRoot) {
    //         return
    //     }

    //     // 如果父容器有父容器，递归调用
    //     const parentScroller = findParentScroller(parent.parentElement)
    //     if (parentScroller) {
    //         scrollParentToVisible(parentScroller)
    //     }
    // }

    // // 滚动结束时才递归调用滚动
    // function onScrollEnd(e: Event) {
    //     const target = e.target as HTMLElement

    //     if ((target.scrollTop = targetScrollTop)) {
    //         target.removeEventListener('scroll', onScrollEnd)

    //         // 如果父容器有父容器，递归调用
    //         const parentScroller = findParentScroller(target.parentElement)
    //         if (parentScroller) {
    //             scrollParentToVisible(parentScroller)
    //         }
    //     }
    // }

    // // 初始滚动，从元素的父容器开始
    // const parentScroller = findParentScroller(element.parentElement)
    // if (parentScroller) {
    //     scrollParentToVisible(parentScroller)
    // }
}

/**
 * 滚动到视图中指定的某个元素
 * @param viewId
 * @param index
 * @param scrollIntoViewOptions
 */
export function scroll2ViewItem(viewId: string, index: number, scrollIntoViewOptions?: ScrollIntoViewOptions) {
    requestAnimationFrame(() => {
        document
            .querySelector(`div[data-node-id="${viewId}"] div[data-view-item="${index}"]`)
            ?.scrollIntoView({ behavior: 'smooth', ...scrollIntoViewOptions })
    })
}

/**
 * 滚动到视图指定位置，左或右，到底
 * @param dom
 * @param scrollOptions
 */
export function scroll2EndLeftOrRight(dom: HTMLElement, scrollOptions?: ScrollToOptions) {
    requestAnimationFrame(() => {
        dom?.scrollTo(scrollOptions)
    })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const merge = (target: Record<string, any>, source: Record<string, any>, path: string[]) => {
    // let targetValue = target
    let sourceValue = source

    // 遍历路径
    for (const part of path) {
        // if (targetValue && typeof targetValue === 'object' && part in targetValue) {
        //     targetValue = targetValue[part]
        // } else {
        //     break
        // }
        if (sourceValue && typeof sourceValue === 'object' && part in sourceValue) {
            sourceValue = sourceValue[part]
        } else {
            break
        }
    }

    if (sourceValue !== undefined) {
        // 在目标对象中设置更新的值
        let targetRef = target
        for (let i = 0; i < path.length - 1; i++) {
            const part = path[i]
            if (!targetRef[part]) {
                targetRef[part] = {} // 创建嵌套对象
            }
            targetRef = targetRef[part]
        }
        targetRef[path[path.length - 1]] = sourceValue
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepMerge = (target: Record<string, any>, source: Record<string, any>, breakKeys: string[] = [], currentPath = '') => {
    const mergeObj = JSON.parse(JSON.stringify(target))
    const sourceObj = JSON.parse(JSON.stringify(source))
    for (const path of breakKeys) {
        const pathParts = path.split('.')
        merge(mergeObj, sourceObj, pathParts)
    }

    return mergeObj
}

export function mergeSingleBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(base: T, breakPoint: Partial<T>): T {
    const target = {
        ...base,
        breakKeys: breakPoint.breakKeys
    }
    return deepMerge(target, breakPoint, ['id', 'name', ...(breakPoint.breakKeys || [])]) as T
}

export function mergeBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(base: T, breakPoints: Partial<T>[]): T[] | undefined {
    return breakPoints?.map(breakPoint => {
        return mergeSingleBreakPointConfigure(base, breakPoint)
    })
}

export function getBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(
    previewType: ApplicationPreviewEnum,
    base: T,
    breakPoints: Partial<T>[]
) {
    if (previewType === MAIN_BREAK_POINT) {
        return base
    }
    const data = mergeBreakPointConfigure(base, breakPoints)
    const alternative = { ...base, id: previewType }
    return find(item => item.id === previewType, data || []) || alternative
}

export function getBlockWithMergedBreakPoint<T extends BlockAbstract>(previewType: ApplicationPreviewEnum, data: T) {
    if (previewType === MAIN_BREAK_POINT) {
        return data
    }
    const { breakPoint, breakPoints } = data.config
    const mergeBreakPoints = mergeBreakPointConfigure(breakPoint, breakPoints)
    const currentBreakPoint = find(item => item.id === previewType, mergeBreakPoints || [])
    return {
        ...data,
        config: {
            ...data.config,
            breakPoint: currentBreakPoint || breakPoint
        }
    }
}

export function getPageWithMergedBreakPoint<T extends PageAbstract>(previewType: ApplicationPreviewEnum, data: T) {
    if (previewType === MAIN_BREAK_POINT) {
        return data
    }
    const { breakPoint, breakPoints } = data
    const mergeBreakPoints = mergeBreakPointConfigure(breakPoint, breakPoints)
    const currentBreakPoint = find(item => item.id === previewType, mergeBreakPoints || [])
    return {
        ...data,
        breakPoint: currentBreakPoint || breakPoint
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findMissingKeysDeep = (data: any, maxLevel: number | undefined, parentKey = '', level = 1): string[] => {
    let missingKeys: string[] = []
    if (typeof data !== 'object' || Array.isArray(data)) {
        return missingKeys
    }
    if (maxLevel && maxLevel < level) {
        return [parentKey]
    }
    Object.keys(data).forEach(key => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key
        if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
            const nestedMissingKeys = findMissingKeysDeep(data[key], maxLevel, currentKey, level + 1)
            missingKeys = [...missingKeys, ...nestedMissingKeys]
        } else {
            missingKeys.push(currentKey)
        }
    })
    return missingKeys
}

// export const findMissingKeysDeep = (base: any, data: any, maxLevel: number, parentKey = '', level = 0) => {
//     let missingKeys: string[] = [];
//     if (typeof base !== 'object' || Array.isArray(base) || typeof data !== 'object' || Array.isArray(base)) {
//         return missingKeys
//     }
//     if (maxLevel < level) {
//         return missingKeys
//     }
//     // 遍历 obj1 中的所有键
//     Object.keys(base).forEach(key => {
//         const currentKey = parentKey ? `${parentKey}.${key}` : key;

//         // eslint-disable-next-line no-prototype-builtins
//         if (base.hasOwnProperty(key)) {
//             // eslint-disable-next-line no-prototype-builtins
//             if (data.hasOwnProperty(key)) {
//                 // 如果 obj1 和 obj2 的该 key 对应的值还是对象，则递归
//                 if (typeof base[key] === 'object' && base[key] !== null && !Array.isArray(base[key])) {
//                     const nestedMissingKeys = findMissingKeysDeep(base[key], data[key] || {}, maxLevel, currentKey, level + 1);
//                     missingKeys = [...missingKeys, ...nestedMissingKeys]; // 合并嵌套的缺失键
//                 }
//             } else {
//                 missingKeys.push(currentKey); // 如果 obj2 没有该 key，记录下来
//             }
//         }
//     });

//     return missingKeys;
// }
