import type { BaseBreakPointProtocol, RichTextContentProtocol } from '../../protocols'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export enum QrBarcodeEnum {
    'QR_CODE' = 'QR_CODE',
    'BARCODE' = 'BARCODE'
}

export interface QrBarcodeBlockConfig extends BaseBreakPointProtocol {
    codeType: QrBarcodeEnum
    content: {
        value: RichTextContentProtocol | undefined
    }
    barWidth?: number
}

export abstract class QrBarcodeBlockAbstract extends BaseBlock {
    type = BlockType.qrBarcode

    abstract override config: QrBarcodeBlockConfig
}
