import type { Field, TypeInstanceMap } from '@lighthouse/core'
import type { Editor } from '@tiptap/core'
import { Extension } from '@tiptap/core'
import type { JSONContent } from '@tiptap/react'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import isEqual from 'fast-deep-equal'
import { find, max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { useImmer } from 'use-immer'

import { innerTypeNameMap } from '../../../../constants'
import { getFieldIcon } from '../../../../utils'
import type { FunctionObject, MethodList } from '../constant'
import { innerTypeToMethods, methodList } from '../constant'
import { splitString } from './constants'
import { DataQuoteField } from './DataQuote'
import { FormulaDefinitions, FormulaMode } from './FormulaDefinitions'
import { FormulaFooter } from './FormulaFooter'
import * as SC from './styles'
import { interceptFormulaReverseText, interceptFormulaText, replaceText } from './utils'

interface FormulaEditorState {
    mode: FormulaMode
    selectFunction: FunctionObject | null
    functionList: MethodList[]
    range: [number, number] | []
    activeItem: FunctionObject | null
    innerType?: TypeInstanceMap
}
interface FormulaEditorProps {
    fields: Field[]
    content?: JSONContent
    fieldId: string
    onChange: (content: JSONContent) => void
    onOk?: (json?: JSONContent) => void
    onCancel?: () => void
}

export const FormulaEditor: React.FC<FormulaEditorProps> = ({ fields, content, fieldId, onChange, onOk, onCancel }) => {
    const methodRef = useRef<HTMLDivElement>(null)
    const { height } = useWindowSize()
    const [state, setState] = useImmer<FormulaEditorState>({
        mode: FormulaMode.all,
        selectFunction: null,
        functionList: methodList,
        range: [],
        activeItem: null
    })

    const { mode, functionList, range, activeItem, innerType } = state
    // const[state, ]
    const editTorMaxHeight = height - 600

    const functions = useMemo(() => {
        return functionList.reduce<Record<string, FunctionObject>>((prev, item) => {
            item.list.forEach(item => {
                prev[item.name.toLocaleLowerCase()] = item
            })
            return prev
        }, {})
    }, [functionList])

    const getFieldActiveItem: (field: Field) => FunctionObject = useCallback(field => {
        return {
            id: field.id,
            name: field.name,
            fn: field.name,
            type: field.type,
            class: 'field',
            title: field.name,
            describe: '表格的一列，返回当前行中该列对应的值，可用于公式计算',
            icon: getFieldIcon(field.id, field.type, field.innerType),
            extra: field.innerType ? `${innerTypeNameMap[field.innerType]}` : undefined
        }
    }, [])



    const scrollActiveItem = useCallback((id: string) => {
        const checkedDoms = document.querySelector(`[data-adapter='${id}']`)
        if (!checkedDoms || !methodRef.current) {
            return
        }
        const { scrollTop: scrollListTop, clientHeight } = methodRef.current
        const { y: parentY } = methodRef.current.getBoundingClientRect()

        const { y, height: itemHeight } = checkedDoms.getBoundingClientRect()
        const itemScrollTop = y - parentY + scrollListTop
        const scrollTop = itemScrollTop - clientHeight + itemHeight
        methodRef.current.scrollTo({ top: max(scrollTop, 0) })
    }, [])

    const handleHoverFunction = useCallback(
        (item: FunctionObject, isScroll = true) => {
            setState(draft => {
                draft.activeItem = item
            })
            isScroll && scrollActiveItem(item.id)
        },
        [scrollActiveItem, setState]
    )

    const handleHoverData = useCallback(
        (item: Field, isScroll = true) => {
            setState(draft => {
                draft.activeItem = getFieldActiveItem(item)
            })
            isScroll && scrollActiveItem(item.id)
        },
        [getFieldActiveItem, scrollActiveItem, setState]
    )

    const searchFunction = useCallback(
        (val: string) => {
            return functionList.reduce<MethodList[]>((prev, item) => {
                const list = item.list.filter(item => {
                    return item.name.toLocaleLowerCase().includes(val)
                })
                if (list.length > 0) {
                    prev.push({
                        ...item,
                        list
                    })
                }
                return prev
            }, [])
        },
        [functionList]
    )

    const anchorScroll = useCallback((id: string) => {
        if (!methodRef.current) {
            return
        }
        const checkedDoms = document.querySelector(`[data-adapter='${id}']`) as HTMLDivElement
        if (!checkedDoms) {
            return
        }

        const top = checkedDoms.offsetTop
        methodRef.current.scrollTop = top
    }, [])

    const noNodeBeforeCase = useCallback(() => {
        setState(draft => {
            draft.mode = FormulaMode.all
            draft.selectFunction = null
            draft.functionList = methodList
            draft.range = []
            if (fields.length > 0) {
                draft.activeItem = getFieldActiveItem(fields[0])
            }
        })
    }, [fields, getFieldActiveItem, setState])

    const nodeAfterDotCase = useCallback(() => {
        const functionList = methodList.filter(item => {
            if (!innerType) {
                return true
            }
            const functionTypeList = innerTypeToMethods[innerType]
            return functionTypeList.includes(item.type)
        })
        setState(draft => {
            draft.mode = FormulaMode.function
            draft.range = []
            draft.innerType = undefined
            draft.functionList = functionList
            draft.activeItem = functionList[0].list[0]
        })
    }, [innerType, setState])

    const cursorBeforeRightParenthesisCase = useCallback(() => {
        setState(draft => {
            draft.mode = FormulaMode.none
            draft.innerType = undefined
            draft.range = []
            draft.activeItem = null
        })
    }, [setState])

    const cursorInParenthesisCase = useCallback(() => {
        setState(draft => {
            draft.mode = FormulaMode.all
            draft.innerType = undefined
            draft.functionList = methodList
            draft.range = []
            if (fields.length > 0) {
                draft.activeItem = getFieldActiveItem(fields[0])
            }
        })
    }, [fields, getFieldActiveItem, setState])

    const cursorBeforeNodeCase = useCallback(() => {
        setState(draft => {
            draft.mode = FormulaMode.all
            draft.range = []
            if (fields.length > 0) {
                draft.activeItem = getFieldActiveItem(fields[0])
            }
        })
    }, [fields, getFieldActiveItem, setState])

    const otherCase = useCallback(() => {
        setState(draft => {
            draft.mode = FormulaMode.all
            draft.range = []
            if (fields.length > 0) {
                const { id, name, type, innerType } = fields[0]
                draft.activeItem = getFieldActiveItem(fields[0])
            }
        })
    }, [fields, getFieldActiveItem, setState])

    const editor = useEditor(
        {
            autofocus: false,
            enableInputRules: true,
            enablePasteRules: true,
            extensions: [
                Extension.create({
                    addKeyboardShortcuts() {
                        return {
                            ArrowUp: e => {
                                return true
                            },
                            ArrowDown: () => {
                                return true
                            }
                        }
                    }
                }),
                StarterKit.configure({
                    // history: true,
                    heading: false,
                    bulletList: false,
                    orderedList: false,
                    blockquote: false,
                    codeBlock: false,
                    horizontalRule: false
                }),
                DataQuoteField.configure({
                    HTMLAttributes: {
                        class: 'field'
                    }
                })
                // DataQuoteDatasource.configure({
                //     HTMLAttributes: {
                //         class: 'dataSource'
                //     }
                // })
            ],
            // onCreate({editor}){

            // },
            onUpdate({ editor, transaction }) {
                const json = editor.getJSON()
                onChange(json)
            },
            onSelectionUpdate({ editor }) {
                const {
                    view: {
                        state: { selection }
                    }
                } = editor
                const { $anchor, node } = selection as Editor['view']['state']['selection'] & { node: { attrs: { id: string } } }
                const { nodeBefore, nodeAfter } = $anchor
                if (nodeBefore && nodeBefore.attrs.id) {
                    const nodeId = nodeBefore.attrs.id
                    const field = find(item => item.id === nodeId, fields)
                    if (field) {
                        setState(draft => {
                            draft.mode = FormulaMode.all
                            draft.innerType = field.innerType
                            draft.activeItem = getFieldActiveItem(field)
                            draft.functionList = methodList
                            draft.range = []
                        })
                        // requestAnimationFrame(() => {
                        //     anchorScroll(nodeId)
                        // })
                        return
                    }
                }

                // 光标前为空：
                if (!nodeBefore) {
                    noNodeBeforeCase()
                    return
                }
                const { isText: beforeIsText, text: beforeText } = nodeBefore

                // node后输入.:
                if (beforeIsText && (beforeText === '.' || beforeText?.slice(-2, beforeText.length) === ').')) {
                    nodeAfterDotCase()
                    return
                }

                // 光标前是 ):
                if (beforeIsText && beforeText?.[beforeText.length - 1] === ')') {
                    cursorBeforeRightParenthesisCase()
                    return
                }

                if (nodeBefore && beforeIsText && beforeText) {
                    const text = replaceText(beforeText)

                    // 匹配方法名
                    const nodeBeforeText = [...text].reverse().join('')
                    const prevText = interceptFormulaReverseText(
                        nodeBeforeText,
                        new Set([...splitString, '(', '.', ')'])
                    ).toLocaleLowerCase()
                    if (nodeAfter && nodeAfter.isText && nodeAfter.text) {
                        const nodeAfterText = nodeAfter.text
                        const nextText = interceptFormulaText(nodeAfterText, new Set([...splitString, '(', '.', ')'])).toLocaleLowerCase()
                        const matchText = prevText + nextText
                        const func = functions?.[matchText]
                        if (func) {
                            const functionId = func.id
                            const method = methodList.find(item => item.type === func.type)
                            if(!method){
                                return
                            }
                            setState(draft => {
                                // draft.mode = FormulaMode.function
                                draft.mode = FormulaMode.function
                                draft.activeItem = functions[matchText]
                                draft.functionList = methodList
                                draft.functionList = [{
                                    type: method.type,
                                    name: method.name,
                                    list: [func]
                                }]
                                draft.range = []
                                // draft.insertIndex = distance
                            })
                            // window.location.href = `#${functions[matchText].name}`
                            // requestAnimationFrame(() => {
                            anchorScroll(functionId)
                            // })
                            return
                        }
                    }

                    if (!prevText) {
                        cursorBeforeNodeCase()
                        return
                    }

                    // 当在括号中时
                    if (prevText === '(') {
                        cursorInParenthesisCase()
                        return
                    }
                    // 当直接匹配到方法名时
                    if (functions?.[prevText]) {
                        const functionId = functions[prevText].id
                        setState(draft => {
                            draft.mode = FormulaMode.all
                            draft.activeItem = functions[prevText]
                            draft.functionList = methodList
                            draft.range = []
                        })
                        anchorScroll(functionId)
                        return
                    }

                    const newFunctionList = searchFunction(prevText)
                    if (newFunctionList.length > 0 && prevText !== '()') {
                        const nodeAfterText = nodeAfter?.text || ''
                        // const nextText = interceptFormulaText(nodeAfterText, new Set([')']))
                        setState(draft => {
                            draft.mode = FormulaMode.function
                            draft.functionList = newFunctionList
                            // draft.range = [prevText.length, nextText.length]
                            draft.range = []
                            draft.activeItem = newFunctionList[0].list[0]
                        })
                        return
                    }
                }

                if (!beforeIsText) {
                    cursorBeforeNodeCase()
                    return
                }
                otherCase()
            }
        },
        []
    )

    useEffect(() => {
        if (!editor || editor.isFocused) {
            return
        }
        const prevSelection = editor.state.selection
        const prevContent = editor.getJSON()
        if (!content || !prevContent || isEqual(prevContent, content)) {
            return
        }
        const editorContent = content.content
        if (editorContent) {
            editor.chain().setContent(editorContent, false).setTextSelection(prevSelection).focus('end').run()
        }
    }, [content, editor])

    return (
        <SC.Container maxHeight={editTorMaxHeight}>
            <EditorContent className="formula-editor" editor={editor} />
            <FormulaDefinitions
                ref={methodRef}
                fields={fields}
                mode={mode}
                functionList={functionList}
                fieldId={fieldId}
                activeItem={activeItem}
                editor={editor}
                onHoverFunction={handleHoverFunction}
                onHoverData={handleHoverData}
            />
            <FormulaFooter value={content} onOk={onOk} onCancel={onCancel} />
        </SC.Container>
    )
}
